export enum CalculationQuestionTypeEnum {
  VISITOR_COUNT = 'VISITOR_COUNT',
  WEB_TYPE = 'WEB_TYPE',
  PAGE_COUNT = 'PAGE_COUNT',
  FOCUS = 'FOCUS',
  THIRD_PARTIES = 'THIRD_PARTIES',
}

export const calculationQuestionOrder: CalculationQuestionTypeEnum[] = [
  CalculationQuestionTypeEnum.VISITOR_COUNT,
  CalculationQuestionTypeEnum.WEB_TYPE,
  CalculationQuestionTypeEnum.PAGE_COUNT,
  CalculationQuestionTypeEnum.FOCUS,
  CalculationQuestionTypeEnum.THIRD_PARTIES,
];

export interface CalculationAnswer {
  text: string;
  value: number;
  // DEADCODE Those two below
  resultHeading: string;
  resultDescription: string;
}

interface CalculationQuestion {
  question: string;
  answers: CalculationAnswer[];
}

export const CALCULATION_CONTENT: {
  [key in CalculationQuestionTypeEnum]: CalculationQuestion;
} = {
  [CalculationQuestionTypeEnum.VISITOR_COUNT]: {
    question: 'Kolik návštěvníků týdně očekáváte?',
    answers: [
      {
        text: 'Desítky až tisíce',
        value: 1,
        resultHeading: 'Budou vás navštěvovat desítky až tisíce návštěvníků týdně',
        resultDescription: `
          S takovým množstvím není nejmenší problém. Nejenže ušetříme na kapacitách serverů,
          ale ani nemusíme každý detail přehnaně výkonostně optimalizovat, nemusíme se omezovat
          s využíváním knihoven / kódu třetích strach, riziko krátkého výpadku nepředstavuje
          takový problém, jako když se traffic počítá v milionech - a to vše umožňuje obecně
          levnější vývoj.
        `,
      },
      {
        text: 'Tisíce až desetitisíce',
        value: 1.5,
        resultHeading: 'Budou vás navštěvovat tisíce až desetitisíce návštěvníků týdně',
        resultDescription: `
          To už je slušná návštěvnost a pojí se k ní jisté náklady. Příkladem by byla optimalizace
          výkonostně náročných procesů, zvládání rizik spojených s výpadky a chybami a v neposlední
          řadě vyšší kapacita serverů.
        `,
      },
      {
        text: 'Více',
        value: 2,
        resultHeading: 'Budou vás navštěvovat více než desetitisíce návštěvníků týdně',
        resultDescription: `
          Pečlivý výběr technologií, knihoven, hostingových služeb, optimalizace výkonostně
          náročných procesů, zajištění rizik plynoucích z výpadků a chyb a obecně náklady na provoz
          jsou jen některé z věcí, které zvyšují cenu takto rozsáhlého projektu. Je s tím potřeba
          počítat.
        `,
      },
    ],
  },
  [CalculationQuestionTypeEnum.WEB_TYPE]: {
    question: 'Co je váš projekt?',
    answers: [
      {
        text: 'E-shop',
        value: 25000,
        resultHeading: 'Chcete e-shop',
        resultDescription: `
          Cenově někde mezi prezentacemi a custom aplikacemi jsou právě e-shopy. Někdy je možné
          využít existující řešení a jen si ho upravit, jindy je lepší vytvořit celý e-shop
          od základů. Vybereme tu nejlepší cestu pro vás a vaše zákazníky.
        `,
      },
      {
        text: 'Prezentace',
        value: 15000,
        resultHeading: 'Chcete prezentaci',
        resultDescription: `
          Prezentace zpravidla bývají mezi těmy levnějšími a jednoduššími projekty. Samozřejmě není
          prezentace, jako prezentace. Můžeme udělat webovou vizitku s obrázkem a kontakem, stejně
          tak několikastránkové představení s promyšlenou strukturou.
        `,
      },
      {
        text: 'Něco složitějšího',
        value: 30000,
        resultHeading: 'Chcete složitější projekt',
        resultDescription: `
          Custom aplikace, API, interní systém nebo cokoli jiného - není problém. Vývoj takových
          projektů je samozřejmě nákladnější a složitější, než postavení webové vizitky,
          ale máme zkušenosti i s takovými projekty a jsme připravení se do toho pustit.
        `,
      },
    ],
  },
  [CalculationQuestionTypeEnum.PAGE_COUNT]: {
    question: 'Kolik stránek bude projekt obsahovat?',
    answers: [
      {
        text: 'Jednu',
        value: 1,
        resultHeading: 'Váš projekt bude mít pouze jednu stránku',
        resultDescription: `
          Tady není moc co psát. Čím více stránek / sekcí, tím vyšší cena. Pro některé byznysy je
          jednostránkovka to nejideálnější řešení hned z několika důvodů - jednoduchost a rychlost
          vývoje, nízké náklady a všechny informace přehledně na jednom místě.
        `,
      },
      {
        text: 'Jednotky',
        value: 1.5,
        resultHeading: 'Váš projekt bude mít jednotky stránek',
        resultDescription: `
          Klasický vícestránkový web je často ideální řešení. Projdeme s vámi, co by měla každá
          stránka obsahovat, jaký je její účel a vymyslíme jak stránky vzájemně propojit. Cenově
          není projekt s pár stránkami příliš náročný.
        `,
      },
      {
        text: 'Desítky a více',
        value: 2,
        resultHeading: 'Váš projekt bude mít desítky a více stránek',
        resultDescription: `
          Je otázka, jestli bude každá stránka unikátně vytvořená, nebo se budou stránky generovat
          ze zadaných dat (například nějaký blog), až podle toho můžeme určit přesnější cenu.
        `,
      },
    ],
  },
  [CalculationQuestionTypeEnum.FOCUS]: {
    question: 'Na co dát důraz při tvorbě projektu?',
    answers: [
      {
        text: 'Jednoduchost, rychlost',
        value: 1,
        resultHeading: 'Budeme se soustředit na jednoduchost a rychlost',
        resultDescription: `
          Nepotřebujete žádné extra originální stránky, takže budeme moct využít např.
          šablony nebo veřejně dostupné designové prvky. Výrazně to urychlí a zlevní vývoj
          a ač mezi konkurencí tak moc nevyniknete vizuálně, dostanete dobrý poměr cena/výkon.
        `,
      },
      {
        text: 'Brand, originalita',
        value: 1.5,
        resultHeading: 'Budeme se soustředit na branding a originalitu',
        resultDescription: `
          Web bude mít osobitý vzhled a bude vzbuzovat pocity. Vyniknete před konkurencí nejen
          vaším byznys plánem, ale i vizuální stránkou komunikující vaše sebevědomí a poslání.
        `,
      },
      {
        text: 'Uměleckost, neotřelost',
        value: 2,
        resultHeading: 'Budeme se soustředit na uměleckost a neotřelost provedení',
        resultDescription: `
          Kdo k vám na web dojde na něj už nikdy nezapomene. Uděláte dojem, představíte svou
          kreativní tvář a na internetu bude o další umělecké dílo více. Ideální pro projekty
          připravené experimentovat, pro pozvánky na eventy, umělecké projekty a kohokoli,
          kdo v tom vidí hodnotu.
        `,
      },
    ],
  },
  [CalculationQuestionTypeEnum.THIRD_PARTIES]: {
    question:
      'Bude projekt využívat propojení s třetími stranami (např. platební brána, účet, socky...)?',
    answers: [
      {
        text: 'Ne / nevím',
        value: 0,
        resultHeading: 'Neplánujete napojení na třetí strany',
        resultDescription: `
          Dobře pro vaši peněženku. Ono taky hodně záleží, s jakou třetí stranou by se komunikovalo.
          Občas je napojení rychlé a bezproblémové, jindy je potřeba se napojit na nějaký zmatený
          systém, který sám pořádně nefunguje, a na tom se pak spálí spousta času.
        `,
      },
      {
        text: 'Ano, s několika',
        value: 5000,
        resultHeading: 'Web bude komunikovat s několika třetími stranami',
        resultDescription: `
          Napojit nějakou třetí stranu je potřeba poměrně často. Občas se na tom spálí hodně času,
          jindy méně. Zálěží, s jakým systémem komunikace probíhá.
        `,
      },
      {
        text: 'Ano, s větším počtem',
        value: 15000,
        resultHeading: 'Web bude komunikovat s větším počtem třetích stran',
        resultDescription: `
          Napojíme, na co bude potřeba. Občas to zabere delší dobu, občas to je rychle, ale nějak
          to jde vždycky.
        `,
      },
    ],
  },
};
