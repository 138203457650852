import { FC } from 'react';
import { Helmet } from 'react-helmet';

interface HelmetWrapperProps {
  title: string;
  description: string;
  path: string;
  robots?: boolean;
}

export const HelmetWrapper: FC<HelmetWrapperProps> = ({
  title,
  description,
  robots = true,
  path,
}) => {
  return (
    <Helmet>
      {/* GENERAL */}
      <title>{title} | AmnioM Web</title>
      <meta name="description" content={description} />
      {!robots && <meta name="robots" content="noindex nofollow" />}
      <link rel="canonical" href={`https://amniomweb.cz/${path}`} />
      {/* SOCIALS */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="twitter:image:alt" content={description} />
    </Helmet>
  );
};
