import { motion } from 'framer-motion';
import Square from '../../animations/Square/Square';

import './Contact.scss';

import { Heading1 } from '../../UI/typography/Heading1/Heading1';
import Background from '../../assets/img/backgroundLines.svg';
import { Form } from './Form/Form';

import { pageVariants } from '../../constants/framer-motion-variants';
import { FC, useState } from 'react';
import { HelmetWrapper } from '../common/HelmetWrapper/HelmetWrapper';

export const CONTACT_PATH = 'contact';

export const Contact: FC = () => {
  const [isBlurred, setIsBlurred] = useState(false);

  const setBlur = (value: boolean) => {
    setIsBlurred(value);
  };

  return (
    <motion.div
      className="contact"
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <HelmetWrapper
        title="Klik a uděláme trik"
        description={`
          Jste ready dostat epickej web? Napište, zavolejte, dáme meet a uvidíme,
          co pro vás můžeme udělat. Jen po nás nechtějte nic moc basic :D
        `}
        path={CONTACT_PATH}
      />
      <Square
        config={{ cols: 6, rows: 6, estimatedDuration: 5 }}
        className="contact__square contact__square--1"
      ></Square>
      <Square
        config={{ cols: 6, rows: 6, estimatedDuration: 6 }}
        className="contact__square contact__square--2"
      ></Square>
      <Square
        config={{ cols: 6, rows: 6, estimatedDuration: 7 }}
        className="contact__square contact__square--3"
      ></Square>
      <div className="contact__our" style={{ filter: isBlurred ? 'blur(3px)' : 'none' }}>
        <div className="contact__our__heads">
          <p className="contact__our__heads__header">EMAIL</p>
          <p className="contact__our__heads__header">TELEFON</p>
        </div>
        <div className="contact__our__texts">
          <a href="mailto:team@amniomweb.cz" className="contact__our__texts__text">
            team@amniomweb.cz
          </a>
          <a href="tel:00420735044114" className="contact__our__texts__text">
            +420 735 044 114
          </a>
        </div>
      </div>
      <div className="contact__content">
        <Heading1 style={{ filter: isBlurred ? 'blur(3px)' : 'none' }}>KONTAKT</Heading1>
        <img
          alt="Kontakt pozadí"
          src={Background}
          className="contact__content__background"
          style={{ filter: isBlurred ? 'blur(3px)' : 'none' }}
        />
        <Form setBlur={setBlur} />
        <a
          href="/gdpr"
          target="_blank"
          className="contact__content__secure"
          style={{ filter: isBlurred ? 'blur(3px)' : 'none' }}
        >
          Ochrana osobních údajů
        </a>
      </div>
    </motion.div>
  );
};
