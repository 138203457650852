import { FC, MouseEventHandler, useState } from 'react';
import { createPortal } from 'react-dom';
import classes from './Popup.module.scss';

const modalRoot = document.querySelector('#modal-root') as Element;

interface PopupProps {
  className?: string;
  onClose: () => any;
}

export const Popup: FC<PopupProps> = ({ children, className, onClose }) => {
  const [isMouseDown, setIsMouseDown] = useState(false);

  const onDismissMouseDown = () => {
    setIsMouseDown(true);
  };

  const onDismissMouseUp = () => {
    if (isMouseDown) onClose();
  };

  const stopPropagation: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  const popup = (
    <div className={classes.wrapper} onMouseDown={onDismissMouseDown} onMouseUp={onDismissMouseUp}>
      <div
        className={`${classes.popup} ${className}`}
        onMouseUp={stopPropagation}
        onMouseDown={stopPropagation}
      >
        {children}
      </div>
    </div>
  );

  return createPortal(popup, modalRoot);
};
