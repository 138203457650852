import { FC, LegacyRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Review } from './Review/Review';
import { ReviewEnum, REVIEW_CONTENT } from '../../../content/review.content';

interface ReviewBoxReviewsProps {
  _ref: LegacyRef<HTMLDivElement>;
  isActive: (orderNumber: number) => boolean;
  reviewOrder: ReviewEnum[];
}

const generateReviews = (
  reviewOrder: ReviewEnum[],
  mq_under450: boolean,
  isActive: (orderNumber: number) => boolean,
) => {
  return reviewOrder.map((reviewKey, i) => {
    const review = REVIEW_CONTENT.reviews[reviewKey];

    return (
      <Review
        key={`review_${i}`}
        src={review.customerPhoto}
        alt={review.customer}
        active={isActive(i)}
      >
        {mq_under450 ? review.feedback.short : review.feedback.long}
      </Review>
    );
  });
};

const ReviewBoxReviews: FC<ReviewBoxReviewsProps> = ({ _ref, isActive, reviewOrder }) => {
  const mq_under450 = useMediaQuery({ maxWidth: '450px' });

  return (
    <>
      <div className="review-box__reviews" ref={_ref} data-testid="review-box-reviews">
        {generateReviews(reviewOrder, mq_under450, isActive)}
      </div>
      <div className="review-box__gradient review-box__gradient--top"></div>
      <div className="review-box__gradient review-box__gradient--bot"></div>
    </>
  );
};

ReviewBoxReviews.displayName = ReviewBoxReviews.name;

export { ReviewBoxReviews };
