import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { pageVariants } from '../../../constants/framer-motion-variants';
import { CTA } from '../../../UI/CTA/CTA';
import { Heading3 } from '../../../UI/typography/Heading3/Heading3';
import { Paragraph } from '../../../UI/typography/Paragraph/Paragraph';
import { EventNameEnum, recordEvent } from '../../../utils/recordEvent';
import { SITESAPPS_PATH } from '../../SitesApps/SitesApps';
import { Answers } from '../Calculation';
import classes from './CalculationResult.module.scss';
import { SendAnswersPopup } from './SendAnswersPopup/SendAnswersPopup';
import { getCalculation } from './utils';

interface CalculationResultProps {
  answers: Answers;
}

export const CalculationResult: FC<CalculationResultProps> = ({ answers }) => {
  const [calculatedResult, setCalculatedResult] = useState<number | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);

  useEffect(() => {
    setCalculatedResult(getCalculation(answers));
  }, [answers]);

  useEffect(() => {
    recordEvent(EventNameEnum.questionare_show_results);
  }, []);

  // DEADCODE
  // const generateReport = () => {
  //   return calculationQuestionOrder.map((questionType) => {
  //     const answer = answers[questionType] as CalculationAnswer;
  //     return (
  //       <div
  //         className={classes.calculationResult__block}
  //         key={`${questionType}_${answer.value}`}
  //        >
  //         <Heading3 className={classes.calculationResult__heading} color="default">
  //           {answer.resultHeading}
  //         </Heading3>
  //         <Paragraph weight="light">{answer.resultDescription}</Paragraph>
  //       </div>
  //     );
  //   });
  // };

  const onSubmitIntent = () => {
    recordEvent(EventNameEnum.open_send_answers_popup);
    setIsPopupOpen(true);
  };

  const onPopupClose = () => {
    recordEvent(EventNameEnum.close_send_answers_popup);
    setIsPopupOpen(false);
  };

  const onMailSent = () => {
    recordEvent(EventNameEnum.submit_answers);
    setIsMailSent(true);
    setIsPopupOpen(false);
  };

  return (
    <motion.div
      className={classes.calculationResult}
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className={classes.wrapper}>
        {/* DEADCODE */}
        {/* {generateReport()} */}
        <div className={classes.calculationResult__block}>
          <Heading3 className={classes.calculationResult__heading}>Výsledek</Heading3>
          <Paragraph weight="light">
            Náš odhad pro váš projekt začíná na {calculatedResult}Kč. Samozřejmě je to velmi
            orientační, nakonec se můžeme domluvit na úplně jiné ceně. Občas volíme i jinou formu
            vyrovnání se, všechno záleží na domluvě. Tlačítko dole nám automaticky pošle vaše
            odpovědi. Můžete přidat i další poznámky a dáme vám vědět o možnostech přímo pro vás
            &#128640;
          </Paragraph>
          {isPopupOpen && (
            <SendAnswersPopup
              onClose={onPopupClose}
              onSent={onMailSent}
              answers={answers}
              calculatedResult={calculatedResult}
            />
          )}
        </div>
        <Heading3 className={classes.calculationResult__headingCta}>Ozveme se co nejdřív</Heading3>
        <div className={classes.calculationResult__block}>
          {!isMailSent ? (
            <CTA
              type="main"
              onClick={onSubmitIntent}
              className={`${classes.calculationResult__cta} ${
                isPopupOpen ? classes.calculationResult__cta_invisible : ''
              }`}
            >
              Odeslat odpovědi
            </CTA>
          ) : (
            <CTA type="main" path={`/${SITESAPPS_PATH}`} className={classes.calculationResult__cta}>
              Váš projekt
            </CTA>
          )}
        </div>
      </div>
    </motion.div>
  );
};
