import { FC } from 'react';
import classes from './Paragraph.module.scss';

interface ParagraphProps {
  className?: string;
  weight?: 'light' | 'regular';
}

export const Paragraph: FC<ParagraphProps> = ({ children, className, weight = 'regular' }) => {
  const classNames = `${classes.paragraph} ${className ? className : ''}`;

  return (
    <p
      className={classNames}
      style={{
        fontWeight: weight === 'light' ? 200 : 'regular',
      }}
    >
      {children}
    </p>
  );
};
