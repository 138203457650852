import { FC } from 'react';
import './Heading2.scss';

interface Heading2Props {
  className?: string;
}

export const Heading2: FC<Heading2Props> = ({ children, className }) => {
  return <h2 className={'heading-2' + (className ? ' ' + className : '')}>{children}</h2>;
};
