import { useEffect, useState, useRef, FC } from 'react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

import './Home.scss';

import { Heading1 } from '../../UI/typography/Heading1/Heading1';
import { Heading2 } from '../../UI/typography/Heading2/Heading2';
import { CTA } from '../../UI/CTA/CTA';
import { Section } from '../../UI/Section/Section';
import { ReviewBox } from '../common/ReviewBox/ReviewBox';
import { Planet } from './Planet/Planet';
import { Rocket } from '../../UI/SVG/Rocket/Rocket';
import { MainText } from './MainText/MainText';
import { Gradients } from '../../animations/gradients';
import Square from '../../animations/Square/Square';

import { pageVariants } from '../../constants/framer-motion-variants';
import { HelmetWrapper } from '../common/HelmetWrapper/HelmetWrapper';
import { SITESAPPS_PATH } from '../SitesApps/SitesApps';
import { PORTFOLIO_PATH } from '../Portfolio/Portfolio';
import { RGB_BLACK, RGB_SECONDARY, RGB_WHITE } from '../../constants/colors';
import { IS_AGENT_REACT_SNAP } from '../../constants/environment';

export const HOME_PATH = '';

export const Home: FC = () => {
  const mq_under450 = useMediaQuery({ maxWidth: 450 });

  const [animateRocket, setAnimateRocket] = useState(false);
  const rocketRef = useRef<HTMLElement>(null);
  const headerGradientCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const rocketElement = rocketRef.current;

    const windowScrollHandler = () => {
      if (rocketElement && rocketElement.getBoundingClientRect().top < 400) {
        setAnimateRocket(true);
      }
    };
    window.addEventListener('scroll', windowScrollHandler);

    const canvasElement = headerGradientCanvasRef.current;
    if (!canvasElement) return;

    let gradients: Gradients | null = new Gradients(canvasElement, {
      colors: [RGB_WHITE, RGB_BLACK, RGB_SECONDARY, RGB_BLACK],
      minRadius: 400,
      maxRadius: 900,
      count: 7,
    });

    return () => {
      window.removeEventListener('scroll', windowScrollHandler);
      gradients?.end();
      gradients = null;
    };
  }, [rocketRef, headerGradientCanvasRef]);

  const mainText = mq_under450 ? null : <MainText />;

  return (
    <motion.div
      className="home"
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <HelmetWrapper
        title="Váš projekt, váš styl, váš web"
        description={`
          Je spousta šikovných programátorů, ale ne každý si dá záležet na tom,
          na čem záleží vám. Unikátní projekt, unikátní cílovka, nezapomenutelný zážitek.
          Podržte nám kafe a nechte nás čarovat.
        `}
        path={HOME_PATH}
      />
      {!IS_AGENT_REACT_SNAP && <Planet></Planet>}
      {!IS_AGENT_REACT_SNAP && mainText}
      <canvas ref={headerGradientCanvasRef} className="home__header__gradient"></canvas>
      <section className="home__header" id="header">
        <div className="home__heading">
          <Heading1>Představíme vás světu</Heading1>
        </div>
        <div className="home__links">
          <CTA type="main" path={`/${SITESAPPS_PATH}`} data-testid="home-hero-cta">
            Váš projekt
          </CTA>
        </div>
      </section>
      <Section
        className={`home__rocket ${animateRocket ? 'home__rocket--animate' : null}`}
        _ref={rocketRef}
      >
        <Square config={{ estimatedDuration: 2 }} className="home__square home__square--1"></Square>
        <Square config={{ estimatedDuration: 7 }} className="home__square home__square--2"></Square>
        <Heading2>Raketový start</Heading2>
        <Rocket className="home__rocket__img" />
        <div className="home__rocket__point home__rocket__point--1">Odvážný design</div>
        <div className="home__rocket__point home__rocket__point--2">Máme drive</div>
        <div className="home__rocket__point home__rocket__point--3">
          Animace {'&'} Interaktivita
        </div>
        <div className="home__rocket__point home__rocket__point--4">Každý výtvor je unikát</div>
      </Section>
      <Section className={'home__reviews-section'} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Heading2 className="home__reviews__heading-2">Vyletěli s námi</Heading2>
        <ReviewBox />
        <div className="home__reviews__cta">
          <CTA type="primary" path={`/${PORTFOLIO_PATH}`}>
            Portfolio
          </CTA>
        </div>
      </Section>
    </motion.div>
  );
};
