/* istanbul ignore file */
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { AppWrapper } from './AppWrapper';

const rootElement = document.getElementById('root') as HTMLDivElement;
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<AppWrapper />, rootElement);
} else {
  ReactDOM.render(<AppWrapper />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
