export type FormStatus = 'loading' | 'error' | 'success';

export enum MiddleTopBottomEnum {
  MIDDLE,
  TOP,
  BOTTOM,
}

export enum PrevNextEnum {
  PREV,
  NEXT,
}
