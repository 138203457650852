import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

import './SitesApps.scss';

import { Heading1 } from '../../UI/typography/Heading1/Heading1';
import { Heading2 } from '../../UI/typography/Heading2/Heading2';
import { Heading3 } from '../../UI/typography/Heading3/Heading3';
import { Section } from '../../UI/Section/Section';
import { IconBox } from './IconBox/IconBox';
import { CTA } from '../../UI/CTA/CTA';
import Square from '../../animations/Square/Square';

import { IconGA } from '../../UI/SVG/IconGA/IconGA';
import { IconSEO } from '../../UI/SVG/IconSEO/IconSEO';
import { IconUpkeep } from '../../UI/SVG/IconUpkeep/IconUpkeep';
import { IconFunnel } from '../../UI/SVG/IconFunnel/IconFunnel';
import { Icon90 } from '../../UI/SVG/Icon90/Icon90';
import { IconSecurity } from '../../UI/SVG/IconSecurity/IconSecurity';
import { IconBranding } from '../../UI/SVG/IconBranding/IconBranding';
import { IconLogo } from '../../UI/SVG/IconLogo/IconLogo';
import { IconEshop } from '../../UI/SVG/IconEshop/IconEshop';
import { IconSites } from '../../UI/SVG/IconSites/IconSites';
import { IconPWA } from '../../UI/SVG/IconPWA/IconPWA';

import { ScrollTriggerer } from '../../animations/ScrollTriggerer';
import { pageVariants } from '../../constants/framer-motion-variants';
import { HelmetWrapper } from '../common/HelmetWrapper/HelmetWrapper';
import { CONTACT_PATH } from '../Contact/Contact';

export const SITESAPPS_PATH = 'sites-apps';

export const SitesApps = () => {
  const mq_1200 = useMediaQuery({ maxWidth: 1200 });
  const mq_mobile = useMediaQuery({ maxWidth: 900 });
  const mq_600 = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const config = { hiddenClassName: 'sites-apps__trigger' };
    const scrollTriggerer = new ScrollTriggerer(config);

    return () => {
      scrollTriggerer.remove();
    };
  }, []);

  return (
    <motion.div
      className="sites-apps"
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <HelmetWrapper
        title="Vaše unikátní weby a aplikace"
        description={`
          A k tomu všemu několik přidružených služeb, skoro až all-inclusive. Prostě se o vás
          postaráme od návrhu až po estetickou úpravu po dekádě úspěšných obchodů.
        `}
        path={SITESAPPS_PATH}
      />
      <div className="sites-apps__heading">
        <Heading1 type="secondary">Weby a aplikace</Heading1>
      </div>
      <Section
        className={mq_mobile ? 'sites-apps__mobile-section' : ''}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="sites-apps__icons">
          <IconBox id={0} description="E-shopy">
            <IconEshop className="icon-box__svg sites-apps__trigger" />
          </IconBox>
          <IconBox id={1} description="Webové prezentace">
            <IconSites className="icon-box__svg icon__sites--1 sites-apps__trigger" />
          </IconBox>
          <IconBox id={2} description="Webové aplikace a PWA">
            <IconPWA className="icon-box__svg icon__sites--2 sites-apps__trigger" />
          </IconBox>
        </div>
        <Square
          config={{ cols: 4, rows: 4, estimatedDuration: 2 }}
          className={'sites-apps__Square sites-apps__Square--2'}
        ></Square>
      </Section>
      <Section className={mq_mobile ? 'sites-apps__mobile-section' : ''}>
        <Heading2>Každý si v ceně zaslouží</Heading2>
        <div className="sites-apps__icons sites-apps__icons--free">
          <IconBox id={3} description="Údržbu" style={{ gridArea: 'upkeep' }}>
            <IconUpkeep className="icon-box__svg sites-apps__trigger" />
          </IconBox>
          <IconBox id={4} description="Google Analytics" style={{ gridArea: 'ga' }}>
            <IconGA className="icon-box__svg sites-apps__trigger" />
          </IconBox>
          <IconBox id={5} description="Optimalizaci vyhledávání" style={{ gridArea: 'seo' }}>
            <IconSEO className="icon-box__svg sites-apps__trigger" />
          </IconBox>
        </div>
        <Square
          config={
            mq_600
              ? { cols: 6, rows: 6, estimatedDuration: 5 }
              : { cols: 12, rows: 12, estimatedDuration: 5 }
          }
          className={'sites-apps__Square sites-apps__Square--1'}
        ></Square>
      </Section>
      <Section className={mq_mobile ? 'sites-apps__mobile-section' : ''}>
        <Square
          config={
            mq_600
              ? { cols: 4, rows: 4, estimatedDuration: 2 }
              : { cols: 10, rows: 10, estimatedDuration: 2 }
          }
          className={'sites-apps__Square sites-apps__Square--3'}
        ></Square>
        <Square
          config={{ cols: 10, rows: 10, estimatedDuration: 2 }}
          className={'sites-apps__Square sites-apps__Square--4'}
        ></Square>
        <Square
          config={{ cols: 6, rows: 6, estimatedDuration: 2 }}
          className={'sites-apps__Square sites-apps__Square--5'}
        ></Square>
        <Square
          config={
            mq_1200
              ? { cols: 6, rows: 6, estimatedDuration: 10 }
              : { cols: 12, rows: 12, estimatedDuration: 5 }
          }
          className={'sites-apps__Square sites-apps__Square--6'}
        ></Square>
        <Heading2>Garantujeme</Heading2>
        <div className="sites-apps__icons">
          <IconBox id={6} description="Hodnocení lighthouse">
            <Icon90 className="icon-box__svg sites-apps__trigger" />
          </IconBox>
          <IconBox id={7} description="Profesionální zabezpeční">
            <IconSecurity className="icon-box__svg sites-apps__trigger" />
          </IconBox>
          <IconBox id={8} description="Promyšlený design">
            <IconFunnel className="icon-box__svg sites-apps__trigger" />
          </IconBox>
        </div>
      </Section>
      <Section className="sites-apps__cta-section">
        <Heading3 className="sites-apps__ctaHeading">Jste&nbsp;připraveni? My&nbsp;ano!</Heading3>
        <CTA type="primary" path={`/${CONTACT_PATH}`}>
          Nezávazná konzultace
        </CTA>
      </Section>
      <Section className="sites-apps__brand-logo">
        <div className="sites-apps__icons">
          <IconBox id={9} description="Tvorba loga" className="sites-apps__icons__1">
            <IconLogo className="icon-box__svg sites-apps__trigger" />
          </IconBox>
          <IconBox id={10} description="Branding">
            <IconBranding className="icon-box__svg sites-apps__trigger" />
          </IconBox>
        </div>
      </Section>
    </motion.div>
  );
};
