let inited = false;
let lastClientY: number;
let lastClientX: number;
let movementY = 0;
let movementX = 0;

const initMouseMovement = () => {
  /* istanbul ignore next */
  document.addEventListener('mousemove', (e) => {
    if (!inited) {
      lastClientY = e.clientY;
      lastClientX = e.clientX;
    } else {
      movementY = e.clientY - lastClientY;
      lastClientY = e.clientY;
      movementX = e.clientX - lastClientX;
      lastClientX = e.clientX;
    }
  });
  inited = true;
};

export const useMouseMovement = () => {
  if (!inited) {
    initMouseMovement();
  }

  return {
    y: movementY,
    x: movementX,
  };
};
