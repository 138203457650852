import { DragEventHandler, FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import './Review.scss';

import svg_reviewBlock from '../../../../assets/img/review_block.svg';
import svg_reviewBlockTablet from '../../../../assets/img/review_block-tablet.svg';
import svg_reviewBlockMobile from '../../../../assets/img/review_block-mobile.svg';
import { PersonPhoto } from '../../../../UI/PersonPhoto/PersonPhoto';

interface ReviewProps {
  src: string;
  alt: string;
  active: boolean;
  className?: string;
}

export const Review: FC<ReviewProps> = ({ children, src, alt, active, className }) => {
  const [isTextShowed, setIsTextShowed] = useState(false);

  const mq_under1200 = useMediaQuery({ maxWidth: '1200px' });
  const mq_under700 = useMediaQuery({ maxWidth: '700px' });

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (active && !isTextShowed) {
      timeout = setTimeout(() => {
        setIsTextShowed(true);
      }, 300);
    } else if (!active) {
      setIsTextShowed(false);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [active, isTextShowed]);

  let textClassName = 'review__content__text';
  if (isTextShowed) {
    textClassName += ' review__content__text--showed';
  }

  let reviewBlockSvg = svg_reviewBlock;
  if (mq_under1200) {
    reviewBlockSvg = svg_reviewBlockTablet;
  } else if (mq_under700) {
    reviewBlockSvg = svg_reviewBlockMobile;
  }

  const dragPreventor: DragEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
  };

  return (
    <div className={'review' + (active ? ' review--active' : '') + ` ${className}`}>
      <PersonPhoto src={src} alt={alt} />
      <div className="review__content">
        <img
          onDragStart={dragPreventor}
          draggable={false}
          className="review__content__block"
          src={reviewBlockSvg}
          alt="Review blok"
        />
        <div className={textClassName}>{children}</div>
      </div>
    </div>
  );
};
