/* eslint-disable max-len */
import { FC } from 'react';
import './IconEshop.scss';

interface IconEshopProps {
  className?: string;
}

export const IconEshop: FC<IconEshopProps> = ({ className }) => {
  return (
    <svg
      className={'icon__eshop ' + className}
      width="260"
      height="192"
      viewBox="0 0 260 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__eshop__bank">
          <path
            d="M259.001 54.7681V159.204C256.955 165.942 253.591 172.205 249.104 177.631C244.616 183.057 239.095 187.537 232.861 190.811H27.3204C14.8829 184.295 5.53743 173.112 1.33398 159.715V54.7681H259.001Z"
            stroke="#1DA88F"
            strokeWidth="1.04"
            strokeMiterlimit="10"
          />
          <path
            d="M247.104 58.0181V156.2C245.184 162.536 242.022 168.427 237.803 173.528C233.583 178.63 228.391 182.841 222.528 185.917H29.2906C17.5918 179.796 8.80303 169.28 4.85742 156.681V58.0181H247.104Z"
            stroke="#1DA88F"
            strokeWidth="0.98"
            strokeMiterlimit="10"
          />
          <path
            d="M258.178 55.1206V158.495C256.153 165.164 252.823 171.364 248.381 176.735C243.939 182.106 238.474 186.541 232.303 189.781H28.8512C16.5401 183.331 7.28964 172.262 3.12891 159.001V55.1206H258.178Z"
            stroke="#05F2C7"
            strokeWidth="1.04"
            strokeMiterlimit="10"
          />
          <path
            d="M246.402 58.3374V155.522C244.502 161.793 241.372 167.624 237.195 172.674C233.019 177.724 227.879 181.892 222.076 184.936H30.802C19.2221 178.878 10.5227 168.469 6.61719 155.997V58.3374H246.402Z"
            stroke="#05F2C7"
            strokeWidth="0.98"
            strokeMiterlimit="10"
          />
          <path
            d="M255.404 64.4976V153.509C253.664 159.248 250.801 164.583 246.98 169.206C243.16 173.828 238.459 177.645 233.151 180.435H58.0108C47.4116 174.888 39.448 165.359 35.8691 153.944V64.5279L255.404 64.4976Z"
            stroke="#05F2C7"
            strokeWidth="0.9"
            strokeMiterlimit="10"
          />
          <path
            d="M228.894 72.4678V157.181C227.231 162.649 224.496 167.731 220.849 172.131C217.203 176.532 212.716 180.163 207.652 182.812H40.978C30.8895 177.529 23.3085 168.461 19.8984 157.595V72.4678H228.894Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M242.418 68.8564V149.877C240.832 155.104 238.222 159.964 234.74 164.173C231.259 168.382 226.975 171.857 222.138 174.396H62.706C53.0595 169.343 45.81 160.671 42.5469 150.282V68.8564H242.418Z"
            stroke="#05F2C7"
            strokeWidth="0.82"
            strokeMiterlimit="10"
          />
          <path
            d="M253.686 61.5537V158.748C251.788 165.027 248.659 170.866 244.483 175.925C240.306 180.983 235.166 185.16 229.359 188.213H38.0755C26.4984 182.152 17.8001 171.744 13.8906 159.274V61.5537H253.686Z"
            stroke="#05F2C7"
            strokeWidth="0.98"
            strokeMiterlimit="10"
          />
          <path
            d="M140.737 3.27108L3.17578 50.7178L4.69291 55.1164L142.254 7.66967L140.737 3.27108Z"
            stroke="#05F2C7"
            strokeWidth="0.58"
          />
          <path
            d="M114.731 6.21126L256.557 55.1289L258.044 50.8164L116.218 1.89872L114.731 6.21126Z"
            stroke="#05F2C7"
            strokeWidth="0.59"
          />
          <path
            d="M140.36 6.49424L2.79883 53.9409L4.31596 58.3395L141.877 10.8928L140.36 6.49424Z"
            stroke="#05F2C7"
            strokeWidth="0.58"
          />
          <path
            d="M114.354 9.42268L256.18 58.3403L257.667 54.0278L115.841 5.11016L114.354 9.42268Z"
            stroke="#05F2C7"
            strokeWidth="0.59"
          />
          <path
            d="M256.199 64.2412V154.167C254.441 159.964 251.548 165.355 247.689 170.024C243.829 174.694 239.08 178.55 233.718 181.369H56.7791C46.0711 175.764 38.0257 166.139 34.4102 154.606V64.2719L256.199 64.2412Z"
            stroke="#1DA88F"
            strokeWidth="0.9"
            strokeMiterlimit="10"
          />
          <path
            d="M229.416 72.2935V157.876C227.736 163.4 224.974 168.534 221.289 172.98C217.605 177.426 213.073 181.094 207.957 183.77H39.5714C29.3793 178.434 21.7205 169.272 18.2754 158.295V72.2935H229.416Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M243.08 68.6445V150.497C241.477 155.778 238.84 160.687 235.323 164.94C231.805 169.192 227.477 172.703 222.591 175.267H61.5223C51.7768 170.163 44.4529 161.402 41.1562 150.906V68.6445H243.08Z"
            stroke="#1DA88F"
            strokeWidth="0.82"
            strokeMiterlimit="10"
          />
          <path
            d="M254.462 61.2671V159.459C252.544 165.803 249.384 171.702 245.164 176.812C240.945 181.923 235.752 186.143 229.886 189.227H36.6382C24.9423 183.103 16.1547 172.588 12.2051 159.991V61.2671H254.462Z"
            stroke="#1DA88F"
            strokeWidth="0.98"
            strokeMiterlimit="10"
          />
          <path
            d="M140.354 2.38557L1.38086 50.3193L2.91357 54.7631L141.887 6.82931L140.354 2.38557Z"
            stroke="#1DA88F"
            strokeWidth="0.58"
          />
          <path
            d="M114.081 5.35703L257.363 54.7769L258.866 50.4201L115.584 1.00023L114.081 5.35703Z"
            stroke="#1DA88F"
            strokeWidth="0.59"
          />
          <path
            d="M139.973 5.64193L1 53.5757L2.5327 58.0194L141.506 10.0857L139.973 5.64193Z"
            stroke="#1DA88F"
            strokeWidth="0.58"
          />
          <path
            d="M113.701 8.60117L256.982 58.021L258.485 53.6642L115.203 4.24437L113.701 8.60117Z"
            stroke="#1DA88F"
            strokeWidth="0.59"
          />
        </g>
        <g className="icon__eshop__dollar">
          <path
            d="M130.878 90.5645V95.6739C129.997 95.7257 129.122 95.8487 128.262 96.0417V90.6053L130.878 90.5645Z"
            stroke="#1DA88F"
            strokeWidth="0.88"
            strokeMiterlimit="10"
          />
          <path
            d="M130.878 101.754V143.242C129.977 143.096 129.099 142.838 128.262 142.476V102.255C129.113 101.993 129.99 101.825 130.878 101.754V101.754Z"
            stroke="#1DA88F"
            strokeWidth="0.88"
            strokeMiterlimit="10"
          />
          <path
            d="M128.23 149.169C129.092 149.368 129.966 149.512 130.846 149.598V155.014H128.23V149.169Z"
            stroke="#1DA88F"
            strokeWidth="0.88"
            strokeMiterlimit="10"
          />
          <path
            d="M137.805 90.5645V96.2359C136.869 95.9644 135.912 95.7764 134.943 95.6739V90.5645H137.805Z"
            stroke="#1DA88F"
            strokeWidth="0.92"
            strokeMiterlimit="10"
          />
          <path
            d="M134.965 149.65C135.927 149.59 136.884 149.456 137.826 149.251V155.014H134.965V149.65Z"
            stroke="#1DA88F"
            strokeWidth="0.92"
            strokeMiterlimit="10"
          />
          <path
            d="M132.921 117.879C132.185 117.675 131.5 117.481 130.877 117.276V124.43L131.347 124.573C132.696 124.981 133.912 125.349 134.975 125.707V118.472C134.31 118.278 133.626 118.073 132.921 117.879Z"
            stroke="#1DA88F"
            strokeWidth="0.92"
          />
          <path
            d="M137.805 102.694V142.915C136.873 143.189 135.913 143.357 134.943 143.416V101.836C135.932 101.988 136.895 102.277 137.805 102.694V102.694Z"
            stroke="#1DA88F"
            strokeWidth="0.92"
          />
          <path
            d="M149.761 133.984C149.793 136.849 149.063 139.67 147.646 142.159C146.326 144.547 144.337 146.497 141.923 147.769C140.636 148.448 139.26 148.943 137.836 149.241C136.894 149.446 135.937 149.58 134.975 149.639C134.413 149.639 133.83 149.701 133.237 149.701C132.42 149.701 131.633 149.701 130.877 149.588C129.997 149.502 129.123 149.358 128.261 149.159C125.332 148.524 122.652 147.05 120.546 144.918C117.398 141.73 115.753 137.408 115.641 131.961L120.995 131.389C121.118 133.749 121.782 136.05 122.937 138.113C123.999 139.78 125.502 141.12 127.28 141.986C127.61 142.158 127.951 142.308 128.302 142.435C129.124 142.808 129.989 143.08 130.877 143.243C131.783 143.418 132.703 143.503 133.626 143.498C134.096 143.498 134.535 143.498 134.975 143.447C135.944 143.388 136.904 143.22 137.836 142.946C139.186 142.589 140.437 141.928 141.494 141.015C142.407 140.219 143.132 139.231 143.616 138.121C144.1 137.011 144.332 135.808 144.294 134.597C144.336 133.167 143.982 131.753 143.272 130.51C142.535 129.298 141.473 128.318 140.207 127.679C139.439 127.288 138.647 126.947 137.836 126.657V119.32C139.212 119.766 140.56 120.295 141.872 120.904C144.336 122.054 146.403 123.908 147.813 126.233C149.223 128.557 149.911 131.248 149.792 133.964L149.761 133.984Z"
            stroke="#1DA88F"
            strokeWidth="0.92"
          />
          <path
            d="M148.473 111.197L143.016 111.708C142.73 108.301 141.708 105.777 139.95 104.136C139.316 103.54 138.588 103.053 137.794 102.695C136.885 102.277 135.922 101.988 134.933 101.836C134.155 101.722 133.369 101.668 132.583 101.673C131.97 101.673 131.387 101.673 130.835 101.755C129.948 101.826 129.071 101.994 128.219 102.255C127.126 102.571 126.11 103.111 125.235 103.839C124.417 104.514 123.764 105.366 123.324 106.331C122.885 107.296 122.672 108.349 122.701 109.408C122.647 110.316 122.785 111.224 123.105 112.074C123.426 112.925 123.922 113.698 124.561 114.344C125.645 115.244 126.885 115.937 128.219 116.388V123.592C126.258 122.966 124.389 122.083 122.66 120.966C120.971 119.779 119.602 118.192 118.675 116.347C117.689 114.387 117.198 112.215 117.244 110.022C117.172 108.101 117.497 106.186 118.201 104.398C118.904 102.609 119.97 100.986 121.332 99.6291C123.229 97.791 125.617 96.5416 128.209 96.0321C129.07 95.8391 129.945 95.7161 130.825 95.6642C131.336 95.6642 131.847 95.6029 132.399 95.6029C133.242 95.5979 134.085 95.6457 134.923 95.746C135.891 95.8485 136.849 96.0365 137.784 96.308C138.817 96.5989 139.817 96.9962 140.768 97.4934C143.113 98.7173 145.047 100.604 146.327 102.92C147.705 105.463 148.441 108.304 148.473 111.197Z"
            stroke="#1DA88F"
            strokeWidth="0.92"
          />
          <path
            d="M130.876 95.6426V99.8629C130.141 99.9114 129.41 100.014 128.689 100.17V95.6426H130.876Z"
            stroke="#1DA88F"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M130.876 104.962V139.492C130.124 139.372 129.389 139.159 128.689 138.858V105.432C129.4 105.199 130.133 105.042 130.876 104.962V104.962Z"
            stroke="#1DA88F"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M128.658 144.459C129.379 144.623 130.109 144.74 130.845 144.806V149.374H128.658V144.459Z"
            stroke="#1DA88F"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M136.649 95.6426V100.405C135.868 100.176 135.067 100.019 134.258 99.9345V95.6426H136.649Z"
            stroke="#1DA88F"
            strokeWidth="0.77"
            strokeMiterlimit="10"
          />
          <path
            d="M134.258 144.856C135.062 144.803 135.861 144.694 136.649 144.529V149.373H134.258V144.856Z"
            stroke="#1DA88F"
            strokeWidth="0.77"
            strokeMiterlimit="10"
          />
          <path
            d="M132.573 118.39C131.96 118.226 131.387 118.063 130.846 117.899V123.826L131.234 123.949C132.358 124.286 133.38 124.593 134.3 124.889V118.87C133.727 118.717 133.165 118.553 132.573 118.39Z"
            stroke="#1DA88F"
            strokeWidth="0.77"
          />
          <path
            d="M136.649 105.749V139.246C135.87 139.476 135.068 139.617 134.258 139.665V105.034C135.085 105.158 135.89 105.399 136.649 105.749V105.749Z"
            stroke="#1DA88F"
            strokeWidth="0.77"
          />
          <path
            d="M146.603 131.808C146.628 134.195 146.022 136.547 144.846 138.624C143.739 140.611 142.082 142.236 140.074 143.304C138.994 143.868 137.842 144.281 136.65 144.53C135.862 144.695 135.063 144.804 134.259 144.858C133.789 144.858 133.309 144.858 132.808 144.858C132.134 144.858 131.469 144.857 130.846 144.755C130.11 144.688 129.38 144.572 128.659 144.408C126.223 143.875 123.994 142.647 122.242 140.872C119.612 138.215 118.25 134.618 118.154 130.081L122.62 129.611C122.723 131.577 123.275 133.492 124.234 135.211C125.123 136.595 126.374 137.708 127.852 138.43C128.113 138.573 128.383 138.699 128.659 138.808C129.359 139.109 130.094 139.322 130.846 139.442C131.6 139.587 132.367 139.659 133.135 139.656C133.534 139.656 133.891 139.656 134.259 139.656C135.069 139.608 135.872 139.467 136.65 139.237C137.782 138.944 138.83 138.395 139.716 137.633C140.474 136.967 141.076 136.142 141.477 135.216C141.878 134.29 142.069 133.287 142.036 132.278C142.067 131.101 141.773 129.938 141.187 128.916C140.581 127.904 139.698 127.085 138.643 126.556C138.004 126.234 137.345 125.955 136.671 125.718V119.586C137.812 119.966 138.931 120.41 140.023 120.915C142.039 121.896 143.744 123.417 144.948 125.309C146.106 127.274 146.681 129.528 146.603 131.808V131.808Z"
            stroke="#1DA88F"
            strokeWidth="0.77"
          />
          <path
            d="M145.53 112.831L140.982 113.26C140.747 110.42 139.889 108.315 138.438 106.945C137.909 106.454 137.306 106.05 136.65 105.75C135.89 105.399 135.085 105.158 134.258 105.034C133.609 104.938 132.953 104.89 132.296 104.891C131.796 104.891 131.274 104.891 130.845 104.963C130.104 105.026 129.371 105.166 128.658 105.382C127.753 105.649 126.912 106.098 126.186 106.7C125.502 107.261 124.957 107.97 124.59 108.774C124.223 109.579 124.046 110.456 124.07 111.339C124.028 112.091 124.143 112.842 124.409 113.547C124.674 114.251 125.085 114.891 125.613 115.427C126.524 116.171 127.563 116.742 128.679 117.113V123.111C127.047 122.589 125.49 121.853 124.05 120.925C122.645 119.933 121.508 118.61 120.739 117.072C119.919 115.441 119.509 113.635 119.543 111.809C119.483 110.209 119.756 108.614 120.343 107.124C120.93 105.634 121.82 104.283 122.956 103.154C124.531 101.618 126.52 100.574 128.679 100.15C129.399 99.994 130.13 99.8916 130.866 99.8431C131.295 99.8431 131.734 99.792 132.174 99.792C132.877 99.7906 133.58 99.8316 134.279 99.9147C135.088 99.9993 135.889 100.157 136.67 100.385C137.528 100.637 138.357 100.98 139.143 101.407C141.097 102.429 142.706 104.003 143.772 105.934C144.914 108.054 145.518 110.423 145.53 112.831V112.831Z"
            stroke="#1DA88F"
            strokeWidth="0.77"
          />
          <path
            d="M131.355 90.5532V95.6107C130.483 95.662 129.618 95.7838 128.766 95.9748V90.5937L131.355 90.5532Z"
            stroke="#05F2C7"
            strokeWidth="0.88"
            strokeMiterlimit="10"
          />
          <path
            d="M131.355 101.629V142.696C130.464 142.551 129.594 142.296 128.766 141.938V102.125C129.608 101.866 130.476 101.7 131.355 101.629V101.629Z"
            stroke="#05F2C7"
            strokeWidth="0.88"
            strokeMiterlimit="10"
          />
          <path
            d="M128.736 148.562C129.589 148.759 130.454 148.901 131.326 148.987V154.348H128.736V148.562Z"
            stroke="#05F2C7"
            strokeWidth="0.88"
            strokeMiterlimit="10"
          />
          <path
            d="M138.215 90.5532V96.167C137.289 95.8983 136.341 95.7122 135.383 95.6107V90.5532H138.215Z"
            stroke="#05F2C7"
            strokeWidth="0.92"
            strokeMiterlimit="10"
          />
          <path
            d="M135.402 149.038C136.355 148.978 137.302 148.846 138.235 148.643V154.348H135.402V149.038Z"
            stroke="#05F2C7"
            strokeWidth="0.92"
            strokeMiterlimit="10"
          />
          <path
            d="M133.378 117.59C132.65 117.388 131.972 117.195 131.355 116.993V124.074L131.821 124.215C133.156 124.62 134.36 124.984 135.412 125.338V118.177C134.754 117.984 134.076 117.782 133.378 117.59Z"
            stroke="#05F2C7"
            strokeWidth="0.92"
          />
          <path
            d="M138.215 102.56V142.373C137.293 142.643 136.342 142.81 135.383 142.868V101.71C136.362 101.861 137.315 102.147 138.215 102.56V102.56Z"
            stroke="#05F2C7"
            strokeWidth="0.92"
          />
          <path
            d="M150.047 133.532C150.079 136.367 149.356 139.16 147.953 141.624C146.647 143.987 144.678 145.917 142.289 147.177C141.015 147.848 139.653 148.339 138.243 148.633C137.31 148.837 136.364 148.968 135.411 149.028C134.855 149.028 134.278 149.088 133.691 149.088C132.882 149.088 132.103 149.088 131.355 148.977C130.483 148.891 129.619 148.749 128.765 148.552C125.867 147.923 123.213 146.465 121.129 144.355C118.013 141.199 116.385 136.92 116.273 131.529L121.574 130.962C121.695 133.299 122.353 135.576 123.496 137.618C124.547 139.268 126.035 140.595 127.794 141.452C128.121 141.622 128.459 141.771 128.806 141.897C129.62 142.266 130.476 142.534 131.355 142.696C132.251 142.869 133.163 142.954 134.076 142.949C134.541 142.949 134.976 142.949 135.411 142.898C136.371 142.84 137.321 142.673 138.243 142.402C139.58 142.048 140.818 141.395 141.864 140.491C142.768 139.703 143.485 138.725 143.964 137.626C144.444 136.528 144.673 135.336 144.636 134.138C144.677 132.722 144.327 131.323 143.624 130.092C142.895 128.893 141.843 127.922 140.59 127.291C139.83 126.904 139.046 126.566 138.243 126.279V119.017C139.606 119.457 140.94 119.981 142.239 120.584C144.677 121.722 146.724 123.558 148.119 125.859C149.514 128.16 150.196 130.823 150.078 133.511L150.047 133.532Z"
            stroke="#05F2C7"
            strokeWidth="0.92"
          />
          <path
            d="M148.773 110.975L143.371 111.481C143.088 108.109 142.077 105.611 140.337 103.986C139.709 103.396 138.988 102.914 138.203 102.56C137.303 102.146 136.349 101.86 135.37 101.71C134.6 101.597 133.822 101.543 133.044 101.548C132.437 101.548 131.86 101.548 131.314 101.629C130.436 101.7 129.567 101.866 128.725 102.125C127.643 102.438 126.637 102.971 125.771 103.693C124.961 104.36 124.314 105.204 123.88 106.159C123.445 107.114 123.234 108.156 123.263 109.205C123.209 110.103 123.345 111.002 123.663 111.844C123.98 112.686 124.471 113.451 125.104 114.091C126.176 114.982 127.404 115.667 128.725 116.114V123.245C126.784 122.625 124.934 121.751 123.222 120.645C121.55 119.47 120.195 117.899 119.277 116.073C118.302 114.133 117.816 111.983 117.861 109.812C117.79 107.911 118.112 106.016 118.808 104.245C119.505 102.475 120.56 100.868 121.907 99.5252C123.785 97.7058 126.149 96.4691 128.715 95.9647C129.567 95.7737 130.433 95.6519 131.304 95.6006C131.81 95.6006 132.316 95.5399 132.862 95.5399C133.697 95.5349 134.531 95.5822 135.36 95.6815C136.319 95.783 137.267 95.9691 138.192 96.2378C139.215 96.5258 140.205 96.919 141.146 97.4112C143.468 98.6227 145.381 100.491 146.649 102.782C148.012 105.3 148.741 108.112 148.773 110.975Z"
            stroke="#05F2C7"
            strokeWidth="0.92"
          />
          <path
            d="M131.356 95.5801V99.7576C130.628 99.8055 129.905 99.9069 129.191 100.061V95.5801H131.356Z"
            stroke="#05F2C7"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M131.356 104.805V138.984C130.611 138.865 129.884 138.654 129.191 138.357V105.27C129.895 105.04 130.62 104.884 131.356 104.805V104.805Z"
            stroke="#05F2C7"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M129.16 143.9C129.873 144.063 130.596 144.178 131.325 144.244V148.765H129.16V143.9Z"
            stroke="#05F2C7"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M137.07 95.5801V100.294C136.297 100.068 135.504 99.9122 134.703 99.8284V95.5801H137.07Z"
            stroke="#05F2C7"
            strokeWidth="0.77"
            strokeMiterlimit="10"
          />
          <path
            d="M134.703 144.293C135.499 144.241 136.29 144.133 137.07 143.97V148.764H134.703V144.293Z"
            stroke="#05F2C7"
            strokeWidth="0.77"
            strokeMiterlimit="10"
          />
          <path
            d="M133.034 118.096C132.427 117.934 131.86 117.772 131.324 117.61V123.477L131.709 123.598C132.821 123.932 133.833 124.236 134.743 124.529V118.571C134.177 118.42 133.62 118.258 133.034 118.096Z"
            stroke="#05F2C7"
            strokeWidth="0.77"
          />
          <path
            d="M137.07 105.585V138.741C136.299 138.969 135.505 139.108 134.703 139.156V104.876C135.522 104.999 136.319 105.238 137.07 105.585V105.585Z"
            stroke="#05F2C7"
            strokeWidth="0.77"
          />
          <path
            d="M146.922 131.378C146.946 133.741 146.346 136.068 145.182 138.124C144.086 140.091 142.446 141.7 140.458 142.757C139.39 143.315 138.25 143.724 137.07 143.971C136.289 144.134 135.498 144.242 134.703 144.295C134.238 144.295 133.762 144.295 133.267 144.295C132.599 144.295 131.942 144.295 131.324 144.193C130.596 144.127 129.873 144.012 129.16 143.849C126.748 143.322 124.542 142.106 122.808 140.35C120.205 137.72 118.856 134.159 118.762 129.668L123.182 129.203C123.284 131.149 123.831 133.045 124.78 134.746C125.66 136.116 126.898 137.218 128.361 137.932C128.619 138.073 128.886 138.198 129.16 138.307C129.853 138.604 130.58 138.815 131.324 138.934C132.071 139.078 132.83 139.149 133.59 139.146C133.985 139.146 134.339 139.146 134.703 139.146C135.505 139.098 136.299 138.959 137.07 138.731C138.189 138.441 139.228 137.898 140.104 137.143C140.855 136.484 141.45 135.667 141.847 134.751C142.244 133.834 142.433 132.841 142.4 131.843C142.431 130.678 142.141 129.526 141.561 128.515C140.96 127.513 140.087 126.702 139.042 126.179C138.41 125.861 137.758 125.584 137.09 125.349V119.28C138.22 119.656 139.327 120.095 140.408 120.595C142.404 121.566 144.091 123.072 145.283 124.945C146.43 126.89 146.998 129.121 146.922 131.378V131.378Z"
            stroke="#05F2C7"
            strokeWidth="0.77"
          />
          <path
            d="M145.861 112.594L141.36 113.019C141.127 110.207 140.278 108.123 138.841 106.768C138.318 106.281 137.721 105.882 137.071 105.584C136.32 105.237 135.523 104.999 134.704 104.876C134.062 104.78 133.412 104.733 132.762 104.734C132.267 104.734 131.751 104.734 131.326 104.805C130.592 104.867 129.867 105.006 129.161 105.22C128.266 105.485 127.433 105.929 126.714 106.525C126.038 107.08 125.497 107.782 125.134 108.578C124.772 109.374 124.596 110.243 124.62 111.117C124.578 111.861 124.692 112.605 124.955 113.302C125.218 113.999 125.624 114.633 126.147 115.163C127.049 115.899 128.077 116.465 129.182 116.832V122.769C127.566 122.252 126.025 121.524 124.6 120.605C123.209 119.624 122.083 118.314 121.322 116.791C120.511 115.177 120.105 113.389 120.139 111.582C120.08 109.998 120.349 108.419 120.931 106.945C121.512 105.47 122.393 104.132 123.517 103.015C125.076 101.494 127.045 100.461 129.182 100.041C129.895 99.887 130.618 99.7856 131.346 99.7376C131.771 99.7376 132.206 99.687 132.641 99.687C133.337 99.6857 134.033 99.7262 134.725 99.8084C135.526 99.8922 136.318 100.048 137.092 100.274C137.941 100.524 138.761 100.863 139.539 101.285C141.473 102.297 143.067 103.855 144.121 105.766C145.252 107.865 145.85 110.21 145.861 112.594V112.594Z"
            stroke="#05F2C7"
            strokeWidth="0.77"
          />
        </g>
      </g>
    </svg>
  );
};
