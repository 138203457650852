/* eslint-disable max-len */
import { FC } from 'react';
import './Icon90.scss';

interface Icon90Props {
  className?: string;
}

export const Icon90: FC<Icon90Props> = ({ className }) => {
  return (
    <svg
      className={'icon__90 ' + className}
      width="221"
      height="79"
      viewBox="0 0 221 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__90__number">
          <g className="icon__90__0">
            <path
              d="M107.265 37.0714C107.265 61.2998 98.28 74.6745 82.4868 74.6745C68.5625 74.6745 59.1379 61.6296 58.918 38.0315C58.896 14.1549 69.222 1 83.696 1C98.7124 1 107.265 14.3747 107.265 37.0714ZM68.5698 38.2C68.5698 56.7268 74.2714 67.258 83.0365 67.258C92.9081 67.258 97.6204 55.7447 97.6204 37.5478C97.6204 20.0031 93.1206 8.48984 83.1464 8.48984C74.7038 8.4532 68.5698 18.7646 68.5698 38.2Z"
              stroke="#05F2C7"
              strokeWidth="0.5"
            />
            <path
              d="M109.134 38.9313C109.134 63.1597 100.142 76.5344 84.3559 76.5344C70.4316 76.5344 61.007 63.4895 60.7871 39.8914C60.7871 15.9928 71.0911 2.83789 85.5651 2.83789C100.581 2.83057 109.134 16.2126 109.134 38.9313ZM70.4316 40.0306C70.4316 58.5573 76.1332 69.0812 84.9056 69.0812C94.7699 69.0812 99.4822 57.568 99.4822 39.371C99.4822 21.8337 94.9897 10.3204 85.0155 10.3204C76.5729 10.2838 70.4316 20.6098 70.4316 39.994V40.0306Z"
              stroke="#05F2C7"
              strokeWidth="0.5"
            />
            <path
              d="M107.122 35.6356C107.122 57.9586 98.8406 70.278 84.2934 70.278C71.4683 70.278 62.7839 58.2591 62.586 36.5664C62.5567 14.5292 72.0839 2.41504 85.4073 2.41504C99.2437 2.41504 107.122 14.7491 107.122 35.6356ZM71.4683 36.647C71.4683 53.7153 76.7229 63.4111 84.799 63.4111C93.8865 63.4111 98.2324 52.8066 98.2324 36.0387C98.2324 19.9158 94.0917 9.28195 84.9016 9.28195C77.126 9.28195 71.4683 18.7725 71.4683 36.647Z"
              stroke="#05F2C7"
              strokeWidth="0.5"
            />
            <path
              d="M105.066 39.2706C105.066 63.499 96.0808 76.8738 80.2876 76.8738C66.3632 76.8738 56.9386 63.8288 56.7188 40.2307C56.6968 16.3541 67.0228 3.19922 81.4968 3.19922C96.5131 3.19922 105.066 16.5739 105.066 39.2706ZM66.3706 40.3993C66.3706 58.926 72.0722 69.4572 80.8372 69.4572C90.7089 69.4572 95.4212 57.944 95.4212 39.747C95.4212 22.2023 90.9214 10.6891 80.9472 10.6891C72.5046 10.6524 66.3706 20.9638 66.3706 40.3993Z"
              stroke="#1DA88F"
              strokeWidth="0.5"
            />
            <path
              d="M106.935 41.1315C106.935 65.3599 97.9425 78.7346 82.1567 78.7346C68.2323 78.7346 58.8077 65.6897 58.5879 42.0916C58.5879 18.1929 68.8919 5.03809 83.3659 5.03809C98.3823 5.03076 106.935 18.4128 106.935 41.1315ZM68.2323 42.2308C68.2323 60.7575 73.934 71.2814 82.7064 71.2814C92.5707 71.2814 97.283 59.7682 97.283 41.5712C97.283 24.0339 92.7905 12.5206 82.8163 12.5206C74.3737 12.484 68.2323 22.81 68.2323 42.1942V42.2308Z"
              stroke="#1DA88F"
              strokeWidth="0.5"
            />
            <path
              d="M104.923 37.8349C104.923 60.1578 96.6414 72.4772 82.0941 72.4772C69.2691 72.4772 60.5847 60.4583 60.3868 38.7656C60.3575 16.7285 69.8847 4.61426 83.2081 4.61426C97.0445 4.61426 104.923 16.9483 104.923 37.8349ZM69.2691 38.8462C69.2691 55.9145 74.5237 65.6103 82.5998 65.6103C91.6873 65.6103 96.0331 55.0058 96.0331 38.2379C96.0331 22.115 91.8925 11.4812 82.7024 11.4812C74.9267 11.4812 69.2691 20.9717 69.2691 38.8462Z"
              stroke="#1DA88F"
              strokeWidth="0.5"
            />
          </g>
          <g className="icon__90__9">
            <path
              d="M9.25998 66.6722C11.8589 66.8148 14.4655 66.7044 17.043 66.3424C22.6092 65.659 27.8218 63.2501 31.9494 59.4535C36.669 55.1589 40.0621 48.9076 41.3813 40.4651H41.0515C37.1014 45.2946 31.407 48.1454 24.2763 48.1454C11.4512 48.1454 3.19922 38.4937 3.19922 26.3282C3.19922 12.8435 12.9829 1.00049 27.5669 1.00049C42.1508 1.00049 51.1357 12.8435 51.1357 31.0478C51.1357 46.7237 45.8737 57.6873 38.8602 64.4883C33.3784 69.8601 25.8153 73.1433 18.1349 74.0154C15.2022 74.4929 12.2292 74.677 9.25998 74.5651V66.6722ZM12.7631 25.6686C12.7631 34.5509 18.1349 40.7948 26.4676 40.7948C32.9387 40.7948 37.9808 37.6216 40.5019 33.3416C41.1287 32.2813 41.4342 31.0618 41.3813 29.8312C41.3813 17.6804 36.9108 8.34376 26.7973 8.34376C18.5893 8.34376 12.7631 15.5844 12.7631 25.6686Z"
              stroke="#05F2C7"
              strokeWidth="0.5"
            />
            <path
              d="M11.1159 68.4974C13.7148 68.6402 16.3214 68.5298 18.8989 68.1676C24.4697 67.4826 29.6856 65.0681 33.8126 61.264C38.5249 56.9915 41.9254 50.7401 43.2372 42.2976H42.9148C38.9646 47.1198 33.241 49.9706 26.1396 49.9706C13.3145 49.9706 5.0918 40.3262 5.0918 28.1534C5.0918 14.6687 14.8462 2.83301 29.4301 2.83301C44.0141 2.83301 53.0282 14.6687 53.0282 32.873C53.0282 48.5489 47.7663 59.5125 40.7455 66.3061C35.0693 71.6652 27.7905 75.0123 20.0275 75.8333C17.0917 76.305 14.1166 76.4866 11.1452 76.3756L11.1159 68.4974ZM14.6263 27.5011C14.6263 36.3761 19.9982 42.5981 28.3308 42.5981C34.802 42.5981 39.8367 39.4468 42.3651 35.1742C42.9886 34.1128 43.2915 32.8936 43.2372 31.6638C43.2372 19.4983 38.7448 10.1763 28.6606 10.1763C20.4379 10.1763 14.6263 17.4096 14.6263 27.5011Z"
              stroke="#05F2C7"
              strokeWidth="0.5"
            />
            <path
              d="M16.8422 62.9059C19.2357 63.0354 21.6361 62.9323 24.0096 62.5981C29.1405 61.9673 33.944 59.7423 37.7434 56.2368C42.0893 52.3014 45.2186 46.5484 46.4352 38.7728H46.1347C42.4704 43.2139 37.2451 45.8375 30.686 45.8375C18.8576 45.8375 11.2578 36.9553 11.2578 25.7425C11.2578 13.3205 20.272 2.41553 33.7054 2.41553C47.1387 2.41553 55.42 13.3205 55.42 30.081C55.42 44.5257 50.5685 54.6246 44.1047 60.8612C38.8876 65.8001 32.1934 68.8897 25.0503 69.6555C22.3476 70.0954 19.6078 70.2648 16.8715 70.1612L16.8422 62.9059ZM20.0521 25.1342C20.0521 33.3129 24.999 39.0586 32.672 39.0586C38.6302 39.0586 43.2765 36.1271 45.5997 32.1917C46.1827 31.2223 46.4729 30.105 46.4352 28.9744C46.4352 17.7616 42.2945 9.18716 33.0091 9.18716C25.424 9.17984 20.0521 15.8489 20.0521 25.1342Z"
              stroke="#05F2C7"
              strokeWidth="0.5"
            />
            <path
              d="M7.06076 68.8704C9.65965 69.013 12.2662 68.9026 14.8437 68.5406C20.41 67.8573 25.6226 65.4483 29.7501 61.6517C34.4698 57.3572 37.8629 51.1059 39.1821 42.6633H38.8523C34.9022 47.4929 29.2078 50.3437 22.0771 50.3437C9.25202 50.3437 1 40.6919 1 28.5264C1 15.0418 10.7837 3.19873 25.3676 3.19873C39.9516 3.19873 48.9365 15.0418 48.9365 33.246C48.9365 48.9219 43.6745 59.8855 36.661 66.6865C31.1792 72.0584 23.6161 75.3416 15.9357 76.2137C13.003 76.6911 10.03 76.8752 7.06076 76.7633V68.8704ZM10.5638 27.8668C10.5638 36.7491 15.9357 42.9931 24.2683 42.9931C30.7395 42.9931 35.7816 39.8198 38.3026 35.5399C38.9295 34.4796 39.235 33.2601 39.1821 32.0295C39.1821 19.8787 34.7116 10.542 24.5981 10.542C16.3901 10.542 10.5638 17.7827 10.5638 27.8668Z"
              stroke="#1DA88F"
              strokeWidth="0.5"
            />
            <path
              d="M8.9206 70.6946C11.5195 70.8375 14.1261 70.7271 16.7036 70.3649C22.2744 69.6798 27.4903 67.2654 31.6173 63.4613C36.3296 59.1887 39.7301 52.9374 41.0419 44.4948H40.7194C36.7693 49.3171 31.0457 52.1679 23.9443 52.1679C11.1192 52.1679 2.89648 42.5235 2.89648 30.3506C2.89648 16.866 12.6509 5.03027 27.2348 5.03027C41.8187 5.03027 50.8329 16.866 50.8329 35.0703C50.8329 50.7462 45.571 61.7098 38.5502 68.5034C32.874 73.8624 25.5952 77.2096 17.8322 78.0306C14.8964 78.5023 11.9213 78.6839 8.94992 78.5729L8.9206 70.6946ZM12.431 29.6984C12.431 38.5733 17.8029 44.7953 26.1355 44.7953C32.6067 44.7953 37.6414 41.644 40.1698 37.3714C40.7933 36.31 41.0962 35.0908 41.0419 33.861C41.0419 21.6955 36.5495 12.3735 26.4653 12.3735C18.2426 12.3735 12.431 19.6069 12.431 29.6984Z"
              stroke="#1DA88F"
              strokeWidth="0.5"
            />
            <path
              d="M14.645 65.1046C17.0384 65.2341 19.4388 65.131 21.8123 64.7968C26.9432 64.166 31.7468 61.9411 35.5462 58.4356C39.892 54.5001 43.0213 48.7472 44.2379 40.9715H43.9374C40.2731 45.4126 35.0478 48.0363 28.4887 48.0363C16.6603 48.0363 9.06055 39.154 9.06055 27.9412C9.06055 15.5192 18.0747 4.61426 31.5081 4.61426C44.9414 4.61426 53.2228 15.5192 53.2228 32.2798C53.2228 46.7245 48.3712 56.8233 41.9074 63.0599C36.6903 67.9988 29.9961 71.0884 22.853 71.8543C20.1503 72.2941 17.4105 72.4635 14.6743 72.3599L14.645 65.1046ZM17.8549 27.333C17.8549 35.5117 22.8017 41.2573 30.4748 41.2573C36.4329 41.2573 41.0793 38.3259 43.4024 34.3904C43.9855 33.421 44.2756 32.3037 44.2379 31.1731C44.2379 19.9604 40.0972 11.3859 30.8119 11.3859C23.2268 11.3786 17.8549 18.0476 17.8549 27.333Z"
              stroke="#1DA88F"
              strokeWidth="0.5"
            />
          </g>
        </g>
        <g className="icon__90__perc">
          <path
            d="M171.503 22.2676C171.503 37.2913 163.61 44.7445 154.398 44.7445C145.523 44.7445 137.85 37.6211 137.74 23.257C137.74 9.00284 145.523 1 154.948 1C164.929 1 171.503 8.67306 171.503 22.2676ZM145.413 22.9272C145.193 32.0293 148.594 38.9329 154.618 38.9329C160.979 38.9329 163.83 32.1393 163.83 22.7073C163.83 14.0449 161.309 6.81159 154.618 6.81159C148.594 6.81159 145.413 13.935 145.413 22.9272V22.9272ZM153.746 74.6745L195.622 1H201.8L159.88 74.6745H153.746ZM217.879 51.758C217.879 66.7816 209.986 74.2348 200.884 74.2348C192.009 74.2348 184.328 67.1041 184.218 52.8573C184.218 38.4932 192.009 30.4903 201.433 30.4903C211.407 30.4903 217.879 38.2 217.879 51.758ZM191.789 52.4175C191.679 61.5197 195.182 68.4232 201.103 68.4232C207.465 68.4232 210.315 61.6296 210.315 52.1977C210.315 43.5353 207.794 36.3019 201.103 36.3019C195.072 36.3019 191.789 43.4253 191.789 52.4175Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M173.376 24.1001C173.376 39.1165 165.483 46.577 156.271 46.577C147.388 46.577 139.715 39.4463 139.605 25.0822C139.605 10.8354 147.388 2.83252 156.82 2.83252C166.795 2.83252 173.376 10.5056 173.376 24.1001ZM147.286 24.7597C147.066 33.8545 150.459 40.7654 156.491 40.7654C162.852 40.7654 165.703 33.9645 165.703 24.5399C165.703 15.8774 163.182 8.64411 156.491 8.64411C150.459 8.64411 147.286 15.7675 147.286 24.7597ZM155.633 76.4997L197.494 2.83252H203.636L161.753 76.4997H155.633ZM219.751 53.5905C219.751 68.6068 211.851 76.0673 202.756 76.0673C193.874 76.0673 186.201 68.9366 186.091 54.6824C186.091 40.3257 193.874 32.3375 203.306 32.3375C213.28 32.3375 219.751 39.9959 219.751 53.5905ZM193.654 54.2501C193.544 63.3449 197.054 70.2557 202.976 70.2557C209.33 70.2557 212.181 63.4548 212.181 54.0302C212.181 45.3678 209.66 38.1344 202.976 38.1344C196.945 38.1344 193.654 45.2579 193.654 54.2501Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M166.293 22.0044C166.293 35.8409 159.023 42.7078 150.544 42.7078C142.358 42.7078 135.293 36.112 135.191 22.9205C135.088 9.72901 142.358 2.4004 151.042 2.4004C160.232 2.41506 166.293 9.48717 166.293 22.0044ZM142.263 22.6127C142.057 30.9893 145.194 37.3579 150.742 37.3579C156.605 37.3579 159.228 31.0919 159.228 22.4075C159.228 14.434 156.905 7.75029 150.742 7.75029C145.187 7.76494 142.263 14.3314 142.263 22.6127V22.6127ZM149.936 70.2487L188.514 2.38574H194.164L155.63 70.278L149.936 70.2487ZM209.012 49.1936C209.012 63.03 201.742 69.8969 193.358 69.8969C185.179 69.8969 178.107 63.3012 178.012 50.2049C177.916 37.1087 185.179 29.6042 193.863 29.6042C203.053 29.5822 209.012 36.647 209.012 49.1936ZM184.944 49.7799C184.849 58.1565 188.081 64.5177 193.534 64.5177C199.396 64.5177 202.013 58.2591 202.013 49.5747C202.013 41.5938 199.69 34.9175 193.534 34.9175C188.008 34.9321 184.944 41.4985 184.944 49.7799Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M169.306 24.4669C169.306 39.4905 161.413 46.9437 152.201 46.9437C143.326 46.9437 135.653 39.8203 135.543 25.4562C135.543 11.2021 143.326 3.19922 152.751 3.19922C162.732 3.19922 169.306 10.8723 169.306 24.4669ZM143.216 25.1264C142.996 34.2286 146.397 41.1321 152.421 41.1321C158.782 41.1321 161.633 34.3385 161.633 24.9066C161.633 16.2441 159.112 9.01081 152.421 9.01081C146.397 9.01081 143.216 16.1342 143.216 25.1264V25.1264ZM151.549 76.8738L193.424 3.19922H199.602L157.683 76.8738H151.549ZM215.681 53.9572C215.681 68.9808 207.788 76.434 198.686 76.434C189.811 76.434 182.131 69.3033 182.021 55.0565C182.021 40.6924 189.811 32.6896 199.236 32.6896C209.21 32.6896 215.681 40.3993 215.681 53.9572ZM189.591 54.6168C189.482 63.7189 192.985 70.6225 198.906 70.6225C205.267 70.6225 208.118 63.8288 208.118 54.3969C208.118 45.7345 205.597 38.5011 198.906 38.5011C192.875 38.5011 189.591 45.6246 189.591 54.6168Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M171.18 26.2979C171.18 41.3142 163.287 48.7748 154.075 48.7748C145.193 48.7748 137.52 41.644 137.41 27.2799C137.41 13.0331 145.193 5.03027 154.625 5.03027C164.599 5.03027 171.18 12.7033 171.18 26.2979ZM145.091 26.9575C144.871 36.0523 148.264 42.9632 154.295 42.9632C160.657 42.9632 163.507 36.1622 163.507 26.7376C163.507 18.0752 160.986 10.8419 154.295 10.8419C148.264 10.8419 145.091 17.9653 145.091 26.9575ZM153.438 78.6975L195.299 5.03027H201.44L159.557 78.6975H153.438ZM217.556 55.7882C217.556 70.8046 209.656 78.2651 200.561 78.2651C191.679 78.2651 184.005 71.1344 183.896 56.8802C183.896 42.5235 191.679 34.5353 201.11 34.5353C211.085 34.5353 217.556 42.1937 217.556 55.7882ZM191.459 56.4478C191.349 65.5426 194.859 72.4535 200.781 72.4535C207.135 72.4535 209.985 65.6525 209.985 56.228C209.985 47.5655 207.464 40.3322 200.781 40.3322C194.749 40.3322 191.459 47.4556 191.459 56.4478Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M164.098 24.2032C164.098 38.0396 156.828 44.9065 148.349 44.9065C140.163 44.9065 133.098 38.3107 132.995 25.1192C132.893 11.9277 140.163 4.59913 148.847 4.59913C158.037 4.61379 164.098 11.6859 164.098 24.2032ZM140.067 24.8114C139.862 33.188 142.999 39.5566 148.547 39.5566C154.409 39.5566 157.033 33.2906 157.033 24.6062C157.033 16.6327 154.71 9.94902 148.547 9.94902C142.991 9.96367 140.067 16.5301 140.067 24.8114V24.8114ZM147.74 72.4474L186.318 4.58447H191.969L153.435 72.4767L147.74 72.4474ZM206.816 51.3923C206.816 65.2287 199.546 72.0957 191.162 72.0957C182.984 72.0957 175.912 65.4999 175.816 52.4037C175.721 39.3074 182.984 31.8029 191.668 31.8029C200.858 31.781 206.816 38.8457 206.816 51.3923ZM182.749 51.9786C182.654 60.3552 185.886 66.7165 191.338 66.7165C197.201 66.7165 199.818 60.4578 199.818 51.7734C199.818 43.7925 197.494 37.1162 191.338 37.1162C185.813 37.1308 182.749 43.6973 182.749 51.9786Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
};
