import './Heading1.scss';
import { motion } from 'framer-motion';
import { CSSProperties, FC } from 'react';

interface Heading1Props {
  type?: 'primary' | 'secondary';
  style?: CSSProperties;
}

export const Heading1: FC<Heading1Props> = ({ type = 'primary', children, style }) => {
  let className = 'heading-1';
  if (type === 'secondary') className += ' heading-1--secondary';

  return (
    <motion.h1 style={style} className={className}>
      {children}
    </motion.h1>
  );
};
