import { Variants } from 'framer-motion';

export const pageVariants: Variants = {
  animate: {
    x: 0,
    y: [-5, 5],
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  initial: {
    scale: 0.95,
    opacity: 0,
  },
  exit: {
    scale: 1.05,
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.1,
      ease: 'easeOut',
    },
  },
};
