import { ChangeEventHandler, FC, Fragment, useState } from 'react';
import { BACKEND_URL } from '../../../../constants/environment';
import { CalculationQuestionTypeEnum } from '../../../../content/calculation.content';
import { FormStatus } from '../../../../types/enums';
import { CTA } from '../../../../UI/CTA/CTA';
import { Popup } from '../../../../UI/Popup/Popup';
import { Paragraph } from '../../../../UI/typography/Paragraph/Paragraph';
import { isValidEmail } from '../../../../utils/isValidEmail';
import { Answers } from '../../Calculation';
import classes from './SendAnswersPopup.module.scss';

interface SendAnswersPopupProps {
  onClose: () => any;
  onSent: () => any;
  answers: Answers;
  calculatedResult: number | null;
}

type AnswersToSend = {
  [key in CalculationQuestionTypeEnum]?: string;
};

const mapAnswersToSend = (answers: Answers): AnswersToSend => ({
  [CalculationQuestionTypeEnum.VISITOR_COUNT]:
    answers[CalculationQuestionTypeEnum.VISITOR_COUNT]?.text,
  [CalculationQuestionTypeEnum.WEB_TYPE]: answers[CalculationQuestionTypeEnum.WEB_TYPE]?.text,
  [CalculationQuestionTypeEnum.PAGE_COUNT]: answers[CalculationQuestionTypeEnum.PAGE_COUNT]?.text,
  [CalculationQuestionTypeEnum.FOCUS]: answers[CalculationQuestionTypeEnum.FOCUS]?.text,
  [CalculationQuestionTypeEnum.THIRD_PARTIES]:
    answers[CalculationQuestionTypeEnum.THIRD_PARTIES]?.text,
});

export const SendAnswersPopup: FC<SendAnswersPopupProps> = ({
  onClose,
  onSent,
  answers,
  calculatedResult,
}) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [note, setNote] = useState('');
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [triedSend, setTriedSend] = useState(false);
  const [status, setStatus] = useState<FormStatus>('success');

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    if (!isValidEmail(newValue)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
    setEmail(newValue);
  };

  const onNoteChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setNote(e.target.value);
  };

  const onNoteOpen = () => {
    setIsNoteOpen(true);
  };

  const sendMessage = () => {
    setTriedSend(true);

    if (!isValidEmail(email)) {
      setIsEmailValid(false);
      return;
    }
    setIsEmailValid(true);

    setStatus('loading');

    fetch(`${BACKEND_URL}/answers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        estimate: calculatedResult,
        email: email.trim(),
        note: note,
        answers: mapAnswersToSend(answers),
      }),
    })
      .then((res) => {
        if (!res.ok) setStatus('error');
        return res.json();
      })
      .then((json) => {
        console.log(json);
        if (!json.success) {
          setStatus('error');
        } else {
          onSent();
          setStatus('success');
        }
      })
      .catch((err) => {
        console.log(err);
        setStatus('error');
      });
  };

  return (
    <Popup className={classes.popup} onClose={onClose}>
      <div className={classes.input__wrapper}>
        <label htmlFor="email" className={classes.input__label}>
          <Paragraph>
            Váš e-mail <span>*</span>
          </Paragraph>
        </label>
        <input
          className={`${classes.input} ${classes.input__email} ${
            !isEmailValid && triedSend && classes.input_invalid
          }`}
          type="email"
          id="email"
          onChange={onEmailChange}
          value={email}
        />
      </div>
      <div className={classes.input__wrapper}>
        {isNoteOpen ? (
          <Fragment>
            <label htmlFor="email" className={classes.input__label}>
              <Paragraph>Poznámka</Paragraph>
            </label>
            <textarea
              className={`${classes.input} ${classes.input__note}`}
              name="note"
              onChange={onNoteChange}
              value={note}
            ></textarea>
          </Fragment>
        ) : (
          <div className={classes.openNote} onClick={onNoteOpen}>
            <div className={classes.openNote__plusSign}>+</div>
            <Paragraph>Přidat poznámku</Paragraph>
          </div>
        )}
      </div>
      <CTA
        type="main"
        className={[classes.cta, status === 'loading' ? classes.cta_loading : ''].join(' ')}
        onClick={sendMessage}
      >
        {status === 'loading' ? 'Odesílání...' : 'ODESLAT'}
      </CTA>
      <a href="/gdpr" target="_blank" className={classes.gdpr}>
        Ochrana osobních údajů
      </a>
    </Popup>
  );
};
