/* eslint-disable max-len */
import { FC } from 'react';
import './IconGA.scss';

interface IconGAProps {
  className?: string;
}

export const IconGA: FC<IconGAProps> = ({ className }) => {
  return (
    <svg
      className={'icon__ga ' + className}
      width="99"
      height="113"
      viewBox="0 0 99 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__ga__regular">
          <path
            d="M20.5138 23.8813H8.46074C5.68324 23.8813 3.43164 26.133 3.43164 28.9104V106.015C3.43164 108.792 5.68324 111.044 8.46074 111.044H20.5138C23.2913 111.044 25.5429 108.792 25.5429 106.015V28.9104C25.5429 26.133 23.2913 23.8813 20.5138 23.8813Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.2677 25.2651H6.21464C3.43715 25.2651 1.18555 27.5167 1.18555 30.2942V107.399C1.18555 110.176 3.43715 112.428 6.21464 112.428H18.2677C21.0452 112.428 23.2968 110.176 23.2968 107.399V30.2942C23.2968 27.5167 21.0452 25.2651 18.2677 25.2651Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M22.513 30.6128H11.6041C9.09044 30.6128 7.05273 32.6505 7.05273 35.1641V104.939C7.05273 107.452 9.09044 109.49 11.6041 109.49H22.513C25.0266 109.49 27.0643 107.452 27.0643 104.939V35.1641C27.0643 32.6505 25.0266 30.6128 22.513 30.6128Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M24.713 23.8813H12.66C9.88246 23.8813 7.63086 26.133 7.63086 28.9104V106.015C7.63086 108.792 9.88246 111.044 12.66 111.044H24.713C27.4905 111.044 29.7421 108.792 29.7421 106.015V28.9104C29.7421 26.133 27.4905 23.8813 24.713 23.8813Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M22.4669 25.2651H10.4139C7.63637 25.2651 5.38477 27.5167 5.38477 30.2942V107.399C5.38477 110.176 7.63637 112.428 10.4139 112.428H22.4669C25.2444 112.428 27.496 110.176 27.496 107.399V30.2942C27.496 27.5167 25.2444 25.2651 22.4669 25.2651Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M26.7122 30.6128H15.8033C13.2897 30.6128 11.252 32.6505 11.252 35.1641V104.939C11.252 107.452 13.2897 109.49 15.8033 109.49H26.7122C29.2259 109.49 31.2636 107.452 31.2636 104.939V35.1641C31.2636 32.6505 29.2259 30.6128 26.7122 30.6128Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__ga__large">
          <path
            d="M54.3467 1H43.5341C40.4141 1 37.8848 3.5293 37.8848 6.64935V105.396C37.8848 108.516 40.4141 111.045 43.5341 111.045H54.3467C57.4667 111.045 59.996 108.516 59.996 105.396V6.64935C59.996 3.5293 57.4667 1 54.3467 1Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M52.1025 2.37891H41.29C38.1699 2.37891 35.6406 4.90821 35.6406 8.02826V106.775C35.6406 109.895 38.1699 112.424 41.29 112.424H52.1025C55.2226 112.424 57.7519 109.895 57.7519 106.775V8.02826C57.7519 4.90821 55.2226 2.37891 52.1025 2.37891Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M53.3889 10.0444H43.6031C40.7794 10.0444 38.4902 12.3336 38.4902 15.1573V104.52C38.4902 107.344 40.7794 109.633 43.6031 109.633H53.3889C56.2127 109.633 58.5018 107.344 58.5018 104.52V15.1573C58.5018 12.3336 56.2127 10.0444 53.3889 10.0444Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.5459 1H47.7333C44.6133 1 42.084 3.5293 42.084 6.64935V105.396C42.084 108.516 44.6133 111.045 47.7333 111.045H58.5459C61.6659 111.045 64.1952 108.516 64.1952 105.396V6.64935C64.1952 3.5293 61.6659 1 58.5459 1Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.3018 2.37891H45.4892C42.3691 2.37891 39.8398 4.90821 39.8398 8.02826V106.775C39.8398 109.895 42.3691 112.424 45.4892 112.424H56.3018C59.4218 112.424 61.9511 109.895 61.9511 106.775V8.02826C61.9511 4.90821 59.4218 2.37891 56.3018 2.37891Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.5882 10.0444H47.8024C44.9786 10.0444 42.6895 12.3336 42.6895 15.1573V104.52C42.6895 107.344 44.9786 109.633 47.8024 109.633H57.5882C60.4119 109.633 62.7011 107.344 62.7011 104.52V15.1573C62.7011 12.3336 60.4119 10.0444 57.5882 10.0444Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__ga__small">
          <path
            d="M90.3272 52.4233H76.4637C74.1862 52.4233 72.3398 54.2697 72.3398 56.5472V106.922C72.3398 109.2 74.1862 111.046 76.4637 111.046H90.3272C92.6048 111.046 94.4511 109.2 94.4511 106.922V56.5472C94.4511 54.2697 92.6048 52.4233 90.3272 52.4233Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M88.0812 53.8013H74.2176C71.9401 53.8013 70.0938 55.6476 70.0938 57.9251V108.3C70.0938 110.577 71.9401 112.424 74.2176 112.424H88.0812C90.3587 112.424 92.205 110.577 92.205 108.3V57.9251C92.205 55.6476 90.3587 53.8013 88.0812 53.8013Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M85.6857 56.4424H73.1423C71.08 56.4424 69.4082 58.1142 69.4082 60.1765V105.761C69.4082 107.823 71.08 109.495 73.1423 109.495H85.6857C87.748 109.495 89.4198 107.823 89.4198 105.761V60.1765C89.4198 58.1142 87.748 56.4424 85.6857 56.4424Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M94.5265 52.4233H80.6629C78.3854 52.4233 76.5391 54.2697 76.5391 56.5472V106.922C76.5391 109.2 78.3854 111.046 80.6629 111.046H94.5265C96.804 111.046 98.6503 109.2 98.6503 106.922V56.5472C98.6503 54.2697 96.804 52.4233 94.5265 52.4233Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M92.2804 53.8013H78.4168C76.1393 53.8013 74.293 55.6476 74.293 57.9251V108.3C74.293 110.577 76.1393 112.424 78.4168 112.424H92.2804C94.5579 112.424 96.4042 110.577 96.4042 108.3V57.9251C96.4042 55.6476 94.5579 53.8013 92.2804 53.8013Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M89.8869 56.4424H77.3435C75.2812 56.4424 73.6094 58.1142 73.6094 60.1765V105.761C73.6094 107.823 75.2812 109.495 77.3435 109.495H89.8869C91.9492 109.495 93.621 107.823 93.621 105.761V60.1765C93.621 58.1142 91.9492 56.4424 89.8869 56.4424Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </svg>
  );
};
