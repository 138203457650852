interface ScrollTriggererConfig {
  hiddenClassName: string;
}

export class ScrollTriggerer {
  private hiddenClassName: string;
  private elements: Element[];

  constructor(config: ScrollTriggererConfig) {
    this.hiddenClassName = config.hiddenClassName;

    this.elements = [...Array.from(document.querySelectorAll('.' + this.hiddenClassName))];

    window.addEventListener('scroll', this.onScroll);

    this.onScroll();
  }

  onScroll = () => {
    if (this.elements.length === 0) {
      this.remove();
      return;
    }

    this.elements = this.elements.filter((el) => {
      const shouldBeTriggered = el.getBoundingClientRect().top < window.innerHeight / 1.4;
      if (shouldBeTriggered) {
        el.classList.remove(this.hiddenClassName);
      }
      return !shouldBeTriggered;
    });
  };

  remove = () => {
    window.removeEventListener('scroll', this.onScroll);
  };
}
