import { useRef, useEffect } from 'react';
import { Particle } from './Particle';
import './MainText.scss';

const DEFAULT_RADIUS = 150;
const TEXT = 'PŘEDSTAVÍME VÁS SVĚTU';

export interface MainTextMouseData {
  x?: number;
  y?: number;
  radius: number;
}

export const MainText = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (!canvasElement) return;

    const ctx = canvasElement.getContext('2d');
    if (!ctx) return;

    canvasElement.width = window.innerWidth;
    canvasElement.height = window.innerHeight;

    let particleArray: Particle[] | null = [];

    const mouse: MainTextMouseData = {
      x: undefined,
      y: undefined,
      radius: DEFAULT_RADIUS,
    };

    const windowTouchstartHandler = (event: TouchEvent) => {
      mouse.x = event.touches[0].screenX;
      mouse.y = event.touches[0].screenY + document.documentElement.scrollTop;
    };
    const windowTouchendHandler = () => {
      mouse.x = -100;
      mouse.y = -100;
    };
    const windowTouchcancelHandler = () => {
      mouse.x = -100;
      mouse.y = -100;
    };
    const windowMousemoveHandler = (event: MouseEvent) => {
      mouse.x = event.x;
      mouse.y = event.y + document.documentElement.scrollTop;
    };
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      window.addEventListener('touchstart', windowTouchstartHandler);
      window.addEventListener('touchend', windowTouchendHandler);
      window.addEventListener('touchcancel', windowTouchcancelHandler);
    } else {
      window.addEventListener('mousemove', windowMousemoveHandler);
    }

    const realFontSize = 60;
    let fontSize = 20;
    let scale = 0;

    ctx.fillStyle = 'white';
    ctx.font = fontSize + 'px sans-serif';
    ctx.fillText(TEXT, 0, 80);

    let adjustX = 0;
    let adjustY = 0;

    let isConnect = true;

    let textCoordinates = ctx.getImageData(0, 0, 800, 100);

    ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    const init = () => {
      adjustY = canvasElement.height / 2 - fontSize / 2 - 250;
      scale = 1.1;

      isConnect = true;
      mouse.radius = 150;

      fontSize = 20;

      if (window.innerWidth > 1000) {
        scale = 1.2;
      }
      if (window.innerWidth > 1300) {
        scale = 1.4;
      }
      if (window.innerWidth > 1500) {
        scale = 1.6;
      }
      if (window.innerWidth < 1200) {
        adjustY = canvasElement.height / 2 - fontSize / 2 - 200;
      }
      if (window.innerWidth < 1000) {
        mouse.radius = 100;
        fontSize = 18;
      }
      if (window.innerWidth < 950) {
        scale = 1;
        fontSize = 15;
      }
      if (window.innerWidth < 850) {
        scale = 0.9;
        adjustY = canvasElement.height / 2 - fontSize / 2 - 250;
      }
      if (window.innerWidth < 750) {
        scale = 0.8;
      }
      if (window.innerWidth < 700) {
        scale = 0.73;
        fontSize = 12;
      }
      if (window.innerWidth < 650) {
        scale = 0.7;
      }
      if (window.innerWidth < 600) {
        fontSize = 13;
        scale = 0.6;
        isConnect = false;
        mouse.radius = 50;
      }
      if (window.innerWidth < 510) {
        scale = 0.53;
        adjustY = canvasElement.height / 2 - fontSize / 2 - 100;
      }

      adjustX =
        canvasElement.width / 2 -
        (ctx.measureText(TEXT).width * (realFontSize / fontSize) * scale) / 2;

      particleArray = [];
      for (let y = 0, y2 = textCoordinates.height; y < y2; y++) {
        for (let x = 0, x2 = textCoordinates.width; x < x2; x++) {
          if (textCoordinates.data[y * 4 * x2 + x * 4 + 3] > 128) {
            const positionX = x;
            const positionY = y;

            particleArray.push(
              new Particle({
                x: (scale * positionX * realFontSize) / fontSize + adjustX,
                y: (scale * positionY * realFontSize) / fontSize + adjustY,
                canvasElement: canvasElement,
                mouse: mouse,
              }),
            );
          }
        }
      }
    };

    const resizeUpdate = () => {
      init();

      canvasElement.width = window.innerWidth;
      canvasElement.height = window.innerHeight;
      canvasElement.style.width = `${window.innerWidth}px`;
      canvasElement.style.height = `${window.innerHeight}px`;

      ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

      ctx.fillStyle = 'white';
      ctx.font = fontSize + 'px sans-serif';
      ctx.fillText(TEXT, 0, 80);

      textCoordinates = ctx.getImageData(0, 0, 800, 100);

      ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

      init();
    };

    resizeUpdate();

    const connect = () => {
      if (!particleArray) return;

      let opacityValue = 1;
      for (let a = 0; a < particleArray.length; a++) {
        for (let b = a; b < particleArray.length; b++) {
          const dx = particleArray[a].x - particleArray[b].x;
          const dy = particleArray[a].y - particleArray[b].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 5) {
            opacityValue = 1 - distance / 10;
            ctx.strokeStyle = 'rgba(255, 255, 255' + opacityValue + ')';
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(particleArray[a].x, particleArray[a].y);
            ctx.lineTo(particleArray[b].x, particleArray[b].y);
            ctx.stroke();
            ctx.moveTo(0, 0);
          }
        }
      }
    };

    let animationFrameId: number;
    const animate = () => {
      if (!particleArray) return;

      ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      for (const particle of particleArray) {
        ctx.moveTo(0, 0);
        particle.update();
        particle.draw(ctx);
      }
      if (isConnect) {
        connect();
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    canvasElement.style.opacity = '0';
    const timeout = setTimeout(() => {
      canvasElement.style.opacity = '1';
      animate();
    }, 3000);

    window.addEventListener('resize', resizeUpdate);

    return () => {
      window.removeEventListener('mousemove', windowMousemoveHandler);
      window.removeEventListener('touchstart', windowTouchstartHandler);
      window.removeEventListener('touchend', windowTouchendHandler);
      window.removeEventListener('touchcancel', windowTouchcancelHandler);
      window.removeEventListener('resize', resizeUpdate);
      clearTimeout(timeout);
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
      particleArray = null;
    };
  }, [canvasRef]);

  return <canvas ref={canvasRef} className="MainText"></canvas>;
};
