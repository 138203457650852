/* eslint-disable max-len */
import { FC } from 'react';
import './IconSites.scss';

interface IconSitesProps {
  className?: string;
}

export const IconSites: FC<IconSitesProps> = ({ className }) => {
  return (
    <svg
      className={'icon__sites ' + className}
      width="155"
      height="235"
      viewBox="0 0 155 235"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__sites__cloud">
          <path
            d="M141.441 218.407V230.363H23.709V218.407C23.7078 214.945 24.3888 211.515 25.7131 208.316C27.0374 205.116 28.9792 202.209 31.4273 199.76C33.8755 197.311 36.7822 195.368 39.9813 194.043C43.1804 192.717 46.6094 192.035 50.0722 192.035H54.3566C60.2881 188.657 70.6983 186.427 82.552 186.427C94.4058 186.427 104.825 188.657 110.738 192.035H115.032C118.499 192.029 121.932 192.707 125.137 194.029C128.341 195.352 131.254 197.294 133.707 199.743C136.16 202.193 138.106 205.102 139.433 208.305C140.76 211.508 141.443 214.941 141.441 218.407V218.407Z"
            stroke="#1DA88F"
            strokeWidth="0.96"
            strokeMiterlimit="10"
          />
          <path
            d="M144.504 215.464V227.42H26.8086V215.464C26.8074 212.001 27.4884 208.572 28.8127 205.372C30.1371 202.173 32.0788 199.265 34.5269 196.816C36.9751 194.367 39.8818 192.425 43.0809 191.099C46.2801 189.774 49.709 189.092 53.1718 189.092H57.4562C63.3784 185.723 73.7886 183.493 85.6516 183.493C97.5146 183.493 107.925 185.723 113.838 189.092H118.131C125.126 189.092 131.834 191.87 136.78 196.816C141.725 201.762 144.504 208.47 144.504 215.464V215.464Z"
            stroke="#1DA88F"
            strokeWidth="0.96"
            strokeMiterlimit="10"
          />
          <path
            d="M137.833 217.796V229.021H27.2813V217.796C27.28 214.544 27.9196 211.323 29.1635 208.317C30.4074 205.312 32.2312 202.581 34.5307 200.281C36.8302 197.98 39.5604 196.155 42.5653 194.91C45.5703 193.665 48.791 193.025 52.0436 193.025H56.0689C61.621 189.86 71.4205 187.769 82.5524 187.769C93.6844 187.769 103.502 189.86 109.054 193.025H113.089C119.655 193.029 125.95 195.642 130.59 200.287C135.23 204.932 137.835 211.23 137.833 217.796V217.796Z"
            stroke="#1DA88F"
            strokeWidth="0.91"
            strokeMiterlimit="10"
          />
          <path
            d="M135.259 220.471V231.705H24.7168V220.471C24.7156 217.219 25.3549 213.999 26.5982 210.994C27.8415 207.989 29.6644 205.259 31.963 202.959C34.2615 200.658 36.9906 198.833 39.9945 197.588C42.9983 196.342 46.2181 195.701 49.4699 195.699H53.5322C59.0843 192.535 68.8745 190.443 80.0157 190.443C91.157 190.443 100.938 192.535 106.49 195.699H110.525C117.089 195.707 123.381 198.32 128.02 202.965C132.658 207.61 135.262 213.907 135.259 220.471V220.471Z"
            stroke="#1DA88F"
            strokeWidth="0.91"
            strokeMiterlimit="10"
          />
          <path
            d="M136.814 221.184V233.14H19.082V221.184C19.0808 217.721 19.7618 214.292 21.0862 211.093C22.4105 207.893 24.3522 204.986 26.8004 202.537C29.2485 200.088 32.1552 198.145 35.3544 196.819C38.5535 195.494 41.9824 194.812 45.4453 194.812H49.7296C55.6611 191.434 66.0713 189.204 77.9251 189.204C89.7788 189.204 100.198 191.434 106.111 194.812H110.405C113.872 194.806 117.306 195.483 120.51 196.806C123.714 198.129 126.627 200.071 129.08 202.52C131.533 204.97 133.479 207.879 134.806 211.082C136.133 214.285 136.816 217.717 136.814 221.184V221.184Z"
            stroke="#05F2C7"
            strokeWidth="0.96"
            strokeMiterlimit="10"
          />
          <path
            d="M139.877 218.24V230.196H22.1816V218.24C22.1804 214.778 22.8614 211.348 24.1858 208.149C25.5101 204.949 27.4518 202.042 29.9 199.593C32.3482 197.144 35.2548 195.201 38.454 193.876C41.6531 192.55 45.082 191.868 48.5449 191.868H52.8292C58.7515 188.5 69.1617 186.27 81.0247 186.27C92.8877 186.27 103.298 188.5 109.211 191.868H113.504C120.499 191.868 127.207 194.646 132.153 199.592C137.098 204.538 139.877 211.246 139.877 218.24V218.24Z"
            stroke="#05F2C7"
            strokeWidth="0.96"
            strokeMiterlimit="10"
          />
          <path
            d="M133.206 220.573V231.797H22.6543V220.573C22.6531 217.32 23.2927 214.099 24.5366 211.094C25.7804 208.088 27.6042 205.357 29.9038 203.057C32.2033 200.757 34.9335 198.932 37.9384 197.687C40.9433 196.442 44.1641 195.801 47.4167 195.801H51.4419C56.9941 192.636 66.7935 190.545 77.9255 190.545C89.0574 190.545 98.8754 192.636 104.428 195.801H108.462C115.028 195.806 121.323 198.418 125.963 203.063C130.603 207.709 133.208 214.007 133.206 220.573V220.573Z"
            stroke="#05F2C7"
            strokeWidth="0.91"
            strokeMiterlimit="10"
          />
          <path
            d="M130.632 223.247V234.481H20.0898V223.247C20.0886 219.996 20.7279 216.775 21.9712 213.77C23.2145 210.766 25.0375 208.035 27.336 205.735C29.6346 203.435 32.3637 201.61 35.3675 200.364C38.3714 199.119 41.5911 198.477 44.843 198.476H48.9053C54.4574 195.311 64.2476 193.22 75.3888 193.22C86.53 193.22 96.311 195.311 101.863 198.476H105.898C112.462 198.483 118.754 201.096 123.393 205.742C128.031 210.387 130.635 216.683 130.632 223.247V223.247Z"
            stroke="#05F2C7"
            strokeWidth="0.91"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__sites__person">
          <path
            d="M115.587 99.527C115.506 101.042 115.109 102.524 114.421 103.876C109.477 114.241 106.156 125.305 104.575 136.68C103.489 143.89 103.062 151.184 103.298 158.472C99.2761 161.188 94.4649 162.49 89.6217 162.173L67.2745 160.831C62.4755 160.548 57.902 158.698 54.2548 155.566C54.9538 149.956 55.263 144.304 55.1802 138.651C55.112 126.959 53.1976 115.35 49.5078 104.256C48.9623 102.716 48.7234 101.084 48.8045 99.453L49.6373 85.6375L68.1999 77.2353C70.2516 76.2974 71.8485 74.5851 72.641 72.4729C73.4335 70.3607 73.3572 68.0206 72.4287 65.9645L72.0586 65.1317C71.1954 63.2708 69.694 61.7809 67.8265 60.932C65.9591 60.0832 63.8493 59.9317 61.8797 60.5049C65.4497 58.6166 69.4711 57.7489 73.5021 57.9972L82.3485 58.534L82.6076 54.4717C82.7741 54.6567 82.9407 54.8326 83.1258 55.0176L83.4219 55.3137C84.6619 56.5552 86.1505 57.5202 87.79 58.1454C89.4295 58.7706 91.1827 59.0417 92.9345 58.9411C93.1195 58.9411 93.3231 58.9411 93.5082 58.8671C93.7905 58.8165 94.0666 58.7358 94.3317 58.6265V58.6265C94.587 58.5281 94.8345 58.4106 95.072 58.2749C95.2393 58.1808 95.4 58.0757 95.5532 57.9602C95.7915 57.7956 96.0145 57.6097 96.2195 57.405C96.4313 57.2027 96.6292 56.9863 96.8117 56.7573L100.855 51.6216L99.3749 59.8572C104.252 60.9828 108.611 63.7096 111.756 67.6024C110.215 66.3849 108.301 65.7381 106.338 65.7719C104.374 65.8056 102.483 66.5178 100.985 67.7875L100.282 68.3797C98.5639 69.8523 97.5014 71.9469 97.3278 74.2028C97.1543 76.4588 97.884 78.6913 99.3564 80.4092L115.587 99.527Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M117.576 23.6118C117.623 27.1045 116.758 30.549 115.068 33.6056L111.08 38.6673L107.896 42.7111L100.864 51.6315L96.82 56.7671C96.3551 57.3832 95.767 57.8956 95.093 58.2716C94.4191 58.6476 93.6741 58.879 92.9057 58.951C91.154 59.0516 89.4008 58.7804 87.7613 58.1553C86.1218 57.5301 84.6331 56.5651 83.3931 55.3236L83.097 55.0275C82.9119 54.8424 82.7454 54.6666 82.5788 54.4815C80.967 52.6805 79.9746 50.4104 79.7473 48.0041L78.6091 36.3262L77.8318 28.3866V28.3126C77.4961 26.8591 77.3285 25.3719 77.3321 23.8802C77.3315 18.6638 79.3581 13.6514 82.984 9.9013C86.6098 6.15116 91.5511 3.95681 96.7644 3.78157C102.152 3.65979 107.367 5.67872 111.268 9.39588C115.169 13.113 117.437 18.2252 117.576 23.6118V23.6118Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M120.214 96.7506C120.133 98.2658 119.736 99.7472 119.048 101.1C114.104 111.465 110.783 122.529 109.202 133.903C108.116 141.114 107.689 148.408 107.925 155.695C103.903 158.412 99.0919 159.714 94.2487 159.397L71.9015 158.055C67.1025 157.771 62.529 155.922 58.8818 152.79C59.5807 147.18 59.8899 141.528 59.8071 135.874C59.739 124.182 57.8246 112.574 54.1347 101.479C53.5893 99.9395 53.3504 98.308 53.4315 96.6766L54.2643 82.8611L72.8268 74.4589C74.8786 73.521 76.4754 71.8087 77.2679 69.6965C78.0604 67.5843 77.9841 65.2442 77.0557 63.1881L76.6855 62.3553C75.8224 60.4945 74.3209 59.0045 72.4535 58.1557C70.586 57.3068 68.4762 57.1553 66.5067 57.7286C70.0766 55.8402 74.0981 54.9725 78.1291 55.2209L86.9754 55.7576L87.2345 51.6953C87.4011 51.8804 87.5676 52.0562 87.7527 52.2413L88.0488 52.5374C89.2888 53.7788 90.7775 54.7438 92.4169 55.369C94.0564 55.9942 95.8097 56.2654 97.5614 56.1647C97.7465 56.1647 97.9501 56.1647 98.1351 56.0907C98.4174 56.0401 98.6935 55.9594 98.9587 55.8501V55.8501C99.214 55.7517 99.4614 55.6342 99.699 55.4985C99.8662 55.4045 100.027 55.2994 100.18 55.1839C100.418 55.0192 100.641 54.8334 100.846 54.6287C101.058 54.4264 101.256 54.21 101.439 53.9809L105.482 48.8452L104.002 57.0808C108.878 58.2065 113.237 60.9333 116.383 64.826C114.842 63.6086 112.928 62.9618 110.964 62.9955C109.001 63.0292 107.11 63.7414 105.612 65.0111L104.909 65.6033C103.191 67.0759 102.128 69.1705 101.955 71.4265C101.781 73.6824 102.511 75.9149 103.983 77.6329L120.214 96.7506Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M122.203 20.8355C122.25 24.3281 121.385 27.7726 119.695 30.8293L115.707 35.8909L112.523 39.9347L105.491 48.8551L101.447 53.9908C100.982 54.6068 100.394 55.1192 99.72 55.4952C99.046 55.8713 98.3011 56.1026 97.5327 56.1746C95.7809 56.2752 94.0277 56.0041 92.3882 55.3789C90.7487 54.7537 89.2601 53.7887 88.0201 52.5472L87.724 52.2511C87.5389 52.066 87.3723 51.8902 87.2058 51.7052C85.5939 49.9041 84.6015 47.634 84.3742 45.2277L83.236 33.5498L82.4587 25.6103V25.5362C82.1231 24.0828 81.9554 22.5956 81.959 21.1038C81.9585 15.8875 83.9851 10.8751 87.6109 7.12494C91.2367 3.3748 96.178 1.18044 101.391 1.00521C106.778 0.883418 111.994 2.90235 115.895 6.61951C119.796 10.3367 122.064 15.4488 122.203 20.8355V20.8355Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M120.214 96.7506C120.133 98.2658 119.736 99.7472 119.048 101.1C114.104 111.465 110.783 122.529 109.202 133.903C108.116 141.114 107.689 148.408 107.925 155.695C103.903 158.412 99.0919 159.714 94.2487 159.397L71.9015 158.055C67.1025 157.771 62.529 155.922 58.8818 152.79C59.5807 147.18 59.8899 141.528 59.8071 135.874C59.739 124.182 57.8246 112.574 54.1347 101.479C53.5893 99.9395 53.3504 98.308 53.4315 96.6766L54.2643 82.8611L72.8268 74.4589C74.8786 73.521 76.4754 71.8087 77.2679 69.6965C78.0604 67.5843 77.9841 65.2442 77.0557 63.1881L76.6855 62.3553C75.8224 60.4945 74.3209 59.0045 72.4535 58.1557C70.586 57.3068 68.4762 57.1553 66.5067 57.7286C70.0766 55.8402 74.0981 54.9725 78.1291 55.2209L86.9754 55.7576L87.2345 51.6953C87.4011 51.8804 87.5676 52.0562 87.7527 52.2413L88.0488 52.5374C89.2888 53.7788 90.7775 54.7438 92.4169 55.369C94.0564 55.9942 95.8097 56.2654 97.5614 56.1647C97.7465 56.1647 97.9501 56.1647 98.1351 56.0907C98.4174 56.0401 98.6935 55.9594 98.9587 55.8501V55.8501C99.214 55.7517 99.4614 55.6342 99.699 55.4985C99.8662 55.4045 100.027 55.2994 100.18 55.1839C100.418 55.0192 100.641 54.8334 100.846 54.6287C101.058 54.4264 101.256 54.21 101.439 53.9809L105.482 48.8452L104.002 57.0808C108.878 58.2065 113.237 60.9333 116.383 64.826C114.842 63.6086 112.928 62.9618 110.964 62.9955C109.001 63.0292 107.11 63.7414 105.612 65.0111L104.909 65.6033C103.191 67.0759 102.128 69.1705 101.955 71.4265C101.781 73.6824 102.511 75.9149 103.983 77.6329L120.214 96.7506Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M122.203 20.8355C122.25 24.3281 121.385 27.7726 119.695 30.8293L115.707 35.8909L112.523 39.9347L105.491 48.8551L101.447 53.9908C100.982 54.6068 100.394 55.1192 99.72 55.4952C99.046 55.8713 98.3011 56.1026 97.5327 56.1746C95.7809 56.2752 94.0277 56.0041 92.3882 55.3789C90.7487 54.7537 89.2601 53.7887 88.0201 52.5472L87.724 52.2511C87.5389 52.066 87.3723 51.8902 87.2058 51.7052C85.5939 49.9041 84.6015 47.634 84.3742 45.2277L83.236 33.5498L82.4587 25.6103V25.5362C82.1231 24.0828 81.9554 22.5956 81.959 21.1038C81.9585 15.8875 83.9851 10.8751 87.6109 7.12494C91.2367 3.3748 96.178 1.18044 101.391 1.00521C106.778 0.883418 111.994 2.90235 115.895 6.61951C119.796 10.3367 122.064 15.4488 122.203 20.8355V20.8355Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M152.545 110.807C152.545 111.073 152.527 111.339 152.49 111.603V111.658C152.473 111.879 152.43 112.096 152.36 112.306C152.369 112.349 152.369 112.393 152.36 112.436L141.774 153.956C141.578 154.817 141.211 155.63 140.695 156.347C140.179 157.064 139.525 157.671 138.771 158.131C138.017 158.591 137.179 158.895 136.305 159.027C135.432 159.158 134.541 159.112 133.685 158.894C132.83 158.675 132.026 158.287 131.323 157.753C130.62 157.219 130.03 156.549 129.59 155.784C129.149 155.019 128.866 154.173 128.758 153.296C128.649 152.42 128.717 151.53 128.958 150.68L137.527 117.035C137.317 116.856 137.125 116.658 136.953 116.442L120.241 96.7694L103.937 77.6147C102.464 75.8967 101.734 73.6642 101.908 71.4083C102.081 69.1523 103.144 67.0577 104.862 65.5851L105.565 64.9929C107.063 63.7232 108.954 63.0111 110.918 62.9773C112.881 62.9436 114.796 63.5904 116.336 64.8078C116.336 64.8078 116.336 64.8078 116.336 64.8078C116.771 65.1391 117.171 65.5143 117.53 65.9275L150.519 104.792C151.898 106.421 152.594 108.52 152.462 110.65C152.485 110.669 152.505 110.693 152.519 110.72C152.534 110.747 152.542 110.777 152.545 110.807Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M72.7619 74.4963L54.1993 82.8985L26.3463 95.5481C24.6167 96.328 22.6763 96.5085 20.8325 96.061C18.9887 95.6135 17.347 94.5635 16.1674 93.0774C14.8758 92.0183 14.0382 90.5053 13.8263 88.8485L8.37598 46.3102C8.33906 46.0341 8.32051 45.7559 8.32046 45.4773C8.30929 43.7865 8.94593 42.1555 10.0996 40.9194C11.2533 39.6832 12.8365 38.9357 14.5241 38.8303C16.2118 38.7249 17.8757 39.2696 19.1742 40.3526C20.4728 41.4356 21.3074 42.9747 21.5067 44.6538L25.569 76.2082L65.3591 58.1639C65.7216 57.9928 66.0997 57.8565 66.488 57.7567C68.4576 57.1835 70.5674 57.335 72.4348 58.1838C74.3023 59.0327 75.8037 60.5226 76.6668 62.3835L77.037 63.2163C77.9654 65.2791 78.0365 67.6262 77.2348 69.7414C76.4332 71.8567 74.8243 73.567 72.7619 74.4963Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M120.214 96.7506C120.133 98.2658 119.736 99.7472 119.048 101.1C114.104 111.465 110.783 122.529 109.202 133.903C108.116 141.114 107.689 148.408 107.925 155.695C103.903 158.412 99.0919 159.714 94.2487 159.397L71.9015 158.055C67.1025 157.771 62.529 155.922 58.8818 152.79C59.5807 147.18 59.8899 141.528 59.8071 135.874C59.739 124.182 57.8246 112.574 54.1347 101.479C53.5893 99.9395 53.3504 98.308 53.4315 96.6766L54.2643 82.8611L72.8268 74.4589C74.8786 73.521 76.4754 71.8087 77.2679 69.6965C78.0604 67.5843 77.9841 65.2442 77.0557 63.1881L76.6855 62.3553C75.8224 60.4945 74.3209 59.0045 72.4535 58.1557C70.586 57.3068 68.4762 57.1553 66.5067 57.7286C70.0766 55.8402 74.0981 54.9725 78.1291 55.2209L86.9754 55.7576L87.2345 51.6953C87.4011 51.8804 87.5676 52.0562 87.7527 52.2413L88.0488 52.5374C89.2888 53.7788 90.7775 54.7438 92.4169 55.369C94.0564 55.9942 95.8097 56.2654 97.5614 56.1647C97.7465 56.1647 97.9501 56.1647 98.1351 56.0907C98.4174 56.0401 98.6935 55.9594 98.9587 55.8501V55.8501C99.214 55.7517 99.4614 55.6342 99.699 55.4985C99.8662 55.4045 100.027 55.2994 100.18 55.1839C100.418 55.0192 100.641 54.8334 100.846 54.6287C101.058 54.4264 101.256 54.21 101.439 53.9809L105.482 48.8452L104.002 57.0808C108.878 58.2065 113.237 60.9333 116.383 64.826C114.842 63.6086 112.928 62.9618 110.964 62.9955C109.001 63.0292 107.11 63.7414 105.612 65.0111L104.909 65.6033C103.191 67.0759 102.128 69.1705 101.955 71.4265C101.781 73.6824 102.511 75.9149 103.983 77.6329L120.214 96.7506Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M122.203 20.8355C122.25 24.3281 121.385 27.7726 119.695 30.8293L115.707 35.8909L112.523 39.9347L105.491 48.8551L101.447 53.9908C100.982 54.6068 100.394 55.1192 99.72 55.4952C99.046 55.8713 98.3011 56.1026 97.5327 56.1746C95.7809 56.2752 94.0277 56.0041 92.3882 55.3789C90.7487 54.7537 89.2601 53.7887 88.0201 52.5472L87.724 52.2511C87.5389 52.066 87.3723 51.8902 87.2058 51.7052C85.5939 49.9041 84.6015 47.634 84.3742 45.2277L83.236 33.5498L82.4587 25.6103V25.5362C82.1231 24.0828 81.9554 22.5956 81.959 21.1038C81.9585 15.8875 83.9851 10.8751 87.6109 7.12494C91.2367 3.3748 96.178 1.18044 101.391 1.00521C106.778 0.883418 111.994 2.90235 115.895 6.61951C119.796 10.3367 122.064 15.4488 122.203 20.8355V20.8355Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M120.214 96.7506C120.133 98.2658 119.736 99.7472 119.048 101.1C114.104 111.465 110.783 122.529 109.202 133.903C108.116 141.114 107.689 148.408 107.925 155.695C103.903 158.412 99.0919 159.714 94.2487 159.397L71.9015 158.055C67.1025 157.771 62.529 155.922 58.8818 152.79C59.5807 147.18 59.8899 141.528 59.8071 135.874C59.739 124.182 57.8246 112.574 54.1347 101.479C53.5893 99.9395 53.3504 98.308 53.4315 96.6766L54.2643 82.8611L72.8268 74.4589C74.8786 73.521 76.4754 71.8087 77.2679 69.6965C78.0604 67.5843 77.9841 65.2442 77.0557 63.1881L76.6855 62.3553C75.8224 60.4945 74.3209 59.0045 72.4535 58.1557C70.586 57.3068 68.4762 57.1553 66.5067 57.7286C70.0766 55.8402 74.0981 54.9725 78.1291 55.2209L86.9754 55.7576L87.2345 51.6953C87.4011 51.8804 87.5676 52.0562 87.7527 52.2413L88.0488 52.5374C89.2888 53.7788 90.7775 54.7438 92.4169 55.369C94.0564 55.9942 95.8097 56.2654 97.5614 56.1647C97.7465 56.1647 97.9501 56.1647 98.1351 56.0907C98.4174 56.0401 98.6935 55.9594 98.9587 55.8501V55.8501C99.214 55.7517 99.4614 55.6342 99.699 55.4985C99.8662 55.4045 100.027 55.2994 100.18 55.1839C100.418 55.0192 100.641 54.8334 100.846 54.6287C101.058 54.4264 101.256 54.21 101.439 53.9809L105.482 48.8452L104.002 57.0808C108.878 58.2065 113.237 60.9333 116.383 64.826C114.842 63.6086 112.928 62.9618 110.964 62.9955C109.001 63.0292 107.11 63.7414 105.612 65.0111L104.909 65.6033C103.191 67.0759 102.128 69.1705 101.955 71.4265C101.781 73.6824 102.511 75.9149 103.983 77.6329L120.214 96.7506Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M122.203 20.8355C122.25 24.3281 121.385 27.7726 119.695 30.8293L115.707 35.8909L112.523 39.9347L105.491 48.8551L101.447 53.9908C100.982 54.6068 100.394 55.1192 99.72 55.4952C99.046 55.8713 98.3011 56.1026 97.5327 56.1746C95.7809 56.2752 94.0277 56.0041 92.3882 55.3789C90.7487 54.7537 89.2601 53.7887 88.0201 52.5472L87.724 52.2511C87.5389 52.066 87.3723 51.8902 87.2058 51.7052C85.5939 49.9041 84.6015 47.634 84.3742 45.2277L83.236 33.5498L82.4587 25.6103V25.5362C82.1231 24.0828 81.9554 22.5956 81.959 21.1038C81.9585 15.8875 83.9851 10.8751 87.6109 7.12494C91.2367 3.3748 96.178 1.18044 101.391 1.00521C106.778 0.883418 111.994 2.90235 115.895 6.61951C119.796 10.3367 122.064 15.4488 122.203 20.8355V20.8355Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M152.545 110.807C152.545 111.073 152.527 111.339 152.49 111.603V111.658C152.473 111.879 152.43 112.096 152.36 112.306C152.369 112.349 152.369 112.393 152.36 112.436L141.774 153.956C141.578 154.817 141.211 155.63 140.695 156.347C140.179 157.064 139.525 157.671 138.771 158.131C138.017 158.591 137.179 158.895 136.305 159.027C135.432 159.158 134.541 159.112 133.685 158.894C132.83 158.675 132.026 158.287 131.323 157.753C130.62 157.219 130.03 156.549 129.59 155.784C129.149 155.019 128.866 154.173 128.758 153.296C128.649 152.42 128.717 151.53 128.958 150.68L137.527 117.035C137.317 116.856 137.125 116.658 136.953 116.442L120.241 96.7694L103.937 77.6147C102.464 75.8967 101.734 73.6642 101.908 71.4083C102.081 69.1523 103.144 67.0577 104.862 65.5851L105.565 64.9929C107.063 63.7232 108.954 63.0111 110.918 62.9773C112.881 62.9436 114.796 63.5904 116.336 64.8078C116.336 64.8078 116.336 64.8078 116.336 64.8078C116.771 65.1391 117.171 65.5143 117.53 65.9275L150.519 104.792C151.898 106.421 152.594 108.52 152.462 110.65C152.485 110.669 152.505 110.693 152.519 110.72C152.534 110.747 152.542 110.777 152.545 110.807Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M72.7619 74.4963L54.1993 82.8985L26.3463 95.5481C24.6167 96.328 22.6763 96.5085 20.8325 96.061C18.9887 95.6135 17.347 94.5635 16.1674 93.0774C14.8758 92.0183 14.0382 90.5053 13.8263 88.8485L8.37598 46.3102C8.33906 46.0341 8.32051 45.7559 8.32046 45.4773C8.30929 43.7865 8.94593 42.1555 10.0996 40.9194C11.2533 39.6832 12.8365 38.9357 14.5241 38.8303C16.2118 38.7249 17.8757 39.2696 19.1742 40.3526C20.4728 41.4356 21.3074 42.9747 21.5067 44.6538L25.569 76.2082L65.3591 58.1639C65.7216 57.9928 66.0997 57.8565 66.488 57.7567C68.4576 57.1835 70.5674 57.335 72.4348 58.1838C74.3023 59.0327 75.8037 60.5226 76.6668 62.3835L77.037 63.2163C77.9654 65.2791 78.0365 67.6262 77.2348 69.7414C76.4332 71.8567 74.8243 73.567 72.7619 74.4963Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M117.539 99.471C117.45 100.97 117.053 102.435 116.373 103.774C111.428 114.139 108.107 125.203 106.527 136.578C105.44 143.794 105.012 151.094 105.25 158.388C101.218 161.106 96.3976 162.408 91.5455 162.089L69.1983 160.738C64.4002 160.459 59.8264 158.612 56.1786 155.482C56.8779 149.869 57.187 144.214 57.104 138.558C57.0343 126.866 55.12 115.258 51.4316 104.163C50.8862 102.626 50.6472 100.998 50.7283 99.3692L51.5611 85.5445L70.1237 77.1423C72.1542 76.1994 73.7321 74.4954 74.5165 72.3985C75.3009 70.3016 75.2287 67.9803 74.3155 65.9363L73.9454 65.1035C73.0822 63.2426 71.5808 61.7527 69.7133 60.9038C67.8459 60.055 65.7361 59.9035 63.7665 60.4767C67.3337 58.5801 71.3567 57.7089 75.3889 57.9598L84.2538 58.5335L84.5129 54.4712C84.6794 54.6563 84.846 54.8413 85.0311 55.0264L85.3272 55.3225C86.5676 56.5602 88.0552 57.5221 89.6928 58.1456C91.3304 58.7691 93.0811 59.04 94.8305 58.9406C95.0156 58.9406 95.2192 58.9406 95.4043 58.8666C95.6898 58.8172 95.9691 58.7365 96.2371 58.626V58.626C96.4923 58.5276 96.7398 58.4101 96.9773 58.2744C97.1446 58.1804 97.3053 58.0752 97.4585 57.9598C97.6944 57.7953 97.9172 57.6128 98.1248 57.4138C98.3366 57.2115 98.5345 56.9951 98.717 56.7661L102.761 51.6304L101.28 59.866C106.159 60.9708 110.526 63.6821 113.68 67.5649C112.139 66.3531 110.226 65.7098 108.266 65.7436C106.306 65.7773 104.417 66.4859 102.918 67.75L102.215 68.3422C100.499 69.8161 99.4382 71.9104 99.2647 74.1656C99.0913 76.4208 99.8194 78.6528 101.289 80.3718L117.539 99.471Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M119.529 23.5551C119.57 27.0471 118.706 30.4902 117.021 33.5489L113.033 38.6106L109.85 42.6543L102.817 51.5747L98.7731 56.7104C98.3063 57.3259 97.7163 57.8371 97.0407 58.2116C96.3651 58.586 95.6189 58.8154 94.8496 58.885C93.1002 58.9843 91.3494 58.7134 89.7119 58.0899C88.0743 57.4665 86.5867 56.5045 85.3462 55.2669L84.9946 54.9893C84.8095 54.8042 84.643 54.6191 84.4764 54.4341C82.8612 52.6352 81.8683 50.3639 81.6448 47.9566L80.5622 36.2417L79.7849 28.3022V28.2281C79.4498 26.7745 79.2821 25.2874 79.2852 23.7957C79.2847 18.5794 81.3112 13.567 84.9371 9.81681C88.5629 6.06667 93.5042 3.87232 98.7176 3.69709C104.109 3.57537 109.329 5.5981 113.231 9.32122C117.133 13.0443 119.398 18.1636 119.529 23.5551V23.5551Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M117.539 99.471C117.45 100.97 117.053 102.435 116.373 103.774C111.428 114.139 108.107 125.203 106.527 136.578C105.44 143.794 105.012 151.094 105.25 158.388C101.218 161.106 96.3976 162.408 91.5455 162.089L69.1983 160.738C64.4002 160.459 59.8264 158.612 56.1786 155.482C56.8779 149.869 57.187 144.214 57.104 138.558C57.0343 126.866 55.12 115.258 51.4316 104.163C50.8862 102.626 50.6472 100.998 50.7283 99.3692L51.5611 85.5445L70.1237 77.1423C72.1542 76.1994 73.7321 74.4954 74.5165 72.3985C75.3009 70.3016 75.2287 67.9803 74.3155 65.9363L73.9454 65.1035C73.0822 63.2426 71.5808 61.7527 69.7133 60.9038C67.8459 60.055 65.7361 59.9035 63.7665 60.4767C67.3337 58.5801 71.3567 57.7089 75.3889 57.9598L84.2538 58.5335L84.5129 54.4712C84.6794 54.6563 84.846 54.8413 85.0311 55.0264L85.3272 55.3225C86.5676 56.5602 88.0552 57.5221 89.6928 58.1456C91.3304 58.7691 93.0811 59.04 94.8305 58.9406C95.0156 58.9406 95.2192 58.9406 95.4043 58.8666C95.6898 58.8172 95.9691 58.7365 96.2371 58.626V58.626C96.4923 58.5276 96.7398 58.4101 96.9773 58.2744C97.1446 58.1804 97.3053 58.0752 97.4585 57.9598C97.6944 57.7953 97.9172 57.6128 98.1248 57.4138C98.3366 57.2115 98.5345 56.9951 98.717 56.7661L102.761 51.6304L101.28 59.866C106.159 60.9708 110.526 63.6821 113.68 67.5649C112.139 66.3531 110.226 65.7098 108.266 65.7436C106.306 65.7773 104.417 66.4859 102.918 67.75L102.215 68.3422C100.499 69.8161 99.4382 71.9104 99.2647 74.1656C99.0913 76.4208 99.8194 78.6528 101.289 80.3718L117.539 99.471Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M119.529 23.5551C119.57 27.0471 118.706 30.4902 117.021 33.5489L113.033 38.6106L109.85 42.6543L102.817 51.5747L98.7731 56.7104C98.3063 57.3259 97.7163 57.8371 97.0407 58.2116C96.3651 58.586 95.6189 58.8154 94.8496 58.885C93.1002 58.9843 91.3494 58.7134 89.7119 58.0899C88.0743 57.4665 86.5867 56.5045 85.3462 55.2669L84.9946 54.9893C84.8095 54.8042 84.643 54.6191 84.4764 54.4341C82.8612 52.6352 81.8683 50.3639 81.6448 47.9566L80.5622 36.2417L79.7849 28.3022V28.2281C79.4498 26.7745 79.2821 25.2874 79.2852 23.7957C79.2847 18.5794 81.3112 13.567 84.9371 9.81681C88.5629 6.06667 93.5042 3.87232 98.7176 3.69709C104.109 3.57537 109.329 5.5981 113.231 9.32122C117.133 13.0443 119.398 18.1636 119.529 23.5551V23.5551Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M149.862 113.528C149.862 113.791 149.843 114.054 149.807 114.314V114.37C149.79 114.59 149.747 114.808 149.677 115.018C149.686 115.06 149.686 115.104 149.677 115.147L139.091 156.668C138.9 157.533 138.536 158.352 138.022 159.074C137.508 159.796 136.854 160.408 136.099 160.873C135.344 161.337 134.503 161.646 133.627 161.779C132.751 161.913 131.856 161.868 130.997 161.65C130.138 161.431 129.332 161.041 128.626 160.504C127.92 159.968 127.33 159.295 126.889 158.525C126.449 157.756 126.167 156.906 126.062 156.026C125.956 155.145 126.029 154.253 126.275 153.401L134.844 119.746C134.634 119.568 134.442 119.369 134.27 119.154L117.558 99.4903L101.263 80.3355C99.7928 78.6165 99.0646 76.3846 99.2381 74.1294C99.4115 71.8742 100.472 69.7799 102.188 68.306L102.891 67.7137C104.39 66.4497 106.279 65.741 108.239 65.7073C110.2 65.6736 112.112 66.3169 113.653 67.5287C113.653 67.5287 113.653 67.5287 113.653 67.5287C114.094 67.8605 114.497 68.239 114.856 68.6576L147.919 107.578C149.3 109.206 149.996 111.305 149.862 113.435C149.868 113.466 149.868 113.497 149.862 113.528Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M70.0872 77.2071L51.5246 85.6093L23.6716 98.2496C21.941 99.0285 20 99.2076 18.1561 98.7584C16.3122 98.3091 14.6711 97.2573 13.4927 95.7696C12.1925 94.7165 11.3502 93.2011 11.1423 91.5408L5.68278 49.0209C5.64586 48.7449 5.62731 48.4666 5.62726 48.1881C5.611 46.4955 6.24412 44.861 7.39635 43.6209C8.54858 42.3809 10.1323 41.6296 11.8216 41.5218C13.5108 41.4139 15.1772 41.9576 16.4778 43.041C17.7784 44.1244 18.6143 45.6651 18.8135 47.3461L22.8943 78.8913L62.6844 60.8469C63.0418 60.6788 63.4134 60.5426 63.7948 60.4398C65.7644 59.8665 67.8742 60.018 69.7416 60.8669C71.6091 61.7157 73.1105 63.2056 73.9736 65.0665L74.3438 65.8993C74.8096 66.9209 75.069 68.0244 75.1071 69.1465C75.1452 70.2686 74.9611 71.3871 74.5656 72.4379C74.1701 73.4886 73.5708 74.4509 72.8023 75.2694C72.0337 76.0878 71.111 76.7463 70.0872 77.2071Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M117.539 99.471C117.45 100.97 117.053 102.435 116.373 103.774C111.428 114.139 108.107 125.203 106.527 136.578C105.44 143.794 105.012 151.094 105.25 158.388C101.218 161.106 96.3976 162.408 91.5455 162.089L69.1983 160.738C64.4002 160.459 59.8264 158.612 56.1786 155.482C56.8779 149.869 57.187 144.214 57.104 138.558C57.0343 126.866 55.12 115.258 51.4316 104.163C50.8862 102.626 50.6472 100.998 50.7283 99.3692L51.5611 85.5445L70.1237 77.1423C72.1542 76.1994 73.7321 74.4954 74.5165 72.3985C75.3009 70.3016 75.2287 67.9803 74.3155 65.9363L73.9454 65.1035C73.0822 63.2426 71.5808 61.7527 69.7133 60.9038C67.8459 60.055 65.7361 59.9035 63.7665 60.4767C67.3337 58.5801 71.3567 57.7089 75.3889 57.9598L84.2538 58.5335L84.5129 54.4712C84.6794 54.6563 84.846 54.8413 85.0311 55.0264L85.3272 55.3225C86.5676 56.5602 88.0552 57.5221 89.6928 58.1456C91.3304 58.7691 93.0811 59.04 94.8305 58.9406C95.0156 58.9406 95.2192 58.9406 95.4043 58.8666C95.6898 58.8172 95.9691 58.7365 96.2371 58.626V58.626C96.4923 58.5276 96.7398 58.4101 96.9773 58.2744C97.1446 58.1804 97.3053 58.0752 97.4585 57.9598C97.6944 57.7953 97.9172 57.6128 98.1248 57.4138C98.3366 57.2115 98.5345 56.9951 98.717 56.7661L102.761 51.6304L101.28 59.866C106.159 60.9708 110.526 63.6821 113.68 67.5649C112.139 66.3531 110.226 65.7098 108.266 65.7436C106.306 65.7773 104.417 66.4859 102.918 67.75L102.215 68.3422C100.499 69.8161 99.4382 71.9104 99.2647 74.1656C99.0913 76.4208 99.8194 78.6528 101.289 80.3718L117.539 99.471Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M119.529 23.5551C119.57 27.0471 118.706 30.4902 117.021 33.5489L113.033 38.6106L109.85 42.6543L102.817 51.5747L98.7731 56.7104C98.3063 57.3259 97.7163 57.8371 97.0407 58.2116C96.3651 58.586 95.6189 58.8154 94.8496 58.885C93.1002 58.9843 91.3494 58.7134 89.7119 58.0899C88.0743 57.4665 86.5867 56.5045 85.3462 55.2669L84.9946 54.9893C84.8095 54.8042 84.643 54.6191 84.4764 54.4341C82.8612 52.6352 81.8683 50.3639 81.6448 47.9566L80.5622 36.2417L79.7849 28.3022V28.2281C79.4498 26.7745 79.2821 25.2874 79.2852 23.7957C79.2847 18.5794 81.3112 13.567 84.9371 9.81681C88.5629 6.06667 93.5042 3.87232 98.7176 3.69709C104.109 3.57537 109.329 5.5981 113.231 9.32122C117.133 13.0443 119.398 18.1636 119.529 23.5551V23.5551Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M117.539 99.471C117.45 100.97 117.053 102.435 116.373 103.774C111.428 114.139 108.107 125.203 106.527 136.578C105.44 143.794 105.012 151.094 105.25 158.388C101.218 161.106 96.3976 162.408 91.5455 162.089L69.1983 160.738C64.4002 160.459 59.8264 158.612 56.1786 155.482C56.8779 149.869 57.187 144.214 57.104 138.558C57.0343 126.866 55.12 115.258 51.4316 104.163C50.8862 102.626 50.6472 100.998 50.7283 99.3692L51.5611 85.5445L70.1237 77.1423C72.1542 76.1994 73.7321 74.4954 74.5165 72.3985C75.3009 70.3016 75.2287 67.9803 74.3155 65.9363L73.9454 65.1035C73.0822 63.2426 71.5808 61.7527 69.7133 60.9038C67.8459 60.055 65.7361 59.9035 63.7665 60.4767C67.3337 58.5801 71.3567 57.7089 75.3889 57.9598L84.2538 58.5335L84.5129 54.4712C84.6794 54.6563 84.846 54.8413 85.0311 55.0264L85.3272 55.3225C86.5676 56.5602 88.0552 57.5221 89.6928 58.1456C91.3304 58.7691 93.0811 59.04 94.8305 58.9406C95.0156 58.9406 95.2192 58.9406 95.4043 58.8666C95.6898 58.8172 95.9691 58.7365 96.2371 58.626V58.626C96.4923 58.5276 96.7398 58.4101 96.9773 58.2744C97.1446 58.1804 97.3053 58.0752 97.4585 57.9598C97.6944 57.7953 97.9172 57.6128 98.1248 57.4138C98.3366 57.2115 98.5345 56.9951 98.717 56.7661L102.761 51.6304L101.28 59.866C106.159 60.9708 110.526 63.6821 113.68 67.5649C112.139 66.3531 110.226 65.7098 108.266 65.7436C106.306 65.7773 104.417 66.4859 102.918 67.75L102.215 68.3422C100.499 69.8161 99.4382 71.9104 99.2647 74.1656C99.0913 76.4208 99.8194 78.6528 101.289 80.3718L117.539 99.471Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M119.529 23.5551C119.57 27.0471 118.706 30.4902 117.021 33.5489L113.033 38.6106L109.85 42.6543L102.817 51.5747L98.7731 56.7104C98.3063 57.3259 97.7163 57.8371 97.0407 58.2116C96.3651 58.586 95.6189 58.8154 94.8496 58.885C93.1002 58.9843 91.3494 58.7134 89.7119 58.0899C88.0743 57.4665 86.5867 56.5045 85.3462 55.2669L84.9946 54.9893C84.8095 54.8042 84.643 54.6191 84.4764 54.4341C82.8612 52.6352 81.8683 50.3639 81.6448 47.9566L80.5622 36.2417L79.7849 28.3022V28.2281C79.4498 26.7745 79.2821 25.2874 79.2852 23.7957C79.2847 18.5794 81.3112 13.567 84.9371 9.81681C88.5629 6.06667 93.5042 3.87232 98.7176 3.69709C104.109 3.57537 109.329 5.5981 113.231 9.32122C117.133 13.0443 119.398 18.1636 119.529 23.5551V23.5551Z"
            stroke="#1DA88F"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M149.862 113.528C149.862 113.791 149.843 114.054 149.807 114.314V114.37C149.79 114.59 149.747 114.808 149.677 115.018C149.686 115.06 149.686 115.104 149.677 115.147L139.091 156.668C138.9 157.533 138.536 158.352 138.022 159.074C137.508 159.796 136.854 160.408 136.099 160.873C135.344 161.337 134.503 161.646 133.627 161.779C132.751 161.913 131.856 161.868 130.997 161.65C130.138 161.431 129.332 161.041 128.626 160.504C127.92 159.968 127.33 159.295 126.889 158.525C126.449 157.756 126.167 156.906 126.062 156.026C125.956 155.145 126.029 154.253 126.275 153.401L134.844 119.746C134.634 119.568 134.442 119.369 134.27 119.154L117.558 99.4903L101.263 80.3355C99.7928 78.6165 99.0646 76.3846 99.2381 74.1294C99.4115 71.8742 100.472 69.7799 102.188 68.306L102.891 67.7137C104.39 66.4497 106.279 65.741 108.239 65.7073C110.2 65.6736 112.112 66.3169 113.653 67.5287C113.653 67.5287 113.653 67.5287 113.653 67.5287C114.094 67.8605 114.497 68.239 114.856 68.6576L147.919 107.578C149.3 109.206 149.996 111.305 149.862 113.435C149.868 113.466 149.868 113.497 149.862 113.528Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M70.0872 77.2071L51.5246 85.6093L23.6716 98.2496C21.941 99.0285 20 99.2076 18.1561 98.7584C16.3122 98.3091 14.6711 97.2573 13.4927 95.7696C12.1925 94.7165 11.3502 93.2011 11.1423 91.5408L5.68278 49.0209C5.64586 48.7449 5.62731 48.4666 5.62726 48.1881C5.611 46.4955 6.24412 44.861 7.39635 43.6209C8.54858 42.3809 10.1323 41.6296 11.8216 41.5218C13.5108 41.4139 15.1772 41.9576 16.4778 43.041C17.7784 44.1244 18.6143 45.6651 18.8135 47.3461L22.8943 78.8913L62.6844 60.8469C63.0418 60.6788 63.4134 60.5426 63.7948 60.4398C65.7644 59.8665 67.8742 60.018 69.7416 60.8669C71.6091 61.7157 73.1105 63.2056 73.9736 65.0665L74.3438 65.8993C74.8096 66.9209 75.069 68.0244 75.1071 69.1465C75.1452 70.2686 74.9611 71.3871 74.5656 72.4379C74.1701 73.4886 73.5708 74.4509 72.8023 75.2694C72.0337 76.0878 71.111 76.7463 70.0872 77.2071Z"
            stroke="#1DA88F"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M123.859 97.8059C123.771 99.202 123.4 100.565 122.767 101.813C118.166 111.462 115.077 121.761 113.606 132.349C112.591 139.069 112.192 145.868 112.412 152.661C108.664 155.208 104.175 156.439 99.6516 156.159L78.8312 154.9C74.3597 154.637 70.0981 152.914 66.6998 149.996C67.3383 144.777 67.6166 139.521 67.5326 134.265C67.4654 123.375 65.6823 112.564 62.2489 102.229C61.7413 100.796 61.5181 99.278 61.5919 97.7597L62.3692 84.8881L79.6362 77.0411C81.5481 76.1696 83.0365 74.5757 83.7754 72.6088C84.5143 70.6419 84.4434 68.4623 83.5782 66.5476L83.2358 65.7796C82.4349 64.0467 81.0398 62.6581 79.3032 61.8653C77.5666 61.0726 75.6034 60.9281 73.7695 61.4582C77.0938 59.6953 80.8405 58.8851 84.5961 59.117L92.8317 59.6167L93.0723 55.832L93.5535 56.3502L93.8311 56.6279C94.9882 57.7819 96.3761 58.6785 97.9037 59.2589C99.4314 59.8394 101.064 60.0906 102.696 59.9961L103.223 59.9314C103.491 59.8851 103.752 59.8074 104.001 59.7V59.7C104.237 59.611 104.466 59.5026 104.685 59.3761C104.842 59.2916 104.991 59.1924 105.13 59.08C105.354 58.9333 105.562 58.7627 105.75 58.5711C105.947 58.3821 106.132 58.1813 106.305 57.9696L110.071 53.1855L108.692 60.8567C113.235 61.9001 117.295 64.4386 120.222 68.0652C118.786 66.9338 117.003 66.3333 115.175 66.3653C113.347 66.3974 111.587 67.06 110.191 68.241L109.534 68.787C107.936 70.1489 106.942 72.088 106.768 74.1806C106.595 76.2733 107.257 78.3495 108.609 79.9559L123.859 97.8059Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M125.71 27.0716C125.744 30.3057 124.941 33.4937 123.378 36.3251L119.676 41.0444L116.715 44.8106L110.164 53.1388L106.398 57.9228C105.965 58.4968 105.418 58.9738 104.79 59.3229C104.162 59.672 103.467 59.8854 102.752 59.9493C101.118 60.065 99.4793 59.8337 97.9419 59.2708C96.4044 58.7078 95.0035 57.8258 93.8312 56.6828L93.5536 56.4052L93.0725 55.887C91.5672 54.1984 90.6457 52.0699 90.4445 49.8167L89.3988 38.9161L88.6678 31.5133V31.4485C88.3587 30.0947 88.2035 28.7102 88.2051 27.3215C88.1953 22.4534 90.0813 17.7729 93.4634 14.2716C96.8455 10.7702 101.458 8.72336 106.323 8.56462C108.812 8.50439 111.288 8.93557 113.61 9.83349C115.932 10.7314 118.054 12.0784 119.855 13.7974C121.656 15.5165 123.1 17.5737 124.104 19.8515C125.109 22.1292 125.654 24.5828 125.71 27.0716Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M123.859 97.8059C123.771 99.202 123.4 100.565 122.767 101.813C118.166 111.462 115.077 121.761 113.606 132.349C112.591 139.069 112.192 145.868 112.412 152.661C108.664 155.208 104.175 156.439 99.6516 156.159L78.8312 154.9C74.3597 154.637 70.0981 152.914 66.6998 149.996C67.3383 144.777 67.6166 139.521 67.5326 134.265C67.4654 123.375 65.6823 112.564 62.2489 102.229C61.7413 100.796 61.5181 99.278 61.5919 97.7597L62.3692 84.8881L79.6362 77.0411C81.5481 76.1696 83.0365 74.5757 83.7754 72.6088C84.5143 70.6419 84.4434 68.4623 83.5782 66.5476L83.2358 65.7796C82.4349 64.0467 81.0398 62.6581 79.3032 61.8653C77.5666 61.0726 75.6034 60.9281 73.7695 61.4582C77.0938 59.6953 80.8405 58.8851 84.5961 59.117L92.8317 59.6167L93.0723 55.832L93.5535 56.3502L93.8311 56.6279C94.9882 57.7819 96.3761 58.6785 97.9037 59.2589C99.4314 59.8394 101.064 60.0906 102.696 59.9961L103.223 59.9314C103.491 59.8851 103.752 59.8074 104.001 59.7V59.7C104.237 59.611 104.466 59.5026 104.685 59.3761C104.842 59.2916 104.991 59.1924 105.13 59.08C105.354 58.9333 105.562 58.7627 105.75 58.5711C105.947 58.3821 106.132 58.1813 106.305 57.9696L110.071 53.1855L108.692 60.8567C113.235 61.9001 117.295 64.4386 120.222 68.0652C118.786 66.9338 117.003 66.3333 115.175 66.3653C113.347 66.3974 111.587 67.06 110.191 68.241L109.534 68.787C107.936 70.1489 106.942 72.088 106.768 74.1806C106.595 76.2733 107.257 78.3495 108.609 79.9559L123.859 97.8059Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M125.71 27.0716C125.744 30.3057 124.941 33.4937 123.378 36.3251L119.676 41.0444L116.715 44.8106L110.164 53.1388L106.398 57.9228C105.965 58.4968 105.418 58.9738 104.79 59.3229C104.162 59.672 103.467 59.8854 102.752 59.9493C101.118 60.065 99.4793 59.8337 97.9419 59.2708C96.4044 58.7078 95.0035 57.8258 93.8312 56.6828L93.5536 56.4052L93.0725 55.887C91.5672 54.1984 90.6457 52.0699 90.4445 49.8167L89.3988 38.9161L88.6678 31.5133V31.4485C88.3587 30.0947 88.2035 28.7102 88.2051 27.3215C88.1953 22.4534 90.0813 17.7729 93.4634 14.2716C96.8455 10.7702 101.458 8.72336 106.323 8.56462C108.812 8.50439 111.288 8.93557 113.61 9.83349C115.932 10.7314 118.054 12.0784 119.855 13.7974C121.656 15.5165 123.1 17.5737 124.104 19.8515C125.109 22.1292 125.654 24.5828 125.71 27.0716Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M153.943 110.899C153.957 111.143 153.957 111.387 153.943 111.63V111.686C153.927 111.891 153.883 112.094 153.814 112.287C153.818 112.327 153.818 112.368 153.814 112.408L143.894 151.069C143.716 151.876 143.377 152.638 142.899 153.312C142.42 153.985 141.811 154.556 141.107 154.989C140.404 155.422 139.62 155.709 138.803 155.834C137.987 155.958 137.153 155.917 136.353 155.713C135.552 155.509 134.801 155.146 134.143 154.645C133.486 154.145 132.936 153.517 132.526 152.8C132.115 152.083 131.854 151.29 131.756 150.47C131.658 149.65 131.727 148.818 131.957 148.024L139.943 116.683C139.748 116.517 139.568 116.335 139.406 116.137L123.86 97.8242L108.684 79.9834C107.332 78.377 106.67 76.3008 106.844 74.2081C107.017 72.1154 108.011 70.1764 109.61 68.8144L110.267 68.2685C111.662 67.0875 113.423 66.4249 115.251 66.3928C117.078 66.3608 118.861 66.9613 120.297 68.0927C120.297 68.0927 120.297 68.0927 120.297 68.0927C120.706 68.4016 121.081 68.752 121.417 69.1383L152.139 105.301C153.436 106.811 154.099 108.764 153.989 110.751C153.991 110.805 153.975 110.857 153.943 110.899Z"
            stroke="#1DA88F"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
          <path
            d="M79.6366 77.0409L62.3511 84.8972L36.4413 96.6676C34.833 97.3892 33.0302 97.5544 31.3176 97.1372C29.605 96.7199 28.0803 95.744 26.9842 94.3635C25.7759 93.3797 24.9937 91.9677 24.8003 90.4215L19.6647 50.8165C19.6508 50.5607 19.6508 50.3043 19.6647 50.0485C19.7297 48.5277 20.3552 47.0847 21.4208 45.9977C22.4863 44.9107 23.9165 44.2564 25.4357 44.161C26.9549 44.0657 28.4556 44.5359 29.6488 45.4811C30.8419 46.4264 31.6431 47.7797 31.8978 49.2804L35.6825 78.6973L72.7428 61.8652C73.0723 61.7064 73.4163 61.5794 73.7699 61.4858C75.6038 60.9558 77.567 61.1002 79.3036 61.8929C81.0402 62.6857 82.4353 64.0743 83.2362 65.8072L83.5786 66.5752C84.4351 68.4869 84.5013 70.6596 83.763 72.6199C83.0246 74.5802 81.5414 76.1693 79.6366 77.0409V77.0409Z"
            stroke="#1DA88F"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
          <path
            d="M123.859 97.8059C123.771 99.202 123.4 100.565 122.767 101.813C118.166 111.462 115.077 121.761 113.606 132.349C112.591 139.069 112.192 145.868 112.412 152.661C108.664 155.208 104.175 156.439 99.6516 156.159L78.8312 154.9C74.3597 154.637 70.0981 152.914 66.6998 149.996C67.3383 144.777 67.6166 139.521 67.5326 134.265C67.4654 123.375 65.6823 112.564 62.2489 102.229C61.7413 100.796 61.5181 99.278 61.5919 97.7597L62.3692 84.8881L79.6362 77.0411C81.5481 76.1696 83.0365 74.5757 83.7754 72.6088C84.5143 70.6419 84.4434 68.4623 83.5782 66.5476L83.2358 65.7796C82.4349 64.0467 81.0398 62.6581 79.3032 61.8653C77.5666 61.0726 75.6034 60.9281 73.7695 61.4582C77.0938 59.6953 80.8405 58.8851 84.5961 59.117L92.8317 59.6167L93.0723 55.832L93.5535 56.3502L93.8311 56.6279C94.9882 57.7819 96.3761 58.6785 97.9037 59.2589C99.4314 59.8394 101.064 60.0906 102.696 59.9961L103.223 59.9314C103.491 59.8851 103.752 59.8074 104.001 59.7V59.7C104.237 59.611 104.466 59.5026 104.685 59.3761C104.842 59.2916 104.991 59.1924 105.13 59.08C105.354 58.9333 105.562 58.7627 105.75 58.5711C105.947 58.3821 106.132 58.1813 106.305 57.9696L110.071 53.1855L108.692 60.8567C113.235 61.9001 117.295 64.4386 120.222 68.0652C118.786 66.9338 117.003 66.3333 115.175 66.3653C113.347 66.3974 111.587 67.06 110.191 68.241L109.534 68.787C107.936 70.1489 106.942 72.088 106.768 74.1806C106.595 76.2733 107.257 78.3495 108.609 79.9559L123.859 97.8059Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M125.71 27.0716C125.744 30.3057 124.941 33.4937 123.378 36.3251L119.676 41.0444L116.715 44.8106L110.164 53.1388L106.398 57.9228C105.965 58.4968 105.418 58.9738 104.79 59.3229C104.162 59.672 103.467 59.8854 102.752 59.9493C101.118 60.065 99.4793 59.8337 97.9419 59.2708C96.4044 58.7078 95.0035 57.8258 93.8312 56.6828L93.5536 56.4052L93.0725 55.887C91.5672 54.1984 90.6457 52.0699 90.4445 49.8167L89.3988 38.9161L88.6678 31.5133V31.4485C88.3587 30.0947 88.2035 28.7102 88.2051 27.3215C88.1953 22.4534 90.0813 17.7729 93.4634 14.2716C96.8455 10.7702 101.458 8.72336 106.323 8.56462C108.812 8.50439 111.288 8.93557 113.61 9.83349C115.932 10.7314 118.054 12.0784 119.855 13.7974C121.656 15.5165 123.1 17.5737 124.104 19.8515C125.109 22.1292 125.654 24.5828 125.71 27.0716Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M123.859 97.8059C123.771 99.202 123.4 100.565 122.767 101.813C118.166 111.462 115.077 121.761 113.606 132.349C112.591 139.069 112.192 145.868 112.412 152.661C108.664 155.208 104.175 156.439 99.6516 156.159L78.8312 154.9C74.3597 154.637 70.0981 152.914 66.6998 149.996C67.3383 144.777 67.6166 139.521 67.5326 134.265C67.4654 123.375 65.6823 112.564 62.2489 102.229C61.7413 100.796 61.5181 99.278 61.5919 97.7597L62.3692 84.8881L79.6362 77.0411C81.5481 76.1696 83.0365 74.5757 83.7754 72.6088C84.5143 70.6419 84.4434 68.4623 83.5782 66.5476L83.2358 65.7796C82.4349 64.0467 81.0398 62.6581 79.3032 61.8653C77.5666 61.0726 75.6034 60.9281 73.7695 61.4582C77.0938 59.6953 80.8405 58.8851 84.5961 59.117L92.8317 59.6167L93.0723 55.832L93.5535 56.3502L93.8311 56.6279C94.9882 57.7819 96.3761 58.6785 97.9037 59.2589C99.4314 59.8394 101.064 60.0906 102.696 59.9961L103.223 59.9314C103.491 59.8851 103.752 59.8074 104.001 59.7V59.7C104.237 59.611 104.466 59.5026 104.685 59.3761C104.842 59.2916 104.991 59.1924 105.13 59.08C105.354 58.9333 105.562 58.7627 105.75 58.5711C105.947 58.3821 106.132 58.1813 106.305 57.9696L110.071 53.1855L108.692 60.8567C113.235 61.9001 117.295 64.4386 120.222 68.0652C118.786 66.9338 117.003 66.3333 115.175 66.3653C113.347 66.3974 111.587 67.06 110.191 68.241L109.534 68.787C107.936 70.1489 106.942 72.088 106.768 74.1806C106.595 76.2733 107.257 78.3495 108.609 79.9559L123.859 97.8059Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M125.71 27.0716C125.744 30.3057 124.941 33.4937 123.378 36.3251L119.676 41.0444L116.715 44.8106L110.164 53.1388L106.398 57.9228C105.965 58.4968 105.418 58.9738 104.79 59.3229C104.162 59.672 103.467 59.8854 102.752 59.9493C101.118 60.065 99.4793 59.8337 97.9419 59.2708C96.4044 58.7078 95.0035 57.8258 93.8312 56.6828L93.5536 56.4052L93.0725 55.887C91.5672 54.1984 90.6457 52.0699 90.4445 49.8167L89.3988 38.9161L88.6678 31.5133V31.4485C88.3587 30.0947 88.2035 28.7102 88.2051 27.3215C88.1953 22.4534 90.0813 17.7729 93.4634 14.2716C96.8455 10.7702 101.458 8.72336 106.323 8.56462C108.812 8.50439 111.288 8.93557 113.61 9.83349C115.932 10.7314 118.054 12.0784 119.855 13.7974C121.656 15.5165 123.1 17.5737 124.104 19.8515C125.109 22.1292 125.654 24.5828 125.71 27.0716Z"
            stroke="#1DA88F"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M153.943 110.899C153.957 111.143 153.957 111.387 153.943 111.63V111.686C153.927 111.891 153.883 112.094 153.814 112.287C153.818 112.327 153.818 112.368 153.814 112.408L143.894 151.069C143.716 151.876 143.377 152.638 142.899 153.312C142.42 153.985 141.811 154.556 141.107 154.989C140.404 155.422 139.62 155.709 138.803 155.834C137.987 155.958 137.153 155.917 136.353 155.713C135.552 155.509 134.801 155.146 134.143 154.645C133.486 154.145 132.936 153.517 132.526 152.8C132.115 152.083 131.854 151.29 131.756 150.47C131.658 149.65 131.727 148.818 131.957 148.024L139.943 116.683C139.748 116.517 139.568 116.335 139.406 116.137L123.86 97.8242L108.684 79.9834C107.332 78.377 106.67 76.3008 106.844 74.2081C107.017 72.1154 108.011 70.1764 109.61 68.8144L110.267 68.2685C111.662 67.0875 113.423 66.4249 115.251 66.3928C117.078 66.3608 118.861 66.9613 120.297 68.0927C120.297 68.0927 120.297 68.0927 120.297 68.0927C120.706 68.4016 121.081 68.752 121.417 69.1383L152.139 105.301C153.436 106.811 154.099 108.764 153.989 110.751C153.991 110.805 153.975 110.857 153.943 110.899Z"
            stroke="#1DA88F"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
          <path
            d="M79.6366 77.0409L62.3511 84.8972L36.4413 96.6676C34.833 97.3892 33.0302 97.5544 31.3176 97.1372C29.605 96.7199 28.0803 95.744 26.9842 94.3635C25.7759 93.3797 24.9937 91.9677 24.8003 90.4215L19.6647 50.8165C19.6508 50.5607 19.6508 50.3043 19.6647 50.0485C19.7297 48.5277 20.3552 47.0847 21.4208 45.9977C22.4863 44.9107 23.9165 44.2564 25.4357 44.161C26.9549 44.0657 28.4556 44.5359 29.6488 45.4811C30.8419 46.4264 31.6431 47.7797 31.8978 49.2804L35.6825 78.6973L72.7428 61.8652C73.0723 61.7064 73.4163 61.5794 73.7699 61.4858C75.6038 60.9558 77.567 61.1002 79.3036 61.8929C81.0402 62.6857 82.4353 64.0743 83.2362 65.8072L83.5786 66.5752C84.4351 68.4869 84.5013 70.6596 83.763 72.6199C83.0246 74.5802 81.5414 76.1693 79.6366 77.0409V77.0409Z"
            stroke="#1DA88F"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
          <path
            d="M115.587 99.527C115.506 101.042 115.109 102.524 114.421 103.876C109.477 114.241 106.156 125.305 104.575 136.68C103.489 143.89 103.062 151.184 103.298 158.472C99.2761 161.188 94.4649 162.49 89.6217 162.173L67.2745 160.831C62.4755 160.548 57.902 158.698 54.2548 155.566C54.9538 149.956 55.263 144.304 55.1802 138.651C55.112 126.959 53.1976 115.35 49.5078 104.256C48.9623 102.716 48.7234 101.084 48.8045 99.453L49.6373 85.6375L68.1999 77.2353C70.2516 76.2974 71.8485 74.5851 72.641 72.4729C73.4335 70.3607 73.3572 68.0206 72.4287 65.9645L72.0586 65.1317C71.1954 63.2708 69.694 61.7809 67.8265 60.932C65.9591 60.0832 63.8493 59.9317 61.8797 60.5049C65.4497 58.6166 69.4711 57.7489 73.5021 57.9972L82.3485 58.534L82.6076 54.4717C82.7741 54.6567 82.9407 54.8326 83.1258 55.0176L83.4219 55.3137C84.6619 56.5552 86.1505 57.5202 87.79 58.1454C89.4295 58.7706 91.1827 59.0417 92.9345 58.9411C93.1195 58.9411 93.3231 58.9411 93.5082 58.8671C93.7905 58.8165 94.0666 58.7358 94.3317 58.6265V58.6265C94.587 58.5281 94.8345 58.4106 95.072 58.2749C95.2393 58.1808 95.4 58.0757 95.5532 57.9602C95.7915 57.7956 96.0145 57.6097 96.2195 57.405C96.4313 57.2027 96.6292 56.9863 96.8117 56.7573L100.855 51.6216L99.3749 59.8572C104.252 60.9828 108.611 63.7096 111.756 67.6024C110.215 66.3849 108.301 65.7381 106.338 65.7719C104.374 65.8056 102.483 66.5178 100.985 67.7875L100.282 68.3797C98.5639 69.8523 97.5014 71.9469 97.3278 74.2028C97.1543 76.4588 97.884 78.6913 99.3564 80.4092L115.587 99.527Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M117.576 23.6118C117.623 27.1045 116.758 30.549 115.068 33.6056L111.08 38.6673L107.896 42.7111L100.864 51.6315L96.82 56.7671C96.3551 57.3832 95.767 57.8956 95.093 58.2716C94.4191 58.6476 93.6741 58.879 92.9057 58.951C91.154 59.0516 89.4008 58.7804 87.7613 58.1553C86.1218 57.5301 84.6331 56.5651 83.3931 55.3236L83.097 55.0275C82.9119 54.8424 82.7454 54.6666 82.5788 54.4815C80.967 52.6805 79.9746 50.4104 79.7473 48.0041L78.6091 36.3262L77.8318 28.3866V28.3126C77.4961 26.8591 77.3285 25.3719 77.3321 23.8802C77.3315 18.6638 79.3581 13.6514 82.984 9.9013C86.6098 6.15116 91.5511 3.95681 96.7644 3.78157C102.152 3.65979 107.367 5.67872 111.268 9.39588C115.169 13.113 117.437 18.2252 117.576 23.6118V23.6118Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M147.918 113.583C147.918 113.85 147.9 114.116 147.863 114.379V114.435C147.846 114.655 147.803 114.873 147.733 115.082C147.742 115.125 147.742 115.169 147.733 115.212L137.147 156.732C136.951 157.594 136.584 158.407 136.068 159.123C135.552 159.84 134.898 160.447 134.144 160.907C133.39 161.367 132.552 161.672 131.678 161.803C130.805 161.934 129.914 161.889 129.058 161.67C128.203 161.451 127.399 161.063 126.696 160.529C125.993 159.995 125.403 159.326 124.963 158.56C124.522 157.795 124.239 156.949 124.131 156.072C124.022 155.196 124.09 154.307 124.331 153.457L132.9 119.811C132.69 119.632 132.498 119.434 132.326 119.219L115.614 99.5458L99.3096 80.3911C97.8372 78.6731 97.1075 76.4406 97.281 74.1846C97.4545 71.9287 98.5171 69.8341 100.235 68.3615L100.938 67.7693C102.436 66.4996 104.327 65.7874 106.291 65.7537C108.254 65.72 110.169 66.3667 111.709 67.5842C111.709 67.5842 111.709 67.5842 111.709 67.5842C112.144 67.9155 112.545 68.2907 112.903 68.7039L145.892 107.569C147.271 109.197 147.967 111.296 147.835 113.426C147.859 113.445 147.878 113.469 147.892 113.496C147.907 113.523 147.915 113.553 147.918 113.583Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M68.1349 77.2727L49.5724 85.6749L21.7193 98.3244C19.9897 99.1044 18.0493 99.2849 16.2055 98.8374C14.3618 98.3898 12.72 97.3398 11.5405 95.8537C10.2489 94.7947 9.41127 93.2817 9.19934 91.6249L3.74903 49.0865C3.71211 48.8105 3.69356 48.5322 3.6935 48.2537C3.68234 46.5628 4.31897 44.9319 5.47268 43.6957C6.62638 42.4596 8.20959 41.712 9.89719 41.6066C11.5848 41.5012 13.2487 42.046 14.5473 43.129C15.8458 44.212 16.6804 45.751 16.8797 47.4301L20.942 78.9846L60.7321 60.9403C61.0947 60.7691 61.4727 60.6328 61.861 60.5331C63.8306 59.9598 65.9404 60.1114 67.8079 60.9602C69.6753 61.809 71.1767 63.299 72.0399 65.1599L72.41 65.9927C73.3384 68.0555 73.4096 70.4025 72.6079 72.5178C71.8062 74.6331 70.1973 76.3434 68.1349 77.2727Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M115.587 99.527C115.506 101.042 115.109 102.524 114.421 103.876C109.477 114.241 106.156 125.305 104.575 136.68C103.489 143.89 103.062 151.184 103.298 158.472C99.2761 161.188 94.4649 162.49 89.6217 162.173L67.2745 160.831C62.4755 160.548 57.902 158.698 54.2548 155.566C54.9538 149.956 55.263 144.304 55.1802 138.651C55.112 126.959 53.1976 115.35 49.5078 104.256C48.9623 102.716 48.7234 101.084 48.8045 99.453L49.6373 85.6375L68.1999 77.2353C70.2516 76.2974 71.8485 74.5851 72.641 72.4729C73.4335 70.3607 73.3572 68.0206 72.4287 65.9645L72.0586 65.1317C71.1954 63.2708 69.694 61.7809 67.8265 60.932C65.9591 60.0832 63.8493 59.9317 61.8797 60.5049C65.4497 58.6166 69.4711 57.7489 73.5021 57.9972L82.3485 58.534L82.6076 54.4717C82.7741 54.6567 82.9407 54.8326 83.1258 55.0176L83.4219 55.3137C84.6619 56.5552 86.1505 57.5202 87.79 58.1454C89.4295 58.7706 91.1827 59.0417 92.9345 58.9411C93.1195 58.9411 93.3231 58.9411 93.5082 58.8671C93.7905 58.8165 94.0666 58.7358 94.3317 58.6265V58.6265C94.587 58.5281 94.8345 58.4106 95.072 58.2749C95.2393 58.1808 95.4 58.0757 95.5532 57.9602C95.7915 57.7956 96.0145 57.6097 96.2195 57.405C96.4313 57.2027 96.6292 56.9863 96.8117 56.7573L100.855 51.6216L99.3749 59.8572C104.252 60.9828 108.611 63.7096 111.756 67.6024C110.215 66.3849 108.301 65.7381 106.338 65.7719C104.374 65.8056 102.483 66.5178 100.985 67.7875L100.282 68.3797C98.5639 69.8523 97.5014 71.9469 97.3278 74.2028C97.1543 76.4588 97.884 78.6913 99.3564 80.4092L115.587 99.527Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M117.576 23.6118C117.623 27.1045 116.758 30.549 115.068 33.6056L111.08 38.6673L107.896 42.7111L100.864 51.6315L96.82 56.7671C96.3551 57.3832 95.767 57.8956 95.093 58.2716C94.4191 58.6476 93.6741 58.879 92.9057 58.951C91.154 59.0516 89.4008 58.7804 87.7613 58.1553C86.1218 57.5301 84.6331 56.5651 83.3931 55.3236L83.097 55.0275C82.9119 54.8424 82.7454 54.6666 82.5788 54.4815C80.967 52.6805 79.9746 50.4104 79.7473 48.0041L78.6091 36.3262L77.8318 28.3866V28.3126C77.4961 26.8591 77.3285 25.3719 77.3321 23.8802C77.3315 18.6638 79.3581 13.6514 82.984 9.9013C86.6098 6.15116 91.5511 3.95681 96.7644 3.78157C102.152 3.65979 107.367 5.67872 111.268 9.39588C115.169 13.113 117.437 18.2252 117.576 23.6118V23.6118Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M115.587 99.527C115.506 101.042 115.109 102.524 114.421 103.876C109.477 114.241 106.156 125.305 104.575 136.68C103.489 143.89 103.062 151.184 103.298 158.472C99.2761 161.188 94.4649 162.49 89.6217 162.173L67.2745 160.831C62.4755 160.548 57.902 158.698 54.2548 155.566C54.9538 149.956 55.263 144.304 55.1802 138.651C55.112 126.959 53.1976 115.35 49.5078 104.256C48.9623 102.716 48.7234 101.084 48.8045 99.453L49.6373 85.6375L68.1999 77.2353C70.2516 76.2974 71.8485 74.5851 72.641 72.4729C73.4335 70.3607 73.3572 68.0206 72.4287 65.9645L72.0586 65.1317C71.1954 63.2708 69.694 61.7809 67.8265 60.932C65.9591 60.0832 63.8493 59.9317 61.8797 60.5049C65.4497 58.6166 69.4711 57.7489 73.5021 57.9972L82.3485 58.534L82.6076 54.4717C82.7741 54.6567 82.9407 54.8326 83.1258 55.0176L83.4219 55.3137C84.6619 56.5552 86.1505 57.5202 87.79 58.1454C89.4295 58.7706 91.1827 59.0417 92.9345 58.9411C93.1195 58.9411 93.3231 58.9411 93.5082 58.8671C93.7905 58.8165 94.0666 58.7358 94.3317 58.6265V58.6265C94.587 58.5281 94.8345 58.4106 95.072 58.2749C95.2393 58.1808 95.4 58.0757 95.5532 57.9602C95.7915 57.7956 96.0145 57.6097 96.2195 57.405C96.4313 57.2027 96.6292 56.9863 96.8117 56.7573L100.855 51.6216L99.3749 59.8572C104.252 60.9828 108.611 63.7096 111.756 67.6024C110.215 66.3849 108.301 65.7381 106.338 65.7719C104.374 65.8056 102.483 66.5178 100.985 67.7875L100.282 68.3797C98.5639 69.8523 97.5014 71.9469 97.3278 74.2028C97.1543 76.4588 97.884 78.6913 99.3564 80.4092L115.587 99.527Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M117.576 23.6118C117.623 27.1045 116.758 30.549 115.068 33.6056L111.08 38.6673L107.896 42.7111L100.864 51.6315L96.82 56.7671C96.3551 57.3832 95.767 57.8956 95.093 58.2716C94.4191 58.6476 93.6741 58.879 92.9057 58.951C91.154 59.0516 89.4008 58.7804 87.7613 58.1553C86.1218 57.5301 84.6331 56.5651 83.3931 55.3236L83.097 55.0275C82.9119 54.8424 82.7454 54.6666 82.5788 54.4815C80.967 52.6805 79.9746 50.4104 79.7473 48.0041L78.6091 36.3262L77.8318 28.3866V28.3126C77.4961 26.8591 77.3285 25.3719 77.3321 23.8802C77.3315 18.6638 79.3581 13.6514 82.984 9.9013C86.6098 6.15116 91.5511 3.95681 96.7644 3.78157C102.152 3.65979 107.367 5.67872 111.268 9.39588C115.169 13.113 117.437 18.2252 117.576 23.6118V23.6118Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M147.918 113.583C147.918 113.85 147.9 114.116 147.863 114.379V114.435C147.846 114.655 147.803 114.873 147.733 115.082C147.742 115.125 147.742 115.169 147.733 115.212L137.147 156.732C136.951 157.594 136.584 158.407 136.068 159.123C135.552 159.84 134.898 160.447 134.144 160.907C133.39 161.367 132.552 161.672 131.678 161.803C130.805 161.934 129.914 161.889 129.058 161.67C128.203 161.451 127.399 161.063 126.696 160.529C125.993 159.995 125.403 159.326 124.963 158.56C124.522 157.795 124.239 156.949 124.131 156.072C124.022 155.196 124.09 154.307 124.331 153.457L132.9 119.811C132.69 119.632 132.498 119.434 132.326 119.219L115.614 99.5458L99.3096 80.3911C97.8372 78.6731 97.1075 76.4406 97.281 74.1846C97.4545 71.9287 98.5171 69.8341 100.235 68.3615L100.938 67.7693C102.436 66.4996 104.327 65.7874 106.291 65.7537C108.254 65.72 110.169 66.3667 111.709 67.5842C111.709 67.5842 111.709 67.5842 111.709 67.5842C112.144 67.9155 112.545 68.2907 112.903 68.7039L145.892 107.569C147.271 109.197 147.967 111.296 147.835 113.426C147.859 113.445 147.878 113.469 147.892 113.496C147.907 113.523 147.915 113.553 147.918 113.583Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M68.1349 77.2727L49.5724 85.6749L21.7193 98.3244C19.9897 99.1044 18.0493 99.2849 16.2055 98.8374C14.3618 98.3898 12.72 97.3398 11.5405 95.8537C10.2489 94.7947 9.41127 93.2817 9.19934 91.6249L3.74903 49.0865C3.71211 48.8105 3.69356 48.5322 3.6935 48.2537C3.68234 46.5628 4.31897 44.9319 5.47268 43.6957C6.62638 42.4596 8.20959 41.712 9.89719 41.6066C11.5848 41.5012 13.2487 42.046 14.5473 43.129C15.8458 44.212 16.6804 45.751 16.8797 47.4301L20.942 78.9846L60.7321 60.9403C61.0947 60.7691 61.4727 60.6328 61.861 60.5331C63.8306 59.9598 65.9404 60.1114 67.8079 60.9602C69.6753 61.809 71.1767 63.299 72.0399 65.1599L72.41 65.9927C73.3384 68.0555 73.4096 70.4025 72.6079 72.5178C71.8062 74.6331 70.1973 76.3434 68.1349 77.2727Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M112.912 102.247C112.823 103.747 112.426 105.211 111.746 106.55C106.801 116.915 103.48 127.979 101.9 139.354C100.813 146.57 100.385 153.871 100.623 161.164C96.591 163.882 91.7706 165.184 86.9186 164.866L64.5714 163.515C59.7732 163.235 55.1994 161.388 51.5517 158.259C52.2509 152.645 52.5601 146.99 52.477 141.334C52.4074 129.642 50.493 118.034 46.8046 106.939C46.2592 105.402 46.0203 103.774 46.1014 102.146L46.9342 88.3208L65.4967 79.9187C67.5272 78.9757 69.1052 77.2717 69.8896 75.1749C70.6739 73.078 70.6017 70.7567 69.6886 68.7127L69.3184 67.8798C68.4553 66.019 66.9538 64.529 65.0864 63.6802C63.2189 62.8313 61.1091 62.6798 59.1396 63.2531C62.7068 61.3565 66.7297 60.4853 70.762 60.7361L79.6268 61.3099L79.8859 57.2476C80.0525 57.4326 80.219 57.6177 80.4041 57.8028L80.7002 58.0989C81.9407 59.3365 83.4283 60.2985 85.0658 60.922C86.7034 61.5454 88.4541 61.8163 90.2036 61.717C90.3886 61.717 90.5922 61.717 90.7773 61.643C91.0629 61.5936 91.3422 61.5129 91.6101 61.4024V61.4024C91.8654 61.304 92.1129 61.1865 92.3504 61.0508C92.5176 60.9567 92.6784 60.8516 92.8316 60.7361C93.0675 60.5717 93.2902 60.3891 93.4978 60.1902C93.7097 59.9879 93.9075 59.7715 94.0901 59.5424L98.1338 54.4067L96.6533 62.6424C101.532 63.7472 105.899 66.4584 109.053 70.3413C107.512 69.1295 105.6 68.4862 103.639 68.5199C101.679 68.5536 99.7899 69.2623 98.2911 70.5263L97.5879 71.1186C95.8722 72.5925 94.8113 74.6868 94.6378 76.942C94.4643 79.1972 95.1925 81.4291 96.6625 83.1481L112.912 102.247Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M114.902 26.3315C114.943 29.8234 114.079 33.2666 112.394 36.3253L108.406 41.3869L105.223 45.4307L98.1899 54.3511L94.1461 59.4868C93.6794 60.1022 93.0894 60.6135 92.4138 60.988C91.7382 61.3624 90.9919 61.5917 90.2226 61.6614C88.4732 61.7607 86.7225 61.4898 85.0849 60.8663C83.4473 60.2429 81.9597 59.2809 80.7193 58.0432L80.3677 57.7656C80.1826 57.5806 80.016 57.3955 79.8495 57.2104C78.2342 55.4115 77.2414 53.1402 77.0179 50.733L75.9352 39.018L75.1579 31.0785V31.0045C74.8228 29.5509 74.6552 28.0638 74.6582 26.5721C74.6577 21.3557 76.6843 16.3433 80.3101 12.5932C83.9359 8.84304 88.8772 6.64869 94.0906 6.47345C99.4824 6.35174 104.702 8.37447 108.604 12.0976C112.506 15.8207 114.771 20.9399 114.902 26.3315V26.3315Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M112.912 102.247C112.823 103.747 112.426 105.211 111.746 106.55C106.801 116.915 103.48 127.979 101.9 139.354C100.813 146.57 100.385 153.871 100.623 161.164C96.591 163.882 91.7706 165.184 86.9186 164.866L64.5714 163.515C59.7732 163.235 55.1994 161.388 51.5517 158.259C52.2509 152.645 52.5601 146.99 52.477 141.334C52.4074 129.642 50.493 118.034 46.8046 106.939C46.2592 105.402 46.0203 103.774 46.1014 102.146L46.9342 88.3208L65.4967 79.9187C67.5272 78.9757 69.1052 77.2717 69.8896 75.1749C70.6739 73.078 70.6017 70.7567 69.6886 68.7127L69.3184 67.8798C68.4553 66.019 66.9538 64.529 65.0864 63.6802C63.2189 62.8313 61.1091 62.6798 59.1396 63.2531C62.7068 61.3565 66.7297 60.4853 70.762 60.7361L79.6268 61.3099L79.8859 57.2476C80.0525 57.4326 80.219 57.6177 80.4041 57.8028L80.7002 58.0989C81.9407 59.3365 83.4283 60.2985 85.0658 60.922C86.7034 61.5454 88.4541 61.8163 90.2036 61.717C90.3886 61.717 90.5922 61.717 90.7773 61.643C91.0629 61.5936 91.3422 61.5129 91.6101 61.4024V61.4024C91.8654 61.304 92.1129 61.1865 92.3504 61.0508C92.5176 60.9567 92.6784 60.8516 92.8316 60.7361C93.0675 60.5717 93.2902 60.3891 93.4978 60.1902C93.7097 59.9879 93.9075 59.7715 94.0901 59.5424L98.1338 54.4067L96.6533 62.6424C101.532 63.7472 105.899 66.4584 109.053 70.3413C107.512 69.1295 105.6 68.4862 103.639 68.5199C101.679 68.5536 99.7899 69.2623 98.2911 70.5263L97.5879 71.1186C95.8722 72.5925 94.8113 74.6868 94.6378 76.942C94.4643 79.1972 95.1925 81.4291 96.6625 83.1481L112.912 102.247Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M114.902 26.3315C114.943 29.8234 114.079 33.2666 112.394 36.3253L108.406 41.3869L105.223 45.4307L98.1899 54.3511L94.1461 59.4868C93.6794 60.1022 93.0894 60.6135 92.4138 60.988C91.7382 61.3624 90.9919 61.5917 90.2226 61.6614C88.4732 61.7607 86.7225 61.4898 85.0849 60.8663C83.4473 60.2429 81.9597 59.2809 80.7193 58.0432L80.3677 57.7656C80.1826 57.5806 80.016 57.3955 79.8495 57.2104C78.2342 55.4115 77.2414 53.1402 77.0179 50.733L75.9352 39.018L75.1579 31.0785V31.0045C74.8228 29.5509 74.6552 28.0638 74.6582 26.5721C74.6577 21.3557 76.6843 16.3433 80.3101 12.5932C83.9359 8.84304 88.8772 6.64869 94.0906 6.47345C99.4824 6.35174 104.702 8.37447 108.604 12.0976C112.506 15.8207 114.771 20.9399 114.902 26.3315V26.3315Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M145.235 116.304C145.235 116.567 145.216 116.83 145.18 117.091V117.146C145.163 117.367 145.12 117.584 145.05 117.794C145.059 117.837 145.059 117.881 145.05 117.924L134.464 159.444C134.273 160.31 133.909 161.128 133.395 161.85C132.881 162.573 132.227 163.184 131.472 163.649C130.717 164.114 129.876 164.422 129 164.555C128.124 164.689 127.229 164.645 126.37 164.426C125.511 164.207 124.705 163.818 123.999 163.281C123.293 162.744 122.703 162.071 122.262 161.302C121.822 160.532 121.54 159.682 121.435 158.802C121.329 157.922 121.402 157.029 121.648 156.178L130.217 122.523C130.007 122.344 129.815 122.146 129.643 121.93L112.931 102.267L96.6359 83.1119C95.1658 81.3929 94.4376 79.161 94.6111 76.9058C94.7846 74.6506 95.8455 72.5563 97.5612 71.0823L98.2645 70.4901C99.7633 69.226 101.652 68.5174 103.612 68.4837C105.573 68.45 107.485 69.0932 109.026 70.305C109.026 70.305 109.026 70.305 109.026 70.305C109.467 70.6368 109.87 71.0154 110.229 71.434L143.292 110.354C144.673 111.982 145.369 114.081 145.235 116.212C145.241 116.242 145.241 116.274 145.235 116.304Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M65.4602 79.9835L46.8977 88.3857L19.0446 101.026C17.3141 101.805 15.373 101.984 13.5292 101.535C11.6853 101.086 10.0441 100.034 8.86578 98.546C7.56553 97.4929 6.72326 95.9774 6.5154 94.3172L1.05583 51.7973C1.01891 51.5212 1.00036 51.243 1.00031 50.9645C0.984045 49.2718 1.61717 47.6373 2.7694 46.3973C3.92163 45.1573 5.50532 44.406 7.1946 44.2981C8.88389 44.1903 10.5503 44.734 11.8509 45.8174C13.1515 46.9008 13.9874 48.4415 14.1865 50.1224L18.2673 81.6676L58.0574 63.6233C58.4149 63.4552 58.7864 63.3189 59.1678 63.2161C61.1374 62.6429 63.2472 62.7944 65.1147 63.6432C66.9821 64.4921 68.4835 65.982 69.3467 67.8429L69.7168 68.6757C70.1827 69.6972 70.4421 70.8007 70.4801 71.9228C70.5182 73.0449 70.3342 74.1635 69.9387 75.2143C69.5431 76.265 68.9439 77.2273 68.1753 78.0457C67.4068 78.8642 66.4841 79.5227 65.4602 79.9835Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M112.912 102.247C112.823 103.747 112.426 105.211 111.746 106.55C106.801 116.915 103.48 127.979 101.9 139.354C100.813 146.57 100.385 153.871 100.623 161.164C96.591 163.882 91.7706 165.184 86.9186 164.866L64.5714 163.515C59.7732 163.235 55.1994 161.388 51.5517 158.259C52.2509 152.645 52.5601 146.99 52.477 141.334C52.4074 129.642 50.493 118.034 46.8046 106.939C46.2592 105.402 46.0203 103.774 46.1014 102.146L46.9342 88.3208L65.4967 79.9187C67.5272 78.9757 69.1052 77.2717 69.8896 75.1749C70.6739 73.078 70.6017 70.7567 69.6886 68.7127L69.3184 67.8798C68.4553 66.019 66.9538 64.529 65.0864 63.6802C63.2189 62.8313 61.1091 62.6798 59.1396 63.2531C62.7068 61.3565 66.7297 60.4853 70.762 60.7361L79.6268 61.3099L79.8859 57.2476C80.0525 57.4326 80.219 57.6177 80.4041 57.8028L80.7002 58.0989C81.9407 59.3365 83.4283 60.2985 85.0658 60.922C86.7034 61.5454 88.4541 61.8163 90.2036 61.717C90.3886 61.717 90.5922 61.717 90.7773 61.643C91.0629 61.5936 91.3422 61.5129 91.6101 61.4024V61.4024C91.8654 61.304 92.1129 61.1865 92.3504 61.0508C92.5176 60.9567 92.6784 60.8516 92.8316 60.7361C93.0675 60.5717 93.2902 60.3891 93.4978 60.1902C93.7097 59.9879 93.9075 59.7715 94.0901 59.5424L98.1338 54.4067L96.6533 62.6424C101.532 63.7472 105.899 66.4584 109.053 70.3413C107.512 69.1295 105.6 68.4862 103.639 68.5199C101.679 68.5536 99.7899 69.2623 98.2911 70.5263L97.5879 71.1186C95.8722 72.5925 94.8113 74.6868 94.6378 76.942C94.4643 79.1972 95.1925 81.4291 96.6625 83.1481L112.912 102.247Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M114.902 26.3315C114.943 29.8234 114.079 33.2666 112.394 36.3253L108.406 41.3869L105.223 45.4307L98.1899 54.3511L94.1461 59.4868C93.6794 60.1022 93.0894 60.6135 92.4138 60.988C91.7382 61.3624 90.9919 61.5917 90.2226 61.6614C88.4732 61.7607 86.7225 61.4898 85.0849 60.8663C83.4473 60.2429 81.9597 59.2809 80.7193 58.0432L80.3677 57.7656C80.1826 57.5806 80.016 57.3955 79.8495 57.2104C78.2342 55.4115 77.2414 53.1402 77.0179 50.733L75.9352 39.018L75.1579 31.0785V31.0045C74.8228 29.5509 74.6552 28.0638 74.6582 26.5721C74.6577 21.3557 76.6843 16.3433 80.3101 12.5932C83.9359 8.84304 88.8772 6.64869 94.0906 6.47345C99.4824 6.35174 104.702 8.37447 108.604 12.0976C112.506 15.8207 114.771 20.9399 114.902 26.3315V26.3315Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M112.912 102.247C112.823 103.747 112.426 105.211 111.746 106.55C106.801 116.915 103.48 127.979 101.9 139.354C100.813 146.57 100.385 153.871 100.623 161.164C96.591 163.882 91.7706 165.184 86.9186 164.866L64.5714 163.515C59.7732 163.235 55.1994 161.388 51.5517 158.259C52.2509 152.645 52.5601 146.99 52.477 141.334C52.4074 129.642 50.493 118.034 46.8046 106.939C46.2592 105.402 46.0203 103.774 46.1014 102.146L46.9342 88.3208L65.4967 79.9187C67.5272 78.9757 69.1052 77.2717 69.8896 75.1749C70.6739 73.078 70.6017 70.7567 69.6886 68.7127L69.3184 67.8798C68.4553 66.019 66.9538 64.529 65.0864 63.6802C63.2189 62.8313 61.1091 62.6798 59.1396 63.2531C62.7068 61.3565 66.7297 60.4853 70.762 60.7361L79.6268 61.3099L79.8859 57.2476C80.0525 57.4326 80.219 57.6177 80.4041 57.8028L80.7002 58.0989C81.9407 59.3365 83.4283 60.2985 85.0658 60.922C86.7034 61.5454 88.4541 61.8163 90.2036 61.717C90.3886 61.717 90.5922 61.717 90.7773 61.643C91.0629 61.5936 91.3422 61.5129 91.6101 61.4024V61.4024C91.8654 61.304 92.1129 61.1865 92.3504 61.0508C92.5176 60.9567 92.6784 60.8516 92.8316 60.7361C93.0675 60.5717 93.2902 60.3891 93.4978 60.1902C93.7097 59.9879 93.9075 59.7715 94.0901 59.5424L98.1338 54.4067L96.6533 62.6424C101.532 63.7472 105.899 66.4584 109.053 70.3413C107.512 69.1295 105.6 68.4862 103.639 68.5199C101.679 68.5536 99.7899 69.2623 98.2911 70.5263L97.5879 71.1186C95.8722 72.5925 94.8113 74.6868 94.6378 76.942C94.4643 79.1972 95.1925 81.4291 96.6625 83.1481L112.912 102.247Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M114.902 26.3315C114.943 29.8234 114.079 33.2666 112.394 36.3253L108.406 41.3869L105.223 45.4307L98.1899 54.3511L94.1461 59.4868C93.6794 60.1022 93.0894 60.6135 92.4138 60.988C91.7382 61.3624 90.9919 61.5917 90.2226 61.6614C88.4732 61.7607 86.7225 61.4898 85.0849 60.8663C83.4473 60.2429 81.9597 59.2809 80.7193 58.0432L80.3677 57.7656C80.1826 57.5806 80.016 57.3955 79.8495 57.2104C78.2342 55.4115 77.2414 53.1402 77.0179 50.733L75.9352 39.018L75.1579 31.0785V31.0045C74.8228 29.5509 74.6552 28.0638 74.6582 26.5721C74.6577 21.3557 76.6843 16.3433 80.3101 12.5932C83.9359 8.84304 88.8772 6.64869 94.0906 6.47345C99.4824 6.35174 104.702 8.37447 108.604 12.0976C112.506 15.8207 114.771 20.9399 114.902 26.3315V26.3315Z"
            stroke="#05F2C7"
            strokeWidth="1.13"
            strokeMiterlimit="10"
          />
          <path
            d="M145.235 116.304C145.235 116.567 145.216 116.83 145.18 117.091V117.146C145.163 117.367 145.12 117.584 145.05 117.794C145.059 117.837 145.059 117.881 145.05 117.924L134.464 159.444C134.273 160.31 133.909 161.128 133.395 161.85C132.881 162.573 132.227 163.184 131.472 163.649C130.717 164.114 129.876 164.422 129 164.555C128.124 164.689 127.229 164.645 126.37 164.426C125.511 164.207 124.705 163.818 123.999 163.281C123.293 162.744 122.703 162.071 122.262 161.302C121.822 160.532 121.54 159.682 121.435 158.802C121.329 157.922 121.402 157.029 121.648 156.178L130.217 122.523C130.007 122.344 129.815 122.146 129.643 121.93L112.931 102.267L96.6359 83.1119C95.1658 81.3929 94.4376 79.161 94.6111 76.9058C94.7846 74.6506 95.8455 72.5563 97.5612 71.0823L98.2645 70.4901C99.7633 69.226 101.652 68.5174 103.612 68.4837C105.573 68.45 107.485 69.0932 109.026 70.305C109.026 70.305 109.026 70.305 109.026 70.305C109.467 70.6368 109.87 71.0154 110.229 71.434L143.292 110.354C144.673 111.982 145.369 114.081 145.235 116.212C145.241 116.242 145.241 116.274 145.235 116.304Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M65.4602 79.9835L46.8977 88.3857L19.0446 101.026C17.3141 101.805 15.373 101.984 13.5292 101.535C11.6853 101.086 10.0441 100.034 8.86578 98.546C7.56553 97.4929 6.72326 95.9774 6.5154 94.3172L1.05583 51.7973C1.01891 51.5212 1.00036 51.243 1.00031 50.9645C0.984045 49.2718 1.61717 47.6373 2.7694 46.3973C3.92163 45.1573 5.50532 44.406 7.1946 44.2981C8.88389 44.1903 10.5503 44.734 11.8509 45.8174C13.1515 46.9008 13.9874 48.4415 14.1865 50.1224L18.2673 81.6676L58.0574 63.6233C58.4149 63.4552 58.7864 63.3189 59.1678 63.2161C61.1374 62.6429 63.2472 62.7944 65.1147 63.6432C66.9821 64.4921 68.4835 65.982 69.3467 67.8429L69.7168 68.6757C70.1827 69.6972 70.4421 70.8007 70.4801 71.9228C70.5182 73.0449 70.3342 74.1635 69.9387 75.2143C69.5431 76.265 68.9439 77.2273 68.1753 78.0457C67.4068 78.8642 66.4841 79.5227 65.4602 79.9835Z"
            stroke="#05F2C7"
            strokeWidth="1.11"
            strokeMiterlimit="10"
          />
          <path
            d="M119.232 100.582C119.144 101.978 118.773 103.342 118.14 104.589C113.539 114.238 110.45 124.537 108.979 135.126C107.964 141.846 107.565 148.644 107.785 155.437C104.037 157.985 99.5482 159.215 95.0246 158.935L74.2042 157.676C69.7327 157.413 65.4711 155.69 62.0729 152.772C62.7114 147.554 62.9896 142.298 62.9057 137.041C62.8385 126.151 61.0553 115.34 57.6219 105.005C57.1143 103.573 56.8912 102.054 56.9649 100.536L57.7422 87.6644L75.0093 79.8174C76.9211 78.9459 78.4095 77.3521 79.1484 75.3852C79.8873 73.4183 79.8164 71.2387 78.9513 69.324L78.6089 68.5559C77.808 66.8231 76.4128 65.4344 74.6762 64.6417C72.9396 63.8489 70.9765 63.7045 69.1426 64.2345C72.4668 62.4717 76.2135 61.6615 79.9692 61.8934L88.2048 62.3931L88.4454 58.6084L88.9265 59.1266L89.2041 59.4042C90.3613 60.5582 91.7491 61.4548 93.2768 62.0353C94.8045 62.6157 96.4375 62.867 98.069 62.7725L98.5965 62.7077C98.8637 62.6614 99.1246 62.5838 99.3737 62.4764V62.4764C99.6105 62.3873 99.8395 62.279 100.059 62.1525C100.216 62.0679 100.364 61.9688 100.503 61.8564C100.727 61.7097 100.935 61.5391 101.123 61.3475C101.32 61.1585 101.505 60.9576 101.678 60.746L105.444 55.9619L104.065 63.6331C108.608 64.6764 112.668 67.215 115.595 70.8416C114.159 69.7102 112.376 69.1097 110.548 69.1417C108.721 69.1737 106.96 69.8363 105.564 71.0174L104.907 71.5633C103.309 72.9253 102.315 74.8643 102.141 76.957C101.968 79.0496 102.63 81.1259 103.982 82.7323L119.232 100.582Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M121.083 29.848C121.117 33.0821 120.314 36.2701 118.751 39.1015L115.049 43.8208L112.088 47.587L105.537 55.9151L101.771 60.6992C101.338 61.2732 100.791 61.7502 100.163 62.0993C99.5346 62.4483 98.8403 62.6617 98.1247 62.7257C96.4915 62.8414 94.8523 62.6101 93.3149 62.0471C91.7775 61.4841 90.3766 60.6022 89.2043 59.4592L88.9267 59.1816L88.4455 58.6634C86.9402 56.9748 86.0188 54.8463 85.8175 52.5931L84.7719 41.6925L84.0408 34.2897V34.2249C83.7317 32.871 83.5765 31.4866 83.5782 30.0978C83.5684 25.2298 85.4543 20.5492 88.8365 17.0479C92.2186 13.5466 96.8311 11.4997 101.697 11.341C104.185 11.2808 106.661 11.7119 108.983 12.6099C111.305 13.5078 113.427 14.8548 115.228 16.5738C117.029 18.2928 118.473 20.3501 119.477 22.6279C120.482 24.9056 121.027 27.3591 121.083 29.848Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M119.232 100.582C119.144 101.978 118.773 103.342 118.14 104.589C113.539 114.238 110.45 124.537 108.979 135.126C107.964 141.846 107.565 148.644 107.785 155.437C104.037 157.985 99.5482 159.215 95.0246 158.935L74.2042 157.676C69.7327 157.413 65.4711 155.69 62.0729 152.772C62.7114 147.554 62.9896 142.298 62.9057 137.041C62.8385 126.151 61.0553 115.34 57.6219 105.005C57.1143 103.573 56.8912 102.054 56.9649 100.536L57.7422 87.6644L75.0093 79.8174C76.9211 78.9459 78.4095 77.3521 79.1484 75.3852C79.8873 73.4183 79.8164 71.2387 78.9513 69.324L78.6089 68.5559C77.808 66.8231 76.4128 65.4344 74.6762 64.6417C72.9396 63.8489 70.9765 63.7045 69.1426 64.2345C72.4668 62.4717 76.2135 61.6615 79.9692 61.8934L88.2048 62.3931L88.4454 58.6084L88.9265 59.1266L89.2041 59.4042C90.3613 60.5582 91.7491 61.4548 93.2768 62.0353C94.8045 62.6157 96.4375 62.867 98.069 62.7725L98.5965 62.7077C98.8637 62.6614 99.1246 62.5838 99.3737 62.4764V62.4764C99.6105 62.3873 99.8395 62.279 100.059 62.1525C100.216 62.0679 100.364 61.9688 100.503 61.8564C100.727 61.7097 100.935 61.5391 101.123 61.3475C101.32 61.1585 101.505 60.9576 101.678 60.746L105.444 55.9619L104.065 63.6331C108.608 64.6764 112.668 67.215 115.595 70.8416C114.159 69.7102 112.376 69.1097 110.548 69.1417C108.721 69.1737 106.96 69.8363 105.564 71.0174L104.907 71.5633C103.309 72.9253 102.315 74.8643 102.141 76.957C101.968 79.0496 102.63 81.1259 103.982 82.7323L119.232 100.582Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M121.083 29.848C121.117 33.0821 120.314 36.2701 118.751 39.1015L115.049 43.8208L112.088 47.587L105.537 55.9151L101.771 60.6992C101.338 61.2732 100.791 61.7502 100.163 62.0993C99.5346 62.4483 98.8403 62.6617 98.1247 62.7257C96.4915 62.8414 94.8523 62.6101 93.3149 62.0471C91.7775 61.4841 90.3766 60.6022 89.2043 59.4592L88.9267 59.1816L88.4455 58.6634C86.9402 56.9748 86.0188 54.8463 85.8175 52.5931L84.7719 41.6925L84.0408 34.2897V34.2249C83.7317 32.871 83.5765 31.4866 83.5782 30.0978C83.5684 25.2298 85.4543 20.5492 88.8365 17.0479C92.2186 13.5466 96.8311 11.4997 101.697 11.341C104.185 11.2808 106.661 11.7119 108.983 12.6099C111.305 13.5078 113.427 14.8548 115.228 16.5738C117.029 18.2928 118.473 20.3501 119.477 22.6279C120.482 24.9056 121.027 27.3591 121.083 29.848Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M149.316 113.676C149.33 113.919 149.33 114.163 149.316 114.407V114.462C149.3 114.668 149.256 114.87 149.187 115.064C149.191 115.104 149.191 115.144 149.187 115.184L139.267 153.845C139.089 154.652 138.75 155.415 138.272 156.088C137.793 156.762 137.184 157.332 136.48 157.765C135.777 158.198 134.993 158.486 134.177 158.61C133.36 158.735 132.526 158.693 131.726 158.489C130.925 158.285 130.174 157.922 129.516 157.422C128.859 156.921 128.309 156.294 127.899 155.576C127.489 154.859 127.227 154.067 127.129 153.246C127.031 152.426 127.1 151.594 127.33 150.801L135.316 119.459C135.121 119.294 134.941 119.111 134.779 118.913L119.233 100.601L104.057 82.7598C102.705 81.1534 102.043 79.0771 102.217 76.9845C102.39 74.8918 103.384 72.9528 104.983 71.5908L105.64 71.0448C107.035 69.8638 108.796 69.2012 110.624 69.1692C112.452 69.1371 114.234 69.7376 115.67 70.869C115.67 70.869 115.67 70.869 115.67 70.869C116.079 71.1779 116.454 71.5283 116.79 71.9147L147.512 108.077C148.809 109.587 149.472 111.54 149.362 113.528C149.364 113.581 149.348 113.633 149.316 113.676Z"
            stroke="#05F2C7"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
          <path
            d="M75.0097 79.8173L57.7241 87.6735L31.8143 99.444C30.2061 100.166 28.4033 100.331 26.6907 99.9136C24.9781 99.4963 23.4533 98.5204 22.3572 97.1399C21.1489 96.156 20.3667 94.744 20.1734 93.1979L15.0377 53.5929C15.0239 53.3371 15.0239 53.0807 15.0377 52.8248C15.1027 51.304 15.7283 49.8611 16.7938 48.7741C17.8594 47.687 19.2895 47.0328 20.8087 46.9374C22.3279 46.842 23.8287 47.3123 25.0218 48.2575C26.215 49.2027 27.0161 50.5561 27.2708 52.0568L31.0555 81.4737L68.1158 64.6416C68.4454 64.4828 68.7893 64.3558 69.1429 64.2622C70.9769 63.7321 72.94 63.8766 74.6766 64.6693C76.4132 65.4621 77.8084 66.8507 78.6093 68.5836L78.9517 69.3516C79.8081 71.2633 79.8744 73.436 79.136 75.3963C78.3976 77.3566 76.9145 78.9457 75.0097 79.8173V79.8173Z"
            stroke="#05F2C7"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
          <path
            d="M119.232 100.582C119.144 101.978 118.773 103.342 118.14 104.589C113.539 114.238 110.45 124.537 108.979 135.126C107.964 141.846 107.565 148.644 107.785 155.437C104.037 157.985 99.5482 159.215 95.0246 158.935L74.2042 157.676C69.7327 157.413 65.4711 155.69 62.0729 152.772C62.7114 147.554 62.9896 142.298 62.9057 137.041C62.8385 126.151 61.0553 115.34 57.6219 105.005C57.1143 103.573 56.8912 102.054 56.9649 100.536L57.7422 87.6644L75.0093 79.8174C76.9211 78.9459 78.4095 77.3521 79.1484 75.3852C79.8873 73.4183 79.8164 71.2387 78.9513 69.324L78.6089 68.5559C77.808 66.8231 76.4128 65.4344 74.6762 64.6417C72.9396 63.8489 70.9765 63.7045 69.1426 64.2345C72.4668 62.4717 76.2135 61.6615 79.9692 61.8934L88.2048 62.3931L88.4454 58.6084L88.9265 59.1266L89.2041 59.4042C90.3613 60.5582 91.7491 61.4548 93.2768 62.0353C94.8045 62.6157 96.4375 62.867 98.069 62.7725L98.5965 62.7077C98.8637 62.6614 99.1246 62.5838 99.3737 62.4764V62.4764C99.6105 62.3873 99.8395 62.279 100.059 62.1525C100.216 62.0679 100.364 61.9688 100.503 61.8564C100.727 61.7097 100.935 61.5391 101.123 61.3475C101.32 61.1585 101.505 60.9576 101.678 60.746L105.444 55.9619L104.065 63.6331C108.608 64.6764 112.668 67.215 115.595 70.8416C114.159 69.7102 112.376 69.1097 110.548 69.1417C108.721 69.1737 106.96 69.8363 105.564 71.0174L104.907 71.5633C103.309 72.9253 102.315 74.8643 102.141 76.957C101.968 79.0496 102.63 81.1259 103.982 82.7323L119.232 100.582Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M121.083 29.848C121.117 33.0821 120.314 36.2701 118.751 39.1015L115.049 43.8208L112.088 47.587L105.537 55.9151L101.771 60.6992C101.338 61.2732 100.791 61.7502 100.163 62.0993C99.5346 62.4483 98.8403 62.6617 98.1247 62.7257C96.4915 62.8414 94.8523 62.6101 93.3149 62.0471C91.7775 61.4841 90.3766 60.6022 89.2043 59.4592L88.9267 59.1816L88.4455 58.6634C86.9402 56.9748 86.0188 54.8463 85.8175 52.5931L84.7719 41.6925L84.0408 34.2897V34.2249C83.7317 32.871 83.5765 31.4866 83.5782 30.0978C83.5684 25.2298 85.4543 20.5492 88.8365 17.0479C92.2186 13.5466 96.8311 11.4997 101.697 11.341C104.185 11.2808 106.661 11.7119 108.983 12.6099C111.305 13.5078 113.427 14.8548 115.228 16.5738C117.029 18.2928 118.473 20.3501 119.477 22.6279C120.482 24.9056 121.027 27.3591 121.083 29.848Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M119.232 100.582C119.144 101.978 118.773 103.342 118.14 104.589C113.539 114.238 110.45 124.537 108.979 135.126C107.964 141.846 107.565 148.644 107.785 155.437C104.037 157.985 99.5482 159.215 95.0246 158.935L74.2042 157.676C69.7327 157.413 65.4711 155.69 62.0729 152.772C62.7114 147.554 62.9896 142.298 62.9057 137.041C62.8385 126.151 61.0553 115.34 57.6219 105.005C57.1143 103.573 56.8912 102.054 56.9649 100.536L57.7422 87.6644L75.0093 79.8174C76.9211 78.9459 78.4095 77.3521 79.1484 75.3852C79.8873 73.4183 79.8164 71.2387 78.9513 69.324L78.6089 68.5559C77.808 66.8231 76.4128 65.4344 74.6762 64.6417C72.9396 63.8489 70.9765 63.7045 69.1426 64.2345C72.4668 62.4717 76.2135 61.6615 79.9692 61.8934L88.2048 62.3931L88.4454 58.6084L88.9265 59.1266L89.2041 59.4042C90.3613 60.5582 91.7491 61.4548 93.2768 62.0353C94.8045 62.6157 96.4375 62.867 98.069 62.7725L98.5965 62.7077C98.8637 62.6614 99.1246 62.5838 99.3737 62.4764V62.4764C99.6105 62.3873 99.8395 62.279 100.059 62.1525C100.216 62.0679 100.364 61.9688 100.503 61.8564C100.727 61.7097 100.935 61.5391 101.123 61.3475C101.32 61.1585 101.505 60.9576 101.678 60.746L105.444 55.9619L104.065 63.6331C108.608 64.6764 112.668 67.215 115.595 70.8416C114.159 69.7102 112.376 69.1097 110.548 69.1417C108.721 69.1737 106.96 69.8363 105.564 71.0174L104.907 71.5633C103.309 72.9253 102.315 74.8643 102.141 76.957C101.968 79.0496 102.63 81.1259 103.982 82.7323L119.232 100.582Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M121.083 29.848C121.117 33.0821 120.314 36.2701 118.751 39.1015L115.049 43.8208L112.088 47.587L105.537 55.9151L101.771 60.6992C101.338 61.2732 100.791 61.7502 100.163 62.0993C99.5346 62.4483 98.8403 62.6617 98.1247 62.7257C96.4915 62.8414 94.8523 62.6101 93.3149 62.0471C91.7775 61.4841 90.3766 60.6022 89.2043 59.4592L88.9267 59.1816L88.4455 58.6634C86.9402 56.9748 86.0188 54.8463 85.8175 52.5931L84.7719 41.6925L84.0408 34.2897V34.2249C83.7317 32.871 83.5765 31.4866 83.5782 30.0978C83.5684 25.2298 85.4543 20.5492 88.8365 17.0479C92.2186 13.5466 96.8311 11.4997 101.697 11.341C104.185 11.2808 106.661 11.7119 108.983 12.6099C111.305 13.5078 113.427 14.8548 115.228 16.5738C117.029 18.2928 118.473 20.3501 119.477 22.6279C120.482 24.9056 121.027 27.3591 121.083 29.848Z"
            stroke="#05F2C7"
            strokeWidth="1.05"
            strokeMiterlimit="10"
          />
          <path
            d="M149.316 113.676C149.33 113.919 149.33 114.163 149.316 114.407V114.462C149.3 114.668 149.256 114.87 149.187 115.064C149.191 115.104 149.191 115.144 149.187 115.184L139.267 153.845C139.089 154.652 138.75 155.415 138.272 156.088C137.793 156.762 137.184 157.332 136.48 157.765C135.777 158.198 134.993 158.486 134.177 158.61C133.36 158.735 132.526 158.693 131.726 158.489C130.925 158.285 130.174 157.922 129.516 157.422C128.859 156.921 128.309 156.294 127.899 155.576C127.489 154.859 127.227 154.067 127.129 153.246C127.031 152.426 127.1 151.594 127.33 150.801L135.316 119.459C135.121 119.294 134.941 119.111 134.779 118.913L119.233 100.601L104.057 82.7598C102.705 81.1534 102.043 79.0771 102.217 76.9845C102.39 74.8918 103.384 72.9528 104.983 71.5908L105.64 71.0448C107.035 69.8638 108.796 69.2012 110.624 69.1692C112.452 69.1371 114.234 69.7376 115.67 70.869C115.67 70.869 115.67 70.869 115.67 70.869C116.079 71.1779 116.454 71.5283 116.79 71.9147L147.512 108.077C148.809 109.587 149.472 111.54 149.362 113.528C149.364 113.581 149.348 113.633 149.316 113.676Z"
            stroke="#05F2C7"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
          <path
            d="M75.0097 79.8173L57.7241 87.6735L31.8143 99.444C30.2061 100.166 28.4033 100.331 26.6907 99.9136C24.9781 99.4963 23.4533 98.5204 22.3572 97.1399C21.1489 96.156 20.3667 94.744 20.1734 93.1979L15.0377 53.5929C15.0239 53.3371 15.0239 53.0807 15.0377 52.8248C15.1027 51.304 15.7283 49.8611 16.7938 48.7741C17.8594 47.687 19.2895 47.0328 20.8087 46.9374C22.3279 46.842 23.8287 47.3123 25.0218 48.2575C26.215 49.2027 27.0161 50.5561 27.2708 52.0568L31.0555 81.4737L68.1158 64.6416C68.4454 64.4828 68.7893 64.3558 69.1429 64.2622C70.9769 63.7321 72.94 63.8766 74.6766 64.6693C76.4132 65.4621 77.8084 66.8507 78.6093 68.5836L78.9517 69.3516C79.8081 71.2633 79.8744 73.436 79.136 75.3963C78.3976 77.3566 76.9145 78.9457 75.0097 79.8173V79.8173Z"
            stroke="#05F2C7"
            strokeWidth="1.03"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </svg>
  );
};
