/* eslint-disable max-len */
import { FC } from 'react';
import './IconSEO.scss';

interface IconSEOProps {
  className?: string;
}

export const IconSEO: FC<IconSEOProps> = ({ className }) => {
  return (
    <svg
      className={'icon__seo ' + className}
      width="88"
      height="117"
      viewBox="0 0 88 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__seo__glass">
          <path
            d="M43.9689 81.5747C65.5415 81.5747 83.0295 64.1345 83.0295 42.6209C83.0295 21.1072 65.5415 3.66699 43.9689 3.66699C22.3963 3.66699 4.9082 21.1072 4.9082 42.6209C4.9082 64.1345 22.3963 81.5747 43.9689 81.5747Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M43.9678 84.2424C67.0175 84.2424 85.7031 65.608 85.7031 42.6212C85.7031 19.6345 67.0175 1 43.9678 1C20.918 1 2.23242 19.6345 2.23242 42.6212C2.23242 65.608 20.918 84.2424 43.9678 84.2424Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M45.881 82.0152C67.4536 82.0152 84.9417 64.5749 84.9417 43.0613C84.9417 21.5477 67.4536 4.10742 45.881 4.10742C24.3084 4.10742 6.82031 21.5477 6.82031 43.0613C6.82031 64.5749 24.3084 82.0152 45.881 82.0152Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M45.8779 84.6829C68.9277 84.6829 87.6132 66.0484 87.6132 43.0617C87.6132 20.0749 68.9277 1.44043 45.8779 1.44043C22.8281 1.44043 4.14258 20.0749 4.14258 43.0617C4.14258 66.0484 22.8281 84.6829 45.8779 84.6829Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M65.7672 79.122L63.6567 80.1976C62.0179 81.0329 61.3684 83.0348 62.2059 84.6691L76.4706 112.505C77.3082 114.139 79.3156 114.787 80.9543 113.951L83.0648 112.876C84.7036 112.04 85.3531 110.039 84.5156 108.404L70.2509 80.5688C69.4134 78.9345 67.4059 78.2868 65.7672 79.122Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M67.5406 79.0698L65.4301 80.1454C63.7914 80.9806 63.1418 82.9826 63.9793 84.6168L78.2441 112.452C79.0816 114.087 81.089 114.734 82.7278 113.899L84.8383 112.823C86.477 111.988 87.1266 109.986 86.289 108.352L72.0243 80.5166C71.1868 78.8823 69.1794 78.2345 67.5406 79.0698Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M63.5389 79.6968L61.5424 80.7143C59.9927 81.5042 59.3784 83.3974 60.1704 84.943L73.6619 111.269C74.4539 112.815 76.3523 113.428 77.9021 112.638L79.8986 111.62C81.4484 110.83 82.0626 108.937 81.2706 107.392L67.7792 81.065C66.9871 79.5195 65.0887 78.9069 63.5389 79.6968Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M65.2245 79.6524L63.228 80.6699C61.6782 81.4598 61.0639 83.353 61.856 84.8985L75.3474 111.225C76.1395 112.771 78.0379 113.383 79.5876 112.593L81.5841 111.576C83.1339 110.786 83.7482 108.893 82.9561 107.347L69.4647 81.0206C68.6727 79.4751 66.7743 78.8625 65.2245 79.6524Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M42.5392 83.0049C64.1118 83.0049 81.5999 65.5647 81.5999 44.051C81.5999 22.5374 64.1118 5.09717 42.5392 5.09717C20.9666 5.09717 3.47852 22.5374 3.47852 44.051C3.47852 65.5647 20.9666 83.0049 42.5392 83.0049Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M42.54 85.6716C65.5898 85.6716 84.2753 67.0372 84.2753 44.0504C84.2753 21.0637 65.5898 2.4292 42.54 2.4292C19.4902 2.4292 0.804688 21.0637 0.804688 44.0504C0.804688 67.0372 19.4902 85.6716 42.54 85.6716Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M44.4513 83.4444C66.0239 83.4444 83.512 66.0041 83.512 44.4905C83.512 22.9769 66.0239 5.53662 44.4513 5.53662C22.8787 5.53662 5.39062 22.9769 5.39062 44.4905C5.39062 66.0041 22.8787 83.4444 44.4513 83.4444Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M44.4521 86.1135C67.5019 86.1135 86.1875 67.4791 86.1875 44.4923C86.1875 21.5056 67.5019 2.87109 44.4521 2.87109C21.4023 2.87109 2.7168 21.5056 2.7168 44.4923C2.7168 67.4791 21.4023 86.1135 44.4521 86.1135Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M64.3355 80.5517L62.2251 81.6273C60.5863 82.4626 59.9368 84.4645 60.7743 86.0988L75.039 113.934C75.8765 115.569 77.8839 116.216 79.5227 115.381L81.6332 114.305C83.2719 113.47 83.9215 111.468 83.084 109.834L68.8192 81.9985C67.9817 80.3642 65.9743 79.7165 64.3355 80.5517Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M66.109 80.4999L63.9985 81.5756C62.3597 82.4108 61.7102 84.4127 62.5477 86.047L76.8124 113.882C77.65 115.517 79.6574 116.165 81.2961 115.329L83.4066 114.254C85.0454 113.418 85.6949 111.416 84.8574 109.782L70.5927 81.9468C69.7552 80.3125 67.7477 79.6647 66.109 80.4999Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.1112 81.127L60.1147 82.1445C58.5649 82.9344 57.9507 84.8276 58.7427 86.3731L72.2341 112.7C73.0262 114.245 74.9246 114.858 76.4744 114.068L78.4709 113.05C80.0206 112.26 80.6349 110.367 79.8429 108.822L66.3514 82.4952C65.5594 80.9497 63.661 80.3371 62.1112 81.127Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M63.7967 81.0816L61.8003 82.0991C60.2505 82.889 59.6362 84.7822 60.4282 86.3277L73.9197 112.654C74.7117 114.2 76.6101 114.812 78.1599 114.022L80.1564 113.005C81.7062 112.215 82.3204 110.322 81.5284 108.776L68.037 82.4498C67.2449 80.9043 65.3465 80.2917 63.7967 81.0816Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__seo__text">
          <path
            d="M21.4235 50.2308C22.6959 50.9932 24.1497 51.402 25.6341 51.4148C28.0351 51.4148 29.4386 50.1516 29.4386 48.3337C29.4386 46.6391 28.4676 45.6928 26.0092 44.7288C23.0433 43.6681 21.2072 42.1319 21.2072 39.5746C21.2072 36.7532 23.5552 34.6537 27.0906 34.6537C28.4836 34.6068 29.8662 34.9094 31.1114 35.534L30.4626 37.4442C29.4021 36.8684 28.2142 36.5659 27.0067 36.5639C24.5262 36.5639 23.5817 38.0429 23.5817 39.2797C23.5817 40.9743 24.6895 41.8106 27.1965 42.801C30.286 43.985 31.8396 45.4419 31.8396 48.1269C31.8396 50.9263 29.7608 53.3471 25.4708 53.3471C23.7053 53.3471 21.803 52.8365 20.832 52.1895L21.4235 50.2308Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M44.4729 44.5794H37.4111V51.1201H45.2894V53.0876H35.0586V34.9487H44.8789V36.9118H37.4066V42.6339H44.4685L44.4729 44.5794Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M63.8815 43.8443C63.8815 50.0901 60.0725 53.3957 55.4338 53.3957C50.6318 53.3957 47.2598 49.6852 47.2598 44.1964C47.2598 38.4348 50.8481 34.6714 55.703 34.6714C60.6684 34.6538 63.8815 38.448 63.8815 43.8443ZM49.7579 44.1436C49.7579 48.017 51.8632 51.4854 55.5618 51.4854C59.2604 51.4854 61.3878 48.0698 61.3878 43.9544C61.3878 40.3451 59.4988 36.5817 55.5883 36.5817C51.6778 36.5817 49.7667 40.1426 49.7667 44.126L49.7579 44.1436Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M22.4151 51.1512C23.6862 51.9128 25.1384 52.3216 26.6213 52.3352C29.0223 52.3352 30.4258 51.072 30.4258 49.2541C30.4258 47.5595 29.4548 46.5912 26.9964 45.6492C24.0349 44.5841 22.2076 43.0523 22.2076 40.495C22.2076 37.6824 24.5468 35.5697 28.0822 35.5697C29.4754 35.5203 30.8586 35.8231 32.103 36.45L31.4763 38.3691C30.4159 37.7929 29.2279 37.4903 28.0204 37.4887C25.5399 37.4887 24.5954 38.9721 24.5954 40.2089C24.5954 41.9035 25.7032 42.7398 28.2102 43.7302C31.2997 44.9142 32.8533 46.3711 32.8533 49.0561C32.8533 51.8555 30.7745 54.2763 26.4844 54.2763C24.719 54.2763 22.8167 53.7657 21.8457 53.1187L22.4151 51.1512Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M45.4656 45.4998H38.4038V52.0405H46.2821V54.0036H36.0469V35.8691H45.8672V37.8322H38.3949V43.5543H45.4567L45.4656 45.4998Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M64.8737 44.7456C64.8737 50.9871 61.0647 54.297 56.426 54.297C51.624 54.297 48.252 50.5865 48.252 45.0934C48.252 39.3361 51.8402 35.5684 56.6952 35.5684C61.6606 35.5684 64.8737 39.3625 64.8737 44.7456ZM50.7501 45.0405C50.7501 48.9183 52.8554 52.3868 56.554 52.3868C60.2526 52.3868 62.3712 48.9711 62.3712 44.8513C62.3712 41.2464 60.4821 37.4786 56.5717 37.4786C52.6612 37.4786 50.7589 41.0571 50.7589 45.0405H50.7501Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M21.4956 48.6867C22.6961 49.4057 24.0675 49.7917 25.4678 49.8047C27.732 49.8047 29.0517 48.6118 29.0517 46.8864C29.0517 45.2887 28.1381 44.3775 25.8253 43.4884C23.0271 42.4981 21.297 41.0543 21.297 38.6467C21.297 36.0057 23.5038 34.0074 26.8405 34.0074C28.1548 33.9628 29.459 34.2505 30.6318 34.8437L30.0183 36.644C29.0188 36.1071 27.9008 35.8272 26.7655 35.8297C24.413 35.8341 23.5303 37.2426 23.5303 38.3958C23.5303 39.9936 24.5719 40.7814 26.9376 41.6926C29.8373 42.8106 31.3115 44.2059 31.3115 46.7148C31.3115 49.3557 29.3563 51.6313 25.309 51.6313C23.6583 51.6313 21.8531 51.1515 20.9395 50.5441L21.4956 48.6867Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M43.2196 43.3646H36.555V49.5268H43.9787V51.3799H34.3438V34.2886H43.6124V36.1416H36.5506V41.5424H43.2152L43.2196 43.3646Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M61.4867 42.6574C61.4867 48.5423 57.9028 51.6586 53.5421 51.6586C49.0182 51.6586 45.8359 48.1373 45.8359 42.9875C45.8359 37.5604 49.2212 34.0127 53.7805 34.0127C58.481 34.0127 61.4867 37.5868 61.4867 42.6574ZM48.1884 42.9391C48.1884 46.588 50.1701 49.8583 53.6569 49.8583C57.1437 49.8583 59.1475 46.6408 59.1475 42.7586C59.1475 39.3606 57.382 35.8129 53.6834 35.8129C49.9848 35.8129 48.2061 39.1845 48.2061 42.9391H48.1884Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
          />
          <path
            d="M22.8531 48.8006C24.1256 49.563 25.5794 49.9718 27.0638 49.9846C29.4648 49.9846 30.8683 48.7214 30.8683 46.9035C30.8683 45.2089 29.8973 44.2626 27.4389 43.2987C24.473 42.2379 22.6369 40.7017 22.6369 38.1444C22.6369 35.323 24.9849 33.2235 28.5203 33.2235C29.9133 33.1766 31.2958 33.4793 32.5411 34.1038L31.8923 36.0141C30.8318 35.4383 29.6439 35.1357 28.4364 35.1338C25.9559 35.1338 25.0114 36.6127 25.0114 37.8495C25.0114 39.5441 26.1192 40.3804 28.6262 41.3708C31.7157 42.5548 33.2693 44.0117 33.2693 46.6967C33.2693 49.4961 31.1905 51.9169 26.9005 51.9169C25.135 51.9169 23.2327 51.4064 22.2617 50.7593L22.8531 48.8006Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M45.9006 43.1482H38.8388V49.6889H46.7171V51.6564H36.4863V33.5176H46.3067V35.4807H38.8344V41.2027H45.8962L45.9006 43.1482Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M65.3131 42.4146C65.3131 48.6605 61.5042 51.966 56.8655 51.966C52.0634 51.966 48.6914 48.2555 48.6914 42.7668C48.6914 37.0051 52.2797 33.2418 57.1347 33.2418C62.1 33.2242 65.3131 37.0183 65.3131 42.4146ZM51.1895 42.7139C51.1895 46.5873 53.2948 50.0557 56.9935 50.0557C60.6921 50.0557 62.8194 46.6401 62.8194 42.5247C62.8194 38.9154 60.9304 35.152 57.0199 35.152C53.1095 35.152 51.1984 38.7129 51.1984 42.6963L51.1895 42.7139Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M23.8428 49.7201C25.1139 50.4817 26.5661 50.8905 28.049 50.9041C30.45 50.9041 31.8535 49.6408 31.8535 47.823C31.8535 46.1284 30.8825 45.16 28.4241 44.2181C25.4626 43.1529 23.6354 41.6212 23.6354 39.0639C23.6354 36.2513 25.9746 34.1385 29.5099 34.1385C30.9032 34.0891 32.2863 34.392 33.5307 35.0188L32.904 36.9379C31.8436 36.3618 30.6556 36.0591 29.4481 36.0576C26.9676 36.0576 26.0231 37.5409 26.0231 38.7778C26.0231 40.4724 27.131 41.3087 29.6379 42.299C32.7274 43.483 34.281 44.9399 34.281 47.6249C34.281 50.4243 32.2022 52.8452 27.9122 52.8452C26.1467 52.8452 24.2444 52.3346 23.2734 51.6876L23.8428 49.7201Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M46.8953 44.0681H39.8334V50.6089H47.7118V52.572H37.4766V34.4375H47.2969V36.4006H39.8246V42.1226H46.8864L46.8953 44.0681Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M66.3034 43.3159C66.3034 49.5574 62.4944 52.8673 57.8557 52.8673C53.0537 52.8673 49.6816 49.1568 49.6816 43.6637C49.6816 37.9064 53.2699 34.1387 58.1249 34.1387C63.0903 34.1387 66.3034 37.9328 66.3034 43.3159ZM52.1798 43.6108C52.1798 47.4886 54.2851 50.9571 57.9837 50.9571C61.6823 50.9571 63.8009 47.5414 63.8009 43.4216C63.8009 39.8167 61.9118 36.049 58.0013 36.049C54.0909 36.049 52.1886 39.6274 52.1886 43.6108H52.1798Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M22.9253 47.2555C24.1258 47.9746 25.4972 48.3606 26.8975 48.3735C29.1617 48.3735 30.4814 47.1807 30.4814 45.4553C30.4814 43.8575 29.5678 42.9464 27.255 42.0573C24.4568 41.0669 22.7266 39.6232 22.7266 37.2155C22.7266 34.5746 24.9335 32.5763 28.2702 32.5763C29.5844 32.5316 30.8887 32.8193 32.0615 33.4126L31.448 35.2128C30.4484 34.6759 29.3304 34.396 28.1951 34.3985C25.8427 34.4029 24.9599 35.8114 24.9599 36.9646C24.9599 38.5624 26.0016 39.3503 28.3673 40.2614C31.267 41.3794 32.7412 42.7747 32.7412 45.2836C32.7412 47.9246 30.7859 50.2002 26.7386 50.2002C25.0879 50.2002 23.2828 49.7204 22.3691 49.113L22.9253 47.2555Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M44.6473 41.9349H37.9827V48.0971H45.4065V49.9502H35.7715V32.8589H45.0401V34.7119H37.9783V40.1127H44.6429L44.6473 41.9349Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
          <path
            d="M62.9144 41.2262C62.9144 47.1111 59.3305 50.2274 54.9699 50.2274C50.4459 50.2274 47.2637 46.7062 47.2637 41.5563C47.2637 36.1292 50.6489 32.5815 55.2082 32.5815C59.9087 32.5815 62.9144 36.1556 62.9144 41.2262ZM49.6161 41.5079C49.6161 45.1568 51.5979 48.4272 55.0846 48.4272C58.5714 48.4272 60.5752 45.2096 60.5752 41.3275C60.5752 37.9294 58.8097 34.3818 55.1111 34.3818C51.4125 34.3818 49.6338 37.7534 49.6338 41.5079H49.6161Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
};
