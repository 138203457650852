import { ENABLE_ANALYTICS } from '../constants/environment';

export enum EventNameEnum {
  questionare_answered_one = 'questionare_answered_one',
  questionare_show_results = 'questionare_show_results',
  open_send_answers_popup = 'open_send_answers_popup',
  close_send_answers_popup = 'close_send_answers_popup',
  submit_answers = 'submit_answers',
}

export enum EventCategoryEnum {
  questionare = 'questionare',
}

const EventNameCategoryMap = {
  [EventNameEnum.questionare_answered_one]: EventCategoryEnum.questionare,
  [EventNameEnum.questionare_show_results]: EventCategoryEnum.questionare,
  [EventNameEnum.open_send_answers_popup]: EventCategoryEnum.questionare,
  [EventNameEnum.close_send_answers_popup]: EventCategoryEnum.questionare,
  [EventNameEnum.submit_answers]: EventCategoryEnum.questionare,
};

export const recordEvent = (eventName: EventNameEnum) => {
  if (!ENABLE_ANALYTICS) return;

  window.gtag('event', eventName, {
    event_category: EventNameCategoryMap[eventName],
  });
};
