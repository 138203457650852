export interface RGB {
  r: number;
  g: number;
  b: number;
}

interface GradientsConfig {
  colors: RGB[];
  count?: number;
  maxRadius?: number;
  minRadius?: number;
}

export class Gradients {
  private colors: RGB[];
  private canvasElement?: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D | null;

  private pixelRatio: 1 | 2;

  private totalParticles: number;
  private particles: GlowParticle[];

  private maxRadius: number;
  private minRadius: number;

  private animationFrameId?: number;

  private stageWidth = 0;
  private stageHeight = 0;

  constructor(canvasElement: HTMLCanvasElement, config: GradientsConfig) {
    this.colors = config.colors;
    this.canvasElement = canvasElement;
    this.ctx = this.canvasElement.getContext('2d');

    this.pixelRatio = window.devicePixelRatio > 1 ? 2 : 1;

    this.totalParticles = config.count ? config.count : 25;
    this.particles = [];
    this.maxRadius = config.maxRadius ? config.maxRadius : 90;
    this.minRadius = config.minRadius ? config.minRadius : 40;

    window.addEventListener('resize', this.resize);

    this.resize();
    this.animate();
  }

  animate() {
    if (!this.ctx) return;
    this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight);

    for (const particle of this.particles) {
      particle.animate(this.ctx, this.stageWidth, this.stageHeight);
    }

    this.animationFrameId = window.requestAnimationFrame(this.animate.bind(this));
  }

  resize() {
    if (!this.canvasElement) return;
    this.stageWidth = this.canvasElement.offsetWidth;
    this.stageHeight = this.canvasElement.offsetHeight;

    this.canvasElement.width = this.stageWidth * this.pixelRatio;
    this.canvasElement.height = this.stageHeight * this.pixelRatio;

    this.ctx?.scale(this.pixelRatio, this.pixelRatio);

    this.createParticles();
  }

  createParticles() {
    let curColor = 0;

    this.particles = [];

    for (let i = 0; i < this.totalParticles; i++) {
      const item = new GlowParticle(
        Math.random() * this.stageWidth,
        Math.random() * this.stageHeight,
        Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
        this.colors[curColor],
      );

      if (++curColor >= this.colors.length) {
        curColor = 0;
      }

      this.particles[i] = item;
    }
  }

  end() {
    if (this.animationFrameId) window.cancelAnimationFrame(this.animationFrameId);
    window.removeEventListener('resize', this.resize);
    delete this.canvasElement;
    this.ctx = null;
    this.particles = [];
  }
}

class GlowParticle {
  private vx: number;
  private vy: number;
  private sinValue: number;

  constructor(private x: number, private y: number, private radius: number, private rgb: RGB) {
    this.vx = Math.random() * 4;
    this.vy = Math.random() * 4;
    this.sinValue = Math.random() + 1;
  }

  animate(ctx: CanvasRenderingContext2D, stageWidth: number, stageHeight: number) {
    this.sinValue += 0.01;

    if (this.radius + Math.sin(this.sinValue) > 0) {
      this.radius += Math.sin(this.sinValue);
    }

    if (this.x < 0) {
      this.vx *= -1;
      this.x += 10;
    } else if (this.x > stageWidth) {
      this.vx *= -1;
      this.x -= 10;
    }

    if (this.y < 0) {
      this.vy *= -1;
      this.y += 10;
    } else if (this.y > stageHeight) {
      this.vy *= -1;
      this.y -= 10;
    }

    this.x += this.vx;
    this.y += this.vy;

    ctx.beginPath();

    const g = ctx.createRadialGradient(
      this.x,
      this.y,
      this.radius * 0.01,
      this.x,
      this.y,
      this.radius,
    );
    g.addColorStop(0, `rgba(${this.rgb.r},${this.rgb.g},${this.rgb.b}, 1)`);
    g.addColorStop(1, `rgba(${this.rgb.r},${this.rgb.g},${this.rgb.b}, 0)`);

    ctx.fillStyle = g;
    ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);

    ctx.fill();
  }
}
