import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { pageVariants } from '../../constants/framer-motion-variants';
import { CalculationAnswer, CalculationQuestionTypeEnum } from '../../content/calculation.content';
import { CalculationQuestionare } from './CalculationQuestionare/CalculationQuestionare';
import { CalculationResult } from './CalculationResult/CalculationResult';
import classes from './Calculation.module.scss';

export const CALCULATION_PATH = 'calculation';

export type Answers = {
  [key in CalculationQuestionTypeEnum]?: CalculationAnswer;
};

export const Calculation: FC = () => {
  const [answers, setAnswers] = useState<Answers | null>(null);

  const onQuestionareDone = (answers: Answers) => {
    setAnswers(answers);
  };

  return (
    <motion.div
      className={classes.calculation}
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {!answers ? (
        <CalculationQuestionare onDone={onQuestionareDone} />
      ) : (
        <CalculationResult answers={answers} />
      )}
    </motion.div>
  );
};
