import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import './ReviewBox.scss';

import { ReviewBoxReviews } from './ReviewBoxReviews';

import svg_arrow from '../../../assets/img/arrow.svg';

import { useMouseMovement } from '../../../hooks/useMouseMovement';
import { Gradients } from '../../../animations/gradients';
import { ReviewEnum } from '../../../content/review.content';
import { RGB_BLACK, RGB_BLUE_LIGHT, RGB_SECONDARY, RGB_WHITE } from '../../../constants/colors';
import { MiddleTopBottomEnum, PrevNextEnum } from '../../../types/enums';

const reviewOrder: ReviewEnum[] = [
  ReviewEnum['tatjanahorka.cz'],
  ReviewEnum['fantasymuzeum.cz'],
  ReviewEnum['adluxholo.cz'],
  ReviewEnum['bydevil.cz'],
  ReviewEnum['keramika-rosice.cz'],
];

export const ReviewBox = () => {
  const [activeReview, setActiveReview] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [reviewsElData, setReviewsElData] = useState<{
    reviewsHeight?: number;
    reviewHeight?: number;
    minTop?: number;
    maxTop?: number;
  }>({
    reviewsHeight: undefined,
    reviewHeight: undefined,
    minTop: undefined,
    maxTop: undefined,
  });
  const [position, setPosition] = useState<MiddleTopBottomEnum>(MiddleTopBottomEnum.MIDDLE);

  const reviewsEl = useRef<HTMLDivElement>(null);
  const reviewsBoxEl = useRef<HTMLDivElement>(null);

  const mq_under450 = useMediaQuery({ maxWidth: 450 });
  const mq_under700 = useMediaQuery({ maxWidth: 700 });
  const mq_under1200 = useMediaQuery({ maxWidth: 1200 });

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const isActive = useCallback((reviewNumber) => reviewNumber === activeReview, [activeReview]);

  const setData = useCallback(() => {
    if (!reviewsBoxEl.current || !reviewsEl.current) return;

    const halfReviewBoxHeight = reviewsBoxEl.current.clientHeight / 2;
    const reviewsHeight = reviewsEl.current.clientHeight;

    const reviewHeight = reviewsHeight / reviewOrder.length;
    const minTop = halfReviewBoxHeight - 0.5 * reviewHeight;

    const posititonTop = halfReviewBoxHeight - reviewHeight * 1.5;
    reviewsEl.current.style.top = posititonTop + 'px';

    setReviewsElData((r) => ({
      ...r,
      reviewsHeight: reviewsHeight,
      reviewHeight: reviewHeight,
      minTop: minTop,
      maxTop: minTop - reviewsHeight + reviewHeight,
    }));
    setActiveReview(1);
    setPosition(MiddleTopBottomEnum.MIDDLE);
  }, [mq_under450, mq_under700, mq_under1200]);

  useEffect(() => {
    setData();
    window.addEventListener('resize', setData);

    return () => {
      window.removeEventListener('resize', setData);
    };
  }, [setData]);

  const onMouseUp = () => {
    setDragging(false);
    window.removeEventListener('mouseup', onMouseUp);
  };
  const onMouseDown: MouseEventHandler<HTMLDivElement> = () => {
    setDragging(true);
    window.addEventListener('mouseup', onMouseUp);
  };

  const moveReviews = (movementValue: number) => {
    if (!reviewsEl.current) return;
    if (!reviewsElData.minTop || !reviewsElData.maxTop) return;

    let newTop = parseInt(reviewsEl.current.style.top) + movementValue;
    if (newTop >= reviewsElData.minTop) {
      newTop = reviewsElData.minTop;
      setPosition(MiddleTopBottomEnum.TOP);
    } else if (newTop <= reviewsElData.maxTop) {
      newTop = reviewsElData.maxTop;
      setPosition(MiddleTopBottomEnum.BOTTOM);
    } else {
      setPosition(MiddleTopBottomEnum.MIDDLE);
    }

    reviewsEl.current.style.top = newTop + 'px';

    const percenatgeScrolled =
      (reviewsElData.minTop - newTop) / (reviewsElData.minTop - reviewsElData.maxTop);
    const newActiveReview = Math.round(percenatgeScrolled * (reviewOrder.length - 1));
    if (newActiveReview !== activeReview) {
      setActiveReview(newActiveReview);
    }
  };

  const onMouseMove: MouseEventHandler<HTMLDivElement> = () => {
    if (!dragging) return;
    moveReviews(useMouseMovement().y);
  };

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (!canvasElement) return;

    const config = {
      count: 20,
      colors: [RGB_WHITE, RGB_SECONDARY, RGB_BLACK, RGB_BLUE_LIGHT],
      maxRadius: 100,
      minRadius: 50,
    };

    let gradients: Gradients | null = new Gradients(canvasElement, config);

    return () => {
      gradients?.end();
      gradients = null;
    };
  }, []);

  const onArrowClick = (direction: PrevNextEnum) => {
    if (!reviewsEl.current) return;
    if (!reviewsElData.reviewHeight || !reviewsElData.reviewsHeight) return;

    reviewsEl.current.style.transition = 'all .2s ease-out';

    switch (direction) {
      case PrevNextEnum.PREV:
        moveReviews(reviewsElData.reviewHeight);
        break;
      case PrevNextEnum.NEXT:
        moveReviews(-reviewsElData.reviewHeight);
        break;
    }

    setTimeout(() => {
      if (!reviewsEl.current) return;
      reviewsEl.current.style.transition = 'none';
    }, 200);
  };
  const onArrowClickPrev = () => onArrowClick(PrevNextEnum.PREV);
  const onArrowClickNext = () => onArrowClick(PrevNextEnum.NEXT);

  return (
    <div
      className="review-box"
      ref={reviewsBoxEl}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      draggable={false}
      style={{ cursor: dragging ? 'grabbing' : 'grab' }}
    >
      <canvas ref={canvasRef} className="review-box__canvas"></canvas>
      <ReviewBoxReviews reviewOrder={reviewOrder} isActive={isActive} _ref={reviewsEl} />
      <div className="review-box__arrows">
        <img
          className="review-box__arrow review-box__arrow--prev"
          src={svg_arrow}
          alt="Předchozí"
          onClick={onArrowClickPrev}
          style={{ opacity: position === MiddleTopBottomEnum.TOP ? 0.4 : 1 }}
        />
        <img
          className="review-box__arrow review-box__arrow--next"
          src={svg_arrow}
          alt="Následující"
          onClick={onArrowClickNext}
          style={{ opacity: position === MiddleTopBottomEnum.BOTTOM ? 0.4 : 1 }}
        />
      </div>
    </div>
  );
};
