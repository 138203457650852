/* eslint-disable max-len */
import { FC } from 'react';
import './IconSecurity.scss';

interface IconSecurityProps {
  className?: string;
}

export const IconSecurity: FC<IconSecurityProps> = ({ className }) => {
  return (
    <svg
      className={'icon__security ' + className}
      width="214"
      height="136"
      viewBox="0 0 214 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__security__box">
          <path
            d="M182.628 31.3877H59.3105V101.721H182.628V31.3877Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M184.388 34.4844H61.0703V104.817H184.388V34.4844Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M179.052 27.2075H55.7344V97.5405H179.052V27.2075Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M180.603 39.0342H74.3516V99.6352H180.603V39.0342Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M170.513 33.252H64.2617V93.853H170.513V33.252Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M55.7314 43.3091H28.2344"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M28.2344 43.3066V15.8096"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M8.47266 15.8096H35.9696"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M55.7329 45.1377H27.3477"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M27.3477 45.1388V17.6362"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M6.94141 17.6362H35.3322"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M56.1828 63.5532H5.1582"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M31.0392 89.3522L5.11328 63.4263"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M52.0698 62.3271H1.05078"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M26.9259 88.1261L1 62.2002"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M59.3105 100.777V132.793"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M35.5938 122.256H74.3493"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M57.8867 104.062V129.505"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M39.0371 121.13H69.8317"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M137.643 104.825L159 129.508"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M157.992 129.2L125.438 126.852"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M167.337 114.2L142.654 135.557"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M139.529 107.612L157.211 128.042"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M156.377 127.791L129.43 125.842"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M164.107 115.373L143.672 133.054"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M184.379 85.0093L210.971 85.2591"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M190.961 65.3857L211.008 85.427"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M180.557 83.5723L203.84 83.7888"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M186.322 66.3843L203.871 83.9384"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M88.3164 3.06885H155.974"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M95.3926 3.28467L119.314 27.2064"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M150.465 3.28467L126.543 27.2064"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M90.3652 2.10889H158.022"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M97.4395 2.31445L121.361 25.0593"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M152.514 2.31445L128.592 25.0593"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M179.053 27.2075H202.658"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M199.619 19.5894L211.339 49.4458"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M174.021 25.6313H198.626"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M195.473 17.6855L207.686 48.8133"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M184.29 30.2788H60.9727V100.612H184.29V30.2788Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M186.05 33.3755H62.7324V103.708H186.05V33.3755Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M180.714 26.0996H57.3965V96.4326H180.714V26.0996Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M182.265 37.9253H76.0137V98.5264H182.265V37.9253Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M172.176 32.1445H65.9238V92.7456H172.176V32.1445Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M57.3935 42.2002H29.8965"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M29.8965 42.1977V14.7007"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M10.1348 14.7007H37.6318"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M57.395 44.0288H29.0098"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M29.0098 44.0299V16.5273"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M8.60352 16.5273H36.9943"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M57.8449 62.4443H6.82031"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M32.7013 88.2433L6.77539 62.3174"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M53.7319 61.2183H2.71289"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M28.588 87.0172L2.66211 61.0913"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M60.9727 99.668V131.684"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M37.2559 121.147H76.0115"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M59.5488 102.953V128.396"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M40.6992 120.021H71.4939"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M139.305 103.716L160.662 128.398"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M159.654 128.092L127.1 125.744"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M168.999 113.094L144.316 134.451"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M141.191 106.503L158.873 126.933"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M158.039 126.682L131.092 124.733"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M165.769 114.264L145.334 131.945"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M186.041 83.9004L212.633 84.1502"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M192.623 64.2769L212.67 84.3181"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M182.219 82.4634L205.502 82.6799"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M187.984 65.2754L205.533 82.8295"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M89.9785 1.95996H157.636"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M97.0547 2.17822L120.976 26.1"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M152.127 2.17822L128.205 26.1"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path d="M92.0273 1H159.684" stroke="#05F2C7" strokeWidth="0.3" strokeMiterlimit="10" />
          <path
            d="M99.1016 1.20557L123.023 23.9504"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M154.176 1.20557L130.254 23.9504"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M180.715 26.0996H204.32"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M201.281 18.4805L213.001 48.3369"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M175.684 24.5225H200.288"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M197.129 16.5776L209.342 47.7054"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__security__000">
          <g className="icon__security__000__1">
            <path
              d="M107.487 64.5895C107.487 74.6823 103.745 80.2505 97.1668 80.2505C91.3709 80.2505 87.4404 74.821 87.3516 65.0003C87.3516 55.0519 91.6429 49.5669 97.672 49.5669C103.923 49.5669 107.487 55.1407 107.487 64.5895ZM91.3876 65.0503C91.3876 72.767 93.7581 77.1472 97.411 77.1472C101.525 77.1472 103.484 72.3561 103.484 64.7727C103.484 57.4668 101.614 52.6758 97.461 52.6758C93.9246 52.6758 91.3709 56.9672 91.3709 65.0503H91.3876Z"
              stroke="#05F2C7"
              strokeWidth="0.3"
            />
            <path
              d="M108.688 65.1315C108.688 75.2187 104.941 80.7925 98.3679 80.7925C92.5721 80.7925 88.6416 75.3575 88.5527 65.5423C88.5527 55.5883 92.8441 50.1089 98.8676 50.1089C105.124 50.1089 108.688 55.6771 108.688 65.1315ZM92.5887 65.5867C92.5887 73.3034 94.9593 77.6836 98.6122 77.6836C102.72 77.6836 104.686 72.8926 104.686 65.3147C104.686 58.0088 102.815 53.2122 98.6622 53.2122C95.1258 53.2122 92.5721 57.5036 92.5721 65.5867H92.5887Z"
              stroke="#05F2C7"
              strokeWidth="0.3"
            />
            <path
              d="M105.825 65.6969C105.825 75.7897 102.083 81.3579 95.5046 81.3579C89.7088 81.3579 85.7783 75.9285 85.6895 66.1077C85.6895 56.1593 89.9808 50.6743 96.0098 50.6743C102.261 50.6743 105.825 56.2481 105.825 65.6969ZM89.7255 66.1577C89.7255 73.8744 92.096 78.2546 95.7489 78.2546C99.8626 78.2546 101.822 73.4636 101.822 65.8801C101.822 58.5742 99.9515 53.7832 95.7989 53.7832C92.2625 53.7832 89.7088 58.0746 89.7088 66.1577H89.7255Z"
              stroke="#1DA88F"
              strokeWidth="0.3"
            />
            <path
              d="M107.026 66.2404C107.026 76.3276 103.279 81.9014 96.7058 81.9014C90.91 81.9014 86.9795 76.4664 86.8906 66.6512C86.8906 56.6972 91.182 51.2178 97.2055 51.2178C103.462 51.2178 107.026 56.786 107.026 66.2404ZM90.9266 66.6956C90.9266 74.4123 93.2971 78.7925 96.9501 78.7925C101.058 78.7925 103.024 74.0015 103.024 66.4236C103.024 59.1177 101.153 54.3211 97.0001 54.3211C93.4637 54.3211 90.91 58.6125 90.91 66.6956H90.9266Z"
              stroke="#1DA88F"
              strokeWidth="0.3"
            />
          </g>
          <g className="icon__security__000__2">
            <path
              d="M130.927 64.5895C130.927 74.6823 127.185 80.2505 120.612 80.2505C114.81 80.2505 110.885 74.821 110.791 65.0003C110.791 55.0519 115.088 49.5669 121.111 49.5669C127.368 49.5669 130.927 55.1407 130.927 64.5895ZM114.827 65.0503C114.827 72.767 117.203 77.1472 120.856 77.1472C124.964 77.1472 126.929 72.3561 126.929 64.7727C126.929 57.4668 125.059 52.6758 120.9 52.6758C117.37 52.6758 114.81 56.9672 114.81 65.0503H114.827Z"
              stroke="#05F2C7"
              strokeWidth="0.3"
            />
            <path
              d="M132.12 65.1315C132.12 75.2187 128.378 80.7925 121.8 80.7925C116.004 80.7925 112.073 75.3575 111.984 65.5423C111.984 55.5883 116.276 50.1089 122.305 50.1089C128.561 50.1089 132.12 55.6771 132.12 65.1315ZM116.02 65.5867C116.02 73.3034 118.396 77.6836 122.049 77.6836C126.158 77.6836 128.123 72.8926 128.123 65.3147C128.123 58.0088 126.246 53.2122 122.094 53.2122C118.557 53.2122 116.004 57.5036 116.004 65.5867H116.02Z"
              stroke="#05F2C7"
              strokeWidth="0.3"
            />
            <path
              d="M129.264 65.6969C129.264 75.7897 125.523 81.3579 118.95 81.3579C113.148 81.3579 109.223 75.9285 109.129 66.1077C109.129 56.1593 113.426 50.6743 119.449 50.6743C125.706 50.6743 129.264 56.2481 129.264 65.6969ZM113.165 66.1577C113.165 73.8744 115.541 78.2546 119.194 78.2546C123.302 78.2546 125.267 73.4636 125.267 65.8801C125.267 58.5742 123.396 53.7832 119.238 53.7832C115.708 53.7832 113.148 58.0746 113.148 66.1577H113.165Z"
              stroke="#1DA88F"
              strokeWidth="0.3"
            />
            <path
              d="M130.456 66.2404C130.456 76.3276 126.714 81.9014 120.136 81.9014C114.34 81.9014 110.409 76.4664 110.32 66.6512C110.32 56.6972 114.612 51.2178 120.641 51.2178C126.897 51.2178 130.456 56.786 130.456 66.2404ZM114.356 66.6956C114.356 74.4123 116.732 78.7925 120.385 78.7925C124.494 78.7925 126.459 74.0015 126.459 66.4236C126.459 59.1177 124.582 54.3211 120.43 54.3211C116.893 54.3211 114.34 58.6125 114.34 66.6956H114.356Z"
              stroke="#1DA88F"
              strokeWidth="0.3"
            />
          </g>
          <g className="icon__security__000__3">
            <path
              d="M153.897 66.2404C153.897 76.3276 150.15 81.9014 143.577 81.9014C137.775 81.9014 133.85 76.4664 133.756 66.6512C133.756 56.6972 138.053 51.2178 144.076 51.2178C150.333 51.2178 153.897 56.786 153.897 66.2404ZM137.797 66.6956C137.797 74.4123 140.173 78.7925 143.826 78.7925C147.935 78.7925 149.9 74.0015 149.9 66.4236C149.9 59.1177 148.029 54.3211 143.871 54.3211C140.334 54.3211 137.775 58.6125 137.775 66.6956H137.797Z"
              stroke="#1DA88F"
              strokeWidth="0.3"
            />
            <path
              d="M152.727 65.6969C152.727 75.7897 148.98 81.3579 142.407 81.3579C136.611 81.3579 132.681 75.9285 132.592 66.1077C132.592 56.1593 136.883 50.6743 142.912 50.6743C149.136 50.6743 152.727 56.2481 152.727 65.6969ZM136.628 66.1577C136.628 73.8744 138.998 78.2546 142.651 78.2546C146.765 78.2546 148.725 73.4636 148.725 65.8801C148.725 58.5742 146.854 53.7832 142.701 53.7832C139.137 53.7832 136.583 58.0746 136.583 66.1577H136.628Z"
              stroke="#1DA88F"
              strokeWidth="0.3"
            />
            <path
              d="M155.559 65.1315C155.559 75.2187 151.812 80.7925 145.239 80.7925C139.437 80.7925 135.512 75.3575 135.418 65.5423C135.418 55.5883 139.715 50.1089 145.738 50.1089C151.995 50.1089 155.559 55.6771 155.559 65.1315ZM139.46 65.5867C139.46 73.3034 141.836 77.6836 145.489 77.6836C149.597 77.6836 151.562 72.8926 151.562 65.3147C151.562 58.0088 149.691 53.2122 145.533 53.2122C141.997 53.2122 139.437 57.5036 139.437 65.5867H139.46Z"
              stroke="#05F2C7"
              strokeWidth="0.3"
            />
            <path
              d="M154.389 64.5895C154.389 74.6823 150.642 80.2505 144.069 80.2505C138.273 80.2505 134.343 74.821 134.254 65.0003C134.254 55.0519 138.545 49.5669 144.574 49.5669C150.798 49.5669 154.389 55.1407 154.389 64.5895ZM138.29 65.0503C138.29 72.767 140.66 77.1472 144.313 77.1472C148.427 77.1472 150.387 72.3561 150.387 64.7727C150.387 57.4668 148.516 52.6758 144.363 52.6758C140.799 52.6758 138.245 56.9672 138.245 65.0503H138.29Z"
              stroke="#05F2C7"
              strokeWidth="0.3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
