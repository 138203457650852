import classes from './LoadingFallBack.module.scss';
import AmnioMLogo from '../../assets/img/amn_logo_grey.svg';

export const LoadingFallback = () => {
  return (
    <div className={classes.LoadingFallback}>
      <img src={AmnioMLogo} alt="AmnioM logo" className={classes.LoadingFallback__logo}></img>
    </div>
  );
};
