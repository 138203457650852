import { useEffect, useRef } from 'react';

import './Square.scss';

const randomObjectProperty = (obj) => {
  const keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
};

class SquareAnim {
  constructor(
    canvasRef,
    pC_Coefficient,
    pC_Cols,
    pC_EstimatedDuration,
    pC_Gap,
    pC_MinScale,
    pC_Rows,
  ) {
    this.cols = pC_Cols || 6;
    this.rows = pC_Rows || 6;
    this.gap = pC_Gap || 10;

    this.canvasElement = canvasRef.current;
    this.ctx = this.canvasElement.getContext('2d');
    this.sizes = {
      width: this.canvasElement.offsetWidth,
      height: this.canvasElement.offsetHeight,
    };
    this.canvasElement.width = this.sizes.width;
    this.canvasElement.height = this.sizes.height;

    this.representation = new Array(this.cols * this.rows);

    this.spawnIndex = 0;

    for (let i = 0; i < this.cols; i++) {
      for (let j = 0; j < this.rows; j++) {
        this.representation[i * this.cols + j] = {
          id: i * this.cols + j,
          scale: 1,
          states: [],
        };
      }
    }

    const minScale = pC_MinScale || 0.1;
    const coefficient = pC_Coefficient || 0.7;
    const estimatedDuration = pC_EstimatedDuration || 1; //in seconds
    const timeout = (estimatedDuration / (this.cols * this.rows)) * 1000;

    this.CONFIG = {
      PHASES: {
        COLUMNSTAGGER: {
          coefficient: coefficient,
          minScale: minScale,
          timeout: timeout, //ms
        },
        RANDOM: {
          minScale: minScale,
          coefficient: coefficient,
          timeout: timeout, //ms
        },
        SPIRAL: {
          minScale: minScale,
          coefficient: coefficient,
          timeout: timeout, //ms
        },
      },
    };

    //PHASES
    this.PHASES = {
      IN: {
        SPIRAL: {
          states: {
            FINSIHED: {},
            ANIMATING: {},
          },
          variants: {
            IN: {},
            OUT: {},
          },
          init: () => {
            for (const item of this.representation) {
              item.states = [];
            }

            const directions = {
              LEFT: 'LEFT',
              RIGHT: 'RIGHT',
              TOP: 'TOP',
              BOTTOM: 'BOTTOM',
            };

            const startpoints = {
              TOPLEFT: 'TOPLEFT',
              TOPRIGHT: 'TOPRIGHT',
              BOTTOMRIGHT: 'BOTTOMRIGHT',
              BOTTOMLEFT: 'BOTTOMLEFT',
            };

            const clockwise = Math.random() > 0.5 ? true : false;
            const startpoint = randomObjectProperty(startpoints);

            const decomponeSpiral = (startpoint, clockwise) => {
              let totalCounter = 0;
              let decomponeArray = [];

              let direction = directions.BOTTOM;

              const currentPos = {
                x: null,
                y: null,
              };

              switch (startpoint) {
                case startpoints.TOPLEFT:
                  currentPos.y = 0;
                  currentPos.x = 0;
                  if (clockwise) {
                    direction = directions.RIGHT;
                  } else {
                    direction = directions.BOTTOM;
                  }
                  break;
                case startpoints.TOPRIGHT:
                  currentPos.y = 0;
                  currentPos.x = this.cols - 1;
                  if (clockwise) {
                    direction = directions.BOTTOM;
                  } else {
                    direction = directions.LEFT;
                  }
                  break;
                case startpoints.BOTTOMLEFT:
                  currentPos.y = this.rows - 1;
                  currentPos.x = 0;
                  if (clockwise) {
                    direction = directions.TOP;
                  } else {
                    direction = directions.RIGHT;
                  }
                  break;
                case startpoints.BOTTOMRIGHT:
                  currentPos.y = this.rows - 1;
                  currentPos.x = this.cols - 1;
                  if (clockwise) {
                    direction = directions.LEFT;
                  } else {
                    direction = directions.TOP;
                  }
                  break;
                default:
                  break;
              }

              let fromTop = 0;
              let fromBottom = 0;
              let fromLeft = 0;
              let fromRight = 0;

              while (totalCounter < this.cols * this.rows) {
                const id = currentPos.x * this.rows + currentPos.y;
                decomponeArray.push(id);
                switch (direction) {
                  case directions.LEFT:
                    currentPos.x--;
                    if (currentPos.x === fromLeft) {
                      if (clockwise) {
                        direction = directions.TOP;
                        fromBottom++;
                      } else {
                        direction = directions.BOTTOM;
                        fromTop++;
                      }
                    }
                    break;
                  case directions.RIGHT:
                    currentPos.x++;
                    if (currentPos.x === this.cols - 1 - fromRight) {
                      if (clockwise) {
                        direction = directions.BOTTOM;
                        fromTop++;
                      } else {
                        direction = directions.TOP;
                        fromBottom++;
                      }
                    }
                    break;
                  case directions.TOP:
                    currentPos.y--;
                    if (currentPos.y === fromTop) {
                      if (clockwise) {
                        direction = directions.RIGHT;
                        fromLeft++;
                      } else {
                        direction = directions.LEFT;
                        fromRight++;
                      }
                    }
                    break;
                  case directions.BOTTOM:
                    currentPos.y++;
                    if (currentPos.y === this.rows - 1 - fromBottom) {
                      if (clockwise) {
                        direction = directions.LEFT;
                        fromRight++;
                      } else {
                        direction = directions.RIGHT;
                        fromLeft++;
                      }
                    }
                    break;
                  default:
                    return;
                }

                totalCounter++;
              }
              return decomponeArray;
            };

            const spiralArray = decomponeSpiral(startpoint, clockwise);

            const reverseIt = Math.random() > 0.5 ? true : false;

            if (reverseIt) {
              spiralArray.reverse();
            }

            const timeout = this.CONFIG.PHASES.SPIRAL.timeout;
            const states = this.currentPhase.states;
            const minScale = this.CONFIG.PHASES.SPIRAL.minScale;

            let currentIndex = 0;

            const interval = setInterval(() => {
              const id = spiralArray[currentIndex];

              this.representation[id].states.push(states.ANIMATING);
              this.representation[id].scale = minScale;

              if (currentIndex === this.cols * this.rows - 1) {
                clearInterval(interval);
              }
              currentIndex++;
            }, timeout);
          },
          update: () => {
            const states = this.currentPhase.states;
            const coefficient = this.CONFIG.PHASES.SPIRAL.coefficient;

            let allFinished = true;

            for (const item of this.representation) {
              if (this.isInState(item, states.ANIMATING)) {
                item.scale *= 1 / coefficient;
                if (item.scale > 1) {
                  item.scale = 1;
                  this.removeState(item, states.ANIMATING);
                  item.states.push(states.FINISHED);
                }
              }
              allFinished = allFinished && this.isInState(item, states.FINISHED);
            }

            if (allFinished) {
              this.changePhase();
            }
          },
        },
        COLUMNSTAGGER: {
          states: {
            ANIMATING: {},
            SPAWNING: {},
          },
          variants: {
            LEFTRIGHT: {},
            RIGHTLEFT: {},
            TOPBOTTOM: {},
            BOTTOMTOP: {},
            LEFTRIGHT_R: {},
            RIGHTLEFT_R: {},
            TOPBOTTOM_R: {},
            BOTTOMTOP_R: {},
          },
          init: () => {
            const phase = this.currentPhase;

            const variant = randomObjectProperty(phase.variants);

            for (const item of this.representation) {
              item.states = [];
              item.states.push(phase.states.ANIMATING);
            }

            let counter1 = 0;
            let counter2 = 0;

            const getRealIndex = () => {
              switch (variant) {
                case phase.variants.LEFTRIGHT:
                  return counter1;
                case phase.variants.LEFTRIGHT_R:
                  return (counter2 + 1) * this.rows - 1 - counter1;
                case phase.variants.BOTTOMTOP:
                  return (counter1 + 1) * this.rows - counter2 - 1;
                case phase.variants.RIGHTLEFT:
                  return this.cols * this.rows - 1 - counter1;
                case phase.variants.TOPBOTTOM:
                  return counter1 * this.rows + counter2;
                case phase.variants.RIGHTLEFT_R:
                  return this.cols * this.rows - (counter1 + 1) * this.rows + counter2;
                case phase.variants.TOPBOTTOM_R:
                  return this.cols * this.rows - (counter1 + 1) * this.rows + counter2;
                case phase.variants.BOTTOMTOP_R:
                  return this.rows * this.cols - counter1 * this.rows - 1 - counter2;
                default:
                  break;
              }
            };

            const incrementCounters = () => {
              switch (variant) {
                case phase.variants.LEFTRIGHT:
                  counter1++;
                  break;
                case phase.variants.BOTTOMTOP:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                case phase.variants.RIGHTLEFT:
                  counter1++;
                  break;
                case phase.variants.TOPBOTTOM:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                case phase.variants.LEFTRIGHT_R:
                  counter2++;
                  if (counter2 === this.rows) {
                    counter2 = 0;
                    counter1++;
                  }
                  break;
                case phase.variants.RIGHTLEFT_R:
                  counter2++;
                  if (counter2 === this.rows) {
                    counter2 = 0;
                    counter1++;
                  }
                  break;
                case phase.variants.TOPBOTTOM_R:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                case phase.variants.BOTTOMTOP_R:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                default:
                  break;
              }
            };

            let spawnIndex = 0;

            const interval = setInterval(() => {
              const id = getRealIndex();

              this.representation[id].states.push(phase.states.SPAWNING);
              this.representation[id].scale = this.CONFIG.PHASES.COLUMNSTAGGER.minScale;

              if (spawnIndex === this.representation.length - 1) {
                clearInterval(interval);
                return;
              }

              incrementCounters();
              spawnIndex++;
            }, this.CONFIG.PHASES.COLUMNSTAGGER.timeout);
          },
          update: () => {
            const phase = this.currentPhase;

            let everythingAnimated = true;

            for (const item of this.representation) {
              if (
                this.isInState(item, phase.states.ANIMATING) &&
                this.isInState(item, phase.states.SPAWNING)
              ) {
                item.scale *= 1 / this.CONFIG.PHASES.COLUMNSTAGGER.coefficient;
                if (item.scale > 1) {
                  item.scale = 1;
                  this.removeState(item, phase.states.ANIMATING);
                  this.removeState(item, phase.states.SPAWNING);
                }
              }
              everythingAnimated =
                everythingAnimated && !this.isInState(item, phase.states.ANIMATING);
            }

            if (everythingAnimated) {
              this.changePhase();
            }
          },
        },
        RANDOM: {
          states: {
            FINISHED: {},
            ANIMATING: {},
          },
          update: () => {
            const states = this.currentPhase.states;
            const coefficient = this.CONFIG.PHASES.RANDOM.coefficient;

            let allFinished = true;

            for (const item of this.representation) {
              if (this.isInState(item, states.ANIMATING)) {
                item.scale *= 1 / coefficient;
                if (item.scale > 1) {
                  item.scale = 1;
                  this.removeState(item, states.ANIMATING);
                  item.states.push(states.FINISHED);
                }
              }
              allFinished = allFinished && this.isInState(item, states.FINISHED);
            }

            if (allFinished) {
              this.changePhase();
            }
          },
          init: () => {
            const minScale = this.CONFIG.PHASES.RANDOM.minScale;
            const states = this.currentPhase.states;
            const timeout = this.CONFIG.PHASES.RANDOM.timeout;

            for (const item of this.representation) {
              item.states = [];
            }

            let spawnIndex = 0;

            const interval = setInterval(() => {
              const fullSquares = this.representation.filter((item) => {
                return !(
                  this.isInState(item, states.ANIMATING) || this.isInState(item, states.FINISHED)
                );
              });

              let randomIndex = null;
              if (fullSquares.length !== 0) {
                randomIndex = fullSquares[Math.floor(Math.random() * fullSquares.length)].id;
              }
              if (randomIndex !== null) {
                this.representation[randomIndex].states.push(states.ANIMATING);
                this.representation[randomIndex].scale = minScale;
              }

              if (spawnIndex === this.cols * this.rows - 1) {
                clearInterval(interval);
              }

              spawnIndex++;
            }, timeout);
          },
        },
      },
      OUT: {
        SPIRAL: {
          states: {
            FINSIHED: {},
            ANIMATING: {},
          },
          variants: {
            IN: {},
            OUT: {},
          },
          init: () => {
            for (const item of this.representation) {
              item.states = [];
            }

            const directions = {
              LEFT: 'LEFT',
              RIGHT: 'RIGHT',
              TOP: 'TOP',
              BOTTOM: 'BOTTOM',
            };

            const startpoints = {
              TOPLEFT: 'TOPLEFT',
              TOPRIGHT: 'TOPRIGHT',
              BOTTOMRIGHT: 'BOTTOMRIGHT',
              BOTTOMLEFT: 'BOTTOMLEFT',
            };

            const clockwise = Math.random() > 0.5 ? true : false;
            const startpoint = randomObjectProperty(startpoints);

            const decomponeSpiral = (startpoint, clockwise) => {
              let totalCounter = 0;
              let decomponeArray = [];

              let direction = directions.BOTTOM;

              const currentPos = {
                x: null,
                y: null,
              };

              switch (startpoint) {
                case startpoints.TOPLEFT:
                  currentPos.y = 0;
                  currentPos.x = 0;
                  if (clockwise) {
                    direction = directions.RIGHT;
                  } else {
                    direction = directions.BOTTOM;
                  }
                  break;
                case startpoints.TOPRIGHT:
                  currentPos.y = 0;
                  currentPos.x = this.cols - 1;
                  if (clockwise) {
                    direction = directions.BOTTOM;
                  } else {
                    direction = directions.LEFT;
                  }
                  break;
                case startpoints.BOTTOMLEFT:
                  currentPos.y = this.rows - 1;
                  currentPos.x = 0;
                  if (clockwise) {
                    direction = directions.TOP;
                  } else {
                    direction = directions.RIGHT;
                  }
                  break;
                case startpoints.BOTTOMRIGHT:
                  currentPos.y = this.rows - 1;
                  currentPos.x = this.cols - 1;
                  if (clockwise) {
                    direction = directions.LEFT;
                  } else {
                    direction = directions.TOP;
                  }
                  break;
                default:
                  break;
              }

              let fromTop = 0;
              let fromBottom = 0;
              let fromLeft = 0;
              let fromRight = 0;

              while (totalCounter < this.cols * this.rows) {
                const id = currentPos.x * this.rows + currentPos.y;
                decomponeArray.push(id);
                switch (direction) {
                  case directions.LEFT:
                    currentPos.x--;
                    if (currentPos.x === fromLeft) {
                      if (clockwise) {
                        direction = directions.TOP;
                        fromBottom++;
                      } else {
                        direction = directions.BOTTOM;
                        fromTop++;
                      }
                    }
                    break;
                  case directions.RIGHT:
                    currentPos.x++;
                    if (currentPos.x === this.cols - 1 - fromRight) {
                      if (clockwise) {
                        direction = directions.BOTTOM;
                        fromTop++;
                      } else {
                        direction = directions.TOP;
                        fromBottom++;
                      }
                    }
                    break;
                  case directions.TOP:
                    currentPos.y--;
                    if (currentPos.y === fromTop) {
                      if (clockwise) {
                        direction = directions.RIGHT;
                        fromLeft++;
                      } else {
                        direction = directions.LEFT;
                        fromRight++;
                      }
                    }
                    break;
                  case directions.BOTTOM:
                    currentPos.y++;
                    if (currentPos.y === this.rows - 1 - fromBottom) {
                      if (clockwise) {
                        direction = directions.LEFT;
                        fromRight++;
                      } else {
                        direction = directions.RIGHT;
                        fromLeft++;
                      }
                    }
                    break;
                  default:
                    return;
                }

                totalCounter++;
              }
              return decomponeArray;
            };

            const spiralArray = decomponeSpiral(startpoint, clockwise);

            const reverseIt = Math.random() > 0.5 ? true : false;

            if (reverseIt) {
              spiralArray.reverse();
            }

            const timeout = this.CONFIG.PHASES.SPIRAL.timeout;
            const states = this.currentPhase.states;
            //const minScale = this.CONFIG.PHASES.SPIRAL.minScale;

            let currentIndex = 0;

            const interval = setInterval(() => {
              const id = spiralArray[currentIndex];

              this.representation[id].states.push(states.ANIMATING);

              if (currentIndex === this.cols * this.rows - 1) {
                clearInterval(interval);
              }
              currentIndex++;
            }, timeout);
          },
          update: () => {
            const states = this.currentPhase.states;
            const coefficient = this.CONFIG.PHASES.SPIRAL.coefficient;
            const minScale = this.CONFIG.PHASES.SPIRAL.minScale;

            let allFinished = true;

            for (const item of this.representation) {
              if (this.isInState(item, states.ANIMATING)) {
                item.scale *= coefficient;
                if (item.scale < minScale) {
                  item.scale = 0;
                  this.removeState(item, states.ANIMATING);
                  item.states.push(states.FINISHED);
                }
              }
              allFinished = allFinished && this.isInState(item, states.FINISHED);
            }

            if (allFinished) {
              this.changePhase();
            }
          },
        },
        RANDOMOUT: {
          states: {
            HIDDEN: {},
            ANIMATING: {},
          },
          init: () => {
            const timeout = this.CONFIG.PHASES.RANDOM.timeout;
            const states = this.currentPhase.states;

            for (const item of this.representation) {
              item.states = [];
            }

            let spawnIndex = 0;

            const interval = setInterval(() => {
              const fullSquares = this.representation.filter((item) => {
                return !(
                  this.isInState(item, states.ANIMATING) || this.isInState(item, states.HIDDEN)
                );
              });

              let randomIndex = null;
              if (fullSquares.length !== 0) {
                randomIndex = fullSquares[Math.floor(Math.random() * fullSquares.length)].id;
              }
              if (randomIndex !== null) {
                this.representation[randomIndex].states.push(states.ANIMATING);
              }

              if (spawnIndex === this.cols * this.rows - 1) {
                clearInterval(interval);
              }

              spawnIndex++;
            }, timeout);
          },
          update: () => {
            const states = this.currentPhase.states;

            this.representation.forEach((item) => {
              if (this.isInState(item, states.ANIMATING)) {
                item.scale *= this.CONFIG.PHASES.RANDOM.coefficient;
              }

              if (item.scale < this.CONFIG.PHASES.RANDOM.minScale) {
                item.scale = 0;
                item.states.filter((state) => {
                  return state !== states.ANIMATING;
                });
                item.states.push(states.HIDDEN);
              }
            });

            const allSquaresHidden = this.representation.reduce((prevValue, item) => {
              return prevValue && this.isInState(item, states.HIDDEN);
            }, true);

            if (allSquaresHidden) {
              this.changePhase();
            }
          },
        },
        COLUMNSTAGGER: {
          states: {
            HIDDEN: {},
            REMOVING: {},
          },
          variants: {
            LEFTRIGHT: {},
            RIGHTLEFT: {},
            TOPBOTTOM: {},
            BOTTOMTOP: {},
            LEFTRIGHT_R: {},
            RIGHTLEFT_R: {},
            TOPBOTTOM_R: {},
            BOTTOMTOP_R: {},
          },
          init: () => {
            const variant = randomObjectProperty(this.currentPhase.variants);
            const states = this.currentPhase.states;
            const phase = this.currentPhase;

            for (const item of this.representation) {
              item.states = [];
            }
            let currentIndex = 0;

            let counter1 = 0;
            let counter2 = 0;

            const getRealIndex = () => {
              switch (variant) {
                case phase.variants.LEFTRIGHT:
                  return counter1;
                case phase.variants.LEFTRIGHT_R:
                  return (counter2 + 1) * this.rows - 1 - counter1;
                case phase.variants.BOTTOMTOP:
                  return (counter1 + 1) * this.rows - counter2 - 1;
                case phase.variants.RIGHTLEFT:
                  return this.cols * this.rows - 1 - counter1;
                case phase.variants.TOPBOTTOM:
                  return counter1 * this.rows + counter2;
                case phase.variants.RIGHTLEFT_R:
                  return this.cols * this.rows - (counter1 + 1) * this.rows + counter2;
                case phase.variants.TOPBOTTOM_R:
                  return this.cols * this.rows - (counter1 + 1) * this.rows + counter2;
                case phase.variants.BOTTOMTOP_R:
                  return this.rows * this.cols - counter1 * this.rows - 1 - counter2;
                default:
                  break;
              }
            };

            const incrementCounters = () => {
              switch (variant) {
                case phase.variants.LEFTRIGHT:
                  counter1++;
                  break;
                case phase.variants.BOTTOMTOP:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                case phase.variants.RIGHTLEFT:
                  counter1++;
                  break;
                case phase.variants.TOPBOTTOM:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                case phase.variants.LEFTRIGHT_R:
                  counter2++;
                  if (counter2 === this.rows) {
                    counter2 = 0;
                    counter1++;
                  }
                  break;
                case phase.variants.RIGHTLEFT_R:
                  counter2++;
                  if (counter2 === this.rows) {
                    counter2 = 0;
                    counter1++;
                  }
                  break;
                case phase.variants.TOPBOTTOM_R:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                case phase.variants.BOTTOMTOP_R:
                  counter1++;
                  if (counter1 === this.cols) {
                    counter1 = 0;
                    counter2++;
                  }
                  break;
                default:
                  break;
              }
            };
            const interval = setInterval(() => {
              const id = getRealIndex();
              this.representation[id].states.push(states.REMOVING);
              if (currentIndex === this.representation.length - 1) {
                return clearInterval(interval);
              }
              currentIndex++;
              incrementCounters();
            }, this.CONFIG.PHASES.COLUMNSTAGGER.timeout);
          },
          update: () => {
            const states = this.currentPhase.states;

            let everythingRemoved = true;
            for (const item of this.representation) {
              if (this.isInState(item, states.REMOVING)) {
                item.scale *= this.CONFIG.PHASES.COLUMNSTAGGER.coefficient;
              }
              if (item.scale < this.CONFIG.PHASES.COLUMNSTAGGER.minScale) {
                this.removeState(item, states.REMOVING);
                item.states.push(states.HIDDEN);
                item.scale = 0;
              }
              everythingRemoved = everythingRemoved && this.isInState(item, states.HIDDEN);
            }

            if (everythingRemoved) {
              this.changePhase();
            }
          },
        },
      },
    };

    this.DIRECTIONS = {
      IN: 'IN',
      OUT: 'OUT',
    };

    this.currentPhase = null;

    //Will be switched when changePhase is called
    this.currentDirection = this.DIRECTIONS.IN;

    //Start animation loop
    this.changePhase();
    this.animate();
  }

  animate() {
    this.ctx.clearRect(0, 0, this.sizes.width, this.sizes.height);

    this.ctx.fillStyle = '#162021';

    // const bandWidthHorizontal = (this.sizes.width + this.gap) / this.cols;
    // const bandWidthVertical = (this.sizes.height + this.gap) / this.cols;

    const bandWidthHorizontal = this.sizes.width / this.cols;
    const bandWidthVertical = this.sizes.height / this.cols;

    for (let i = 0; i < this.cols; i++) {
      for (let j = 0; j < this.rows; j++) {
        const scale = this.representation[i * this.cols + j].scale;
        const width = (bandWidthHorizontal - this.gap) * scale;
        const height = (bandWidthVertical - this.gap) * scale;

        this.ctx.fillRect(
          i * bandWidthHorizontal + (bandWidthHorizontal - width) / 2,
          j * bandWidthVertical + (bandWidthVertical - width) / 2,
          width,
          height,
        );
      }
    }

    this.update();

    this.animationFrameId = window.requestAnimationFrame(this.animate.bind(this));
  }

  isInState(item, state) {
    const isContaingState = item.states.reduce((prevValue, curState) => {
      return prevValue || state === curState;
    }, false);
    return isContaingState;
  }

  removeState(item, state) {
    item.states = item.states.filter((curState) => {
      return curState !== state;
    });
  }

  update() {
    const phase = this.currentPhase;
    phase.update();
  }

  randomPhase(direction) {
    let obj = null;
    if (direction === this.DIRECTIONS.IN) {
      obj = this.PHASES.IN;
    } else if (direction === this.DIRECTIONS.OUT) {
      obj = this.PHASES.OUT;
    }
    return randomObjectProperty(obj);
  }

  switchDirection() {
    this.currentDirection =
      this.currentDirection === this.DIRECTIONS.OUT ? this.DIRECTIONS.IN : this.DIRECTIONS.OUT;
  }

  changePhase() {
    //SWITCH DIRECTION
    this.switchDirection();

    //SELECT PHASE
    const phase = this.randomPhase(this.currentDirection);

    //UPDATE PHASE
    this.currentPhase = phase;

    //INIT PHASE
    phase.init();
  }
}

const Square = (props) => {
  const canvasRef = useRef();

  // eslint-disable-next-line react/prop-types
  const { coefficient, cols, estimatedDuration, gap, minScale, rows } = props.config;

  useEffect(() => {
    let sqAnim = new SquareAnim(
      canvasRef,
      coefficient,
      cols,
      estimatedDuration,
      gap,
      minScale,
      rows,
    );

    return () => {
      window.cancelAnimationFrame(sqAnim.animationFrameId);
      sqAnim = null;
    };
  }, [canvasRef, coefficient, cols, estimatedDuration, gap, minScale, rows]);

  // eslint-disable-next-line react/prop-types
  return <canvas className={'Square ' + props.className} ref={canvasRef}></canvas>;
};

export default Square;
