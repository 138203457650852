import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { pageVariants } from '../../../constants/framer-motion-variants';
import {
  CALCULATION_CONTENT,
  calculationQuestionOrder,
  CalculationAnswer,
} from '../../../content/calculation.content';
import { CTA } from '../../../UI/CTA/CTA';
import { Heading3 } from '../../../UI/typography/Heading3/Heading3';
import { Paragraph } from '../../../UI/typography/Paragraph/Paragraph';
import { EventNameEnum, recordEvent } from '../../../utils/recordEvent';
import { Answers } from '../Calculation';
import classes from './CalculationQuestionare.module.scss';

interface CalculationQuestionareProps {
  onDone: (answers: Answers) => void;
}

export const CalculationQuestionare: FC<CalculationQuestionareProps> = ({ onDone }) => {
  const [answers, setAnswers] = useState<Answers>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [chosenAnswer, setChosenAnswer] = useState<CalculationAnswer | null>(null);
  const [done, setDone] = useState(false);

  const chooseAnswerFactory = (answer: CalculationAnswer) => {
    return () => setChosenAnswer(answer);
  };

  const confirmAnswer = () => {
    if (chosenAnswer === null) return;

    recordEvent(EventNameEnum.questionare_answered_one);

    setAnswers((prevState) => ({
      ...prevState,
      [calculationQuestionOrder[currentQuestionIndex]]: chosenAnswer,
    }));

    setChosenAnswer(null);

    if (currentQuestionIndex === calculationQuestionOrder.length - 1) {
      setDone(true);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  useEffect(() => {
    if (done) {
      onDone(answers);
    }
  }, [done]);

  const question = (
    <div>
      <Heading3 className={classes.question} color="white">
        {CALCULATION_CONTENT[calculationQuestionOrder[currentQuestionIndex]].question}
      </Heading3>
      <div className={classes.answersWrapper}>
        {CALCULATION_CONTENT[calculationQuestionOrder[currentQuestionIndex]].answers.map(
          (answer, idx) => (
            <div
              key={`${calculationQuestionOrder[currentQuestionIndex]}_${idx}`}
              className={classes.answer}
              onClick={chooseAnswerFactory(answer)}
            >
              <div className={classes.answer__input}>
                <div
                  className={`${classes.answer__input__filler} ${
                    chosenAnswer?.text === answer.text ? classes.answer__input__filler_active : null
                  }`}
                >
                  &nbsp;
                </div>
              </div>
              <Paragraph weight="light">{answer.text}</Paragraph>
            </div>
          ),
        )}
      </div>
    </div>
  );

  const calcProgressFillerWidth = (): string => {
    let width = 100 * (currentQuestionIndex / calculationQuestionOrder.length);
    if (done) width = 100;
    return `${width}%`;
  };

  return (
    <motion.div
      className={classes.calculationQuestionare}
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className={classes.wrapper}>
        {question}
        <CTA className={classes.cta} type="main" onClick={confirmAnswer}>
          Další otázka
        </CTA>
        <div className={classes.progressBar}>
          <div
            className={classes.progressBar__filler}
            style={{
              width: calcProgressFillerWidth(),
            }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    </motion.div>
  );
};
