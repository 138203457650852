import { CalculationQuestionTypeEnum } from '../../../../content/calculation.content';
import { Answers } from '../../Calculation';

export const getCalculation = (answers: Answers): number => {
  const visitorCountAnswer = answers[CalculationQuestionTypeEnum.VISITOR_COUNT];
  const webTypeAnswer = answers[CalculationQuestionTypeEnum.WEB_TYPE];
  const focusAnswer = answers[CalculationQuestionTypeEnum.FOCUS];
  const pageCountAnswer = answers[CalculationQuestionTypeEnum.PAGE_COUNT];
  const thirdPartiesAnswer = answers[CalculationQuestionTypeEnum.THIRD_PARTIES];

  if (
    visitorCountAnswer === undefined ||
    webTypeAnswer === undefined ||
    focusAnswer === undefined ||
    pageCountAnswer === undefined ||
    thirdPartiesAnswer === undefined
  ) {
    console.log(answers);
    throw new Error('Not all answers provided');
  }

  const visitorCount = visitorCountAnswer.value;
  const webType = webTypeAnswer.value;
  const focus = focusAnswer.value;
  const pageCount = pageCountAnswer.value;
  const thirdParties = thirdPartiesAnswer.value;

  const result = webType * pageCount * focus * visitorCount + thirdParties;

  return result;
};
