import './PersonPhoto.scss';

import { ReviewRound } from '../SVG/ReviewRound/ReviewRound';
import { DragEventHandler, FC } from 'react';

interface PersonPhotoProps {
  src: string;
  alt: string;
  className?: string;
}

export const PersonPhoto: FC<PersonPhotoProps> = ({ alt, src, className }) => {
  const dragPreventor: DragEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
  };

  return (
    <div className={`person-photo ${className}`} data-testid="person-photo">
      <ReviewRound className={'person-photo__round'} />
      <img
        draggable={false}
        onDragStart={dragPreventor}
        className="person-photo__face"
        src={src}
        alt={alt}
      />
    </div>
  );
};
