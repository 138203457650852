import classes from './Footer.module.scss';

import AmnioMLogo from '../../assets/img/amn_logo_grey.svg';

export const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.footer__left}>
        <img
          data-testid="footer-amniom-logo"
          alt="AmnioM Web logo"
          src={AmnioMLogo}
          className={classes.footer__left__logo}
        />
        <p>AmnioM Web s.r.o.</p>
      </div>
      <div className={classes.footer__center}>
        <p className={classes.footer__center__email}>team@amniomweb.cz</p>
        <p>735 044 114</p>
      </div>
      <div className={classes.footer__centerMobile}>
        <img
          alt="AmnioM Web logo"
          src={AmnioMLogo}
          className={classes.footer__centerMobile__logo}
        />
        <p>AmnioM Web s.r.o.</p>
      </div>
      <div className={classes.footer__right}>
        <a href="/gdpr.pdf" target="_blank" className={classes.footer__right__text}>
          Ochrana osobních údajů
        </a>
        <p className={classes.footer__right__inp}>IČO: 116 67 052</p>
      </div>
    </div>
  );
};
