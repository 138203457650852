import './NotFound.scss';

import Square from '../../animations/Square/Square';
import { FC } from 'react';
import { HelmetWrapper } from '../common/HelmetWrapper/HelmetWrapper';

export const NotFound: FC = () => (
  <div className="NotFound">
    <HelmetWrapper
      title="Tak tady toho moc není"
      description={`
        Na tomhle odkazu jsme nic nenašli, ale zkuste se proklikat zbytkem webu.
        Třeba najdete, co hledáte! :)
      `}
      robots={false}
      path=""
    />
    <Square className="NotFound__square NotFound__square_1" config={{ coefficient: 0.94 }}></Square>
    <Square className="NotFound__square NotFound__square_2" config={{ coefficient: 0.95 }}></Square>
    <p className="NotFound__404">
      <p className="NotFound__404__1">404</p>
      <p className="NotFound__404__2">404</p>
    </p>
    <p className="NotFound__sorry">
      <p className="NotFound__sorry__1">Tady nic není :{'('}</p>
      <p className="NotFound__sorry__2">Tady nic není :{'('}</p>
    </p>
  </div>
);
