import { CSSProperties, FC } from 'react';
import { Paragraph } from '../../../UI/typography/Paragraph/Paragraph';
import './IconBox.scss';

interface IconBoxProps {
  className?: string;
  style?: CSSProperties;
  id: number;
  description: string;
}

export const IconBox: FC<IconBoxProps> = ({ children, style, className, id, description }) => {
  return (
    <div
      style={style}
      className={'icon-box sites-apps__trigger' + (className ? ' ' + className : '')}
    >
      {children}
      <Paragraph className={'icon-box__text paragraph--' + id}>{description}</Paragraph>
    </div>
  );
};
