import { FC, useEffect, useRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { motion } from 'framer-motion';
import anime from 'animejs';
import { indexBounceAnimation } from '../../animations/animations';
import { Gradients } from '../../animations/gradients';
import { HOME_PATH } from '../../components/Home/Home';
import { SITESAPPS_PATH } from '../../components/SitesApps/SitesApps';
import { PORTFOLIO_PATH } from '../../components/Portfolio/Portfolio';
import { CONTACT_PATH } from '../../components/Contact/Contact';
import { RGB_BLACK, RGB_BLUE_LIGHT } from '../../constants/colors';
import { CALCULATION_PATH } from '../../components/Calculation/Calculation';

interface NavigationRoutesProps {
  className: string;
  closeMenu: () => void;
}

export const NavigationRoutes: FC<NavigationRoutesProps> = ({ className, closeMenu }) => {
  const navigationRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const navigationElement = navigationRef.current;
    if (!navigationElement) return;

    const textElements = navigationElement.querySelectorAll<HTMLAnchorElement>('a.animate');

    if (className !== 'navigation__mobile__routes') {
      indexBounceAnimation({ targets: navigationElement, translateX: ['150%', 0] });
    }

    const elementMouseenterHandler = (element: HTMLAnchorElement) => {
      anime({ targets: element.children, translateY: 10, delay: anime.stagger(25) });
    };
    const elementMouseleaveHandler = (element: HTMLAnchorElement) => {
      anime({ targets: element.children, translateY: 0, delay: anime.stagger(25) });
    };

    const elementMouseenterHandlers: (() => void)[] = [];
    const elementMouseleaveHandlers: (() => void)[] = [];

    for (const element of Array.from(textElements)) {
      element.style.display = 'flex';
      if (element.textContent) {
        element.innerHTML = element.textContent.replace(/\S/g, '<div class="letter">$&</div>');
      }

      const enterFcn = elementMouseenterHandler.bind(this, element);
      const leaveFcn = elementMouseleaveHandler.bind(this, element);
      elementMouseenterHandlers.push(enterFcn);
      elementMouseleaveHandlers.push(leaveFcn);

      element.addEventListener('mouseenter', enterFcn);
      element.addEventListener('mouseleave', leaveFcn);
    }

    return () => {
      textElements.forEach((element, i) => {
        element.removeEventListener('mouseenter', elementMouseenterHandlers[i]);
        element.removeEventListener('mouseleave', elementMouseleaveHandlers[i]);
      });
      anime.remove(navigationElement);
    };
  }, [navigationRef, className]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const gradients = new Gradients(canvasRef.current, {
      colors: [RGB_BLACK, RGB_BLUE_LIGHT],
    });

    return () => {
      gradients.end();
    };
  }, [canvasRef]);

  const canvas = className.includes('mobile') ? (
    <canvas ref={canvasRef} className="navigation__mobile__routes__canvas"></canvas>
  ) : null;

  const navLinkClassNameFactoryCurry: (
    animate?: boolean,
    className?: string,
  ) => NavLinkProps['className'] = (animate = true, className) => {
    return ({ isActive }) => {
      return `navigation__navlink ${
        isActive && `navigation__navlink--active ${className ? `${className}--active` : null}`
      } ${className} ${animate ? 'animate' : null}`;
    };
  };

  const content = (
    <motion.div
      key="1"
      ref={navigationRef}
      initial={{ translateX: '100%' }}
      animate={{ translateX: '0%' }}
      transition={{ duration: 0.1 }}
      exit={{ translateX: '100%' }}
      className={className}
      onClick={closeMenu}
    >
      {canvas}
      <NavLink className={navLinkClassNameFactoryCurry()} to={`/${HOME_PATH}`}>
        Domů
      </NavLink>
      <NavLink className={navLinkClassNameFactoryCurry()} to={`/${SITESAPPS_PATH}`}>
        Váš&nbsp;projekt
      </NavLink>
      <NavLink className={navLinkClassNameFactoryCurry()} to={`/${PORTFOLIO_PATH}`}>
        Portfolio
      </NavLink>
      <NavLink
        className={navLinkClassNameFactoryCurry(false, 'navigation__navlink--highlight')}
        to={`/${CALCULATION_PATH}`}
      >
        Kalkulace
      </NavLink>
      <NavLink className={navLinkClassNameFactoryCurry()} to={`/${CONTACT_PATH}`}>
        Kontakt
      </NavLink>
    </motion.div>
  );

  return content;
};
