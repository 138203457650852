import { MainTextMouseData } from './MainText';

interface ParticleConstructorArgs {
  x: number;
  y: number;
  canvasElement: HTMLCanvasElement;
  mouse: MainTextMouseData;
}

export class Particle {
  public x: number;
  public y: number;

  private mouse: MainTextMouseData;

  private size = 1;

  private baseX: number;
  private baseY: number;

  private density: number;

  constructor({ x, y, canvasElement, mouse }: ParticleConstructorArgs) {
    this.x = Math.random() * canvasElement.width;
    this.y = Math.random() * canvasElement.height;
    this.mouse = mouse;
    this.baseX = x;
    this.baseY = y;
    this.density = Math.random() * 30 + 1;
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = 'white';
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }

  update() {
    if (!this.mouse.x || !this.mouse.y) return;

    const dx = this.mouse.x - this.x;
    const dy = this.mouse.y - this.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const forceDirectionX = dx / distance;
    const forceDirectionY = dy / distance;
    const maxDistance = this.mouse.radius;
    const force = (maxDistance - distance) / maxDistance;
    const directionX = forceDirectionX * force * this.density;
    const directionY = forceDirectionY * force * this.density;

    if (distance < this.mouse.radius) {
      this.x -= directionX;
      this.y -= directionY;
    } else {
      if (this.x !== this.baseX) {
        const dx = this.x - this.baseX;
        this.x -= dx / 10;
      }
      if (this.y !== this.baseY) {
        const dy = this.y - this.baseY;
        this.y -= dy / 10;
      }
    }
  }
}
