import { FC } from 'react';
import classes from './Heading3.module.scss';

interface Heading3Props {
  color?: 'default' | 'white';
  className?: string;
}

export const Heading3: FC<Heading3Props> = ({ children, color = 'default', className }) => {
  let classNames = `${classes.heading3} ${className}`;
  if (color === 'white') {
    classNames += ` ${classes.heading3_white}`;
  }

  return <h3 className={classNames}>{children}</h3>;
};
