import { CSSProperties, FC, MouseEventHandler, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './CTA.module.scss';
import { Gradients } from '../../animations/gradients';
import { RGB_ACTION_LIGHT, RGB_ACTION_DARK } from '../../constants/colors';

interface CTABaseProps {
  type: 'main' | 'primary' | 'secondary';
  className?: string;
  style?: CSSProperties;
  'data-testid'?: string;
}

interface CTAPropsWithPath {
  path: string;
}

interface CTAPropsWithClickHanler {
  onClick: MouseEventHandler<HTMLDivElement>;
}

type CTAProps = CTABaseProps & (CTAPropsWithPath | CTAPropsWithClickHanler);

export const CTA: FC<CTAProps> = (props) => {
  const { type, children, className, style, 'data-testid': dataTestId } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    let gradients: Gradients | null = new Gradients(canvasRef.current, {
      colors: [RGB_ACTION_DARK, RGB_ACTION_LIGHT],
      minRadius: 40,
      maxRadius: 90,
    });

    return () => {
      gradients?.end();
      gradients = null;
    };
  }, [canvasRef]);

  let ctaTypeClassName = classes.cta_main;
  if (type === 'primary') {
    ctaTypeClassName = classes.cta_primary;
  } else if (type === 'secondary') {
    ctaTypeClassName = '';
  }

  if ('onClick' in props) {
    const { onClick } = props;

    return (
      <div
        data-testid={dataTestId}
        style={style}
        className={`${classes.cta} ${ctaTypeClassName} ${className}`}
        onClick={onClick}
      >
        <canvas ref={canvasRef} className={classes.cta__canvas}></canvas>
        <p>{children}</p>
      </div>
    );
  } else {
    const { path } = props;

    return (
      <NavLink to={path}>
        <div
          data-testid={dataTestId}
          style={style}
          className={`${classes.cta} ${ctaTypeClassName} ${className}`}
        >
          <canvas ref={canvasRef} className={classes.cta__canvas}></canvas>
          <p>{children}</p>
        </div>
      </NavLink>
    );
  }
};
