import { useRef, useEffect } from 'react';
import anime from 'animejs';
import * as THREE from 'three';
import classes from './Planet.module.scss';

interface Triangle {
  mesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial> | null;
  rotationX: number;
  rotationY: number;
  rotationZ: number;
}

export const Planet = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (!canvasElement) return;

    let scene: THREE.Scene | null = new THREE.Scene();
    const geometry = new THREE.SphereBufferGeometry(1.5, 20, 20);
    const material = new THREE.MeshBasicMaterial({ wireframe: true, color: 0x05f2c7 });
    let planet: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial> | null = new THREE.Mesh(
      geometry,
      material,
    );
    let group: THREE.Group | null = new THREE.Group();

    const mouse: { x?: number; y?: number } = {
      x: undefined,
      y: undefined,
    };

    const documentMousemoveHandler = (event: MouseEvent) => {
      mouse.x = event.x;
      mouse.y = event.y;
    };
    document.addEventListener('mousemove', documentMousemoveHandler);

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    group.add(planet);
    group.position.y = -0.25;
    scene.add(group);

    let camera: THREE.PerspectiveCamera | null = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
    );
    camera.position.z = 3;

    scene.add(camera);

    let renderer: THREE.WebGLRenderer | null = new THREE.WebGLRenderer({ canvas: canvasElement });
    renderer.setSize(sizes.width, sizes.height);

    const clock = new THREE.Clock();

    camera.position.set(0, 2.4, 2.4);

    let randomTriangles: Triangle[] = [];

    const triangleMaterial = new THREE.MeshBasicMaterial({ color: 0x05f2c7, wireframe: true });

    const addRandomTriangle = () => {
      const triangleGeometry = new THREE.BufferGeometry();
      const positionsArray = new Float32Array(9);
      for (let j = 0; j < 9; j++) {
        const value = (Math.random() - 0.5) * 0.5;
        positionsArray[j] = value;
      }
      const positionsAttribute = new THREE.BufferAttribute(positionsArray, 3);
      triangleGeometry.setAttribute('position', positionsAttribute);
      const mesh = new THREE.Mesh(triangleGeometry, triangleMaterial);
      mesh.position.z = -100;
      mesh.position.y = (Math.random() - 0.5) * 60;
      mesh.position.x = (Math.random() - 0.5) * 100;
      randomTriangles.push({
        mesh: mesh,
        rotationX: Math.random() / 4,
        rotationY: Math.random() / 4,
        rotationZ: Math.random() / 4,
      });
      scene && scene.add(mesh);
    };

    for (let i = 0; i < 30; i++) {
      addRandomTriangle();
    }

    let animationFrameId: number;
    const tick = () => {
      if (!camera || !scene || !renderer) return;

      const elapsedTime = clock.getElapsedTime();

      if (planet) {
        planet.rotation.x = elapsedTime / 4;
        planet.rotation.y = elapsedTime / 4;
      }

      for (const triangle of randomTriangles) {
        if (!triangle.mesh) continue;

        triangle.mesh.position.z += 1;
        triangle.mesh.rotation.y = elapsedTime * triangle.rotationY;
        triangle.mesh.rotation.x = elapsedTime * triangle.rotationX;
        triangle.mesh.rotation.z = elapsedTime * triangle.rotationZ;
      }

      randomTriangles = randomTriangles.filter((triangle) => {
        if (!triangle.mesh) return false;

        const isRemoved = triangle.mesh.position.z > 0;
        if (isRemoved) {
          triangle.mesh.geometry.dispose();
          triangle.mesh.material.dispose();
          scene && scene.remove(triangle.mesh);
        }
        return !isRemoved;
      });

      addRandomTriangle();

      renderer.render(scene, camera);
      animationFrameId = requestAnimationFrame(tick);
    };
    tick();

    const resize = () => {
      if (!camera || !planet || !renderer) return;

      const windowHeight = window.document.documentElement.clientHeight;
      sizes.width = window.innerWidth;
      sizes.height = windowHeight + 60;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      if (window.innerWidth < 600) {
        planet.position.y = -1.2;
      } else {
        planet.position.y = -0.5;
      }
    };

    const windowResizeHandler = () => {
      resize();
    };
    window.addEventListener('resize', windowResizeHandler);

    resize();

    const tl = anime.timeline({});
    tl.add({ targets: planet.scale, x: 2.2, y: 2.2, z: 2.2, duration: 10000 });

    return () => {
      for (const triangle of randomTriangles) {
        if (!triangle.mesh) continue;

        triangle.mesh.geometry.dispose();
        triangle.mesh.material.dispose();
        scene && scene.remove(triangle.mesh);
        triangle.mesh = null;
      }

      if (group) {
        planet && group.remove(planet);
        scene && scene.remove(group);
      }

      if (planet) {
        planet.geometry.dispose();
        planet.material.dispose();
      }

      if (camera) {
        scene && scene.remove(camera);
      }

      if (renderer) {
        renderer.renderLists.dispose();
        renderer.dispose();
      }

      triangleMaterial.dispose();
      geometry.dispose();
      material.dispose();
      randomTriangles = [];

      document.removeEventListener('mousemove', documentMousemoveHandler);
      window.removeEventListener('resize', windowResizeHandler);

      cancelAnimationFrame(animationFrameId);

      scene = null;
      camera = null;
      renderer = null;
      planet = null;
      group = null;
    };
  }, []);

  return <canvas ref={canvasRef} className={classes.Planet} id="planet"></canvas>;
};
