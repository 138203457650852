/* eslint-disable max-len */
import { FC } from 'react';
import './IconPWA.scss';

interface IconPWAProps {
  className?: string;
}

export const IconPWA: FC<IconPWAProps> = ({ className }) => {
  return (
    <svg
      className={'icon__pwa ' + className}
      width="290"
      height="209"
      viewBox="0 0 290 209"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="icon__pwa__stand">
        <path
          d="M165.335 203.748H126.476C118.682 203.748 135.199 196.248 135.199 188.058L112.361 187.216C112.368 183.287 113.858 179.52 116.504 176.744C119.15 173.968 122.737 172.409 126.476 172.409H165.335C169.078 172.409 172.668 173.972 175.315 176.753C177.962 179.535 179.449 183.307 179.449 187.241L155.209 188.083C155.209 196.248 173.129 203.748 165.335 203.748Z"
          stroke="#05F2C7"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M164.517 205.238H125.658C117.864 205.238 134.382 197.738 134.382 189.548L111.544 188.706C111.544 184.772 113.031 181 115.678 178.218C118.325 175.437 121.915 173.874 125.658 173.874H164.517C168.261 173.874 171.851 175.437 174.498 178.218C177.145 181 178.632 184.772 178.632 188.706L154.392 189.548C154.392 197.73 172.311 205.238 164.517 205.238Z"
          stroke="#05F2C7"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M166.544 203.874H131.002C123.873 203.874 139.012 197.014 139.012 189.564L118.121 188.773C118.123 185.175 119.485 181.726 121.906 179.183C124.327 176.64 127.611 175.212 131.034 175.212H166.576C169.998 175.214 173.28 176.644 175.699 179.186C178.119 181.729 179.479 185.177 179.481 188.773L157.316 189.564C157.284 197.014 173.666 203.874 166.544 203.874Z"
          stroke="#05F2C7"
          strokeWidth="0.9"
          strokeMiterlimit="10"
        />
        <path
          d="M165.792 205.239H130.25C123.12 205.239 138.26 198.378 138.26 190.887L117.345 190.087C117.345 186.49 118.704 183.039 121.124 180.495C123.544 177.95 126.826 176.52 130.25 176.518H165.792C169.215 176.52 172.498 177.95 174.918 180.495C177.338 183.039 178.697 186.49 178.697 190.087L156.532 190.87C156.532 198.37 172.921 205.239 165.792 205.239Z"
          stroke="#05F2C7"
          strokeWidth="0.9"
          strokeMiterlimit="10"
        />
        <path
          d="M160.954 206.231H122.095C114.301 206.231 130.818 198.731 130.818 190.541L107.98 189.699C107.98 185.765 109.468 181.993 112.114 179.211C114.761 176.43 118.352 174.867 122.095 174.867H160.954C164.697 174.867 168.287 176.43 170.934 179.211C173.581 181.993 175.068 185.765 175.068 189.699L150.828 190.541C150.796 198.731 168.748 206.231 160.954 206.231Z"
          stroke="#1DA88F"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M160.135 207.712H121.277C113.482 207.712 130 200.212 130 192.022L107.162 191.18C107.162 189.233 107.527 187.304 108.237 185.505C108.946 183.706 109.986 182.072 111.297 180.695C112.607 179.319 114.163 178.227 115.876 177.482C117.588 176.738 119.423 176.355 121.277 176.356H160.135C161.989 176.355 163.824 176.738 165.536 177.482C167.249 178.227 168.805 179.319 170.115 180.695C171.426 182.072 172.466 183.706 173.175 185.505C173.885 187.304 174.25 189.233 174.25 191.18L150.01 192.022C149.994 200.187 167.93 207.712 160.135 207.712Z"
          stroke="#1DA88F"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M162.154 206.357H126.611C119.49 206.357 134.622 199.497 134.622 192.005L113.73 191.222C113.73 187.625 115.09 184.176 117.51 181.633C119.93 179.09 123.213 177.661 126.635 177.661H162.178C165.601 177.661 168.884 179.09 171.306 181.633C173.727 184.175 175.089 187.625 175.091 191.222L152.918 192.005C152.894 199.497 169.283 206.357 162.154 206.357Z"
          stroke="#1DA88F"
          strokeWidth="0.9"
          strokeMiterlimit="10"
        />
        <path
          d="M161.409 207.712H125.867C118.737 207.712 133.877 200.852 133.877 193.402L112.962 192.611C112.962 189.014 114.322 185.565 116.742 183.022C119.162 180.479 122.444 179.05 125.867 179.05H161.409C163.104 179.05 164.782 179.401 166.348 180.082C167.913 180.764 169.336 181.762 170.534 183.022C171.733 184.281 172.683 185.776 173.332 187.421C173.98 189.066 174.314 190.83 174.314 192.611L152.149 193.402C152.149 200.852 168.539 207.712 161.409 207.712Z"
          stroke="#1DA88F"
          strokeWidth="0.9"
          strokeMiterlimit="10"
        />
        <path
          d="M179.449 187.2L155.209 188.042C155.209 196.232 173.129 203.733 165.335 203.733H126.476C118.682 203.733 135.199 196.232 135.199 188.042L112.361 187.2"
          stroke="#05F2C7"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M179.449 188.731L157.284 189.523C157.284 197.014 173.666 203.833 166.544 203.833H131.002C123.873 203.833 139.012 196.972 139.012 189.523L118.121 188.731"
          stroke="#05F2C7"
          strokeWidth="0.9"
          strokeMiterlimit="10"
        />
        <path
          d="M175.068 189.683L150.828 190.524C150.828 198.715 168.748 206.215 160.954 206.215H122.095C114.301 206.215 130.818 198.715 130.818 190.524L107.98 189.683"
          stroke="#1DA88F"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M175.067 191.222L152.894 192.005C152.894 199.496 169.283 206.357 162.154 206.357H126.611C119.49 206.357 134.622 199.496 134.622 192.005L113.73 191.222"
          stroke="#1DA88F"
          strokeWidth="0.9"
          strokeMiterlimit="10"
        />
      </g>
      <g className="icon__pwa__screen">
        <path
          d="M267.869 3.96289H23.1334C15.263 3.96289 8.88281 10.6675 8.88281 18.938V159.926C8.88281 168.196 15.263 174.901 23.1334 174.901H267.869C275.74 174.901 282.12 168.196 282.12 159.926V18.938C282.12 10.6675 275.74 3.96289 267.869 3.96289Z"
          stroke="#05F2C7"
          strokeMiterlimit="10"
        />
        <path
          d="M270.816 6.54785H26.0797C18.2093 6.54785 11.8291 13.2524 11.8291 21.5229V162.511C11.8291 170.781 18.2093 177.486 26.0797 177.486H270.816C278.686 177.486 285.066 170.781 285.066 162.511V21.5229C285.066 13.2524 278.686 6.54785 270.816 6.54785Z"
          stroke="#05F2C7"
          strokeMiterlimit="10"
        />
        <path
          d="M261.228 1H19.3922C11.6545 1 5.38184 7.5915 5.38184 15.7225V153.461C5.38184 161.592 11.6545 168.184 19.3922 168.184H261.228C268.966 168.184 275.239 161.592 275.239 153.461V15.7225C275.239 7.5915 268.966 1 261.228 1Z"
          stroke="#05F2C7"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M274.989 8.42383H33.1529C25.4152 8.42383 19.1426 15.0153 19.1426 23.1464V160.885C19.1426 169.016 25.4152 175.608 33.1529 175.608H274.989C282.727 175.608 288.999 169.016 288.999 160.885V23.1464C288.999 15.0153 282.727 8.42383 274.989 8.42383Z"
          stroke="#05F2C7"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M263.479 6.4541H18.7428C10.8724 6.4541 4.49219 13.1587 4.49219 21.4292V162.417C4.49219 170.688 10.8724 177.392 18.7428 177.392H263.479C271.349 177.392 277.729 170.688 277.729 162.417V21.4292C277.729 13.1587 271.349 6.4541 263.479 6.4541Z"
          stroke="#1DA88F"
          strokeMiterlimit="10"
        />
        <path
          d="M266.435 9.03906H21.6989C13.8285 9.03906 7.44824 15.7436 7.44824 24.0141V165.002C7.44824 173.272 13.8285 179.977 21.6989 179.977H266.435C274.305 179.977 280.685 173.272 280.685 165.002V24.0141C280.685 15.7436 274.305 9.03906 266.435 9.03906Z"
          stroke="#1DA88F"
          strokeMiterlimit="10"
        />
        <path
          d="M256.846 3.49121H15.0103C7.27263 3.49121 1 10.0827 1 18.2137V155.952C1 164.083 7.27263 170.675 15.0103 170.675H256.846C264.584 170.675 270.857 164.083 270.857 155.952V18.2137C270.857 10.0827 264.584 3.49121 256.846 3.49121Z"
          stroke="#1DA88F"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
        <path
          d="M270.608 10.9072H28.772C21.0343 10.9072 14.7617 17.4987 14.7617 25.6298V163.368C14.7617 171.499 21.0343 178.091 28.772 178.091H270.608C278.346 178.091 284.618 171.499 284.618 163.368V25.6298C284.618 17.4987 278.346 10.9072 270.608 10.9072Z"
          stroke="#1DA88F"
          strokeWidth="0.98"
          strokeMiterlimit="10"
        />
      </g>
      <g className="icon__pwa__box">
        <path
          d="M143.547 39.0487V138.453H146.519V39.0487H143.547Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <path
          d="M142.408 38.5086V137.913H145.38V38.5086H142.408Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <path
          d="M139.156 41.5389V140.943H142.128V41.5389H139.156Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M138.034 40.992V140.396H141.006V40.992H138.034Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M194.798 86.2207H95.2598V89.3437H194.798V86.2207Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <path
          d="M193.668 85.6748H94.1299V88.7978H193.668V85.6748Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <path
          d="M190.415 88.7031H90.877V91.8261H190.415V88.7031Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M189.286 88.1572H89.748V91.2802H189.286V88.1572Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M175.204 44.9238H106.218C100.568 44.9238 95.9883 49.7365 95.9883 55.6732V128.166C95.9883 134.103 100.568 138.916 106.218 138.916H175.204C180.853 138.916 185.433 134.103 185.433 128.166V55.6732C185.433 49.7365 180.853 44.9238 175.204 44.9238Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M172.856 45.2764H106.177C100.718 45.2764 96.292 49.927 96.292 55.6638V125.733C96.292 131.469 100.718 136.12 106.177 136.12H172.856C178.315 136.12 182.741 131.469 182.741 125.733V55.6638C182.741 49.927 178.315 45.2764 172.856 45.2764Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M176.18 42.7344H109.501C104.042 42.7344 99.6162 47.385 99.6162 53.1218V123.191C99.6162 128.927 104.042 133.578 109.501 133.578H176.18C181.64 133.578 186.065 128.927 186.065 123.191V53.1218C186.065 47.385 181.64 42.7344 176.18 42.7344Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M177.463 44.1826H108.116C102.44 44.1826 97.8389 49.0179 97.8389 54.9825V127.854C97.8389 133.819 102.44 138.654 108.116 138.654H177.463C183.139 138.654 187.74 133.819 187.74 127.854V54.9825C187.74 49.0179 183.139 44.1826 177.463 44.1826Z"
          stroke="#1DA88F"
          strokeWidth="0.4"
        />
        <path
          d="M179.586 42.4395H110.599C104.95 42.4395 100.37 47.2521 100.37 53.1888V125.682C100.37 131.619 104.95 136.431 110.599 136.431H179.586C185.235 136.431 189.815 131.619 189.815 125.682V53.1888C189.815 47.2521 185.235 42.4395 179.586 42.4395Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <path
          d="M177.238 42.7852H110.559C105.099 42.7852 100.674 47.4358 100.674 53.1726V123.241C100.674 128.978 105.099 133.629 110.559 133.629H177.238C182.697 133.629 187.123 128.978 187.123 123.241V53.1726C187.123 47.4358 182.697 42.7852 177.238 42.7852Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <path
          d="M180.562 40.251H113.883C108.424 40.251 103.998 44.9016 103.998 50.6384V120.707C103.998 126.444 108.424 131.095 113.883 131.095H180.562C186.021 131.095 190.447 126.444 190.447 120.707V50.6384C190.447 44.9016 186.021 40.251 180.562 40.251Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <path
          d="M181.844 41.6904H112.497C106.821 41.6904 102.22 46.5257 102.22 52.4903V125.362C102.22 131.327 106.821 136.162 112.497 136.162H181.844C187.52 136.162 192.121 131.327 192.121 125.362V52.4903C192.121 46.5257 187.52 41.6904 181.844 41.6904Z"
          stroke="#05F2C7"
          strokeWidth="0.4"
        />
        <g className="icon__pwa__left-node">
          <path
            d="M146.848 42.9127C147.956 40.8956 142.603 35.4672 134.89 30.788C127.178 26.1088 120.027 23.9508 118.919 25.9679C117.811 27.9849 123.164 33.4133 130.877 38.0925C138.589 42.7717 145.74 44.9297 146.848 42.9127Z"
            stroke="#05F2C7"
            strokeWidth="0.4"
          />
          <path
            d="M146.843 42.0709C147.952 40.0538 142.598 34.6254 134.885 29.9462C127.173 25.267 120.022 23.109 118.914 25.1261C117.806 27.1431 123.159 32.5715 130.872 37.2507C138.584 41.9299 145.735 44.088 146.843 42.0709Z"
            stroke="#05F2C7"
            strokeWidth="0.4"
          />
          <path
            d="M143.432 42.2316C144.414 40.444 139.669 35.6332 132.834 31.4863C125.999 27.3394 119.662 25.4268 118.68 27.2144C117.698 29.002 122.442 33.8129 129.277 37.9597C136.113 42.1066 142.45 44.0192 143.432 42.2316Z"
            stroke="#05F2C7"
            strokeWidth="0.4"
          />
          <path
            d="M142.458 45.398C143.566 43.3809 138.212 37.9525 130.499 33.2734C122.787 28.5942 115.636 26.4361 114.528 28.4532C113.42 30.4703 118.774 35.8987 126.486 40.5779C134.199 45.2571 141.349 47.4151 142.458 45.398Z"
            stroke="#1DA88F"
            strokeWidth="0.4"
          />
          <path
            d="M142.46 44.5552C143.569 42.5382 138.215 37.1098 130.502 32.4306C122.79 27.7514 115.639 25.5933 114.531 27.6104C113.423 29.6275 118.777 35.0559 126.489 39.7351C134.202 44.4143 141.352 46.5723 142.46 44.5552Z"
            stroke="#1DA88F"
            strokeWidth="0.4"
          />
          <path
            d="M139.045 44.7287C140.027 42.9411 135.283 38.1302 128.447 33.9834C121.612 29.8365 115.275 27.9239 114.293 29.7115C113.311 31.4991 118.056 36.3099 124.891 40.4568C131.726 44.6037 138.063 46.5163 139.045 44.7287Z"
            stroke="#1DA88F"
            strokeWidth="0.4"
          />
        </g>
        <g className="icon__pwa__right-node">
          <path
            d="M157.611 38.3663C165.323 33.6871 170.677 28.2587 169.569 26.2416C168.461 24.2245 161.31 26.3826 153.598 31.0618C145.885 35.741 140.531 41.1693 141.639 43.1864C142.748 45.2035 149.898 43.0455 157.611 38.3663Z"
            stroke="#05F2C7"
            strokeWidth="0.4"
          />
          <path
            d="M157.611 37.5264C165.323 32.8473 170.677 27.4189 169.569 25.4018C168.461 23.3847 161.31 25.5428 153.598 30.2219C145.885 34.9011 140.531 40.3295 141.639 42.3466C142.748 44.3637 149.898 42.2056 157.611 37.5264Z"
            stroke="#05F2C7"
            strokeWidth="0.4"
          />
          <path
            d="M159.202 38.2432C166.037 34.0963 170.782 29.2855 169.8 27.4979C168.818 25.7103 162.481 27.6229 155.646 31.7697C148.811 35.9166 144.066 40.7275 145.048 42.515C146.03 44.3026 152.367 42.3901 159.202 38.2432Z"
            stroke="#05F2C7"
            strokeWidth="0.4"
          />
          <path
            d="M153.227 40.8487C160.94 36.1695 166.293 30.7411 165.185 28.724C164.077 26.707 156.926 28.865 149.214 33.5442C141.501 38.2234 136.147 43.6518 137.256 45.6689C138.364 47.6859 145.514 45.5279 153.227 40.8487Z"
            stroke="#1DA88F"
            strokeWidth="0.4"
          />
          <path
            d="M153.227 40.0098C160.94 35.3307 166.293 29.9023 165.185 27.8852C164.077 25.8681 156.926 28.0261 149.214 32.7053C141.501 37.3845 136.147 42.8129 137.256 44.83C138.364 46.8471 145.514 44.689 153.227 40.0098Z"
            stroke="#1DA88F"
            strokeWidth="0.4"
          />
          <path
            d="M154.818 40.7246C161.654 36.5778 166.398 31.7669 165.416 29.9793C164.434 28.1917 158.097 30.1043 151.262 34.2512C144.427 38.3981 139.682 43.2089 140.664 44.9965C141.646 46.7841 147.983 44.8715 154.818 40.7246Z"
            stroke="#1DA88F"
            strokeWidth="0.4"
          />
        </g>
      </g>
    </svg>
  );
};
