import img_horka from '../assets/img/review-horka.png';
import img_gregus from '../assets/img/review-gregus.png';
import img_nepevna from '../assets/img/review-nepevna.png';
import img_kristofovi from '../assets/img/review-kristofovi.png';
import img_kadlecova from '../assets/img/review-kadlecova.jpg';
import img_vitula from '../assets/img/review-vitula.jpg';
import img_web_keramika from '../assets/img/project-keramika-rosice-mobile.jpg';
import img_web_horka from '../assets/img/project-horka-mobile.jpg';
import img_web_adlux from '../assets/img/project-adlux-mobile.jpg';
import img_web_bydevil from '../assets/img/project-bydevil-mobile.jpg';
import img_web_kristofovi from '../assets/img/project-kristofovi-mobile.jpg';
import img_web_gttournament from '../assets/img/project-gttournament-mobile.jpg';

export enum ReviewEnum {
  'tatjanahorka.cz' = 'tatjanahorka.cz',
  'fantasymuzeum.cz' = 'fantasymuzeum.cz',
  'adluxholo.cz' = 'adluxholo.cz',
  'bydevil.cz' = 'bydevil.cz',
  'keramika-rosice.cz' = 'keramika-rosice.cz',
  'gttournament.cz' = 'gttournament.cz',
  // TODO FIX WEB AND READD
  // 'grandeglamping.cz' = 'grandeglamping.cz',
}

export const REVIEW_CONTENT: {
  reviews: {
    [key in ReviewEnum]: {
      url: string;
      customer: string;
      customerPhoto: string;
      heading: string;
      websiteImage: string;
      feedback: {
        long: string;
        short: string;
      };
    };
  };
} = {
  reviews: {
    [ReviewEnum['tatjanahorka.cz']]: {
      url: 'https://www.tatjanahorka.cz',
      customer: 'MUDr. Taťjána Horká',
      customerPhoto: img_horka,
      websiteImage: img_web_horka,
      heading: 'Taťjána Horká',
      feedback: {
        long: `
          "Mladí muži z AmnioM týmu jsou profesionálové každým coulem.
          Flexibilita, operativnost, úžasná kreativita je jejich vizitkou.
          Tito muži své práci rozumí, vypadá to, že s počítačem v ruce se už narodili.
          Přeji jim mnoho spokojených klientů."
        `,
        short: `
          "Mladí muži z AmnioM týmu jsou profesionálové každým coulem.
          Flexibilita, operativnost, úžasná kreativita je jejich vizitkou."
        `,
      },
    },
    [ReviewEnum['fantasymuzeum.cz']]: {
      url: 'https://www.fantasymuzeum.cz',
      customer: 'Manželé Krištofovi',
      customerPhoto: img_kristofovi,
      websiteImage: img_web_kristofovi,
      heading: 'Fantasy Muzeum',
      feedback: {
        long: `
          "Spolupráce s AmnioM týmem nás mile překvapila.
          Pánové jsou mladí, plní moderních nápadů, které dodají šmrnc každému webu.
          Komunikují rychle a vyřeší za klienta vše potřebné, tým budeme určitě doporučovat dál,
          a i my, v případě potřeby, se na AmnioM tým opět rádi obrátíme."
        `,
        short: `
          "Spolupráce s AmnioM týmem nás mile překvapila.
          Pánové jsou mladí, plní moderních nápadů, které dodají šmrnc každému webu.
          Komunikují rychle a vyřeší za klienta vše potřebné."
        `,
      },
    },
    [ReviewEnum['adluxholo.cz']]: {
      url: 'https://www.adluxholo.cz',
      customer: 'Benedikt Greguš',
      customerPhoto: img_gregus,
      websiteImage: img_web_adlux,
      heading: 'AdLux',
      feedback: {
        long: `
          "Team AmnioM je kolektiv mladých ambiciózních lidí,
          zakládají si na tom, aby pečlivě poznali svého zákazníka, analyzovali jeho potřeby,
          požadavky a sestavili mu web přímo na míru tak, aby co nejlépe působil na cílovou skupinu.
          Poměr ceny, kterou platíte za dodanou kvalitu, je velmi dobrý."
        `,
        short: `
          "Team AmnioM je kolektiv mladých ambiciózních lidí.
          Zakládají si na tom, aby pečlivě poznali svého zákazníka,
          a poměr ceny, kterou platíte za dodanou kvalitu, je velmi dobrý."
        `,
      },
    },
    [ReviewEnum['bydevil.cz']]: {
      url: 'https://www.bydevil.cz',
      customer: 'Sára Kadlecová',
      customerPhoto: img_kadlecova,
      websiteImage: img_web_bydevil,
      heading: 'ByDevil',
      feedback: {
        long: `
          "AmnioM Team mi pomohl rozjet prodej triček.
          Domluvili jsme se, že za mě obstarají všechno kolem,
          a já tak budu moct dělat jen to, co mě baví, což je kreslení."
        `,
        short: `
          "AmnioM Team mi pomohl rozjet prodej triček.
          Domluvili jsme se, že za mě obstarají všechno kolem,
          a já tak budu moct dělat jen to, co mě baví, což je kreslení."
        `,
      },
    },
    [ReviewEnum['keramika-rosice.cz']]: {
      url: 'https://www.keramika-rosice.cz',
      customer: 'Ing. Vlastimila Nepevná',
      customerPhoto: img_nepevna,
      heading: 'Keramika z Rosic',
      websiteImage: img_web_keramika,
      feedback: {
        long: `
          "Děkuji AmnioM Teamu za profesionální přístup
          při přípravě a zpracování mých webových stránek.
          Veškeré moje požadavky a náměty byly rychle zpracovány k mé naprosté spokojenosti.
          Spolupráce s vaší firmou byla vstřícná a operativní."
        `,
        short: `
          "Děkuji AmnioM Teamu za profesionální přístup
          při přípravě a zpracování mých webových stránek.
          Spolupráce s vaší firmou byla vstřícná a operativní."
        `,
      },
    },
    [ReviewEnum['gttournament.cz']]: {
      url: 'https://www.gttournament.cz',
      customer: 'Ondřej Vitula',
      customerPhoto: img_vitula,
      websiteImage: img_web_gttournament,
      heading: 'GT Tournament',
      feedback: {
        long: `
          To se tak jednou pořádal turnaj v počítačových hrách na gymnáziu v Tišnově
          a jelikož organizátorem byl jeden z našich nadaných programátorů,
          udělal meziškolnímu turnaji vlastní webové stránky.
        `,
        short: `
          To se tak jednou pořádal turnaj v počítačových hrách
          a jelikož organizátorem byl jeden z našich programátorů,
          udělal meziškolnímu turnaji vlastní webové stránky.
        `,
      },
    },
    // TODO Fix web
    // [ReviewEnum['grandeglamping.cz']]: {
    //   url: 'https://www.grandeglamping.cz',
    //   customer: 'Květoš Tesař',
    //   customerPhoto: img_tesar,
    //   heading: 'Grande Glamping',
    //   feedback: {
    //     long: `
    //       Potřeboval jsem webové stránky s rezervačním systémem. Systém funguje,
    //       když je něco potřeba tak není problém to pořešit. Cením originální
    //       a příjemný design webu.
    //     `,
    //     short: `
    //       Potřeboval jsem webové stránky s rezervačním systémem. Systém funguje,
    //       když je něco potřeba tak není problém to pořešit. Cením originální
    //       a příjemný design webu.
    //     `,
    //   },
    // },
  },
};
