/* eslint-disable max-len */
import { FC } from 'react';
import './IconBranding.scss';

interface IconBrandingProps {
  className?: string;
}

export const IconBranding: FC<IconBrandingProps> = ({ className }) => {
  return (
    <svg
      className={'icon__branding ' + className}
      width="298"
      height="247"
      viewBox="0 0 298 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="icon__branding__eye">
        <g className="icon__branding__eye__pupil">
          <path
            d="M150.675 105.543C158.108 105.543 164.134 99.5175 164.134 92.0845C164.134 84.6516 158.108 78.626 150.675 78.626C143.242 78.626 137.217 84.6516 137.217 92.0845C137.217 99.5175 143.242 105.543 150.675 105.543Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M150.047 104.659C157.48 104.659 163.506 98.6337 163.506 91.2008C163.506 83.7678 157.48 77.7422 150.047 77.7422C142.614 77.7422 136.589 83.7678 136.589 91.2008C136.589 98.6337 142.614 104.659 150.047 104.659Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M152.183 103.649C159.058 103.649 164.631 98.0756 164.631 91.2008C164.631 84.326 159.058 78.7529 152.183 78.7529C145.308 78.7529 139.735 84.326 139.735 91.2008C139.735 98.0756 145.308 103.649 152.183 103.649Z"
            stroke="#1DA88F"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M154.675 103.543C162.108 103.543 168.134 97.5175 168.134 90.0845C168.134 82.6516 162.108 76.626 154.675 76.626C147.242 76.626 141.217 82.6516 141.217 90.0845C141.217 97.5175 147.242 103.543 154.675 103.543Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M154.047 102.659C161.48 102.659 167.506 96.6337 167.506 89.2008C167.506 81.7678 161.48 75.7422 154.047 75.7422C146.614 75.7422 140.589 81.7678 140.589 89.2008C140.589 96.6337 146.614 102.659 154.047 102.659Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M156.183 101.649C163.058 101.649 168.631 96.0756 168.631 89.2008C168.631 82.326 163.058 76.7529 156.183 76.7529C149.308 76.7529 143.735 82.326 143.735 89.2008C143.735 96.0756 149.308 101.649 156.183 101.649Z"
            stroke="#05F2C7"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M172.948 92.0963C172.951 95.6691 172.094 99.1901 170.448 102.361C168.803 105.532 166.417 108.26 163.493 110.314C155.097 112.439 146.303 112.439 137.907 110.314C134.982 108.26 132.595 105.533 130.947 102.362C129.299 99.191 128.438 95.6699 128.438 92.0963C128.438 88.5227 129.299 85.0016 130.947 81.8307C132.595 78.6598 134.982 75.9322 137.907 73.8786C146.301 71.7358 155.099 71.7358 163.493 73.8786C166.417 75.9322 168.803 78.6601 170.448 81.8313C172.094 85.0025 172.951 88.5235 172.948 92.0963V92.0963Z"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M172.321 91.2522C172.322 94.8259 171.462 98.3473 169.815 101.518C168.167 104.689 165.779 107.417 162.854 109.47C154.46 111.613 145.662 111.613 137.267 109.47C134.345 107.414 131.96 104.686 130.314 101.516C128.668 98.345 127.809 94.8248 127.809 91.2522C127.809 87.6796 128.668 84.1595 130.314 80.9888C131.96 77.8181 134.345 75.09 137.267 73.0345C145.664 70.9091 154.457 70.9091 162.854 73.0345C165.78 75.0867 168.168 77.814 169.816 80.9853C171.464 84.1565 172.323 87.6783 172.321 91.2522V91.2522Z"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M172.793 91.2529C172.797 94.5598 172.004 97.819 170.48 100.754C168.957 103.689 166.749 106.214 164.042 108.114C156.26 110.093 148.106 110.093 140.323 108.114C137.62 106.211 135.414 103.686 133.892 100.751C132.369 97.8165 131.574 94.5589 131.574 91.2529C131.574 87.9468 132.369 84.6892 133.892 81.7547C135.414 78.8202 137.62 76.2947 140.323 74.3913C148.106 72.4126 156.26 72.4126 164.042 74.3913C166.747 76.293 168.954 78.8181 170.477 81.753C172 84.6879 172.795 87.9463 172.793 91.2529V91.2529Z"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M187.213 92.0963C183.771 100.578 174.893 107.359 163.494 110.314C155.097 112.439 146.304 112.439 137.907 110.314C126.508 107.359 117.617 100.565 114.188 92.0963C117.63 83.6143 126.508 76.8339 137.907 73.8786C146.302 71.7358 155.1 71.7358 163.494 73.8786C174.893 76.8339 183.771 83.576 187.213 92.0963Z"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M185.997 91.2529C182.811 99.1079 174.585 105.326 164.043 108.114C156.261 110.093 148.107 110.093 140.324 108.114C129.783 105.326 121.557 99.044 118.371 91.2529C121.557 83.4105 129.783 77.1802 140.324 74.3913C148.107 72.4126 156.261 72.4126 164.043 74.3913C174.585 77.0779 182.811 83.3594 185.997 91.2529Z"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M186.571 91.2522C183.143 99.7342 174.251 106.515 162.853 109.47C154.458 111.613 145.66 111.613 137.266 109.47C125.829 106.463 116.95 99.683 113.509 91.2522C116.95 82.783 125.829 75.9898 137.228 73.0345C145.624 70.9091 154.418 70.9091 162.814 73.0345C174.251 75.9002 183.13 82.7319 186.571 91.2522Z"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />

        <path
          d="M191.213 90.0963C187.771 98.5783 178.893 105.359 167.494 108.314C159.097 110.439 150.304 110.439 141.907 108.314C130.508 105.359 121.617 98.5655 118.188 90.0963C121.63 81.6143 130.508 74.8339 141.907 71.8786C150.302 69.7358 159.1 69.7358 167.494 71.8786C178.893 74.8339 187.771 81.576 191.213 90.0963Z"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M176.948 90.0963C176.951 93.6691 176.094 97.1901 174.448 100.361C172.803 103.532 170.417 106.26 167.493 108.314C159.097 110.439 150.303 110.439 141.907 108.314C138.982 106.26 136.595 103.533 134.947 100.362C133.299 97.191 132.438 93.6699 132.438 90.0963C132.438 86.5227 133.299 83.0016 134.947 79.8307C136.595 76.6598 138.982 73.9322 141.907 71.8786C150.301 69.7358 159.099 69.7358 167.493 71.8786C170.417 73.9322 172.803 76.6601 174.448 79.8313C176.094 83.0025 176.951 86.5235 176.948 90.0963V90.0963Z"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />

        <path
          d="M190.571 89.2522C187.143 97.7342 178.251 104.515 166.853 107.47C158.458 109.613 149.66 109.613 141.266 107.47C129.829 104.463 120.95 97.683 117.509 89.2522C120.95 80.783 129.829 73.9898 141.228 71.0345C149.624 68.9091 158.418 68.9091 166.814 71.0345C178.251 73.9002 187.13 80.7319 190.571 89.2522Z"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M176.321 89.2522C176.322 92.8259 175.462 96.3473 173.815 99.5184C172.167 102.689 169.779 105.417 166.854 107.47C158.46 109.613 149.662 109.613 141.267 107.47C138.345 105.414 135.96 102.686 134.314 99.5157C132.668 96.345 131.809 92.8248 131.809 89.2522C131.809 85.6796 132.668 82.1595 134.314 78.9888C135.96 75.8181 138.345 73.09 141.267 71.0345C149.664 68.9091 158.457 68.9091 166.854 71.0345C169.78 73.0867 172.168 75.814 173.816 78.9853C175.464 82.1565 176.323 85.6783 176.321 89.2522V89.2522Z"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M189.997 89.2529C186.811 97.1079 178.585 103.326 168.043 106.114C160.261 108.093 152.107 108.093 144.324 106.114C133.783 103.326 125.557 97.044 122.371 89.2529C125.557 81.4105 133.783 75.1802 144.324 72.3913C152.107 70.4126 160.261 70.4126 168.043 72.3913C178.585 75.0779 186.811 81.3594 189.997 89.2529Z"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M176.793 89.2529C176.797 92.5598 176.004 95.819 174.48 98.7542C172.957 101.689 170.749 104.214 168.042 106.114C160.26 108.093 152.106 108.093 144.323 106.114C141.62 104.211 139.414 101.686 137.892 98.751C136.369 95.8165 135.574 92.5589 135.574 89.2529C135.574 85.9468 136.369 82.6892 137.892 79.7547C139.414 76.8202 141.62 74.2947 144.323 72.3913C152.106 70.4126 160.26 70.4126 168.042 72.3913C170.747 74.293 172.954 76.8181 174.477 79.753C176 82.6879 176.795 85.9463 176.793 89.2529V89.2529Z"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M116.037 79.4775C115.82 80.7043 115.886 81.9644 116.229 83.162C116.63 84.5516 117.399 85.8071 118.455 86.7953"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M118.583 76.0762C118.366 77.2987 118.432 78.5546 118.775 79.7479C119.173 81.1426 119.942 82.403 121.001 83.3939"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M119.851 74.5527C119.634 75.7795 119.699 77.0396 120.043 78.2372C120.444 79.6304 121.213 80.8899 122.269 81.8833"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M121.232 73.0947C121.015 74.3172 121.08 75.5732 121.424 76.7664C121.821 78.1612 122.591 79.4215 123.65 80.4125"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M122.869 71.418C122.651 72.6447 122.717 73.9049 123.06 75.1024C123.462 76.4957 124.231 77.7551 125.286 78.7485"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M125.173 69.8828C124.944 71.1038 125.005 72.3617 125.352 73.5545C125.76 74.9447 126.528 76.2025 127.578 77.2006"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M130.865 67.8486C130.129 68.9739 129.685 70.2652 129.573 71.6056C129.461 72.9459 129.686 74.2929 130.226 75.5246"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M132.644 66.9277C131.952 67.967 131.514 69.1547 131.365 70.3947C131.191 71.8328 131.407 73.2912 131.992 74.6165"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M136.239 65.2266C135.557 66.2665 135.12 67.4475 134.96 68.6808C134.797 70.1228 135.013 71.5822 135.587 72.9153"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M136.239 65.2266C135.557 66.2665 135.12 67.4475 134.96 68.6808C134.797 70.1228 135.013 71.5822 135.587 72.9153"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M138.35 64.3311C137.668 65.3757 137.231 66.5608 137.071 67.798C136.903 69.236 137.119 70.6927 137.698 72.0198"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M141.114 63.8057C140.427 64.8431 139.99 66.0253 139.835 67.2599C139.664 68.7008 139.876 70.1615 140.449 71.4944"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M146.703 63.666C145.666 64.5268 144.861 65.633 144.361 66.8842C143.86 68.1354 143.68 69.4919 143.837 70.8303"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M150.62 62.9238C149.657 63.716 148.894 64.7241 148.394 65.8663C147.805 67.1903 147.584 68.6489 147.754 70.0881"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M152.614 62.6943C151.576 63.5516 150.77 64.6554 150.27 65.9048C149.769 67.1542 149.59 68.5092 149.748 69.8458"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M152.614 62.6943C151.576 63.5516 150.77 64.6554 150.27 65.9048C149.769 67.1542 149.59 68.5092 149.748 69.8458"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M157.694 62.7578C156.738 63.5571 155.977 64.5633 155.468 65.7003C154.877 67.0241 154.656 68.4829 154.828 69.9221"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M160.97 63.666C159.734 64.2004 158.653 65.0371 157.826 66.0991C156.999 67.161 156.452 68.4141 156.236 69.7428"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.221 63.666C161.987 64.2064 160.908 65.0465 160.081 66.11C159.254 67.1735 158.707 68.4266 158.487 69.7556"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.215 63.8711C163.98 64.4105 162.901 65.2504 162.074 66.314C161.247 67.3777 160.7 68.6313 160.481 69.9607"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M167.174 64.0879C165.94 64.6283 164.861 65.4684 164.034 66.5319C163.208 67.5954 162.66 68.8485 162.44 70.1775"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.168 64.4082C167.934 64.9498 166.854 65.79 166.025 66.8531C165.197 67.9162 164.646 69.1687 164.422 70.4978"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.168 64.4082C167.934 64.9498 166.854 65.79 166.025 66.8531C165.197 67.9162 164.646 69.1687 164.422 70.4978"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M171.459 64.8428C170.225 65.3822 169.145 66.222 168.318 67.2857C167.491 68.3493 166.944 69.603 166.726 70.9324"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.221 63.666C161.987 64.2064 160.908 65.0465 160.081 66.11C159.254 67.1735 158.707 68.4266 158.487 69.7556"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M167.174 64.0879C165.94 64.6283 164.861 65.4684 164.034 66.5319C163.208 67.5954 162.66 68.8485 162.44 70.1775"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.168 64.4082C167.934 64.9498 166.854 65.79 166.025 66.8531C165.197 67.9162 164.646 69.1687 164.422 70.4978"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.168 64.4082C167.934 64.9498 166.854 65.79 166.025 66.8531C165.197 67.9162 164.646 69.1687 164.422 70.4978"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M174.965 65.6104C173.821 66.107 172.808 66.8651 172.01 67.8236C171.083 68.9217 170.465 70.2462 170.219 71.6616"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M179.046 69.0137C177.702 69.1231 176.407 69.5666 175.279 70.304C174.151 71.0413 173.225 72.049 172.585 73.2355"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M180.863 69.8457C179.531 69.9654 178.249 70.4137 177.133 71.1505C176.017 71.8873 175.101 72.8896 174.467 74.0675"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M182.654 70.6895C181.414 70.7855 180.213 71.1711 179.149 71.8153C177.907 72.5675 176.887 73.6358 176.193 74.9112"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M184.406 71.6357C183.073 71.7509 181.789 72.1974 180.672 72.9347C179.555 73.672 178.64 74.6767 178.01 75.8575"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M184.406 71.6357C183.073 71.7509 181.789 72.1974 180.672 72.9347C179.555 73.672 178.64 74.6767 178.01 75.8575"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M188.538 74.6045C187.298 74.7149 186.1 75.1041 185.032 75.7431C183.795 76.4906 182.779 77.555 182.09 78.8263"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M192.71 78.9153C191.383 78.7047 190.025 78.8284 188.759 79.2752C187.492 79.7219 186.357 80.4777 185.456 81.474"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M192.71 78.9153C191.383 78.7047 190.025 78.8284 188.759 79.2752C187.492 79.7219 186.357 80.4777 185.456 81.474"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M194.424 80.5016C193.097 80.2967 191.741 80.4232 190.475 80.8696C189.21 81.316 188.074 82.0686 187.17 83.0602"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M196.036 82.7659C194.803 82.5698 193.541 82.6662 192.352 83.0473C190.972 83.4841 189.734 84.2839 188.77 85.3629"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M116.754 79.248C116.53 80.4744 116.596 81.736 116.946 82.9325C117.347 84.3228 118.111 85.5813 119.159 86.5786"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M119.301 75.8447C119.072 77.0657 119.133 78.3236 119.48 79.5164C119.892 80.908 120.664 82.1659 121.719 83.1625"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M120.568 74.3359C120.351 75.5584 120.416 76.8144 120.76 78.0076C121.161 79.4038 121.935 80.6643 122.998 81.6537"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M121.95 72.8633C121.721 74.0885 121.782 75.3505 122.129 76.5478C122.545 77.9348 123.311 79.1908 124.356 80.1939"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M123.588 71.2012C123.365 72.4226 123.426 73.6789 123.767 74.8729C124.175 76.263 124.943 77.5208 125.993 78.519"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M125.877 69.6533C125.66 70.8758 125.726 72.1318 126.069 73.325C126.467 74.7198 127.236 75.9801 128.295 76.9711"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M131.583 67.6318C130.889 68.666 130.451 69.8497 130.303 71.086C130.128 72.5282 130.344 73.9911 130.93 75.3206"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M133.347 66.7109C132.607 67.8349 132.161 69.1264 132.049 70.4674C131.938 71.8084 132.164 73.156 132.708 74.3869"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M136.955 64.9453C136.262 65.9846 135.824 67.1723 135.676 68.4123C135.501 69.8503 135.718 71.3087 136.303 72.6341"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M136.955 64.9453C136.262 65.9846 135.824 67.1723 135.676 68.4123C135.501 69.8503 135.718 71.3087 136.303 72.6341"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M139.118 64.1006C138.426 65.1398 137.987 66.3275 137.839 67.5676C137.665 69.0056 137.881 70.464 138.466 71.7894"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M141.83 63.5762C141.148 64.6209 140.711 65.806 140.551 67.0432C140.376 68.4812 140.593 69.9396 141.178 71.265"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M147.407 63.3975C146.8375 64.2618 145.574 65.369 145.076 66.6195C144.578 67.87 144.399 69.2247 144.554 70.5617"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M151.321 62.6943C150.284 63.5551 149.479 64.6614 148.979 65.9126C148.478 67.1638 148.298 68.5202 148.456 69.8586"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M153.317 62.4629C152.362 63.2622 151.6 64.2684 151.091 65.4054C150.513 66.7326 150.292 68.1882 150.452 69.6271"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M153.317 62.4629C152.362 63.2622 151.6 64.2684 151.091 65.4054C150.513 66.7326 150.292 68.1882 150.452 69.6271"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M158.397 62.5264C157.441 63.3257 156.68 64.3319 156.171 65.4688C155.594 66.7964 155.373 68.2517 155.531 69.6906"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M161.685 63.4346C160.543 63.93 159.532 64.6832 158.73 65.635C157.806 66.7351 157.189 68.0587 156.938 69.473"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.936 63.4482C162.701 63.9877 161.621 64.8275 160.795 65.8912C159.968 66.9548 159.42 68.2085 159.202 69.5379"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.919 63.666C164.689 64.2112 163.612 65.0527 162.786 66.1154C161.96 67.178 161.41 68.4286 161.186 69.7556"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M167.889 63.8574C166.654 64.3969 165.574 65.2367 164.748 66.3003C163.921 67.364 163.373 68.6176 163.155 69.947"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.872 64.1904C168.637 64.7248 167.555 65.5615 166.728 66.6235C165.901 67.6854 165.354 68.9385 165.139 70.2673"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.872 64.1904C168.637 64.7248 167.555 65.5615 166.728 66.6235C165.901 67.6854 165.354 68.9385 165.139 70.2673"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M172.177 64.6133C170.943 65.1549 169.863 65.9951 169.034 67.0582C168.206 68.1213 167.655 69.3738 167.431 70.7029"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.936 63.4482C162.701 63.9877 161.621 64.8275 160.795 65.8912C159.968 66.9548 159.42 68.2085 159.202 69.5379"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M167.889 63.8574C166.654 64.3969 165.574 65.2367 164.748 66.3003C163.921 67.364 163.373 68.6176 163.155 69.947"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.872 64.1904C168.637 64.7248 167.555 65.5615 166.728 66.6235C165.901 67.6854 165.354 68.9385 165.139 70.2673"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M169.872 64.1904C168.637 64.7248 167.555 65.5615 166.728 66.6235C165.901 67.6854 165.354 68.9385 165.139 70.2673"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M175.667 65.3799C174.433 65.9193 173.353 66.7591 172.526 67.8228C171.699 68.8864 171.152 70.1401 170.934 71.4695"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M179.762 68.7832C178.519 68.8913 177.316 69.2806 176.244 69.9218C175.006 70.6694 173.991 71.7337 173.302 73.005"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M181.58 69.6143C180.336 69.7223 179.133 70.1117 178.062 70.7529C176.824 71.5004 175.808 72.5648 175.119 73.8361"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M183.357 70.459C182.023 70.5742 180.74 71.0207 179.622 71.758C178.505 72.4952 177.59 73.4999 176.96 74.6808"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M185.136 71.4053C183.806 71.5316 182.527 71.9826 181.411 72.7187C180.296 73.4548 179.378 74.4535 178.739 75.6271"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M185.136 71.4053C183.806 71.5316 182.527 71.9826 181.411 72.7187C180.296 73.4548 179.378 74.4535 178.739 75.6271"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M189.256 74.3867C188.015 74.4828 186.815 74.8684 185.75 75.5125C184.508 76.2647 183.489 77.3331 182.795 78.6085"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M193.425 78.6853C192.196 78.4959 190.939 78.5922 189.753 78.9667C188.374 79.4125 187.135 80.2107 186.158 81.2823"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M193.425 78.6853C192.196 78.4959 190.939 78.5922 189.753 78.9667C188.374 79.4125 187.135 80.2107 186.158 81.2823"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M195.139 80.2971C193.905 80.101 192.643 80.1974 191.454 80.5786C190.074 81.0153 188.837 81.8151 187.872 82.8942"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M196.737 82.5364C195.411 82.3258 194.053 82.4495 192.786 82.8962C191.519 83.343 190.384 84.0988 189.483 85.0951"
          stroke="#05F2C7"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />

        <path
          d="M112.037 81.4775C111.82 82.7043 111.886 83.9644 112.229 85.162C112.63 86.5516 113.399 87.8071 114.455 88.7953"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M114.583 78.0762C114.366 79.2987 114.432 80.5546 114.775 81.7479C115.173 83.1426 115.942 84.403 117.001 85.3939"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M115.851 76.5527C115.634 77.7795 115.699 79.0396 116.043 80.2372C116.444 81.6304 117.213 82.8899 118.269 83.8833"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M117.232 75.0947C117.015 76.3172 117.08 77.5732 117.424 78.7664C117.821 80.1612 118.591 81.4215 119.65 82.4125"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M118.869 73.418C118.651 74.6447 118.717 75.9049 119.06 77.1024C119.462 78.4957 120.231 79.7551 121.286 80.7485"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M121.173 71.8828C120.944 73.1038 121.005 74.3617 121.352 75.5545C121.76 76.9447 122.528 78.2025 123.578 79.2006"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M126.865 69.8486C126.129 70.9739 125.685 72.2652 125.573 73.6056C125.461 74.9459 125.686 76.2929 126.226 77.5246"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M128.644 68.9277C127.952 69.967 127.514 71.1547 127.365 72.3947C127.191 73.8328 127.407 75.2912 127.992 76.6165"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M132.239 67.2266C131.557 68.2665 131.12 69.4475 130.96 70.6808C130.797 72.1228 131.013 73.5822 131.587 74.9153"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M132.239 67.2266C131.557 68.2665 131.12 69.4475 130.96 70.6808C130.797 72.1228 131.013 73.5822 131.587 74.9153"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M134.35 66.3311C133.668 67.3757 133.231 68.5608 133.071 69.798C132.903 71.236 133.119 72.6927 133.698 74.0198"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M137.114 65.8057C136.427 66.8431 135.99 68.0253 135.835 69.2599C135.664 70.7008 135.876 72.1615 136.449 73.4944"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M142.703 65.666C141.666 66.5268 140.861 67.633 140.361 68.8842C139.86 70.1354 139.68 71.4919 139.837 72.8303"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M146.62 64.9238C145.657 65.716 144.894 66.7241 144.394 67.8663C143.805 69.1903 143.584 70.6489 143.754 72.0881"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M148.614 64.6943C147.576 65.5516 146.77 66.6554 146.27 67.9048C145.769 69.1542 145.59 70.5092 145.748 71.8458"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M148.614 64.6943C147.576 65.5516 146.77 66.6554 146.27 67.9048C145.769 69.1542 145.59 70.5092 145.748 71.8458"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M153.694 64.7578C152.738 65.5571 151.977 66.5633 151.468 67.7003C150.877 69.0241 150.656 70.4829 150.828 71.9221"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M156.97 65.666C155.734 66.2004 154.653 67.0371 153.826 68.0991C152.999 69.161 152.452 70.4141 152.236 71.7428"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M159.221 65.666C157.987 66.2064 156.908 67.0465 156.081 68.11C155.254 69.1735 154.707 70.4266 154.487 71.7556"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M161.215 65.8711C159.98 66.4105 158.901 67.2504 158.074 68.314C157.247 69.3777 156.7 70.6313 156.481 71.9607"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.174 66.0879C161.94 66.6283 160.861 67.4684 160.034 68.5319C159.208 69.5954 158.66 70.8485 158.44 72.1775"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.168 66.4082C163.934 66.9498 162.854 67.79 162.025 68.8531C161.197 69.9162 160.646 71.1687 160.422 72.4978"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.168 66.4082C163.934 66.9498 162.854 67.79 162.025 68.8531C161.197 69.9162 160.646 71.1687 160.422 72.4978"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M167.459 66.8428C166.225 67.3822 165.145 68.222 164.318 69.2857C163.491 70.3493 162.944 71.603 162.726 72.9324"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M159.221 65.666C157.987 66.2064 156.908 67.0465 156.081 68.11C155.254 69.1735 154.707 70.4266 154.487 71.7556"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.174 66.0879C161.94 66.6283 160.861 67.4684 160.034 68.5319C159.208 69.5954 158.66 70.8485 158.44 72.1775"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.168 66.4082C163.934 66.9498 162.854 67.79 162.025 68.8531C161.197 69.9162 160.646 71.1687 160.422 72.4978"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.168 66.4082C163.934 66.9498 162.854 67.79 162.025 68.8531C161.197 69.9162 160.646 71.1687 160.422 72.4978"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M170.965 67.6104C169.821 68.107 168.808 68.8651 168.01 69.8236C167.083 70.9217 166.465 72.2462 166.219 73.6616"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M175.046 71.0137C173.702 71.1231 172.407 71.5666 171.279 72.304C170.151 73.0413 169.225 74.049 168.585 75.2355"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M176.863 71.8457C175.531 71.9654 174.249 72.4137 173.133 73.1505C172.017 73.8873 171.101 74.8896 170.467 76.0675"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M178.654 72.6895C177.414 72.7855 176.213 73.1711 175.149 73.8153C173.907 74.5675 172.887 75.6358 172.193 76.9112"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M180.406 73.6357C179.073 73.7509 177.789 74.1974 176.672 74.9347C175.555 75.672 174.64 76.6767 174.01 77.8575"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M180.406 73.6357C179.073 73.7509 177.789 74.1974 176.672 74.9347C175.555 75.672 174.64 76.6767 174.01 77.8575"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M184.538 76.6045C183.298 76.7149 182.1 77.1041 181.032 77.7431C179.795 78.4906 178.779 79.555 178.09 80.8263"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M188.71 80.9153C187.383 80.7047 186.025 80.8284 184.759 81.2752C183.492 81.7219 182.357 82.4777 181.456 83.474"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M188.71 80.9153C187.383 80.7047 186.025 80.8284 184.759 81.2752C183.492 81.7219 182.357 82.4777 181.456 83.474"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M190.424 82.5016C189.097 82.2967 187.741 82.4232 186.475 82.8696C185.21 83.316 184.074 84.0686 183.17 85.0602"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M192.036 84.7659C190.803 84.5698 189.541 84.6662 188.352 85.0473C186.972 85.4841 185.734 86.2839 184.77 87.3629"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M112.754 81.248C112.53 82.4744 112.596 83.736 112.946 84.9325C113.347 86.3228 114.111 87.5813 115.159 88.5786"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M115.301 77.8447C115.072 79.0657 115.133 80.3236 115.48 81.5164C115.892 82.908 116.664 84.1659 117.719 85.1625"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M116.568 76.3359C116.351 77.5584 116.416 78.8144 116.76 80.0076C117.161 81.4038 117.935 82.6643 118.998 83.6537"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M117.95 74.8633C117.721 76.0885 117.782 77.3505 118.129 78.5478C118.545 79.9348 119.311 81.1908 120.356 82.1939"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M119.588 73.2012C119.365 74.4226 119.426 75.6789 119.767 76.8729C120.175 78.263 120.943 79.5208 121.993 80.519"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M121.877 71.6533C121.66 72.8758 121.726 74.1318 122.069 75.325C122.467 76.7198 123.236 77.9801 124.295 78.9711"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M127.583 69.6318C126.889 70.666 126.451 71.8497 126.303 73.086C126.128 74.5282 126.344 75.9911 126.93 77.3206"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M129.347 68.7109C128.607 69.8349 128.161 71.1264 128.049 72.4674C127.938 73.8084 128.164 75.156 128.708 76.3869"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M132.955 66.9453C132.262 67.9846 131.824 69.1723 131.676 70.4123C131.501 71.8503 131.718 73.3087 132.303 74.6341"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M132.955 66.9453C132.262 67.9846 131.824 69.1723 131.676 70.4123C131.501 71.8503 131.718 73.3087 132.303 74.6341"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M135.118 66.1006C134.426 67.1398 133.987 68.3275 133.839 69.5676C133.665 71.0056 133.881 72.464 134.466 73.7894"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M137.83 65.5762C137.148 66.6209 136.711 67.806 136.551 69.0432C136.376 70.4812 136.593 71.9396 137.178 73.265"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M143.407 65.3975C142.375 66.2618 141.574 67.369 141.076 68.6195C140.578 69.87 140.399 71.2247 140.554 72.5617"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M147.321 64.6943C146.284 65.5551 145.479 66.6614 144.979 67.9126C144.478 69.1638 144.298 70.5202 144.456 71.8586"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M149.317 64.4629C148.362 65.2622 147.6 66.2684 147.091 67.4054C146.513 68.7326 146.292 70.1882 146.452 71.6271"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M149.317 64.4629C148.362 65.2622 147.6 66.2684 147.091 67.4054C146.513 68.7326 146.292 70.1882 146.452 71.6271"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M154.397 64.5264C153.441 65.3257 152.68 66.3319 152.171 67.4688C151.594 68.7964 151.373 70.2517 151.531 71.6906"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M157.685 65.4346C156.543 65.93 155.532 66.6832 154.73 67.635C153.806 68.7351 153.189 70.0587 152.938 71.473"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M159.936 65.4482C158.701 65.9877 157.621 66.8275 156.795 67.8912C155.968 68.9548 155.42 70.2085 155.202 71.5379"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M161.919 65.666C160.689 66.2112 159.612 67.0527 158.786 68.1154C157.96 69.178 157.41 70.4286 157.186 71.7556"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.889 65.8574C162.654 66.3969 161.574 67.2367 160.748 68.3003C159.921 69.364 159.373 70.6176 159.155 71.947"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.872 66.1904C164.637 66.7248 163.555 67.5615 162.728 68.6235C161.901 69.6854 161.354 70.9385 161.139 72.2673"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.872 66.1904C164.637 66.7248 163.555 67.5615 162.728 68.6235C161.901 69.6854 161.354 70.9385 161.139 72.2673"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M168.177 66.6133C166.943 67.1549 165.863 67.9951 165.034 69.0582C164.206 70.1213 163.655 71.3738 163.431 72.7029"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M159.936 65.4482C158.701 65.9877 157.621 66.8275 156.795 67.8912C155.968 68.9548 155.42 70.2085 155.202 71.5379"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M163.889 65.8574C162.654 66.3969 161.574 67.2367 160.748 68.3003C159.921 69.364 159.373 70.6176 159.155 71.947"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.872 66.1904C164.637 66.7248 163.555 67.5615 162.728 68.6235C161.901 69.6854 161.354 70.9385 161.139 72.2673"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M165.872 66.1904C164.637 66.7248 163.555 67.5615 162.728 68.6235C161.901 69.6854 161.354 70.9385 161.139 72.2673"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M171.667 67.3799C170.433 67.9193 169.353 68.7591 168.526 69.8228C167.699 70.8864 167.152 72.1401 166.934 73.4695"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M175.762 70.7832C174.519 70.8913 173.316 71.2806 172.244 71.9218C171.006 72.6694 169.991 73.7337 169.302 75.005"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M177.58 71.6143C176.336 71.7223 175.133 72.1117 174.062 72.7529C172.824 73.5004 171.808 74.5648 171.119 75.8361"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M179.357 72.459C178.023 72.5742 176.74 73.0207 175.622 73.758C174.505 74.4952 173.59 75.4999 172.96 76.6808"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M181.136 73.4053C179.806 73.5316 178.527 73.9826 177.411 74.7187C176.296 75.4548 175.378 76.4535 174.739 77.6271"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M181.136 73.4053C179.806 73.5316 178.527 73.9826 177.411 74.7187C176.296 75.4548 175.378 76.4535 174.739 77.6271"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M185.256 76.3867C184.015 76.4828 182.815 76.8684 181.75 77.5125C180.508 78.2647 179.489 79.3331 178.795 80.6085"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M189.425 80.6853C188.196 80.4959 186.939 80.5922 185.753 80.9667C184.374 81.4125 183.135 82.2107 182.158 83.2823"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M189.425 80.6853C188.196 80.4959 186.939 80.5922 185.753 80.9667C184.374 81.4125 183.135 82.2107 182.158 83.2823"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M191.139 82.2971C189.905 82.101 188.643 82.1974 187.454 82.5786C186.074 83.0153 184.837 83.8151 183.872 84.8942"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M192.737 84.5364C191.411 84.3258 190.053 84.4495 188.786 84.8962C187.519 85.343 186.384 86.0988 185.483 87.0951"
          stroke="#1DA88F"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M279.939 141.377L261.453 149.181C258.158 150.557 254.453 150.575 251.146 149.231C247.839 147.886 245.197 145.288 243.798 142.004L221.934 90.2163C213.133 136.662 216.267 184.581 231.043 229.484C231.029 233.979 229.238 238.286 226.059 241.465C222.881 244.643 218.574 246.435 214.079 246.448H94.6916C90.1967 246.435 85.8897 244.643 82.7112 241.465C79.5328 238.286 77.7412 233.979 77.7277 229.484C82.9965 195.336 85.1675 160.781 84.2139 126.242C83.7406 109.688 82.5892 94.0415 80.9644 79.3804L42.3798 137.309C40.3888 140.273 37.306 142.329 33.8043 143.029C30.3026 143.729 26.6662 143.016 23.6887 141.044L7.0574 129.914C4.0911 127.928 2.0326 124.847 1.33247 121.346C0.63234 117.846 1.34762 114.21 3.32175 111.236L67.6722 14.6589C69.3105 12.1824 71.728 10.323 74.5422 9.3753V9.3753C76.4834 8.71776 78.5499 8.51538 80.5819 8.7838C82.6138 9.05221 84.5569 9.78423 86.2609 10.9233L102.892 22.0407C119.582 26.9946 136.912 29.4604 154.321 29.3585C169.349 29.4182 184.326 27.6046 198.906 23.9597C202.232 18.8424 202.117 19.5332 205.75 17.4351L224.237 9.63117C227.384 8.28634 230.926 8.19481 234.139 9.3753H234.228C235.942 10.0195 237.507 11.0063 238.827 12.2755C240.147 13.5447 241.194 15.0696 241.904 16.7571L287.052 123.671C288.448 126.963 288.484 130.674 287.151 133.992C285.818 137.311 283.225 139.966 279.939 141.377V141.377Z"
        stroke="#1DA88F"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M284.531 141.377L266.044 149.181C262.75 150.558 259.045 150.576 255.738 149.231C252.43 147.887 249.789 145.289 248.39 142.004L226.526 90.2169C217.724 136.662 220.859 184.582 235.635 229.485C235.621 233.98 233.83 238.287 230.651 241.465C227.473 244.644 223.166 246.435 218.671 246.449H99.2962C94.8013 246.435 90.4943 244.644 87.3158 241.465C84.1374 238.287 82.3458 233.98 82.3323 229.485C87.6011 195.337 89.7721 160.782 88.8185 126.243C88.294 109.688 87.1938 94.0421 85.569 79.3809L46.9716 137.309C44.9806 140.274 41.8978 142.33 38.3961 143.03C34.8944 143.73 31.258 143.016 28.2805 141.045L11.6492 129.915C8.6829 127.928 6.62439 124.847 5.92426 121.347C5.22414 117.846 5.93942 114.211 7.91355 111.236L72.264 14.6595C73.9023 12.183 76.3198 10.3236 79.134 9.37588V9.37588C81.0752 8.71834 83.1417 8.51596 85.1737 8.78437C87.2057 9.05279 89.1487 9.78481 90.8526 10.9239L107.484 22.0413C124.174 26.9952 141.504 29.461 158.913 29.359C173.941 29.4188 188.918 27.6052 203.498 23.9603C206.811 18.8429 206.709 19.5338 210.342 17.4357L228.829 9.63175C231.98 8.28353 235.527 8.19199 238.743 9.37588H238.833C240.547 10.0201 242.112 11.0069 243.431 12.2761C244.751 13.5452 245.798 15.0701 246.509 16.7576L291.708 123.723C293.084 127.017 293.102 130.722 291.758 134.029C290.413 137.337 287.815 139.978 284.531 141.377V141.377Z"
        stroke="#1DA88F"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M265.814 129.069L248.211 136.477C246.662 137.13 245.001 137.471 243.321 137.482C241.641 137.492 239.975 137.172 238.419 136.539C236.862 135.905 235.446 134.972 234.251 133.791C233.055 132.611 232.104 131.206 231.451 129.658L210.739 80.4932C202.402 124.589 205.377 170.078 219.387 212.712C219.377 216.979 217.679 221.068 214.663 224.086C211.647 227.104 207.56 228.805 203.293 228.819H89.9572C85.6895 228.805 81.6006 227.104 78.5829 224.087C75.5652 221.069 73.8639 216.98 73.8504 212.712C78.8567 180.295 80.9206 147.492 80.0168 114.703C79.569 98.9796 78.4688 84.1265 76.9336 70.2074L40.2807 125.219C38.3959 128.04 35.4675 129.997 32.1398 130.659C28.8121 131.321 25.3577 130.634 22.5364 128.75L6.69824 118.182C3.87645 116.302 1.91678 113.378 1.24988 110.053C0.58299 106.728 1.26344 103.274 3.1417 100.451L64.2426 8.77387C65.8087 6.41078 68.1252 4.64413 70.8184 3.75889V3.75889C72.6624 3.13985 74.6233 2.94945 76.552 3.20217C78.4806 3.45489 80.3263 4.14408 81.9486 5.21732L97.8123 15.7718C113.655 20.4782 130.105 22.819 146.632 22.7186C160.896 22.7765 175.112 21.0575 188.952 17.6013C192.099 12.7782 191.997 13.4051 195.451 11.4093L213.003 4.00196C215.976 2.7507 219.312 2.66845 222.342 3.77168H222.432C224.067 4.37877 225.561 5.31387 226.822 6.5195C228.082 7.72513 229.083 9.17582 229.763 10.7824L272.62 112.285C273.277 113.833 273.623 115.497 273.636 117.179C273.649 118.861 273.331 120.53 272.699 122.089C272.067 123.648 271.133 125.067 269.952 126.265C268.77 127.463 267.364 128.416 265.814 129.069Z"
        stroke="#1DA88F"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M277.634 146.699L260.082 154.107C258.534 154.759 256.872 155.101 255.192 155.111C253.512 155.122 251.846 154.802 250.29 154.168C248.733 153.535 247.317 152.602 246.122 151.421C244.926 150.241 243.975 148.836 243.322 147.288L222.623 98.1231C214.285 142.219 217.261 187.708 231.271 230.342C231.261 234.609 229.563 238.698 226.547 241.716C223.531 244.734 219.444 246.435 215.177 246.449H101.815C97.5513 246.432 93.4669 244.729 90.454 241.711C87.4411 238.693 85.7444 234.606 85.7343 230.342C90.7358 197.924 92.7954 165.121 91.8879 132.332C91.4401 116.609 90.3399 101.756 88.8047 87.8373L52.1646 142.849C51.2323 144.247 50.0338 145.447 48.6374 146.382C47.2411 147.317 45.6743 147.967 44.0265 148.296C42.3788 148.625 40.6823 148.626 39.034 148.3C37.3858 147.973 35.818 147.325 34.4203 146.392L18.5694 135.825C15.7527 133.947 13.7954 131.028 13.1264 127.71C12.4573 124.391 13.1311 120.942 15 118.119L76.1009 26.4038C77.667 24.0407 79.9835 22.274 82.6767 21.3888V21.3888C84.5207 20.7697 86.4816 20.5793 88.4103 20.8321C90.339 21.0848 92.1846 21.774 93.8069 22.8472L109.645 33.4017C125.487 38.1081 141.938 40.4489 158.464 40.3485C172.729 40.4064 186.945 38.6874 200.785 35.2311C203.945 30.4081 203.829 31.0349 207.284 29.0392L224.836 21.6318C227.809 20.3806 231.144 20.2983 234.175 21.4016H234.265V21.4655C235.9 22.0726 237.394 23.0077 238.655 24.2133C239.915 25.419 240.916 26.8697 241.595 28.4763L284.453 129.978C285.759 133.101 285.775 136.613 284.497 139.747C283.219 142.881 280.751 145.381 277.634 146.699V146.699Z"
        stroke="#1DA88F"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M283.939 139.377L265.453 147.181C262.158 148.557 258.453 148.575 255.146 147.231C251.839 145.886 249.197 143.288 247.798 140.004L225.934 88.2163C217.133 134.662 220.267 182.581 235.043 227.484C235.029 231.979 233.238 236.286 230.059 239.465C226.881 242.643 222.574 244.435 218.079 244.448H98.6916C94.1967 244.435 89.8897 242.643 86.7112 239.465C83.5328 236.286 81.7412 231.979 81.7277 227.484C86.9965 193.336 89.1675 158.781 88.2139 124.242C87.7406 107.688 86.5892 92.0415 84.9644 77.3804L46.3798 135.309C44.3888 138.273 41.306 140.329 37.8043 141.029C34.3026 141.729 30.6662 141.016 27.6887 139.044L11.0574 127.914C8.0911 125.928 6.0326 122.847 5.33247 119.346C4.63234 115.846 5.34762 112.21 7.32175 109.236L71.6722 12.6589C73.3105 10.1824 75.728 8.32304 78.5422 7.3753V7.3753C80.4834 6.71776 82.5499 6.51538 84.5819 6.7838C86.6138 7.05221 88.5569 7.78423 90.2609 8.9233L106.892 20.0407C123.582 24.9946 140.912 27.4604 158.321 27.3585C173.349 27.4182 188.326 25.6046 202.906 21.9597C206.232 16.8424 206.117 17.5332 209.75 15.4351L228.237 7.63117C231.384 6.28634 234.926 6.19481 238.139 7.3753H238.228C239.942 8.01948 241.507 9.00629 242.827 10.2755C244.147 11.5447 245.194 13.0696 245.904 14.7571L291.052 121.671C292.448 124.963 292.484 128.674 291.151 131.992C289.818 135.311 287.225 137.966 283.939 139.377V139.377Z"
        stroke="#05F2C7"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M288.531 139.377L270.044 147.181C266.75 148.558 263.045 148.576 259.738 147.231C256.43 145.887 253.789 143.289 252.39 140.004L230.526 88.2169C221.724 134.662 224.859 182.582 239.635 227.485C239.621 231.98 237.83 236.287 234.651 239.465C231.473 242.644 227.166 244.435 222.671 244.449H103.296C98.8013 244.435 94.4943 242.644 91.3158 239.465C88.1374 236.287 86.3458 231.98 86.3323 227.485C91.6011 193.337 93.7721 158.782 92.8185 124.243C92.294 107.688 91.1938 92.0421 89.569 77.3809L50.9716 135.309C48.9806 138.274 45.8978 140.33 42.3961 141.03C38.8944 141.73 35.258 141.016 32.2805 139.045L15.6492 127.915C12.6829 125.928 10.6244 122.847 9.92426 119.347C9.22414 115.846 9.93942 112.211 11.9135 109.236L76.264 12.6595C77.9023 10.183 80.3198 8.32361 83.134 7.37588C85.0752 6.71834 87.1417 6.51596 89.1737 6.78437C91.2056 7.05279 93.1487 7.78481 94.8526 8.92387L111.484 20.0413C128.174 24.9952 145.504 27.461 162.913 27.359C177.941 27.4188 192.918 25.6052 207.498 21.9603C210.811 16.8429 210.709 17.5338 214.342 15.4357L232.829 7.63175C235.98 6.28353 239.527 6.19199 242.743 7.37588H242.833C244.547 8.02005 246.112 9.00687 247.431 10.2761C248.751 11.5452 249.798 13.0701 250.509 14.7576L295.708 121.723C297.084 125.017 297.102 128.722 295.758 132.029C294.413 135.337 291.815 137.978 288.531 139.377Z"
        stroke="#05F2C7"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M269.814 127.069L252.211 134.477C250.662 135.13 249.001 135.471 247.321 135.482C245.641 135.492 243.975 135.172 242.419 134.539C240.862 133.905 239.446 132.972 238.251 131.791C237.055 130.611 236.104 129.206 235.451 127.658L214.739 78.4932C206.402 122.589 209.377 168.078 223.387 210.712C223.377 214.979 221.679 219.068 218.663 222.086C215.647 225.104 211.56 226.805 207.293 226.819H93.9572C89.6895 226.805 85.6006 225.104 82.5829 222.087C79.5652 219.069 77.8639 214.98 77.8504 210.712C82.8567 178.295 84.9206 145.492 84.0168 112.703C83.569 96.9796 82.4688 82.1265 80.9336 68.2074L44.2807 123.219C42.3959 126.04 39.4675 127.997 36.1398 128.659C32.8121 129.321 29.3577 128.634 26.5364 126.75L10.6982 116.182C7.87645 114.302 5.91678 111.378 5.24988 108.053C4.58299 104.728 5.26344 101.274 7.1417 98.4508L68.2426 6.77387C69.8087 4.41078 72.1252 2.64413 74.8184 1.75889V1.75889C76.6624 1.13985 78.6233 0.949447 80.552 1.20217C82.4806 1.45489 84.3263 2.14408 85.9486 3.21732L101.812 13.7718C117.655 18.4782 134.105 20.819 150.632 20.7186C164.896 20.7765 179.112 19.0575 192.952 15.6013C196.099 10.7782 195.997 11.4051 199.451 9.40929L217.003 2.00196C219.976 0.750702 223.312 0.668454 226.342 1.77168H226.432C228.067 2.37877 229.561 3.31387 230.822 4.5195C232.082 5.72513 233.083 7.17582 233.763 8.78242L276.62 110.285C277.277 111.833 277.623 113.497 277.636 115.179C277.649 116.861 277.331 118.53 276.699 120.089C276.067 121.648 275.133 123.067 273.952 124.265C272.77 125.463 271.364 126.416 269.814 127.069Z"
        stroke="#05F2C7"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M281.634 144.699L264.082 152.107C262.534 152.759 260.872 153.101 259.192 153.111C257.512 153.122 255.846 152.802 254.29 152.168C252.733 151.535 251.317 150.602 250.122 149.421C248.926 148.241 247.975 146.836 247.322 145.288L226.623 96.1231C218.285 140.219 221.261 185.708 235.271 228.342C235.261 232.609 233.563 236.698 230.547 239.716C227.531 242.734 223.444 244.435 219.177 244.449H105.815C101.551 244.432 97.4669 242.729 94.454 239.711C91.4411 236.693 89.7444 232.606 89.7343 228.342C94.7358 195.924 96.7954 163.121 95.8879 130.332C95.4401 114.609 94.3399 99.7564 92.8047 85.8373L56.1646 140.849C55.2323 142.247 54.0338 143.447 52.6374 144.382C51.2411 145.317 49.6743 145.967 48.0265 146.296C46.3788 146.625 44.6823 146.626 43.034 146.3C41.3858 145.973 39.818 145.325 38.4203 144.392L22.5694 133.825C19.7527 131.947 17.7954 129.028 17.1264 125.71C16.4573 122.391 17.1311 118.942 19 116.119L80.1009 24.4038C81.667 22.0407 83.9835 20.274 86.6767 19.3888V19.3888C88.5207 18.7697 90.4816 18.5793 92.4103 18.8321C94.339 19.0848 96.1846 19.774 97.8069 20.8472L113.645 31.4017C129.487 36.1081 145.938 38.4489 162.464 38.3485C176.729 38.4064 190.945 36.6874 204.785 33.2311C207.945 28.4081 207.829 29.0349 211.284 27.0392L228.836 19.6318C231.809 18.3806 235.144 18.2983 238.175 19.4016H238.265V19.4655C239.9 20.0726 241.394 21.0077 242.655 22.2133C243.915 23.419 244.916 24.8697 245.595 26.4763L288.453 127.978C289.759 131.101 289.775 134.613 288.497 137.747C287.219 140.881 284.751 143.381 281.634 144.699V144.699Z"
        stroke="#05F2C7"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
