import { useEffect, useState, useRef, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import anime from 'animejs';

import { NavigationRoutes } from './NavigationRoutes';
import { indexBounceAnimation } from '../../animations/animations';
import amnLogo from '../../assets/img/amn_logo_grey.svg';

import './Navigation.scss';
import { HOME_PATH } from '../../components/Home/Home';

export const Navigation = () => {
  const [opened, setOpened] = useState(false);

  const AmnioMref = useRef<HTMLDivElement>(null);
  const hamburgerRef = useRef<HTMLDivElement>(null);

  const toggleMenuHandler = () => {
    setOpened((o) => {
      if (o) {
        document.documentElement.style.overflowY = 'visible';
      } else {
        document.documentElement.style.overflowY = 'hidden';
      }
      return !o;
    });
  };

  const closeMenu = () => {
    setOpened(false);
    document.documentElement.style.overflowY = 'visible';
  };

  useEffect(() => {
    window.addEventListener('resize', closeMenu);
    window.addEventListener('scroll', closeMenu);

    const amniomElement = AmnioMref.current;
    const hamburgerElement = hamburgerRef.current;

    indexBounceAnimation({ targets: amniomElement, translateX: ['-150%', 0] });
    indexBounceAnimation({
      targets: hamburgerElement,
      translateX: ['10rem', 0],
    });

    return () => {
      window.removeEventListener('resize', closeMenu);
      window.removeEventListener('scroll', closeMenu);
      anime.remove(amniomElement);
      anime.remove(hamburgerElement);
    };
  }, [AmnioMref, hamburgerRef]);

  const onClickHandler = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggleMenuHandler();
  };

  return (
    <div className="navigation">
      <div className="navigation__amniom" ref={AmnioMref}>
        <NavLink onClick={closeMenu} to={`/${HOME_PATH}`} className="navigation__amniom__navlink">
          <img
            data-testid="navigation-amniom-logo"
            className="navigation__amniom__img"
            src={amnLogo}
            alt="AmnioM Logo"
          />
          AmnioM Web
        </NavLink>
      </div>
      <div className="navigation__mobile">
        <div
          onClick={onClickHandler}
          className={`navigation__mobile__menu ${opened ? 'navigation__mobile__menu--open' : ''}`}
        >
          <div ref={hamburgerRef} className="navigation__mobile__menu__hamburger"></div>
        </div>
        <AnimatePresence>
          {opened && (
            <NavigationRoutes className="navigation__mobile__routes" closeMenu={closeMenu} />
          )}
        </AnimatePresence>
      </div>
      <NavigationRoutes className="navigation__routes" closeMenu={closeMenu} />
    </div>
  );
};
