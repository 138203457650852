import './PortfolioItem.scss';
import Background from '../../../assets/img/backgroundLines.svg';
import { Heading2 } from '../../../UI/typography/Heading2/Heading2';
import { Paragraph } from '../../../UI/typography/Paragraph/Paragraph';
import { PersonPhoto } from '../../../UI/PersonPhoto/PersonPhoto';
import { FC } from 'react';

interface PortfolioItemProps {
  heading: string;
  url: string;
  customer: string;
  customerPhoto: string;
  websiteImage: string;
}

export const PortfolioItem: FC<PortfolioItemProps> = ({
  children,
  heading,
  url,
  websiteImage,
  customer,
  customerPhoto,
}) => {
  const companyLink = url.replace('https://', '');
  return (
    <div className="PortfolioItem">
      <div className="PortfolioItem__irframeContainer">
        <img className="PortfolioItem__irframeContainer__photo" src={websiteImage} alt={heading} />
        <img
          className="PortfolioItem__irframeContainer__photo--background"
          src={Background}
          alt="Pozadí"
        />
      </div>

      <div className="PortfolioItem__detail">
        <PersonPhoto src={customerPhoto} alt={customer} className="PortfolioItem__detail__review" />
        <h2 className="PortfolioItem__detail__customer">{customer}</h2>
        <div className="PortfolioItem__detail__heading">
          <Heading2 className="Heading2">{heading}</Heading2>
          <a
            rel="noreferrer"
            target="_blank"
            className="PortfolioItem__detail__heading__link"
            href={url}
          >
            {companyLink}
          </a>
        </div>
        <Paragraph className="paragraph PortfolioItem__detail__paragraph">{children}</Paragraph>
      </div>
    </div>
  );
};
