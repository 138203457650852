/* istanbul ignore file */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { LoadingFallback } from './layouts/loading-fallback/LoadingFallback';

import AmnioMLogo from './assets/img/amn_logo_grey.svg';

export const AppWrapper = () => (
  <React.StrictMode>
    <BrowserRouter>
      <link rel="preload" as="image" href={AmnioMLogo}></link>
      <React.Suspense fallback={<LoadingFallback />}>
        <App />
      </React.Suspense>
    </BrowserRouter>
  </React.StrictMode>
);
