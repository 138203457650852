import { ChangeEventHandler, FC, useRef, useState } from 'react';

import './Form.scss';

import formLine from '../../../assets/img/formLine.svg';
import { CTA } from '../../../UI/CTA/CTA';
import { FormModal } from './FormModal/FormModal';
import { AnimatePresence } from 'framer-motion';
import { FormStatus } from '../../../types/enums';
import { isValidEmail } from '../../../utils/isValidEmail';
import { BACKEND_URL } from '../../../constants/environment';

interface FormProps {
  setBlur: (value: boolean) => void;
}

enum InputType {
  EMAIL = 'email',
  NAME = 'name',
  MESSAGE = 'message',
}

export const Form: FC<FormProps> = ({ setBlur }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [msg, setMsg] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isMsgValid, setIsMsgValid] = useState(true);

  const [status, setStatus] = useState<FormStatus>('success');
  const [showModal, setShowModal] = useState(false);

  const [inputRefs] = useState({
    [InputType.EMAIL]: useRef<HTMLInputElement>(null),
    [InputType.NAME]: useRef<HTMLInputElement>(null),
  });

  const validate = (inputType: InputType, value: string) => {
    value = value.trim();

    switch (inputType) {
      case InputType.EMAIL:
        if (!isValidEmail(value)) {
          setIsEmailValid(false);
          return false;
        } else setIsEmailValid(true);
        break;
      case InputType.NAME:
        if (!value || !(value.length > 4)) {
          setIsNameValid(false);
          return false;
        } else setIsNameValid(true);
        break;
      case InputType.MESSAGE:
        if (!value || !(value.length > 19)) {
          setIsMsgValid(false);
          return false;
        } else setIsMsgValid(true);
        break;
    }

    return true;
  };

  const emailInputHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    validate(InputType.EMAIL, value);
    setEmail(value);
  };
  const nameInputHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    validate(InputType.NAME, value);
    setName(value);
  };
  const msgInputHandler: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const { value } = e.target;
    validate(InputType.MESSAGE, value);
    setMsg(value);
  };

  const clearInputs = () => {
    setEmail('');
    setName('');
    setMsg('');
  };

  const sendMessage = () => {
    const mailVal = validate(InputType.EMAIL, email);
    const nameVal = validate(InputType.NAME, name);
    const msgVal = validate(InputType.MESSAGE, msg);

    if (!(mailVal && nameVal && msgVal)) return;

    setStatus('loading');

    fetch(`${BACKEND_URL}/email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.trim(),
        name: name.trim(),
        message: msg.trim(),
      }),
    })
      .then((res) => {
        if (!res.ok) setStatus('error');
        return res.json();
      })
      .then((json) => {
        if (!json.success) {
          setStatus('error');
        } else {
          clearInputs();
          setStatus('success');
        }
      })
      .catch(() => setStatus('error'))
      .finally(() => {
        setShowModal(true);
        setBlur(true);
      });
  };

  const dismissModal = () => {
    setShowModal(false);
    setBlur(false);
  };

  const focusInput = (input: InputType) => {
    if (input === InputType.MESSAGE) return;
    const inputRef = inputRefs[input].current;
    if (inputRef) inputRef.focus();
  };

  return (
    <>
      <AnimatePresence>
        {showModal ? <FormModal status={status} dismissModal={dismissModal} /> : null}
      </AnimatePresence>
      <form
        className="form"
        method="post"
        autoComplete="new-password"
        style={{ filter: showModal ? 'blur(3px)' : 'none' }}
      >
        <div
          data-testid="contact-form-email"
          className={'form__upper' + (isEmailValid ? '' : ' form__upper--error')}
          onClick={focusInput.bind(null, InputType.EMAIL)}
        >
          <label className="form__upper__head" htmlFor="email">
            EMAIL
          </label>
          <img alt="" src={formLine} className="form__upper__line" />
          <input
            ref={inputRefs[InputType.EMAIL]}
            autoComplete="on"
            className="form__upper__body"
            type="text"
            id="email"
            placeholder="vas@email.cz"
            value={email}
            onChange={emailInputHandler}
          />
        </div>
        <div
          data-testid="contact-form-name"
          className={'form__upper' + (isNameValid ? '' : ' form__upper--error')}
          onClick={focusInput.bind(null, InputType.NAME)}
        >
          <label className="form__upper__head" htmlFor="name">
            JMÉNO
          </label>
          <img alt="" src={formLine} className="form__upper__line" />
          <input
            ref={inputRefs[InputType.NAME]}
            autoComplete="new-password"
            className="form__upper__body"
            type="text"
            id="name"
            placeholder="Vaše Celé Jméno"
            value={name}
            onChange={nameInputHandler}
          />
        </div>
        <textarea
          data-testid="contact-form-message"
          className={'form__textarea' + (isMsgValid ? '' : ' form__textarea--error')}
          cols={30}
          rows={10}
          placeholder="Vaše zpráva..."
          value={msg}
          onChange={msgInputHandler}
        />
      </form>
      <CTA
        data-testid="contact-form-submit"
        className="contact__content__button"
        type="primary"
        onClick={sendMessage}
        style={{ filter: showModal ? 'blur(3px)' : 'none' }}
      >
        {status === 'loading' ? 'Odesílání...' : 'ODESLAT'}
      </CTA>
    </>
  );
};
