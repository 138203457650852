import { motion, Variants } from 'framer-motion';
import { FC, ReactElement } from 'react';
import { FormStatus } from '../../../../types/enums';

import './FormModal.scss';

const formModalVariants: Variants = {
  animate: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  initial: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

interface FormModalProps {
  status: FormStatus;
  dismissModal: () => void;
}

export const FormModal: FC<FormModalProps> = ({ status, dismissModal }) => {
  let content: ReactElement;
  if (status === 'success') {
    content = (
      <>
        <h4 className="form-modal__heading">E-mail odeslán</h4>
        <p className="form-modal__text">Odpovíme vám co nejdříve!</p>
      </>
    );
  } else {
    content = (
      <>
        <h4 className="form-modal__heading">E-mail se neodeslal</h4>
        <p className="form-modal__text">
          Zkuste to za chvíli znovu, nebo nás kontaktujte na{' '}
          <a href="mailto:team@amniomweb.cz">team@amniomweb.cz</a> nebo{' '}
          <a href="tel:00420735044114">735 044 114</a>.
        </p>
      </>
    );
  }

  return (
    <motion.div
      className={`form-modal form-modal--${status}`}
      onClick={dismissModal}
      variants={formModalVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div data-testid="form-modal" className="form-modal__content">
        {content}
      </div>
    </motion.div>
  );
};
