import { CSSProperties, FC, LegacyRef } from 'react';
import './Section.scss';

interface SectionProps {
  style?: CSSProperties;
  className?: string;
  _ref?: LegacyRef<HTMLElement>;
}

export const Section: FC<SectionProps> = ({ style, children, className, _ref }) => {
  return (
    <section
      ref={_ref}
      style={{ ...style }}
      className={'section' + (className ? ' ' + className : '')}
    >
      {children}
    </section>
  );
};
