/* eslint-disable max-len */
import { FC } from 'react';
import './IconUpkeep.scss';

interface IconUpkeepProps {
  className: string;
}

export const IconUpkeep: FC<IconUpkeepProps> = ({ className }) => {
  return (
    <svg
      className={'icon__upkeep ' + className}
      width="128"
      height="112"
      viewBox="0 0 128 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__upkeep__tick-box">
          <path
            d="M117.643 2.6084H77.5281C73.5204 2.6084 70.2715 5.85731 70.2715 9.86504V49.9802C70.2715 53.988 73.5204 57.2369 77.5281 57.2369H117.643C121.651 57.2369 124.9 53.988 124.9 49.9802V9.86504C124.9 5.85731 121.651 2.6084 117.643 2.6084Z"
            stroke="#1DA88F"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M117.643 6.35059H77.5281C73.5204 6.35059 70.2715 9.59949 70.2715 13.6072V53.7224C70.2715 57.7302 73.5204 60.9791 77.5281 60.9791H117.643C121.651 60.9791 124.9 57.7302 124.9 53.7224V13.6072C124.9 9.59949 121.651 6.35059 117.643 6.35059Z"
            stroke="#05F2C7"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M116.552 4.74219H76.4363C72.4286 4.74219 69.1797 7.9911 69.1797 11.9988V52.114C69.1797 56.1218 72.4286 59.3707 76.4363 59.3707H116.552C120.559 59.3707 123.808 56.1218 123.808 52.114V11.9988C123.808 7.9911 120.559 4.74219 116.552 4.74219Z"
            stroke="#05F2C7"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M119.99 10.4243H81.2561C77.3857 10.4243 74.248 13.5619 74.248 17.4324V56.1667C74.248 60.0372 77.3857 63.1748 81.2561 63.1748H119.99C123.861 63.1748 126.999 60.0372 126.999 56.1667V17.4324C126.999 13.5619 123.861 10.4243 119.99 10.4243Z"
            stroke="#05F2C7"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M116.552 1H76.4363C72.4286 1 69.1797 4.24891 69.1797 8.25664V48.3718C69.1797 52.3796 72.4286 55.6285 76.4363 55.6285H116.552C120.559 55.6285 123.808 52.3796 123.808 48.3718V8.25664C123.808 4.24891 120.559 1 116.552 1Z"
            stroke="#1DA88F"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M119.992 6.68213H81.2581C77.3876 6.68213 74.25 9.81975 74.25 13.6902V52.4245C74.25 56.295 77.3876 59.4326 81.2581 59.4326H119.992C123.863 59.4326 127 56.295 127 52.4245V13.6902C127 9.81975 123.863 6.68213 119.992 6.68213Z"
            stroke="#1DA88F"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__upkeep__tick">
          <path
            d="M98.221 44.5116L85.834 36.0459L84.1315 38.537L96.5185 47.0027L98.221 44.5116Z"
            stroke="#05F2C7"
            strokeWidth="0.9"
            strokeMiterlimit="10"
          />
          <path
            d="M95.1856 47.212L112.043 22.5464L109.335 20.6959L92.4779 45.3615L95.1856 47.212Z"
            stroke="#05F2C7"
            strokeWidth="0.81"
            strokeMiterlimit="10"
          />
          <path
            d="M97.0874 42.7034L87.0547 35.8467L85.6756 37.8646L95.7083 44.7213L97.0874 42.7034Z"
            stroke="#05F2C7"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M94.6302 44.8906L108.285 24.9106L106.091 23.4107L92.4356 43.3907L94.6302 44.8906Z"
            stroke="#05F2C7"
            strokeWidth="0.66"
            strokeMiterlimit="10"
          />
          <path
            d="M98.2229 40.7694L85.8359 32.3037L84.1335 34.7948L96.5204 43.2605L98.2229 40.7694Z"
            stroke="#1DA88F"
            strokeWidth="0.9"
            strokeMiterlimit="10"
          />
          <path
            d="M95.1856 43.4699L112.043 18.8042L109.335 16.9537L92.4779 41.6193L95.1856 43.4699Z"
            stroke="#1DA88F"
            strokeWidth="0.81"
            strokeMiterlimit="10"
          />
          <path
            d="M97.0874 38.9612L87.0547 32.1045L85.6756 34.1224L95.7083 40.9791L97.0874 38.9612Z"
            stroke="#1DA88F"
            strokeWidth="0.73"
            strokeMiterlimit="10"
          />
          <path
            d="M94.6302 41.1484L108.285 21.1685L106.091 19.6686L92.4356 39.6485L94.6302 41.1484Z"
            stroke="#1DA88F"
            strokeWidth="0.66"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__upkeep__hand">
          <path
            d="M75.4162 65.683C78.5301 63.5012 81.4784 61.4022 84.3161 59.393C84.8961 58.9787 89.1009 56.0029 90.8823 51.3699C91.4347 49.9131 91.5728 48.6979 91.0618 47.6415C90.2678 46.0328 88.2586 45.5702 87.7339 45.4942C86.5795 45.2518 85.3794 45.3526 84.2816 45.7842C82.5407 47.5386 80.7109 49.2025 78.7994 50.7693C76.5838 52.5783 74.2595 54.25 71.8397 55.775C70.0314 57.1678 68.1357 58.4432 66.1642 59.5932C63.2643 61.2779 62.4012 61.4022 61.6693 61.1536C60.0537 60.6358 60.102 58.7302 58.4311 58.3918C57.2159 58.1571 56.6359 59.0823 55.1929 58.6818C54.5922 58.5299 54.4127 58.2952 53.4322 57.6047C51.4368 56.2238 50.4357 55.5334 49.2895 55.4574C49.0134 55.4574 48.7855 55.4574 45.6716 56.935C43.0755 58.1295 42.8614 58.3159 42.2193 58.3159C40.6589 58.3159 40.2723 57.3492 38.7671 57.3354C37.3862 57.3354 36.385 58.1364 35.5427 58.8061C27.492 65.2066 27.6439 64.8683 26.7187 65.9592C24.6581 68.4022 22.9775 71.1418 21.7336 74.0858C19.6968 78.6773 20.5875 79.2918 18.6957 82.7993C17.0419 85.6892 14.8862 88.261 12.3297 90.3942C10.4632 92.0971 8.42994 93.6076 6.26063 94.9029C5.58218 97.2503 5.55194 99.7376 6.17313 102.101C6.79432 104.464 8.04376 106.615 9.78884 108.325C15.0294 113.234 22.217 111.287 23.011 111.087C27.2158 109.879 27.0294 107.635 32.5047 104.231C36.3574 101.842 37.3378 102.36 48.385 98.5554C55.8903 95.9662 59.6463 94.675 61.1239 93.3701C66.827 88.3436 64.4795 83.6278 70.7073 79.1675C72.1642 78.1387 74.85 76.5438 76.6935 73.2918C77.7775 71.3447 77.8397 70.0536 77.6602 69.0869C77.5968 68.4355 77.3884 67.8066 77.0503 67.2463C76.7122 66.6859 76.2529 66.2084 75.7062 65.8487C75.59 65.7723 75.4615 65.7162 75.3264 65.683C74.2779 65.2883 73.1171 65.3156 72.0882 65.7589C70.1778 66.2104 68.3787 67.0442 66.7994 68.21C64.7491 69.7296 63.1269 71.7537 62.0905 74.0858"
            stroke="#05F2C7"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M70.5755 65.6824C73.6894 63.5006 76.6376 61.4016 79.4754 59.3924C80.0554 58.9781 84.2602 56.0023 86.0416 51.3694C86.5939 49.9125 86.732 48.6973 86.2211 47.6409C85.4271 46.0322 83.4179 45.5696 82.8931 45.4936C81.7498 45.2585 80.5628 45.3592 79.4754 45.7836C77.737 47.5381 75.9095 49.202 74.0001 50.7687C71.781 52.5762 69.4545 54.2479 67.0335 55.7745C65.2252 57.1672 63.3295 58.4426 61.3579 59.5927C58.4581 61.2774 57.595 61.4016 56.8631 61.1531C55.2475 60.6352 55.2958 58.7296 53.6249 58.3913C52.4097 58.1565 51.8297 59.0817 50.3867 58.6813C49.7929 58.5294 49.6065 58.2946 48.626 57.6041C46.6306 56.2232 45.6295 55.5328 44.4833 55.4568C44.2071 55.4568 43.9793 55.4568 40.8654 56.9344C38.2693 58.1289 38.0552 58.3153 37.4131 58.3153C35.8527 58.3153 35.466 57.3487 33.9609 57.3349C32.58 57.3349 31.5788 58.1358 30.7364 58.8055C22.7617 65.206 22.9136 64.8677 21.9953 65.9586C19.9308 68.4 18.2477 71.1399 17.0034 74.0852C14.9665 78.6767 15.8572 79.2912 13.9723 82.7987C12.3143 85.6876 10.1565 88.2592 7.59944 90.3937C5.73297 92.0966 3.69968 93.6071 1.53037 94.9023C0.853432 97.2498 0.823957 99.7367 1.44507 102.1C2.06618 104.463 3.31476 106.613 5.05858 108.325C10.306 113.234 17.4867 111.287 18.2807 111.086C22.4856 109.878 22.2991 107.634 27.7744 104.23C31.6271 101.841 32.6076 102.359 43.6548 98.5548C51.1531 95.9656 54.9091 94.6745 56.3867 93.3695C62.076 88.343 59.7147 83.6272 65.9287 79.1669C67.3856 78.1382 70.0714 76.5432 71.9149 73.2912C73.0059 71.3441 73.0611 70.053 72.8816 69.0864C72.8185 68.4357 72.611 67.8073 72.2741 67.2471C71.9373 66.6869 71.4796 66.2089 70.9345 65.8481C70.8152 65.7728 70.6847 65.7168 70.5479 65.6824C69.4993 65.2878 68.3385 65.315 67.3096 65.7584C65.3992 66.2099 63.6001 67.0437 62.0208 68.2095C59.9705 69.729 58.3483 71.7532 57.3119 74.0852"
            stroke="#05F2C7"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M71.4542 68.2641C74.3955 66.1928 77.1711 64.225 79.85 62.3263C80.3955 61.9396 84.3656 59.1295 86.0641 54.7313C86.5819 53.3504 86.7546 52.2111 86.2367 51.2169C85.4841 49.6979 83.5854 49.2905 83.0952 49.187C82.0162 48.9666 80.8965 49.0625 79.8708 49.4632C78.2288 51.1183 76.5027 52.6877 74.6993 54.1651C72.6089 55.8717 70.416 57.4489 68.1331 58.8878C66.4269 60.2038 64.6372 61.4077 62.7752 62.492C60.0134 64.08 59.2332 64.1974 58.5358 63.9626C57.0168 63.4724 57.0583 61.6772 55.484 61.3734C54.3379 61.1594 53.7924 62.0086 52.4322 61.6496C51.873 61.553 51.7003 61.3596 50.7406 60.6692C48.8626 59.3504 47.9236 58.7014 46.8672 58.6462C46.6048 58.6462 46.3908 58.6462 43.4149 60.0271C41.0329 61.1732 40.8257 61.3596 40.2527 61.3596C38.7751 61.3596 38.4092 60.4413 37.0076 60.4275C35.8631 60.5125 34.7862 61.002 33.9696 61.8084C26.3746 67.843 26.5127 67.5254 25.6496 68.5472C23.703 70.8527 22.1155 73.4388 20.9408 76.2181C19.0213 80.5473 19.8567 81.1272 18.0754 84.4414C16.5176 87.1742 14.4859 89.608 12.0754 91.629C10.3117 93.2373 8.38931 94.6623 6.33772 95.8822C5.69912 98.0974 5.67194 100.444 6.25907 102.673C6.8462 104.903 8.02574 106.932 9.6726 108.545C14.6162 113.178 21.4103 111.307 22.1421 111.12C26.1122 109.981 25.9396 107.882 31.118 104.651C34.7498 102.393 35.7026 102.883 46.0801 99.2999C53.1572 96.8488 56.7061 95.6336 58.0939 94.4046C63.4794 89.6612 61.2631 85.2147 67.1388 81.003C68.5197 80.0363 71.0606 78.5311 72.7867 75.4793C73.8086 73.6427 73.8707 72.4276 73.6981 71.5162C73.6374 70.9023 73.4404 70.3098 73.1215 69.7818C72.8025 69.2538 72.3697 68.8037 71.8546 68.4644C71.7362 68.371 71.5999 68.3028 71.4542 68.2641C70.4664 67.8907 69.3722 67.9155 68.4024 68.3332C66.5978 68.7587 64.8992 69.5481 63.4104 70.6531C61.4769 72.0803 59.9452 73.983 58.9639 76.1767"
            stroke="#05F2C7"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M75.4162 61.9408C78.5301 59.759 81.4784 57.66 84.3161 55.6508C84.8961 55.2365 89.1009 52.2607 90.8823 47.6278C91.4347 46.1709 91.5728 44.9557 91.0618 43.8993C90.2678 42.2906 88.2586 41.828 87.7339 41.752C86.5795 41.5096 85.3794 41.6104 84.2816 42.042C82.5407 43.7964 80.7109 45.4603 78.7994 47.0271C76.5838 48.8361 74.2595 50.5078 71.8397 52.0328C70.0314 53.4256 68.1357 54.701 66.1642 55.851C63.2643 57.5357 62.4012 57.66 61.6693 57.4114C60.0537 56.8936 60.102 54.988 58.4311 54.6496C57.2159 54.4149 56.6359 55.3401 55.1929 54.9396C54.5922 54.7877 54.4127 54.553 53.4322 53.8625C51.4368 52.4816 50.4357 51.7912 49.2895 51.7152C49.0134 51.7152 48.7855 51.7152 45.6716 53.1928C43.0755 54.3873 42.8614 54.5737 42.2193 54.5737C40.6589 54.5737 40.2723 53.6071 38.7671 53.5933C37.3862 53.5933 36.385 54.3942 35.5427 55.0639C27.492 61.4644 27.6439 61.1261 26.7187 62.217C24.6581 64.66 22.9775 67.3996 21.7336 70.3436C19.6968 74.9351 20.5875 75.5496 18.6957 79.0571C17.0419 81.947 14.8862 84.5189 12.3297 86.652C10.4632 88.3549 8.42994 89.8654 6.26063 91.1607C5.58218 93.5081 5.55194 95.9954 6.17313 98.3586C6.79432 100.722 8.04376 102.873 9.78884 104.583C15.0294 109.492 22.217 107.545 23.011 107.345C27.2158 106.137 27.0294 103.893 32.5047 100.489C36.3574 98.0997 37.3378 98.6176 48.385 94.8132C55.8903 92.224 59.6463 90.9328 61.1239 89.6279C66.827 84.6014 64.4795 79.8856 70.7073 75.4253C72.1642 74.3965 74.85 72.8016 76.6935 69.5496C77.7775 67.6025 77.8397 66.3114 77.6602 65.3447C77.5968 64.6934 77.3884 64.0644 77.0503 63.5041C76.7122 62.9438 76.2529 62.4662 75.7062 62.1065C75.59 62.0301 75.4615 61.974 75.3264 61.9408C74.2779 61.5461 73.1171 61.5734 72.0882 62.0168C70.1778 62.4682 68.3787 63.302 66.7994 64.4679C64.7491 65.9874 63.1269 68.0116 62.0905 70.3436"
            stroke="#1DA88F"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M70.5755 61.9402C73.6894 59.7584 76.6376 57.6594 79.4754 55.6502C80.0554 55.236 84.2602 52.2601 86.0416 47.6272C86.5939 46.1703 86.732 44.9551 86.2211 43.8988C85.4271 42.29 83.4179 41.8274 82.8931 41.7515C81.7498 41.5163 80.5628 41.617 79.4754 42.0414C77.737 43.7959 75.9095 45.4598 74.0001 47.0265C71.781 48.8341 69.4545 50.5057 67.0335 52.0323C65.2252 53.4251 63.3295 54.7004 61.3579 55.8505C58.4581 57.5352 57.595 57.6594 56.8631 57.4109C55.2475 56.893 55.2958 54.9874 53.6249 54.6491C52.4097 54.4143 51.8297 55.3395 50.3867 54.9391C49.7929 54.7872 49.6065 54.5524 48.626 53.862C46.6306 52.4811 45.6295 51.7906 44.4833 51.7147C44.2071 51.7147 43.9793 51.7147 40.8654 53.1922C38.2693 54.3867 38.0552 54.5731 37.4131 54.5731C35.8527 54.5731 35.466 53.6065 33.9609 53.5927C32.58 53.5927 31.5788 54.3936 30.7364 55.0634C22.7617 61.4638 22.9136 61.1255 21.9953 62.2164C19.9308 64.6578 18.2477 67.3978 17.0034 70.343C14.9665 74.9345 15.8572 75.549 13.9723 79.0565C12.3143 81.9454 10.1565 84.517 7.59944 86.6515C5.73297 88.3544 3.69968 89.8649 1.53037 91.1601C0.853432 93.5076 0.823957 95.9945 1.44507 98.3574C2.06618 100.72 3.31476 102.871 5.05858 104.582C10.306 109.492 17.4867 107.545 18.2807 107.344C22.4856 106.136 22.2991 103.892 27.7744 100.488C31.6271 98.0992 32.6076 98.617 43.6548 94.8126C51.1531 92.2234 54.9091 90.9323 56.3867 89.6273C62.076 84.6008 59.7147 79.8851 65.9287 75.4247C67.3856 74.396 70.0714 72.801 71.9149 69.549C73.0059 67.6019 73.0611 66.3108 72.8816 65.3442C72.8185 64.6935 72.611 64.0652 72.2741 63.5049C71.9373 62.9447 71.4796 62.4668 70.9345 62.1059C70.8152 62.0306 70.6847 61.9747 70.5479 61.9402C69.4993 61.5456 68.3385 61.5728 67.3096 62.0162C65.3992 62.4677 63.6001 63.3015 62.0208 64.4673C59.9705 65.9868 58.3483 68.011 57.3119 70.343"
            stroke="#1DA88F"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
          <path
            d="M71.4542 64.5219C74.3955 62.4506 77.1711 60.4828 79.85 58.5841C80.3955 58.1974 84.3656 55.3873 86.0641 50.9891C86.5819 49.6082 86.7546 48.469 86.2367 47.4747C85.4841 45.9557 83.5854 45.5484 83.0952 45.4448C82.0162 45.2244 80.8965 45.3203 79.8708 45.721C78.2288 47.3761 76.5027 48.9455 74.6993 50.4229C72.6089 52.1295 70.416 53.7067 68.1331 55.1456C66.4269 56.4616 64.6372 57.6655 62.7752 58.7498C60.0134 60.3378 59.2332 60.4552 58.5358 60.2204C57.0168 59.7302 57.0583 57.935 55.484 57.6312C54.3379 57.4172 53.7924 58.2665 52.4322 57.9074C51.873 57.8108 51.7003 57.6174 50.7406 56.927C48.8626 55.6082 47.9236 54.9592 46.8672 54.904C46.6048 54.904 46.3908 54.904 43.4149 56.2849C41.0329 57.431 40.8257 57.6174 40.2527 57.6174C38.7751 57.6174 38.4092 56.6991 37.0076 56.6853C35.8631 56.7703 34.7862 57.2598 33.9696 58.0662C26.3746 64.1008 26.5127 63.7832 25.6496 64.805C23.703 67.1105 22.1155 69.6966 20.9408 72.4759C19.0213 76.8051 19.8567 77.385 18.0754 80.6992C16.5176 83.432 14.4859 85.8658 12.0754 87.8868C10.3117 89.4951 8.38931 90.9201 6.33772 92.14C5.69912 94.3552 5.67194 96.7019 6.25907 98.9313C6.8462 101.161 8.02574 103.19 9.6726 104.803C14.6162 109.436 21.4103 107.565 22.1421 107.378C26.1122 106.239 25.9396 104.14 31.118 100.909C34.7498 98.6509 35.7026 99.1412 46.0801 95.5577C53.1572 93.1066 56.7061 91.8914 58.0939 90.6624C63.4794 85.919 61.2631 81.4725 67.1388 77.2608C68.5197 76.2941 71.0606 74.789 72.7867 71.7372C73.8086 69.9006 73.8707 68.6854 73.6981 67.774C73.6374 67.1601 73.4404 66.5676 73.1215 66.0396C72.8025 65.5116 72.3697 65.0615 71.8546 64.7222C71.7362 64.6288 71.5999 64.5607 71.4542 64.5219C70.4664 64.1485 69.3722 64.1733 68.4024 64.591C66.5978 65.0165 64.8992 65.806 63.4104 66.9109C61.4769 68.3381 59.9452 70.2408 58.9639 72.4345"
            stroke="#1DA88F"
            strokeWidth="0.62"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </svg>
  );
};
