/* eslint-disable max-len */
import { FC } from 'react';
import './Rocket.scss';

interface RocketProps {
  className?: string;
}

export const Rocket: FC<RocketProps> = ({ className }) => {
  return (
    <svg
      className={'rocket ' + className}
      width="921"
      height="810"
      viewBox="0 0 921 810"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <g className="rocket__body rocket__body--2">
          <path
            d="M156.18 596.851L154.03 594.401C137.475 582.111 123.735 566.428 113.73 548.401L111.8 546.201L112.48 546.011L131.48 540.651C139.48 538.431 147.73 533.491 154.64 526.861C155.7 525.941 156.64 524.861 157.73 523.861C161.51 520.281 165.47 516.661 169.58 513.071L588.32 145.991L602.2 135.071L639.46 108.901L639.89 108.531L640.42 108.241L652.92 100.581L700.92 71.1906C707.21 67.3506 708.75 69.1106 704.12 74.8406L668.7 118.611L659.47 130.011L659.11 130.501L658.69 130.871L627.87 164.391L615.23 176.711L207.57 534.101C199.87 540.921 191.953 547.471 183.82 553.751C183.433 554.124 183.011 554.459 182.56 554.751C178.645 557.747 175.097 561.194 171.99 565.021C169.079 568.567 166.669 572.497 164.83 576.701L156.45 596.151L156.18 596.851Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M263.93 719.791L261.77 717.341C256.982 713.844 252.439 710.025 248.17 705.911C245.17 702.981 242.08 699.821 239.17 696.451C236.26 693.081 233.61 689.851 231.17 686.511C227.603 681.669 224.369 676.59 221.49 671.311L219.56 669.111L220.24 668.921L239.19 663.561C247.19 661.341 255.44 656.411 262.35 649.781C263.35 648.781 264.35 647.781 265.44 646.781C269.22 643.191 273.18 639.581 277.28 635.981L696.04 268.861L709.92 257.941L747.3 231.801L747.73 231.431L748.26 231.131L760.76 223.481L808.76 194.091C815.05 190.251 816.59 192.011 811.96 197.741L776.57 241.511L767.34 252.901L766.98 253.391L766.56 253.771L735.74 287.241C731.48 291.411 727.36 295.411 723.1 299.571L315.37 657.061C307.643 663.848 299.71 670.364 291.57 676.611C291.183 676.984 290.761 677.319 290.31 677.611C286.395 680.604 282.847 684.047 279.74 687.871C276.824 691.409 274.412 695.333 272.57 699.531L264.19 718.971L263.93 719.791Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M237.57 727.06L208.84 694.24L231.13 686.521C233.59 689.854 236.257 693.164 239.13 696.451C242.08 699.821 245.07 702.93 248.13 705.91L237.57 727.06Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M129.85 604.171L101.1 571.371L123.39 563.641C125.85 566.974 128.516 570.287 131.39 573.581C134.39 576.951 137.33 580.051 140.39 583.031L129.85 604.171Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M840.69 147.24L407.85 526.68C385.68 546.05 359.85 559.41 336.11 563.85C312.37 568.29 292.5 563.42 280.96 550.34C271.2 539.04 269.33 521.66 275.77 501.97C282.21 482.28 296.43 461.89 315.33 445.24L761.02 54.5205L776.97 44.2405L822.97 23.4305L823.53 23.1705L824.28 23.0605L840.72 18.5105L903.83 0.960485C905.562 0.433155 907.389 0.29654 909.18 0.560484C910.638 0.765429 911.978 1.47278 912.97 2.56048C913.918 3.68014 914.448 5.09377 914.47 6.56048C914.496 8.37029 914.12 10.1633 913.37 11.8105L887.78 72.2405L881.12 87.9505L880.91 88.6805L880.58 89.2005L853.95 131.91L841.67 146.33C841.36 146.64 841.02 146.93 840.69 147.24Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M165.26 594.36L163.11 591.9C146.557 579.609 132.818 563.926 122.81 545.9L120.88 543.7L121.57 543.5L140.57 538.08C148.57 535.86 156.82 530.93 163.73 524.3C164.737 523.307 165.763 522.307 166.81 521.3C170.603 517.707 174.553 514.107 178.66 510.5L597.46 143.35C602.15 139.67 606.65 136.11 611.33 132.43L648.6 106.27L649.02 105.89L649.56 105.6L662.06 97.9504L710.06 68.5504C716.35 64.7104 717.89 66.4704 713.26 72.2104L677.83 115.97L668.61 127.37L668.25 127.86L667.82 128.23L637.01 161.75C632.74 165.91 628.63 169.91 624.37 174.08L216.69 531.6C208.957 538.387 201.017 544.907 192.87 551.16C192.487 551.534 192.068 551.869 191.62 552.16C187.7 555.154 184.148 558.601 181.04 562.43C178.132 565.979 175.723 569.909 173.88 574.11L165.51 593.56L165.26 594.36Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M273.01 717.24L270.85 714.79C266.081 711.307 261.554 707.505 257.3 703.41C254.24 700.48 251.21 697.32 248.3 693.96C245.39 690.6 242.74 687.35 240.3 684.02C236.719 679.184 233.468 674.113 230.57 668.84L228.64 666.64L229.32 666.45L248.27 661.08C256.27 658.86 264.52 653.93 271.43 647.3C272.49 646.37 273.43 645.3 274.51 644.3C278.3 640.71 282.26 637.1 286.36 633.51L705.22 266.33L719.1 255.41L756.36 229.24L756.79 228.86L757.32 228.57L769.83 220.92L817.83 191.52C824.12 187.68 825.67 189.44 821.04 195.18L785.68 238.99L776.46 250.39L776.1 250.88L775.68 251.25L744.86 284.77C740.6 288.94 736.48 292.93 732.22 297.1L324.49 654.57C316.756 661.357 308.82 667.874 300.68 674.12C300.293 674.494 299.871 674.828 299.42 675.12C295.503 678.114 291.955 681.561 288.85 685.39C285.939 688.939 283.526 692.868 281.68 697.07L273.31 716.52L273.01 717.24Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M246.699 724.56L217.939 691.77L240.239 684.04C242.699 687.373 245.366 690.687 248.239 693.98C251.189 697.35 254.179 700.45 257.239 703.43L246.699 724.56Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M138.939 601.651L110.199 568.861L132.489 561.141C134.949 564.474 137.616 567.784 140.489 571.071C143.439 574.441 146.429 577.551 149.489 580.531L138.939 601.651Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M817.39 161.151L414.57 514.331C393.94 532.331 369.96 544.751 347.84 548.881C325.72 553.011 307.27 548.531 296.5 536.401C287.37 525.891 285.62 509.681 291.62 491.331C297.62 472.981 310.89 453.961 328.53 438.451L743.32 74.7807L758.17 65.2407L800.82 45.9007L801.33 45.6607C801.58 45.6007 801.8 45.5907 802.05 45.5407L817.34 41.2407L876.16 24.9507C877.763 24.4815 879.45 24.3723 881.1 24.6307C882.446 24.8417 883.681 25.5048 884.6 26.5107C885.481 27.5488 885.975 28.8596 886 30.2207C886.031 31.8948 885.689 33.5548 885 35.0807L861.2 91.3107L855.02 105.921L854.8 106.611L854.5 107.091L829.73 146.831L818.29 160.301C818.005 160.6 817.705 160.884 817.39 161.151V161.151Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M188.719 577.331L186.719 575.041C171.407 563.532 158.646 548.976 149.239 532.291L147.409 530.241L148.049 530.071L165.699 525.071C173.119 523.001 180.839 518.401 187.269 512.221L190.149 509.461C193.736 506.061 197.403 502.727 201.149 499.461L591.029 157.611L603.939 147.441L638.619 123.081L639.019 122.741L639.519 122.461L651.159 115.351L695.949 88.0307C701.789 84.4707 703.229 86.1107 698.949 91.4307L665.949 132.181L657.369 142.781L657.029 143.251L656.639 143.591L627.989 174.861C623.989 178.741 620.199 182.461 616.219 186.351L236.709 519.051C229.523 525.371 222.143 531.434 214.569 537.241C214.209 537.575 213.817 537.873 213.399 538.131C209.755 540.916 206.455 544.125 203.569 547.691C200.857 550.992 198.61 554.65 196.889 558.561L189.089 576.671L188.719 577.331Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M289 691.711L287 689.441C282.552 686.214 278.344 682.668 274.41 678.831C271.57 676.081 268.74 673.151 265.99 670.011C263.24 666.871 260.83 663.861 258.54 660.761C255.227 656.257 252.198 651.551 249.47 646.671L247.67 644.671L248.3 644.491L265.93 639.491C274.081 637.01 281.507 632.587 287.57 626.601C288.51 625.691 289.48 624.771 290.45 623.851C294.043 620.451 297.693 617.117 301.4 613.851L691.4 271.991C695.77 268.571 699.96 265.261 704.31 261.841L739.01 237.471L739.4 237.131L739.89 236.861L751.53 229.721L796.23 202.371C802.09 198.791 803.53 200.431 799.23 205.771L766.23 246.511L757.57 257.131L757.24 257.571L756.85 257.921L728.15 289.131C724.15 293.011 720.36 296.721 716.4 300.601L336.9 633.301C329.7 639.621 322.307 645.687 314.72 651.501C314.358 651.838 313.966 652.143 313.55 652.411C309.905 655.195 306.602 658.401 303.71 661.961C300.999 665.265 298.755 668.926 297.04 672.841L289.25 690.931L289 691.711Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M264.51 698.511L237.76 667.991L258.51 660.801C260.8 663.891 263.28 666.991 265.97 670.051C268.66 673.111 271.55 676.111 274.38 678.861L264.51 698.511Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M164.23 584.12L137.47 553.6L158.22 546.4C160.52 549.51 162.99 552.59 165.69 555.66C168.39 558.73 171.27 561.73 174.09 564.46L164.23 584.12Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M795.129 178.761L424.499 503.681C405.499 520.251 383.499 531.681 363.109 535.491C342.719 539.301 325.779 535.161 315.889 523.981C307.509 514.311 305.889 499.421 311.419 482.551C316.949 465.681 329.129 448.241 345.329 433.941L726.019 100.241L726.879 99.4908L740.569 90.6508L779.769 72.8408L780.239 72.6208C780.45 72.5768 780.664 72.5468 780.879 72.5308L794.959 68.6308L849.209 53.5208C850.694 53.0628 852.262 52.9432 853.799 53.1708C855.048 53.3528 856.196 53.9603 857.049 54.8908C857.859 55.8551 858.308 57.0714 858.319 58.3308C858.345 59.8853 858.023 61.4259 857.379 62.8408L835.449 114.511L829.739 127.961C829.696 128.172 829.636 128.38 829.559 128.581L829.299 129.031L806.509 165.591L795.989 177.991L795.129 178.761Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M216.77 561.64L214.93 559.53C200.755 549.017 188.99 535.597 180.42 520.17L178.77 518.29L179.35 518.13L195.59 513.53C203.054 511.253 209.857 507.204 215.42 501.73C216.31 500.94 217.2 500.01 218.06 499.2C221.29 496.13 224.69 493.04 228.21 489.95L586.88 175.51C590.88 172.37 594.66 169.4 598.76 166.17L630.669 143.76L631.039 143.43L631.49 143.19L642.18 136.65L683.299 111.48C688.689 108.19 690.009 109.7 686.049 114.62L655.71 152.09L647.83 161.83L647.53 162.24L647.16 162.57L620.76 191.24C617.11 194.79 613.68 198.15 609.94 201.78L260.82 507.88C254.233 513.72 247.456 519.33 240.49 524.71C240.165 525.015 239.809 525.287 239.43 525.52C236.07 528.094 233.029 531.058 230.37 534.35C227.881 537.389 225.818 540.754 224.24 544.35L217.09 560.99L216.77 561.64Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M309.04 666.87L307.19 664.77C303.098 661.796 299.216 658.545 295.57 655.04C292.96 652.52 290.39 649.85 287.83 646.93C285.27 644.01 283.07 641.28 280.96 638.42C277.9 634.283 275.13 629.94 272.67 625.42L271.02 623.53L271.61 623.38L287.89 618.83C295.346 616.557 302.142 612.516 307.7 607.05C308.57 606.19 309.48 605.33 310.34 604.5C313.58 601.43 316.98 598.33 320.49 595.26L679.18 280.8L691.06 271.45L722.97 249.04L723.33 248.73L723.78 248.47L734.479 241.93L775.62 216.71C780.99 213.44 782.309 214.94 778.359 219.83L748.03 257.32L740.15 267.05L739.83 267.48L739.479 267.79L713.08 296.5L702.27 307.05L353.15 613.12C346.53 618.934 339.73 624.517 332.75 629.87L331.68 630.7C328.321 633.26 325.28 636.211 322.62 639.49C320.132 642.53 318.07 645.894 316.49 649.49L309.32 666.14L309.04 666.87Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M286.5 673.121L261.88 645.041L280.97 638.421C283.083 641.281 285.373 644.121 287.84 646.941C290.37 649.821 292.97 652.521 295.58 655.041L286.5 673.121Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M194.24 567.88L169.63 539.81L188.72 533.24C190.82 536.087 193.107 538.92 195.58 541.74C198.11 544.63 200.71 547.33 203.32 549.85L194.24 567.88Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M269.33 594.651L241.14 619.371C239.81 620.445 238.213 621.136 236.52 621.371C235.915 621.492 235.289 621.442 234.711 621.227C234.133 621.011 233.627 620.638 233.25 620.151L226.57 612.591C226.172 612.178 225.888 611.668 225.746 611.113C225.604 610.557 225.608 609.974 225.76 609.421C226.105 607.888 226.855 606.476 227.93 605.331C228.09 605.181 228.25 605.031 228.43 604.891L256.62 580.181L269.33 594.651Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M250.869 573.59L222.679 598.3C222.247 598.675 221.782 599.01 221.289 599.3C220.116 599.949 218.799 600.293 217.459 600.3C216.956 600.318 216.455 600.22 215.996 600.014C215.536 599.807 215.13 599.498 214.809 599.11L208.159 591.53C207.726 591.092 207.423 590.542 207.285 589.941C207.147 589.341 207.18 588.714 207.379 588.13C207.851 586.483 208.76 584.994 210.009 583.82L238.199 559.11L250.869 573.59Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M227.159 613.24L223.039 616.86C221.563 618.193 219.769 619.123 217.829 619.56C217.133 619.752 216.399 619.76 215.698 619.585C214.998 619.41 214.354 619.057 213.829 618.56L206.889 610.65C206.472 610.061 206.214 609.375 206.138 608.657C206.063 607.939 206.174 607.213 206.459 606.55C207.146 604.684 208.303 603.027 209.819 601.74L213.939 598.12L215.269 599.63C215.621 600.024 216.085 600.3 216.599 600.42C217.219 600.551 217.86 600.551 218.479 600.42C219.219 600.27 219.933 600.014 220.599 599.66C221.339 599.268 222.03 598.792 222.659 598.24L250.849 573.53L256.639 580.13L228.449 604.84L227.949 605.28C226.892 606.29 226.105 607.548 225.659 608.94C225.479 609.437 225.424 609.971 225.499 610.494C225.574 611.017 225.777 611.514 226.089 611.94L227.159 613.24Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M378.75 554.66L303.23 620.88C301.876 622.101 300.261 622.997 298.51 623.5C297.855 623.72 297.163 623.804 296.476 623.747C295.788 623.69 295.119 623.494 294.51 623.17C294.269 623.033 294.056 622.853 293.88 622.64L210.57 527.64C210.134 527.132 209.871 526.498 209.82 525.83C209.749 525.034 209.851 524.233 210.12 523.48C210.427 522.572 210.865 521.713 211.42 520.93C212.034 520.071 212.745 519.286 213.54 518.59L289.07 452.38L316.15 445.81C339.83 440.09 364.87 426.45 386.54 407.45L567.24 249.03L567.92 248.65L621.86 218.24L707.21 170.16C708.467 169.41 709.888 168.977 711.35 168.9C711.902 168.85 712.458 168.924 712.977 169.118C713.497 169.312 713.966 169.62 714.35 170.02C714.69 170.452 714.931 170.954 715.056 171.49C715.18 172.026 715.185 172.582 715.07 173.12C714.798 174.558 714.184 175.91 713.28 177.06L654.43 255.37L617.23 304.87L616.77 305.5L435.98 463.95C414.34 482.95 397.55 505.95 388.77 528.7L385.57 537.09L378.75 554.66Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M393.199 680.571L392.479 681.211C389.281 684.045 385.639 686.336 381.699 687.991C378.395 689.436 374.8 690.09 371.199 689.901C362.659 689.231 351.469 690.181 347.069 686.431C341.959 682.061 336.989 677.521 332.259 672.951C329.733 670.471 327.269 667.998 324.869 665.531C322.869 663.411 320.929 661.331 318.999 659.131C314.943 654.539 311.128 649.739 307.569 644.751C302.652 637.899 298.266 630.681 294.449 623.161C295.059 623.482 295.729 623.677 296.416 623.732C297.104 623.787 297.796 623.701 298.449 623.481C300.202 622.98 301.817 622.084 303.169 620.861L378.689 554.651L385.439 537.061L388.669 528.641C397.449 505.921 414.239 482.891 435.879 463.891L616.699 305.421L615.159 307.421L484.339 480.041L483.749 481.121C480.53 485.3 477.787 489.825 475.569 494.611C465.687 516.467 457.983 539.244 452.569 562.611C451.562 566.542 451.517 570.659 452.439 574.611C453.48 576.272 454.249 578.088 454.719 579.991C460.419 601.591 435.299 643.671 393.199 680.571Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M166.109 421.541L165.849 421.771C162.533 424.656 159.716 428.069 157.509 431.871C155.592 435.049 154.445 438.631 154.159 442.331C153.709 450.771 151.159 461.621 154.309 466.551C157.969 472.191 161.819 477.711 165.739 483.001C167.859 485.831 169.999 488.611 172.119 491.301C173.919 493.541 175.769 495.761 177.699 497.951C181.725 502.559 185.984 506.958 190.459 511.131C196.615 516.895 203.197 522.187 210.149 526.961C209.913 526.314 209.809 525.627 209.845 524.939C209.881 524.252 210.056 523.579 210.359 522.961C211.085 521.291 212.183 519.81 213.569 518.631L289.099 452.411L307.419 448.031L316.179 445.921C339.859 440.181 364.879 426.551 386.549 407.581L567.309 249.091L565.139 250.361L376.859 357.361L375.699 357.801C371.14 360.439 366.3 362.559 361.269 364.121C338.32 371.092 314.739 375.781 290.869 378.121C286.836 378.606 282.744 378.116 278.939 376.691C277.445 375.428 275.757 374.415 273.939 373.691C253.209 365.241 208.209 384.631 166.109 421.541Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M271.839 599.42L243.649 624.14C242.322 625.211 240.728 625.902 239.039 626.14C238.435 626.26 237.809 626.21 237.231 625.995C236.653 625.779 236.147 625.407 235.769 624.92L229.109 617.32C228.712 616.907 228.428 616.398 228.284 615.843C228.141 615.287 228.142 614.704 228.289 614.15C228.644 612.621 229.392 611.211 230.459 610.06L230.959 609.62L259.149 584.91L271.839 599.42Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M253.379 578.36L225.189 603.07C224.761 603.455 224.292 603.79 223.789 604.07C222.617 604.719 221.3 605.063 219.959 605.07C219.455 605.088 218.954 604.988 218.494 604.78C218.035 604.572 217.629 604.261 217.309 603.87L210.649 596.28C210.214 595.844 209.91 595.293 209.772 594.692C209.634 594.091 209.668 593.463 209.869 592.88C210.337 591.231 211.247 589.741 212.499 588.57L240.689 563.86L253.379 578.36Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M229.679 618.01L225.569 621.63C224.091 622.965 222.293 623.895 220.349 624.33C219.653 624.524 218.918 624.534 218.217 624.359C217.516 624.184 216.872 623.829 216.349 623.33L209.409 615.42C208.989 614.832 208.729 614.145 208.654 613.427C208.578 612.708 208.69 611.982 208.979 611.32C209.666 609.454 210.823 607.797 212.339 606.51L216.459 602.89L217.789 604.41C218.117 604.741 218.517 604.99 218.958 605.14C219.399 605.289 219.868 605.334 220.329 605.27C221.572 605.151 222.767 604.729 223.809 604.04C224.296 603.746 224.758 603.411 225.189 603.04L253.379 578.33L259.159 584.93L230.969 609.64L230.469 610.08C229.411 611.091 228.627 612.354 228.189 613.75C228.008 614.247 227.953 614.782 228.03 615.306C228.107 615.829 228.313 616.326 228.629 616.75L229.679 618.01Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M381.27 559.441L305.75 625.661C304.392 626.876 302.779 627.771 301.03 628.281C300.376 628.5 299.684 628.584 298.996 628.527C298.308 628.471 297.639 628.274 297.03 627.951C296.811 627.778 296.625 627.568 296.48 627.331L213.18 532.331C212.745 531.821 212.479 531.188 212.42 530.521C212.351 529.722 212.449 528.918 212.71 528.161C213.02 527.254 213.461 526.398 214.02 525.621C214.631 524.756 215.343 523.967 216.14 523.271L291.57 457.121L318.65 450.561C342.34 444.841 367.38 431.241 389.04 412.241L569.75 253.781L570.43 253.401L624.43 223.011L709.77 174.931C711.032 174.182 712.455 173.747 713.92 173.661C714.471 173.584 715.033 173.646 715.554 173.84C716.075 174.035 716.54 174.355 716.907 174.774C717.274 175.192 717.531 175.695 717.655 176.237C717.78 176.78 717.768 177.344 717.62 177.881C717.347 179.322 716.725 180.674 715.81 181.821L656.97 260.131L619.78 309.651L619.29 310.291L438.51 468.741C416.85 487.741 400.05 510.741 391.29 533.491L388.05 541.901L381.27 559.441Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M395.709 685.34L394.999 685.96C391.804 688.801 388.161 691.095 384.219 692.75C380.918 694.202 377.32 694.853 373.719 694.65C365.179 693.98 354.009 694.95 349.589 691.19C344.489 686.82 339.509 682.27 334.789 677.71C332.249 675.23 329.783 672.754 327.389 670.28C325.459 668.08 323.449 666.09 321.519 663.89C317.409 659.2 313.619 654.39 310.139 649.52C305.23 642.665 300.847 635.448 297.029 627.93C297.638 628.255 298.307 628.452 298.995 628.509C299.683 628.566 300.375 628.481 301.029 628.26C302.783 627.756 304.398 626.856 305.749 625.63L381.259 559.44L388.009 541.84L391.239 533.44C399.999 510.71 416.799 487.68 438.459 468.69L619.209 310.24L617.749 312.14L486.939 484.77L486.339 485.84C483.136 490.024 480.403 494.548 478.189 499.33C468.303 521.185 460.599 543.962 455.189 567.33C454.176 571.26 454.125 575.376 455.039 579.33C456.089 580.984 456.862 582.798 457.329 584.7C462.929 606.37 437.709 648.52 395.709 685.34Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M168.63 426.32L168.37 426.55C165.052 429.424 162.228 432.823 160.01 436.61C158.083 439.782 156.926 443.361 156.63 447.06C156.17 455.49 153.63 466.36 156.78 471.29C160.44 476.92 164.3 482.45 168.21 487.73C170.33 490.57 172.46 493.337 174.6 496.03C176.53 498.23 178.24 500.49 180.16 502.68C184.192 507.282 188.454 511.678 192.93 515.85C199.083 521.617 205.665 526.909 212.62 531.68C212.376 531.036 212.267 530.348 212.302 529.659C212.336 528.971 212.513 528.297 212.82 527.68C213.545 526.005 214.647 524.52 216.04 523.34L291.57 457.12L309.9 452.73L318.66 450.62C342.33 444.91 367.37 431.28 389.03 412.29L569.76 253.83L567.68 255.02L379.36 362.09L378.22 362.55C373.653 365.182 368.806 367.295 363.77 368.85C340.831 375.791 317.263 380.453 293.41 382.77C289.355 383.279 285.236 382.795 281.41 381.36C279.921 380.089 278.232 379.075 276.41 378.36C255.72 370.01 210.63 389.49 168.63 426.32Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M279.23 578.821L252.57 602.181C251.305 603.211 249.785 603.878 248.17 604.111C247.593 604.226 246.995 604.174 246.446 603.961C245.897 603.749 245.419 603.384 245.07 602.911L238.84 595.801C238.446 595.421 238.161 594.942 238.015 594.414C237.869 593.886 237.867 593.329 238.01 592.801C238.333 591.326 239.052 589.967 240.09 588.871L240.55 588.471L267.2 565.101L279.23 578.821Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M261.749 558.87L235.099 582.24C234.694 582.595 234.255 582.91 233.789 583.18C232.679 583.815 231.427 584.159 230.149 584.18C229.671 584.196 229.196 584.101 228.761 583.904C228.326 583.706 227.942 583.411 227.639 583.04L221.339 575.85C220.914 575.443 220.614 574.922 220.475 574.35C220.335 573.778 220.361 573.178 220.549 572.62C220.99 571.047 221.855 569.626 223.049 568.51L249.699 545.15L261.749 558.87Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M239.309 596.411L235.409 599.831C234.018 601.098 232.318 601.977 230.479 602.381C229.822 602.563 229.128 602.575 228.465 602.414C227.801 602.253 227.19 601.925 226.689 601.461L220.129 593.971C219.736 593.415 219.49 592.767 219.417 592.09C219.343 591.413 219.444 590.728 219.709 590.101C220.355 588.33 221.451 586.759 222.889 585.541L226.789 582.121L228.039 583.551C228.349 583.864 228.726 584.103 229.142 584.247C229.559 584.39 230.002 584.436 230.439 584.381C231.621 584.265 232.757 583.863 233.749 583.211C234.206 582.914 234.638 582.579 235.039 582.211L261.729 558.811L267.209 565.061L240.569 588.461L240.099 588.871C239.096 589.827 238.35 591.02 237.929 592.341C237.75 592.815 237.693 593.326 237.765 593.828C237.836 594.33 238.034 594.806 238.339 595.211L239.309 596.411Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M382.819 540.94L311.339 603.6C310.061 604.759 308.536 605.61 306.879 606.09C306.259 606.297 305.603 606.375 304.952 606.32C304.3 606.265 303.666 606.078 303.089 605.77C302.881 605.608 302.704 605.408 302.569 605.18L223.739 515.24C223.332 514.678 223.078 514.018 223.002 513.327C222.927 512.636 223.033 511.938 223.309 511.3C223.969 509.504 225.081 507.909 226.539 506.67L297.919 444.09L323.449 437.92C345.889 432.51 369.609 419.58 390.119 401.58L561.119 251.66L561.749 251.3L612.749 222.6L693.569 177.09C694.762 176.385 696.106 175.977 697.489 175.9C698.008 175.86 698.53 175.934 699.017 176.117C699.504 176.3 699.945 176.588 700.309 176.96C700.633 177.368 700.862 177.842 700.979 178.349C701.097 178.855 701.1 179.382 700.989 179.89C700.733 181.251 700.154 182.53 699.299 183.62L643.569 257.76L608.379 304.6L607.939 305.19L436.869 455.19C416.349 473.19 400.419 494.98 392.099 516.51L389.049 524.51L382.819 540.94Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M396.42 660.08L395.79 660.63C392.768 663.319 389.321 665.488 385.59 667.05C382.467 668.419 379.064 669.032 375.66 668.84C367.53 668.18 356.99 669.13 352.79 665.53C347.94 661.42 343.24 657.11 338.79 652.78C336.37 650.44 334.02 648.1 331.79 645.78C329.9 643.78 328.05 641.78 326.23 639.78C322.408 635.43 318.81 630.887 315.45 626.17C310.812 619.679 306.667 612.85 303.05 605.74C303.627 606.048 304.261 606.235 304.913 606.288C305.565 606.342 306.22 606.261 306.84 606.05C308.494 605.57 310.019 604.722 311.3 603.57L382.76 540.92L389.19 524.4L392.24 516.4C400.57 494.87 416.49 473.06 437.01 455.08L608.01 305.16L606.62 306.98L482.84 470.34L482.26 471.34C479.23 475.293 476.648 479.569 474.56 484.09C465.182 504.763 457.867 526.311 452.72 548.42C451.763 552.144 451.712 556.042 452.57 559.79C453.575 561.354 454.315 563.074 454.76 564.88C460.07 585.38 436.25 625.16 396.42 660.08Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M181.569 414.99L181.329 415.2C178.19 417.932 175.519 421.158 173.419 424.75C171.607 427.76 170.518 431.149 170.239 434.65C169.819 442.65 167.379 452.93 170.389 457.57C173.839 462.93 177.489 468.14 181.209 473.13C183.209 475.797 185.209 478.417 187.209 480.99C188.899 483.11 190.649 485.22 192.469 487.3C196.291 491.658 200.329 495.82 204.569 499.77C210.391 505.234 216.619 510.249 223.199 514.771C222.969 514.158 222.866 513.505 222.897 512.852C222.928 512.198 223.092 511.558 223.379 510.97C224.059 509.396 225.084 507.994 226.379 506.87L297.889 444.17L315.229 440.01L323.399 438.01C345.839 432.55 369.539 419.63 390.059 401.65L561.059 251.73L559.059 252.87L380.969 354.14L379.869 354.58C375.556 357.088 370.974 359.101 366.209 360.58C344.482 367.145 322.161 371.556 299.569 373.75C295.748 374.205 291.874 373.739 288.269 372.39C286.853 371.191 285.248 370.235 283.519 369.56C263.939 361.66 221.389 380.07 181.569 414.99Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M291.569 566.99L268.949 586.82C267.89 587.688 266.614 588.251 265.259 588.45C264.771 588.545 264.267 588.502 263.802 588.325C263.337 588.148 262.931 587.845 262.629 587.45L257.289 581.36C256.973 581.026 256.747 580.616 256.635 580.169C256.523 579.723 256.528 579.255 256.649 578.81C256.929 577.585 257.526 576.454 258.379 575.53L258.769 575.19L281.389 555.36L291.569 566.99Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M276.7 550.081L254.08 569.911C253.743 570.216 253.374 570.484 252.98 570.711C252.034 571.237 250.972 571.522 249.89 571.541C249.482 571.55 249.077 571.465 248.707 571.291C248.338 571.118 248.013 570.861 247.76 570.541L242.43 564.451C242.081 564.099 241.838 563.658 241.726 563.175C241.615 562.693 241.641 562.189 241.8 561.721C242.178 560.401 242.907 559.208 243.91 558.271L266.57 538.471L276.7 550.081Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M257.76 581.86L254.45 584.75C253.265 585.825 251.821 586.573 250.26 586.92C249.702 587.081 249.111 587.092 248.547 586.951C247.984 586.811 247.467 586.524 247.05 586.12L241.57 579.83C241.232 579.359 241.022 578.808 240.96 578.231C240.899 577.655 240.988 577.072 241.22 576.54C241.769 575.044 242.7 573.717 243.92 572.69L247.22 569.79L248.28 571C248.541 571.255 248.857 571.447 249.203 571.563C249.549 571.679 249.917 571.716 250.28 571.67C251.272 571.572 252.224 571.228 253.05 570.67C253.44 570.434 253.808 570.163 254.15 569.86L276.71 550.08L281.35 555.38L258.73 575.24L258.34 575.58C257.493 576.391 256.864 577.402 256.51 578.52C256.358 578.923 256.31 579.357 256.37 579.783C256.429 580.209 256.594 580.614 256.85 580.96L257.76 581.86Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M831.57 149.72L398.76 529.16C376.61 548.54 350.81 561.93 327.03 566.39C303.25 570.85 283.39 566.02 271.83 552.96C262.02 541.66 260.14 524.25 266.59 504.53C273.04 484.81 287.29 464.39 306.24 447.72L751 57.8103L752 56.9303L768 46.6503L813.81 25.8503L814.37 25.5903L815.13 25.4803L831.57 20.9303L894.8 3.45031C896.532 2.92567 898.359 2.79246 900.15 3.06031C901.602 3.27147 902.938 3.97797 903.93 5.06031C904.878 6.17954 905.408 7.59344 905.43 9.06031C905.46 10.8695 905.088 12.6629 904.34 14.3103L878.68 74.6903L872.02 90.3903L871.8 91.1203L871.47 91.6503L844.91 134.4L832.63 148.86L831.57 149.72Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
        </g>
        <g className="rocket__body rocket__body--1">
          <path
            d="M379.31 534.949L318.73 588.059C317.64 589.035 316.345 589.752 314.94 590.159C314.415 590.335 313.859 590.402 313.307 590.356C312.755 590.309 312.219 590.151 311.73 589.889C311.558 589.75 311.413 589.58 311.3 589.389L244.46 513.149C244.112 512.674 243.894 512.115 243.829 511.529C243.764 510.943 243.854 510.35 244.09 509.809C244.645 508.286 245.59 506.934 246.83 505.889L307.33 452.889L329.02 447.659C348.02 443.069 368.11 432.119 385.49 416.879L530.49 289.799L531.02 289.509L574.32 265.169L642.76 226.579C643.764 225.986 644.896 225.643 646.06 225.579C646.497 225.526 646.94 225.58 647.352 225.738C647.763 225.895 648.129 226.15 648.419 226.482C648.709 226.813 648.913 227.21 649.015 227.638C649.116 228.067 649.111 228.513 649 228.939C648.78 230.073 648.289 231.136 647.57 232.039L600.36 294.839L570.57 334.589L570.19 335.089L425.18 462.239C407.82 477.479 394.35 495.939 387.28 514.169L384.68 520.899L379.31 534.949Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M390.88 635.89L390.31 636.4C387.746 638.676 384.823 640.514 381.66 641.84C379.009 643.005 376.121 643.529 373.23 643.37C366.36 642.84 357.42 643.61 353.88 640.58C349.77 637.1 345.78 633.45 342 629.78C340 627.78 338 625.78 336.06 623.78C334.55 622.06 332.89 620.43 331.34 618.66C328.04 614.9 325.02 611.03 322.25 607.17C318.309 601.677 314.796 595.89 311.74 589.86C312.229 590.121 312.765 590.279 313.317 590.326C313.869 590.372 314.425 590.305 314.95 590.13C316.355 589.722 317.65 589.005 318.74 588.03L379.31 534.92L384.74 520.8L387.34 514.07C394.4 495.84 407.87 477.38 425.24 462.14L570.24 335.06L569.06 336.6L464.09 475.1L463.6 475.97C461.032 479.328 458.842 482.96 457.07 486.8C449.149 504.327 442.972 522.592 438.63 541.33C437.823 544.487 437.782 547.793 438.51 550.97C439.336 552.283 439.944 553.722 440.31 555.23C444.79 572.56 424.64 606.3 390.88 635.89Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M208.741 428.129L208.541 428.309C205.879 430.613 203.615 433.339 201.841 436.379C200.298 438.92 199.374 441.787 199.141 444.749C198.771 451.529 196.691 460.239 199.221 464.139C202.151 468.669 205.221 473.139 208.391 477.329C210.081 479.599 211.791 481.839 213.511 483.999C215.021 485.719 216.421 487.579 217.961 489.339C221.261 493.099 224.701 496.609 228.171 499.849C233.1 504.475 238.375 508.717 243.951 512.539C243.757 512.02 243.671 511.467 243.698 510.913C243.726 510.359 243.866 509.817 244.111 509.319C244.695 507.978 245.577 506.788 246.691 505.839L307.241 452.749L321.951 449.239L328.951 447.549C347.951 442.959 368.021 432.009 385.391 416.769L530.391 289.689L528.711 290.689L377.841 376.689L376.911 377.049C373.249 379.167 369.361 380.868 365.321 382.119C346.901 387.658 327.985 391.383 308.841 393.239C305.598 393.615 302.312 393.221 299.251 392.089C298.054 391.096 296.703 390.305 295.251 389.749C278.571 382.979 242.501 398.529 208.741 428.129Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M837.3 152.5L404.48 531.94C382.32 551.32 356.48 564.7 332.74 569.16C309 573.62 289.11 568.79 277.55 555.73C267.74 544.43 265.85 527.03 272.3 507.31C278.75 487.59 293.01 467.16 311.96 450.49L756.72 60.5799L757.72 59.6999L773.72 49.4199L819.53 28.6299L820.09 28.3699L820.84 28.2499L837.28 23.7099L900.47 6.23986C902.2 5.72029 904.023 5.58715 905.81 5.84986C907.266 6.0612 908.604 6.76748 909.6 7.84986C910.539 8.97381 911.065 10.3855 911.09 11.8499C911.128 13.6587 910.759 15.4528 910.01 17.0999L884.39 77.4599L877.73 93.1599L877.52 93.8999L877.19 94.4199L850.63 137.17L838.35 151.63L837.3 152.5Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M161.891 599.619L159.741 597.169C143.193 584.872 129.454 569.191 119.441 551.169L117.511 548.969L118.201 548.779L137.201 543.419C145.201 541.199 153.451 536.269 160.361 529.639C161.361 528.709 162.361 527.639 163.441 526.639C167.234 523.053 171.184 519.453 175.291 515.839L594.091 148.689C598.781 145.009 603.281 141.449 607.961 137.769L645.231 111.609L645.651 111.229L646.191 110.939L658.691 103.289L706.691 73.8893C712.981 70.0493 714.521 71.8093 709.891 77.5493L674.461 121.309L665.241 132.709L664.881 133.199L664.451 133.569L633.641 167.089L620.971 179.489L213.321 536.869C205.621 543.689 197.704 550.243 189.571 556.529C189.184 556.903 188.762 557.238 188.311 557.529C184.394 560.523 180.846 563.97 177.741 567.799C174.833 571.335 172.421 575.251 170.571 579.439L162.201 598.889L161.891 599.619Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M269.64 722.56L267.49 720.109C262.701 716.615 258.157 712.796 253.89 708.68C250.83 705.75 247.8 702.589 244.89 699.229C241.98 695.869 239.32 692.619 236.89 689.289C233.326 684.446 230.092 679.368 227.21 674.089L225.28 671.89L225.97 671.69L244.92 666.33C252.92 664.11 261.17 659.179 268.07 652.549L271.16 649.549C274.95 645.959 278.91 642.35 283.01 638.76L701.76 271.63C706.44 267.95 710.94 264.39 715.63 260.71L753.02 234.57L753.44 234.2L753.98 233.91L766.48 226.249L814.48 196.859C820.77 193.019 822.31 194.78 817.68 200.51L782.28 244.24L773.06 255.639L772.7 256.13L772.27 256.5L741.46 290.02C737.19 294.18 733.08 298.179 728.82 302.349L321.09 659.83C313.364 666.616 305.424 673.133 297.27 679.38C296.889 679.755 296.47 680.09 296.02 680.38C292.1 683.373 288.549 686.82 285.44 690.649C282.532 694.198 280.123 698.128 278.28 702.33L269.91 721.779L269.64 722.56Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M243.3 729.83L214.57 697.04L236.86 689.31C239.32 692.643 241.987 695.956 244.86 699.25C247.81 702.62 250.79 705.72 253.86 708.7L243.3 729.83Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M135.57 606.94L106.82 574.14L129.11 566.42C131.57 569.753 134.237 573.067 137.11 576.36C140.06 579.72 143.05 582.83 146.11 585.81L135.57 606.94Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M846.401 150L413.571 529.46C391.401 548.82 365.571 562.19 341.831 566.62C318.091 571.05 298.221 566.2 286.681 553.11C276.911 541.82 275.051 524.43 281.481 504.75C287.911 485.07 302.141 464.67 321.051 448.01L766.731 57.2897L782.731 47.0097L828.731 26.1897L829.301 25.9397L830.051 25.8197L846.401 21.2397L909.571 3.72968C911.303 3.20722 913.13 3.07069 914.921 3.32968C916.376 3.54102 917.715 4.2473 918.711 5.32968C919.65 6.45364 920.176 7.86528 920.201 9.32968C920.231 11.142 919.858 12.9385 919.111 14.5897L893.501 74.9597L886.841 90.6697L886.631 91.3997L886.301 91.9197L859.671 134.63L847.391 149.1C847.071 149.406 846.741 149.706 846.401 150Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M170.981 597.13L168.831 594.68C152.276 582.39 138.536 566.707 128.531 548.68L126.601 546.479L127.281 546.289L146.281 540.859C154.281 538.639 162.531 533.71 169.441 527.08C170.441 526.08 171.491 525.08 172.531 524.08C176.311 520.493 180.261 516.893 184.381 513.279L603.201 146.239L617.071 135.239L654.331 109.079L654.761 108.699L655.291 108.409L667.801 100.759L715.801 71.3595C722.091 67.5295 723.641 69.2795 719.011 75.0195L683.571 118.829L674.381 130.239L674.021 130.729L673.591 131.099L642.771 164.599C638.511 168.769 634.401 172.759 630.131 176.929L222.401 534.38C214.681 541.166 206.737 547.683 198.571 553.93C198.187 554.306 197.765 554.641 197.311 554.93C193.396 557.926 189.848 561.373 186.741 565.2C183.826 568.735 181.413 572.655 179.571 576.849L171.201 596.289L170.981 597.13Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M278.72 720.029L276.57 717.58C271.801 714.112 267.274 710.323 263.02 706.24C259.96 703.32 256.93 700.159 253.97 696.789C251.01 693.419 248.41 690.179 245.97 686.849C242.406 682.006 239.172 676.928 236.29 671.649L234.36 669.45L235.05 669.26L254.05 663.899C262.05 661.679 270.3 656.75 277.2 650.12C278.26 649.19 279.25 648.12 280.29 647.12C284.07 643.53 288.04 639.92 292.14 636.32L710.94 269.109L724.82 258.24L762.08 232.07L762.51 231.7L763.04 231.4L775.54 223.749L823.54 194.359C829.83 190.519 831.37 192.28 826.74 198.01L791.39 241.829L782.17 253.22L781.81 253.71L781.38 254.09L750.57 287.549L737.92 299.88L330.21 657.339C322.45 664.133 314.51 670.653 306.39 676.899C306.005 677.271 305.587 677.605 305.14 677.899C301.226 680.881 297.678 684.315 294.57 688.13C291.662 691.678 289.253 695.608 287.41 699.81L279.03 719.24L278.72 720.029Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M252.41 727.34L223.66 694.54L245.95 686.81C248.41 690.143 251.077 693.456 253.95 696.75C256.9 700.12 259.88 703.22 262.95 706.2L252.41 727.34Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M823.111 163.919L420.241 517.099C399.621 535.099 375.641 547.519 353.521 551.649C331.401 555.779 312.951 551.299 302.181 539.169C293.051 528.659 291.301 512.459 297.301 494.099C303.301 475.739 316.571 456.739 334.211 441.239L749.031 77.5493L763.891 67.9793L806.571 48.6293L807.081 48.3893C807.331 48.3293 807.541 48.3193 807.801 48.2693L823.081 44.0593L881.901 27.7593C883.504 27.2901 885.19 27.1809 886.841 27.4393C888.19 27.6482 889.429 28.3114 890.351 29.3193C891.222 30.367 891.715 31.6773 891.751 33.0393C891.781 34.7101 891.44 36.3669 890.751 37.8893L866.901 94.0893L860.721 108.689L860.511 109.379L860.211 109.859L835.441 149.599L824.001 163.079C823.661 163.429 823.421 163.649 823.111 163.919Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M194.441 580.1L192.441 577.81C177.135 566.295 164.375 551.74 154.961 535.06L153.141 532.98L153.781 532.81L171.431 527.81C178.851 525.74 186.571 521.13 193.001 514.95L195.871 512.2C199.464 508.8 203.131 505.467 206.871 502.2L596.741 160.38L609.661 150.24L644.341 125.89L644.731 125.54L645.241 125.26L656.871 118.15L701.661 90.83C707.501 87.27 708.941 88.91 704.661 94.24L671.661 134.98L663.081 145.58L662.741 146.05L662.351 146.4L633.701 177.66C629.701 181.54 625.921 185.27 621.931 189.15L242.431 521.85C235.237 528.17 227.847 534.24 220.261 540.06C219.906 540.393 219.517 540.688 219.101 540.94C215.456 543.728 212.153 546.937 209.261 550.5C206.549 553.793 204.298 557.44 202.571 561.34L194.771 579.45L194.441 580.1Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M294.72 694.49L292.72 692.21C288.275 688.983 284.071 685.437 280.14 681.6C277.3 678.85 274.47 675.92 271.72 672.79C268.97 669.66 266.55 666.63 264.27 663.54C260.956 659.031 257.924 654.322 255.19 649.44L253.4 647.39L254.03 647.21L271.66 642.21C279.08 640.15 286.8 635.55 293.24 629.37C294.18 628.45 295.15 627.54 296.11 626.61C299.697 623.21 303.347 619.877 307.06 616.61L697.06 274.74L709.97 264.59L744.63 240.24L745.02 239.9L745.51 239.63L757.15 232.5L801.85 205.15C807.71 201.57 809.14 203.21 804.85 208.55L771.85 249.28L763.26 259.89L762.93 260.34L762.54 260.68L733.84 291.89C729.84 295.77 726.04 299.48 722.09 303.36L342.57 636.07C335.384 642.39 327.994 648.457 320.4 654.27C320.035 654.608 319.64 654.912 319.22 655.18C315.575 657.968 312.272 661.177 309.38 664.74C306.671 668.042 304.427 671.699 302.71 675.61L294.93 693.7L294.72 694.49Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M270.23 701.239L243.48 670.729L264.23 663.529C266.52 666.619 269 669.719 271.68 672.779C274.36 675.839 277.26 678.839 280.1 681.589L270.23 701.239Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M169.95 586.9L143.19 556.37L163.94 549.17C166.24 552.28 168.71 555.36 171.4 558.43C174.09 561.5 176.98 564.5 179.81 567.24L169.95 586.9Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M800.84 181.529L430.22 506.449C411.22 523.019 389.22 534.449 368.83 538.269C348.44 542.089 331.5 537.929 321.61 526.759C313.23 517.079 311.61 502.189 317.14 485.319C322.67 468.449 334.85 450.979 351.05 436.719L731.74 102.969L732.57 102.239L746.24 93.4294L785.47 75.6194L785.94 75.3994C786.15 75.3994 786.37 75.3294 786.58 75.3094L800.66 71.4094L854.91 56.2994C856.392 55.8468 857.956 55.7273 859.49 55.9494C860.748 56.1042 861.906 56.7168 862.741 57.6703C863.577 58.6239 864.032 59.8517 864.02 61.1194C864.044 62.6706 863.723 64.2077 863.08 65.6194L841.17 117.239L835.46 130.689C835.417 130.904 835.357 131.115 835.28 131.319V131.319L835.01 131.759L812.22 168.329L801.7 180.719L800.84 181.529Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M222.49 564.41L220.65 562.31C206.479 551.79 194.714 538.368 186.14 522.94L184.49 521.06L185.07 520.9L201.3 516.3C208.767 514.02 215.572 509.972 221.14 504.5C222.03 503.72 222.92 502.78 223.78 501.97C227.01 498.91 230.4 495.81 233.93 492.72L592.57 178.29L604.44 168.94L636.36 146.53L636.73 146.21L637.18 145.97L647.86 139.42L689.02 114.24C694.41 110.95 695.73 112.46 691.77 117.38L661.43 154.85L653.57 164.6L653.28 165.01L652.91 165.34L626.51 194.05L615.69 204.59L266.57 510.65C259.984 516.497 253.21 522.107 246.25 527.48C245.923 527.783 245.568 528.054 245.19 528.29C241.828 530.864 238.783 533.828 236.12 537.12C233.631 540.157 231.571 543.522 230 547.12L222.85 563.77L222.49 564.41Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M314.75 669.65L312.91 667.54C308.82 664.571 304.941 661.324 301.3 657.82C298.69 655.29 296.12 652.62 293.56 649.71C291 646.8 288.8 644.05 286.69 641.19C283.627 637.055 280.857 632.711 278.4 628.19L276.75 626.31L277.33 626.15L293.57 621.6C301.029 619.321 307.828 615.277 313.39 609.81C314.25 608.96 315.17 608.09 316.02 607.27C319.27 604.19 322.66 601.09 326.17 598.02L684.9 283.57C688.9 280.42 692.67 277.45 696.78 274.23L728.69 251.81L729.04 251.5L729.5 251.24L740.19 244.71L781.33 219.48C786.71 216.21 788.03 217.72 784.07 222.61L753.75 260.09L745.86 269.83L745.57 270.24L745.22 270.55L718.8 299.24C715.16 302.81 711.72 306.15 707.98 309.8L358.86 615.89C352.24 621.71 345.443 627.293 338.47 632.64L337.39 633.47C334.039 636.035 331.002 638.985 328.34 642.26C325.848 645.296 323.785 648.661 322.21 652.26L315.03 668.91L314.75 669.65Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M292.22 675.899L267.57 647.809L286.69 641.239C288.804 644.086 291.094 646.923 293.56 649.749C296.09 652.629 298.69 655.329 301.29 657.859L292.22 675.899Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M199.95 570.66L175.34 542.58L194.44 535.96C196.527 538.813 198.813 541.65 201.3 544.47C203.83 547.35 206.43 550.05 209.03 552.58L199.95 570.66Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M275.05 597.43L246.86 622.14C245.528 623.21 243.932 623.901 242.24 624.14C241.635 624.262 241.007 624.211 240.429 623.993C239.851 623.776 239.346 623.401 238.97 622.91L232.32 615.33C231.922 614.917 231.637 614.408 231.494 613.853C231.35 613.298 231.352 612.714 231.5 612.16C231.855 610.631 232.603 609.221 233.67 608.07C233.83 607.91 234 607.77 234.17 607.63L262.36 582.91L275.05 597.43Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M256.571 576.36L228.381 601.07C227.951 601.444 227.49 601.779 227.001 602.07C225.824 602.709 224.51 603.052 223.171 603.07C222.667 603.087 222.167 602.989 221.708 602.782C221.248 602.576 220.842 602.268 220.521 601.88L213.871 594.29C213.434 593.854 213.129 593.304 212.991 592.702C212.853 592.101 212.888 591.473 213.091 590.89C213.558 589.241 214.468 587.75 215.721 586.58L243.911 561.87L256.571 576.36Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M232.88 616L228.76 619.62C227.284 620.952 225.49 621.882 223.55 622.32C222.854 622.512 222.12 622.521 221.419 622.346C220.718 622.171 220.074 621.817 219.55 621.32L212.57 613.44C212.15 612.852 211.89 612.165 211.815 611.447C211.739 610.728 211.852 610.002 212.14 609.34C212.823 607.472 213.981 605.814 215.5 604.53L219.62 600.92L220.94 602.42C221.293 602.818 221.762 603.094 222.28 603.21C222.896 603.346 223.534 603.346 224.15 603.21C224.893 603.06 225.61 602.804 226.28 602.45C227.022 602.062 227.714 601.585 228.34 601.03L256.57 576.33L262.35 582.93L234.16 607.65L233.66 608.08C232.608 609.092 231.824 610.349 231.38 611.74C231.2 612.237 231.145 612.771 231.22 613.294C231.295 613.817 231.498 614.314 231.81 614.74L232.88 616Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M384.47 557.44L308.94 623.65C307.588 624.867 305.977 625.763 304.23 626.27C303.576 626.488 302.885 626.572 302.197 626.517C301.51 626.462 300.841 626.269 300.23 625.95C299.989 625.806 299.773 625.623 299.59 625.41L216.29 530.41C215.855 529.9 215.589 529.267 215.53 528.6C215.464 527.804 215.567 527.004 215.83 526.25C216.14 525.344 216.581 524.488 217.14 523.71C217.751 522.845 218.463 522.057 219.26 521.36L294.78 455.15L321.86 448.58C345.57 442.84 370.57 429.24 392.24 410.24L572.95 251.78L573.63 251.39L627.63 220.99L712.97 172.92C714.232 172.17 715.655 171.735 717.12 171.65C717.671 171.576 718.231 171.64 718.751 171.835C719.271 172.03 719.735 172.351 720.101 172.768C720.467 173.186 720.724 173.688 720.85 174.229C720.975 174.77 720.965 175.334 720.82 175.87C720.55 177.31 719.932 178.663 719.02 179.81L660.18 258.12L622.98 307.63L622.51 308.25L441.7 466.72C420.05 485.72 403.26 508.72 394.48 531.47L391.24 539.87L384.47 557.44Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M398.92 683.349L398.19 683.979C394.993 686.811 391.355 689.101 387.42 690.759C384.115 692.212 380.515 692.866 376.91 692.669C368.38 691.999 357.19 692.949 352.79 689.199C347.67 684.829 342.71 680.289 337.97 675.719C335.45 673.249 332.97 670.719 330.58 668.299C328.58 666.179 326.64 664.099 324.72 661.899C320.675 657.305 316.87 652.506 313.32 647.519C308.41 640.665 304.027 633.447 300.21 625.929C300.819 626.253 301.488 626.449 302.176 626.506C302.864 626.563 303.556 626.479 304.21 626.259C305.957 625.752 307.568 624.856 308.92 623.639L384.45 557.429L391.2 539.829L394.43 531.419C403.21 508.689 419.99 485.669 441.64 466.669L622.41 308.239L620.88 310.239L490.06 482.809L489.47 483.899C486.26 488.075 483.526 492.596 481.32 497.379C471.425 519.235 463.705 542.012 458.27 565.379C457.269 569.312 457.225 573.426 458.14 577.379C459.189 579.033 459.963 580.847 460.43 582.749C466.14 604.369 441.02 646.439 398.92 683.349Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M171.83 424.309L171.57 424.539C168.257 427.428 165.44 430.84 163.23 434.639C161.309 437.815 160.161 441.398 159.88 445.099C159.42 453.539 156.88 464.399 160.02 469.319C163.69 474.969 167.54 480.479 171.45 485.769C173.58 488.599 175.71 491.379 177.84 494.069C179.627 496.309 181.484 498.529 183.41 500.729C187.53 505.396 191.787 509.786 196.18 513.899C202.334 519.666 208.916 524.958 215.87 529.729C215.631 529.084 215.526 528.396 215.56 527.709C215.595 527.021 215.768 526.348 216.07 525.729C216.806 524.063 217.906 522.583 219.29 521.399L294.81 455.189L313.14 450.799L321.89 448.689C345.57 442.949 370.59 429.319 392.26 410.349L573.03 251.859L570.85 253.129L382.57 360.089L381.42 360.539C376.855 363.17 372.012 365.287 366.98 366.849C344.027 373.806 320.442 378.478 296.57 380.799C292.533 381.29 288.437 380.799 284.63 379.369C283.139 378.103 281.45 377.09 279.63 376.369C258.93 367.999 213.93 387.399 171.83 424.309Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M277.57 602.24L249.37 626.91C248.04 627.983 246.443 628.674 244.75 628.91C244.146 629.033 243.519 628.985 242.94 628.769C242.362 628.553 241.856 628.179 241.48 627.69L234.83 620.1C234.431 619.688 234.145 619.179 234.001 618.623C233.857 618.067 233.86 617.484 234.01 616.93C234.365 615.4 235.113 613.99 236.18 612.84L236.68 612.4L264.87 587.68L277.57 602.24Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M259.09 581.13L230.9 605.84C229.457 607.113 227.604 607.823 225.68 607.84C225.177 607.859 224.676 607.762 224.216 607.555C223.756 607.349 223.35 607.039 223.03 606.65L216.37 599.05C215.934 598.614 215.629 598.064 215.489 597.463C215.349 596.862 215.381 596.234 215.58 595.65C216.052 594.002 216.961 592.513 218.21 591.34L246.4 566.63L259.09 581.13Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M235.39 620.79L231.27 624.4C229.799 625.74 228.003 626.671 226.06 627.1C225.364 627.294 224.629 627.304 223.928 627.129C223.226 626.954 222.583 626.599 222.06 626.1L215.11 618.24C214.687 617.653 214.425 616.966 214.35 616.247C214.274 615.528 214.388 614.802 214.68 614.14C215.359 612.272 216.513 610.614 218.03 609.33L222.16 605.72L223.49 607.24C223.82 607.565 224.218 607.811 224.656 607.96C225.095 608.109 225.561 608.157 226.02 608.1C227.262 607.978 228.457 607.556 229.5 606.87C229.986 606.575 230.448 606.241 230.88 605.87L259.07 581.16L264.86 587.76L236.67 612.43L236.17 612.87C235.112 613.879 234.325 615.138 233.88 616.53C233.7 617.028 233.647 617.562 233.723 618.085C233.8 618.609 234.005 619.105 234.32 619.53L235.39 620.79Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M386.99 562.24L311.46 628.45C310.106 629.67 308.492 630.566 306.74 631.07C306.088 631.295 305.395 631.383 304.707 631.328C304.018 631.273 303.349 631.076 302.74 630.75C302.521 630.574 302.335 630.361 302.19 630.12L218.89 535.12C218.449 534.613 218.179 533.979 218.12 533.31C218.05 532.514 218.152 531.712 218.42 530.959C218.726 530.048 219.167 529.188 219.73 528.409C220.342 527.545 221.053 526.756 221.85 526.06L297.28 459.93L324.37 453.37C348.06 447.66 373.09 434.01 394.76 415.02L575.47 256.56L576.15 256.169L630.1 225.779L715.45 177.71C716.708 176.95 718.133 176.511 719.6 176.43C720.152 176.379 720.709 176.453 721.228 176.647C721.748 176.841 722.217 177.149 722.6 177.55C722.941 177.981 723.182 178.483 723.307 179.019C723.431 179.555 723.436 180.112 723.32 180.65C723.041 182.089 722.421 183.44 721.51 184.59L662.67 262.9L625.48 312.419L624.99 313.06L444.23 471.509C422.56 490.509 405.77 513.51 397.01 536.26L393.77 544.669L386.99 562.24Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M401.42 688.12L400.72 688.74C397.522 691.574 393.88 693.864 389.94 695.52C386.639 696.974 383.042 697.629 379.44 697.43C370.9 696.76 359.72 697.72 355.31 693.96C350.21 689.6 345.23 685.05 340.51 680.48C337.964 678.006 335.497 675.53 333.11 673.05C331.18 670.85 329.17 668.86 327.24 666.66C323.13 661.97 319.34 657.16 315.86 652.29C310.949 645.434 306.563 638.217 302.74 630.7C303.35 631.024 304.019 631.22 304.706 631.277C305.394 631.333 306.086 631.249 306.74 631.03C308.494 630.525 310.109 629.626 311.46 628.4L386.98 562.24L393.72 544.65L396.96 536.24C405.72 513.51 422.52 490.48 444.18 471.49L624.92 313.01L623.47 314.91L492.66 487.54L492.06 488.61C488.859 492.795 486.126 497.319 483.91 502.1C474.019 523.952 466.315 546.73 460.91 570.1C459.893 574.029 459.845 578.147 460.77 582.1C461.821 583.756 462.591 585.573 463.05 587.48C468.65 609.14 443.43 651.29 401.42 688.12Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M174.34 429.089L174.08 429.319C170.763 432.195 167.942 435.597 165.73 439.389C163.806 442.559 162.646 446.134 162.34 449.829C161.89 458.269 159.34 469.129 162.5 474.059C166.16 479.689 170.02 485.219 173.92 490.499C176.047 493.353 178.18 496.123 180.32 498.809C182.25 501.009 183.96 503.259 185.88 505.449C189.907 510.06 194.17 514.459 198.65 518.629C204.802 524.396 211.384 529.684 218.34 534.449C218.098 533.805 217.99 533.118 218.023 532.43C218.056 531.742 218.228 531.068 218.53 530.449C219.266 528.777 220.37 527.294 221.76 526.109L297.28 459.899L315.57 455.509L324.331 453.399C348.011 447.689 373.041 434.049 394.701 415.059L575.48 256.609L573.391 257.789L385.081 364.859L383.94 365.319C379.373 367.95 374.526 370.063 369.491 371.619C346.553 378.564 322.985 383.227 299.13 385.539C295.075 386.049 290.957 385.566 287.13 384.129C285.638 382.864 283.949 381.851 282.13 381.129C261.44 372.779 216.35 392.239 174.34 429.089Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M284.951 581.59L258.301 604.95C257.033 605.981 255.509 606.649 253.891 606.88C253.316 606.992 252.721 606.941 252.174 606.73C251.628 606.52 251.151 606.159 250.801 605.69L244.571 598.58C244.18 598.197 243.898 597.718 243.752 597.191C243.606 596.664 243.602 596.108 243.741 595.58C244.071 594.11 244.789 592.756 245.821 591.66C245.97 591.515 246.127 591.378 246.291 591.25L272.931 567.89L284.951 581.59Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M267.461 561.65L240.821 585.01C240.41 585.362 239.968 585.677 239.501 585.95C238.39 586.583 237.139 586.926 235.861 586.95C235.383 586.966 234.908 586.871 234.473 586.673C234.037 586.476 233.653 586.18 233.351 585.81L227.051 578.62C226.63 578.212 226.333 577.693 226.195 577.123C226.056 576.553 226.083 575.955 226.271 575.4C226.706 573.823 227.567 572.398 228.761 571.28L255.411 547.92L267.461 561.65Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M245.031 599.18L241.131 602.6C239.738 603.865 238.038 604.744 236.201 605.15C235.544 605.342 234.848 605.358 234.183 605.196C233.518 605.035 232.906 604.701 232.411 604.23L225.851 596.75C225.448 596.196 225.197 595.547 225.123 594.866C225.05 594.185 225.156 593.497 225.431 592.87C226.077 591.102 227.173 589.534 228.611 588.32L232.511 584.9L233.761 586.33C234.069 586.644 234.447 586.881 234.864 587.024C235.28 587.166 235.724 587.209 236.161 587.15C237.34 587.039 238.474 586.637 239.461 585.98C239.921 585.683 240.356 585.349 240.761 584.98L267.441 561.59L272.921 567.84L246.281 591.24C246.117 591.368 245.96 591.505 245.811 591.65C244.804 592.599 244.057 593.79 243.641 595.11C243.459 595.583 243.401 596.095 243.471 596.596C243.541 597.098 243.737 597.574 244.041 597.98L245.031 599.18Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M388.57 543.72L317.1 606.38C315.814 607.53 314.287 608.377 312.63 608.86C312.013 609.07 311.358 609.152 310.708 609.098C310.058 609.045 309.425 608.858 308.85 608.55C308.641 608.384 308.464 608.181 308.33 607.95L229.5 518.03C229.092 517.467 228.838 516.808 228.763 516.117C228.687 515.426 228.793 514.727 229.07 514.09C229.726 512.292 230.839 510.696 232.3 509.46L303.68 446.88L329.21 440.71C351.65 435.3 375.36 422.37 395.88 404.38L566.88 254.45L567.51 254.09L618.51 225.39L699.3 179.87C700.492 179.161 701.836 178.75 703.22 178.67C703.742 178.6 704.272 178.661 704.765 178.846C705.257 179.031 705.696 179.335 706.043 179.731C706.389 180.127 706.633 180.603 706.751 181.115C706.869 181.628 706.858 182.162 706.72 182.67C706.462 184.032 705.879 185.311 705.02 186.4L649.32 260.54L614.13 307.39L613.69 307.97L442.62 457.97C422.09 475.97 406.17 497.76 397.85 519.29L394.8 527.29L388.57 543.72Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M402.141 662.85L401.5 663.41C398.477 666.09 395.035 668.255 391.31 669.82C388.186 671.195 384.779 671.808 381.37 671.61C373.24 670.96 362.71 671.9 358.51 668.31C353.65 664.19 348.95 659.88 344.51 655.55C342.1 653.21 339.75 650.87 337.51 648.55C335.62 646.55 333.771 644.55 331.951 642.55C328.123 638.202 324.521 633.659 321.16 628.94C316.52 622.453 312.379 615.623 308.771 608.51C309.347 608.818 309.981 609.005 310.633 609.058C311.285 609.112 311.941 609.031 312.56 608.82C314.218 608.348 315.745 607.499 317.021 606.34L388.47 543.64L394.9 527.12L397.951 519.12C406.271 497.59 422.2 475.78 442.72 457.8L613.721 307.87L612.331 309.7L488.57 473.12L488 474.12C484.962 478.069 482.376 482.345 480.29 486.87C470.912 507.559 463.59 529.119 458.43 551.24C457.482 554.965 457.434 558.863 458.29 562.61C459.29 564.178 460.03 565.896 460.48 567.7C465.79 588.15 441.971 627.93 402.141 662.85Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M187.271 417.76L187.031 417.97C183.889 420.703 181.217 423.932 179.121 427.53C177.297 430.534 176.208 433.926 175.941 437.43C175.511 445.43 173.071 455.7 176.091 460.34C179.541 465.7 183.191 470.91 186.911 475.9C188.911 478.6 190.911 481.223 192.911 483.77C194.601 485.88 196.351 487.99 198.171 490.07C201.985 494.427 206.017 498.589 210.251 502.54C216.078 508.001 222.309 513.016 228.891 517.54C228.66 516.929 228.558 516.278 228.589 515.626C228.619 514.975 228.784 514.336 229.071 513.75C229.743 512.169 230.77 510.763 232.071 509.64L303.581 446.94L320.921 442.78L329.091 440.78C351.531 435.32 375.221 422.4 395.741 404.42L566.741 254.5L564.741 255.64L386.681 356.92L385.591 357.35C381.277 359.856 376.695 361.868 371.931 363.35C350.211 369.927 327.897 374.351 305.311 376.56C301.486 377.015 297.608 376.548 294.001 375.2C292.588 374.004 290.986 373.051 289.261 372.38C269.661 364.43 227.101 382.84 187.271 417.76Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M297.24 569.76L274.62 589.59C273.557 590.458 272.278 591.021 270.92 591.22C270.434 591.313 269.931 591.268 269.468 591.092C269.006 590.915 268.601 590.613 268.3 590.22L262.95 584.12C262.635 583.785 262.411 583.375 262.299 582.928C262.187 582.482 262.191 582.014 262.31 581.57C262.598 580.345 263.198 579.215 264.05 578.29L264.44 577.95L287.06 558.12L297.24 569.76Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M282.421 552.859L259.801 572.689C259.457 572.988 259.086 573.253 258.691 573.479C257.752 574.015 256.691 574.301 255.611 574.309C255.202 574.322 254.796 574.238 254.426 574.064C254.056 573.89 253.732 573.632 253.481 573.309L248.141 567.239C247.795 566.886 247.554 566.444 247.444 565.962C247.335 565.48 247.361 564.977 247.521 564.509C247.894 563.19 248.62 561.997 249.621 561.059L272.241 541.239L282.421 552.859Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M263.471 584.63L260.171 587.53C258.985 588.601 257.541 589.345 255.981 589.69C255.421 589.849 254.83 589.86 254.266 589.721C253.701 589.583 253.183 589.3 252.761 588.9L247.251 582.6C246.91 582.132 246.699 581.582 246.638 581.007C246.576 580.431 246.667 579.85 246.901 579.32C247.45 577.823 248.376 576.493 249.591 575.46L252.901 572.57L253.961 573.78C254.223 574.034 254.538 574.226 254.884 574.342C255.231 574.458 255.598 574.495 255.961 574.45C256.954 574.346 257.908 574.003 258.741 573.45C259.128 573.214 259.496 572.946 259.841 572.65L282.401 552.87L287.041 558.16L264.421 577.99L264.031 578.33C263.18 579.14 262.548 580.151 262.191 581.27C262.042 581.674 261.997 582.108 262.058 582.534C262.119 582.96 262.285 583.364 262.541 583.71L263.471 584.63Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M385.02 537.719L324.45 590.829C323.36 591.804 322.065 592.522 320.66 592.929C320.135 593.105 319.579 593.172 319.028 593.125C318.476 593.079 317.939 592.92 317.45 592.659C317.278 592.521 317.13 592.355 317.01 592.169L250.17 515.919C249.823 515.445 249.606 514.887 249.543 514.303C249.48 513.718 249.572 513.127 249.81 512.589C250.36 511.063 251.306 509.71 252.55 508.669L313.05 455.669L334.74 450.429C353.74 445.839 373.82 434.889 391.2 419.649L536.2 292.569L536.74 292.279L580.04 267.939L648.48 229.349C649.479 228.752 650.608 228.408 651.77 228.349C652.211 228.283 652.662 228.327 653.082 228.478C653.501 228.629 653.877 228.883 654.174 229.215C654.472 229.547 654.682 229.948 654.786 230.382C654.89 230.815 654.885 231.268 654.77 231.699C654.557 232.845 654.07 233.922 653.35 234.839L606.14 297.649L576.34 337.389L575.96 337.889L430.96 464.989C413.59 480.229 400.12 498.689 393.06 516.919L390.46 523.659L385.02 537.719Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M396.571 638.67L395.991 639.17C393.429 641.451 390.506 643.29 387.341 644.61C384.695 645.78 381.809 646.304 378.921 646.14C372.051 645.61 363.101 646.38 359.561 643.36C355.461 639.87 351.471 636.22 347.691 632.55C345.691 630.55 343.691 628.55 341.741 626.55C340.241 624.83 338.571 623.2 337.031 621.44C333.731 617.67 330.701 613.81 327.941 609.94C324 604.447 320.486 598.66 317.431 592.63C317.918 592.895 318.455 593.056 319.007 593.103C319.56 593.149 320.116 593.08 320.641 592.9C322.043 592.495 323.336 591.777 324.421 590.8L385.001 537.69L390.431 523.58L393.031 516.84C400.091 498.61 413.561 480.15 430.921 464.91L575.921 337.83L574.741 339.37L469.801 477.88L469.321 478.75C466.744 482.103 464.554 485.736 462.791 489.58C454.865 507.102 448.688 525.364 444.351 544.1C443.543 547.261 443.502 550.57 444.231 553.75C445.053 555.066 445.66 556.504 446.031 558.01C450.511 575.33 430.361 609.07 396.571 638.67Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M214.461 430.899L214.251 431.079C211.595 433.386 209.335 436.112 207.561 439.149C206.017 441.69 205.089 444.556 204.851 447.519C204.491 454.299 202.411 463.009 204.941 466.909C207.861 471.449 210.941 475.859 214.111 480.099C215.801 482.369 217.501 484.619 219.231 486.769C220.741 488.499 222.141 490.349 223.681 492.109C226.981 495.869 230.411 499.379 233.881 502.629C238.815 507.25 244.094 511.489 249.671 515.309C249.477 514.79 249.391 514.237 249.418 513.683C249.446 513.129 249.586 512.587 249.831 512.089C250.415 510.754 251.294 509.568 252.401 508.619L312.961 455.529L327.671 451.999L334.671 450.299C353.671 445.709 373.741 434.759 391.111 419.519L536.111 292.439L534.431 293.439L383.571 379.429L382.651 379.799C378.984 381.912 375.093 383.609 371.051 384.859C352.636 390.428 333.718 394.176 314.571 396.049C311.331 396.424 308.049 396.03 304.991 394.899C303.793 393.908 302.442 393.118 300.991 392.559C284.301 385.749 248.221 401.309 214.461 430.899Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
        </g>
        <g className="rocket__fire rocket__fire--2">
          <path
            d="M153.64 776.58L152.66 778.57L150.73 780.11L127.2 794.33L110.84 804.22L125.75 787.61L134.1 784.3L153.64 776.58Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M152.66 778.569L152.41 779.099L150.729 780.109L152.66 778.569Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M159.04 765.59L153.64 776.58L134.1 784.3L125.75 787.61L135.88 776.3L144.82 766.34L147.04 765.49L151.68 763.72L154.87 762.51L143.74 774.4L159.04 765.59Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M175.45 732.2L168.89 745.55L151.45 759.08L151.07 759.38L151.84 758.52L175.45 732.2Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M162.1 773.229L160.89 773.969L161.26 773.56L162.1 773.229Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M209.5 714.03L186.37 731.98L184.82 730.52L180.53 726.53L200.44 704.35L200.98 703.74L202.88 706.03L205.17 708.8L209.5 714.03Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M222.4 736.77L205.47 747.01L203.68 743.9L222.4 736.77Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M150.73 780.109L120.35 804.289L127.2 794.329L150.73 780.109Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M193.75 738.87L180.91 752.59L180.07 753.49L168.04 766.32L156.67 775.38L161.26 773.56L160.89 773.97L148.34 787.36L152.41 779.1L152.66 778.57L153.64 776.58L159.04 765.59L161.87 759.85L165.28 752.91L166.82 749.74L168.89 745.55L175.45 732.2L178.96 725.06L180.53 726.53L184.82 730.52L186.37 731.98L188.76 734.21L193.75 738.87Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M178.96 725.06L175.45 732.2L168.89 745.549L151.45 759.08L151.84 758.52L176.46 722.729L178.96 725.06Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M135.88 776.3L125.75 787.61L117.68 790.8L135.88 776.3Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M233.3 743.03L159.18 778.8L167.55 771.07L167.58 771.05L209.74 754.39L205.47 747.01L203.68 743.9L222.4 736.77L226.36 734.38L222.13 729.27L223.43 728.51L227.67 734.76L233.3 743.03Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M168.89 745.55L166.82 749.74L165.28 752.91L159.75 757.31L154.87 762.51L151.68 763.72L147.04 765.49L144.82 766.34L139.47 768.38L151.07 759.38L151.45 759.08L168.89 745.55Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M231.97 733.12L227.67 734.76L222.4 736.77L226.36 734.38L222.13 729.27L223.43 728.51L227.93 725.93L231.97 733.12Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M209.5 714.03L186.37 731.98L184.82 730.52L180.53 726.53L178.96 725.06L192 698.51L200.44 704.35L202.88 706.03L205.84 708.08L205.17 708.8L209.5 714.03Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M209.5 714.03L186.37 731.98L184.82 730.52L205.17 708.8L209.5 714.03Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M216.52 708.59L211.83 712.23L205.84 708.08L211.41 702.13L216.52 708.59Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M209.74 754.39L167.58 771.05L167.55 771.07L162.1 773.23L161.26 773.56L156.67 775.38L168.04 766.32L189.32 749.37L194.88 744.95L197.47 743.47L201.93 740.89L203.68 743.9L205.47 747.01L209.74 754.39Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M226.36 734.379L222.4 736.769L203.68 743.899L201.93 740.889L201.35 739.879L198.09 734.239L195.13 729.129L188.76 734.209L186.37 731.979L209.5 714.029L213.02 718.289L218.11 724.419L222.13 729.269L226.36 734.379Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M209.5 714.03L186.37 731.98L184.82 730.52L180.53 726.53L178.96 725.06L192 698.51L200.44 704.35L202.88 706.03L205.84 708.08L205.17 708.8L209.5 714.03Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M165.28 752.91L161.87 759.85L159.04 765.59L143.74 774.4L154.87 762.51L159.75 757.31L165.28 752.91Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M201.93 740.89L197.47 743.47L194.88 744.95L189.32 749.37L168.04 766.32L180.07 753.49L180.91 752.59L193.75 738.87L188.76 734.21L195.13 729.13L198.09 734.24L201.35 739.88L201.93 740.89Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M229.53 724.999L227.93 725.929L223.43 728.509L222.13 729.269L218.11 724.419L213.02 718.289L216 715.109L211.83 712.229L216.52 708.589L217.68 707.689L222.24 715.799L229.53 724.999Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M216 715.11L213.02 718.29L209.5 714.03L205.17 708.8L205.84 708.08L211.83 712.23L216 715.11Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M209.5 714.03L186.37 731.98L184.82 730.52L205.17 708.8L209.5 714.03Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M216.52 708.59L211.83 712.23L205.84 708.08L211.41 702.13L216.52 708.59Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M157.67 779.59L156.69 781.58L154.76 783.12L131.24 797.35L114.88 807.24L129.78 790.62L138.13 787.32L157.67 779.59Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M156.69 781.58L156.44 782.11L154.76 783.12L156.69 781.58Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M163.07 768.599L157.67 779.589L138.13 787.319L129.78 790.619L139.92 779.309L148.85 769.359L151.07 768.509L155.72 766.739L158.9 765.529L147.77 777.409L163.07 768.599Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M179.48 735.21L172.92 748.57L155.48 762.09L155.1 762.39L155.88 761.53L179.48 735.21Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M166.13 776.24L164.92 776.98L165.3 776.57L166.13 776.24Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M213.54 717.04L190.41 735L188.85 733.54L184.57 729.54L204.47 707.36L205.01 706.75L206.91 709.04L209.2 711.81L213.54 717.04Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M226.43 739.79L209.5 750.02L207.71 746.92L226.43 739.79Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M154.76 783.12L124.38 807.3L131.24 797.35L154.76 783.12Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M197.78 741.879L184.94 755.599L184.1 756.499L172.07 769.329L160.7 778.389L165.3 776.569L164.92 776.979L152.37 790.379L156.44 782.109L156.69 781.579L157.67 779.589L163.07 768.599L165.9 762.859L169.31 755.919L170.86 752.759L172.92 748.569L179.48 735.209L182.99 728.069L184.57 729.539L188.85 733.539L190.41 734.999L192.79 737.219L197.78 741.879Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M182.99 728.07L179.479 735.21L172.919 748.57L155.479 762.09L155.88 761.53L180.49 725.74L182.99 728.07Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M139.92 779.31L129.78 790.62L121.72 793.81L139.92 779.31Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M237.34 746.049L163.22 781.809L171.58 774.079L171.61 774.059L213.78 757.399L209.5 750.019L207.71 746.919L226.43 739.789L230.39 737.389L226.16 732.279L227.46 731.529L231.71 737.769L237.34 746.049Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M172.92 748.569L170.86 752.759L169.31 755.919L163.78 760.319L158.9 765.529L155.72 766.739L151.07 768.509L148.85 769.359L143.5 771.399L155.1 762.389L155.48 762.089L172.92 748.569Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M236 736.129L231.71 737.769L226.43 739.789L230.39 737.389L226.16 732.279L227.46 731.529L231.96 728.939L236 736.129Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M213.54 717.039L190.41 734.999L188.85 733.539L184.57 729.539L182.99 728.069L196.04 701.529L204.47 707.359L206.91 709.039L209.87 711.089L209.2 711.809L213.54 717.039Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M213.54 717.04L190.41 735L188.85 733.54L209.2 711.81L213.54 717.04Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M220.55 711.599L215.86 715.239L209.87 711.089L215.44 705.149L220.55 711.599Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M213.78 757.4L171.61 774.06L171.58 774.08L166.13 776.24L165.3 776.57L160.7 778.39L172.07 769.33L193.36 752.39L198.92 747.97L201.5 746.48L205.97 743.91L207.71 746.92L209.5 750.02L213.78 757.4Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M230.39 737.39L226.43 739.79L207.71 746.92L205.97 743.91L205.38 742.9V742.89L202.12 737.25L199.16 732.14L192.79 737.22L190.41 735L213.54 717.04L217.06 721.3L222.14 727.43L226.16 732.28L230.39 737.39Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M213.54 717.039L190.41 734.999L188.85 733.539L184.57 729.539L182.99 728.069L196.04 701.529L204.47 707.359L206.91 709.039L209.87 711.089L209.2 711.809L213.54 717.039Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M169.31 755.92L165.9 762.86L163.07 768.6L147.77 777.41L158.9 765.53L163.78 760.32L169.31 755.92Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M205.97 743.91L201.5 746.48L198.92 747.97L193.36 752.39L172.07 769.33L184.1 756.5L184.94 755.6L197.78 741.88L192.79 737.22L199.16 732.14L202.12 737.25L205.38 742.89V742.9L205.97 743.91Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M233.559 728.02L231.959 728.94L227.459 731.53L226.159 732.28L222.139 727.43L217.059 721.3L220.039 718.12L215.859 715.24L220.549 711.6L221.709 710.7L226.269 718.81L233.559 728.02Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M220.04 718.12L217.06 721.3L213.54 717.04L209.2 711.81L209.87 711.09L215.86 715.24L220.04 718.12Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M213.54 717.04L190.41 735L188.85 733.54L209.2 711.81L213.54 717.04Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M220.55 711.599L215.86 715.239L209.87 711.089L215.44 705.149L220.55 711.599Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
        </g>
        <g className="rocket__fire rocket__fire--1">
          <path
            d="M49.05 655.41L48.07 657.4L46.14 658.94L22.61 673.17L6.25 683.06L21.16 666.44L29.51 663.14L49.05 655.41Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M48.0696 657.399L47.8097 657.929L46.1396 658.939L48.0696 657.399Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M54.4502 644.42L49.0502 655.41L29.5102 663.14L21.1602 666.44L31.2902 655.14L40.2302 645.18L42.4502 644.33L47.0902 642.56L50.2802 641.35L39.1502 653.23L54.4502 644.42Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M70.8595 611.03L64.2995 624.39L46.8595 637.91L46.4795 638.21L47.2495 637.35L70.8595 611.03Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M57.5098 652.06L56.2998 652.8L56.6698 652.39L57.5098 652.06Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M104.909 592.86L81.7794 610.82L80.2295 609.36L75.9395 605.36L95.8494 583.18L96.3895 582.57L98.2895 584.87L100.579 587.63L104.909 592.86Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M117.81 615.609L100.88 625.839L99.0898 622.739L117.81 615.609Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M46.1398 658.939L15.7598 683.129L22.6098 673.169L46.1398 658.939Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M89.16 617.7L76.31 631.42L75.47 632.32L63.45 645.15L52.07 654.21L56.67 652.39L56.3 652.8L43.75 666.2L47.81 657.93L48.07 657.4L49.05 655.41L54.45 644.42L57.27 638.68L60.69 631.74L62.23 628.58L64.3 624.39L70.86 611.03L74.36 603.89L75.94 605.36L80.23 609.36L81.78 610.82L84.17 613.04L89.16 617.7Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M74.3594 603.89L70.8594 611.03L64.2994 624.39L46.8594 637.91L47.2494 637.35L71.8694 601.57L74.3594 603.89Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M31.2898 655.14L21.1598 666.44L13.0898 669.63L31.2898 655.14Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M128.71 621.87L54.5898 657.63L62.9598 649.91L62.9898 649.88L105.15 633.22L100.88 625.84L99.0898 622.74L117.81 615.61L121.77 613.21L117.54 608.11L118.84 607.35L123.08 613.6L128.71 621.87Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M64.2999 624.39L62.2299 628.58L60.6899 631.74L55.1599 636.14L50.2799 641.35L47.0899 642.56L42.4499 644.33L40.2299 645.18L34.8799 647.22L46.4799 638.21L46.8599 637.91L64.2999 624.39Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M127.38 611.96L123.08 613.6L117.81 615.61L121.77 613.21L117.54 608.11L118.84 607.35L123.34 604.76L127.38 611.96Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M104.909 592.86L81.7794 610.82L80.2294 609.36L75.9394 605.36L74.3594 603.89L87.4094 577.35L95.8494 583.18L98.2894 584.87L101.249 586.92L100.579 587.63L104.909 592.86Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M104.909 592.86L81.7795 610.82L80.2295 609.36L100.579 587.63L104.909 592.86Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M111.93 587.42L107.24 591.06L101.25 586.92L106.81 580.97L111.93 587.42Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M105.149 633.219L62.9893 649.88L62.9593 649.909L57.5093 652.06L56.6693 652.39L52.0693 654.21L63.4493 645.15L84.7293 628.21L90.2893 623.789L92.8693 622.299L97.3393 619.729L99.0893 622.74L100.879 625.84L105.149 633.219Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M121.769 613.209L117.809 615.609L99.0893 622.739L97.3393 619.729L96.7593 618.719V618.709L93.4993 613.069L90.5393 607.969L84.1693 613.039L81.7793 610.819L104.909 592.859L108.429 597.119L113.519 603.249L117.539 608.109L121.769 613.209Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M104.909 592.86L81.7794 610.82L80.2294 609.36L75.9394 605.36L74.3594 603.89L87.4094 577.35L95.8494 583.18L98.2894 584.87L101.249 586.92L100.579 587.63L104.909 592.86Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M60.6894 631.74L57.2694 638.68L54.4494 644.42L39.1494 653.23L50.2794 641.35L55.1594 636.14L60.6894 631.74Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M97.3402 619.73L92.8702 622.3L90.2902 623.79L84.7302 628.21L63.4502 645.15L75.4702 632.32L76.3102 631.42L89.1602 617.7L84.1702 613.04L90.5402 607.97L93.5002 613.07L96.7602 618.71V618.72L97.3402 619.73Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M124.939 603.84L123.339 604.76L118.839 607.349L117.539 608.109L113.519 603.25L108.429 597.12L111.409 593.94L107.239 591.06L111.929 587.419L113.079 586.52L117.639 594.63L124.939 603.84Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M111.41 593.94L108.43 597.12L104.91 592.86L100.58 587.63L101.25 586.92L107.24 591.06L111.41 593.94Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M104.909 592.86L81.7795 610.82L80.2295 609.36L100.579 587.63L104.909 592.86Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M111.93 587.42L107.24 591.06L101.25 586.92L106.81 580.97L111.93 587.42Z"
            stroke="#05F2C7"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M45.0197 652.399L44.0397 654.389L42.0997 655.929L18.5797 670.149L2.21973 680.039L17.1297 663.429L25.4697 660.129L45.0197 652.399Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M44.0396 654.39L43.7796 654.92L42.0996 655.93L44.0396 654.39Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M50.4099 641.41L45.0199 652.4L25.4699 660.13L17.1299 663.43L27.2599 652.12L36.1999 642.16L38.4099 641.32L43.0599 639.54L46.2499 638.34L35.1199 650.22L50.4099 641.41Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M66.8295 608.02L60.2695 621.37L42.8295 634.9L42.4395 635.2L43.2195 634.34L66.8295 608.02Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M53.4698 649.05L52.2598 649.79L52.6398 649.38L53.4698 649.05Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M100.88 589.85L77.7502 607.8L76.1902 606.35L71.9102 602.35L91.8102 580.17L92.3502 579.56L94.2602 581.85L96.5402 584.62L100.88 589.85Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M113.77 612.59L96.8396 622.83L95.0596 619.73L113.77 612.59Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M42.0997 655.93L11.7197 680.11L18.5797 670.15L42.0997 655.93Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M85.1297 614.69L72.2797 628.41L71.4397 629.31L59.4197 642.14L48.0397 651.2L52.6397 649.38L52.2597 649.79L39.7197 663.18L43.7797 654.92L44.0397 654.39L45.0197 652.4L50.4097 641.41L53.2397 635.67L56.6497 628.73L58.1997 625.57L60.2697 621.37L66.8297 608.02L70.3297 600.88L71.9097 602.35L76.1897 606.35L77.7497 607.8L80.1397 610.03L85.1297 614.69Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M70.3301 600.88L66.8301 608.02L60.2701 621.37L42.8301 634.9L43.2201 634.34L67.8401 598.55L70.3301 600.88Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M27.2596 652.12L17.1296 663.43L9.05957 666.62L27.2596 652.12Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M124.68 618.86L50.5596 654.62L58.9296 646.89L58.9596 646.87L101.12 630.21L96.8396 622.83L95.0596 619.73L113.77 612.59L117.73 610.2L113.5 605.09L114.81 604.34L119.05 610.58L124.68 618.86Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M60.2698 621.37L58.1998 625.57L56.6498 628.73L51.1198 633.13L46.2498 638.34L43.0598 639.54L38.4098 641.32L36.1998 642.16L30.8398 644.2L42.4398 635.2L42.8298 634.9L60.2698 621.37Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M123.35 608.94L119.05 610.58L113.77 612.59L117.73 610.2L113.5 605.09L114.81 604.34L119.3 601.75L123.35 608.94Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M100.88 589.85L77.7501 607.8L76.1901 606.35L71.9101 602.35L70.3301 600.88L83.3801 574.34L91.8101 580.17L94.2601 581.85L97.2101 583.9L96.5401 584.62L100.88 589.85Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M100.879 589.85L77.7495 607.8L76.1895 606.35L96.5395 584.62L100.879 589.85Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M107.9 584.41L103.21 588.05L97.21 583.9L102.78 577.96L107.9 584.41Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M101.12 630.21L58.96 646.87L58.93 646.89L53.47 649.05L52.64 649.38L48.04 651.2L59.42 642.14L80.7 625.2L86.26 620.77L88.84 619.29L93.31 616.72L95.06 619.73L96.84 622.83L101.12 630.21Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M117.73 610.2L113.77 612.59L95.06 619.73L93.31 616.72L92.72 615.71V615.7L89.47 610.06L86.51 604.95L80.14 610.03L77.75 607.8L100.88 589.85L104.4 594.11L109.48 600.24L113.5 605.09L117.73 610.2Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M100.88 589.85L77.7501 607.8L76.1901 606.35L71.9101 602.35L70.3301 600.88L83.3801 574.34L91.8101 580.17L94.2601 581.85L97.2101 583.9L96.5401 584.62L100.88 589.85Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M56.6501 628.729L53.2401 635.669L50.4101 641.409L35.1201 650.219L46.2501 638.34L51.1201 633.13L56.6501 628.729Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M93.3099 616.72L88.8399 619.29L86.2599 620.77L80.6999 625.2L59.4199 642.14L71.4399 629.31L72.2799 628.41L85.1299 614.69L80.1399 610.03L86.5099 604.95L89.4699 610.06L92.7199 615.7V615.71L93.3099 616.72Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M120.91 600.83L119.3 601.75L114.81 604.34L113.5 605.09L109.48 600.24L104.4 594.11L107.38 590.93L103.21 588.05L107.9 584.41L109.05 583.51L113.61 591.62L120.91 600.83Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M107.38 590.929L104.4 594.109L100.88 589.849L96.54 584.619L97.21 583.899L103.21 588.049L107.38 590.929Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M100.879 589.85L77.7495 607.8L76.1895 606.35L96.5395 584.62L100.879 589.85Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
          <path
            d="M107.9 584.41L103.21 588.05L97.21 583.9L102.78 577.96L107.9 584.41Z"
            stroke="#1DA88F"
            strokeWidth="0.85"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="920.61" height="809.42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
