import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Carousel from 'react-elastic-carousel';

import './Portfolio.scss';
import { PortfolioItem } from './PortfolioItem/PortfolioItem';
import { CTA } from '../../UI/CTA/CTA';
import arrows from '../../assets/img/arrowPortfolio.svg';

import { pageVariants } from '../../constants/framer-motion-variants';
import Square from '../../animations/Square/Square';
import { REVIEW_CONTENT, ReviewEnum } from '../../content/review.content';
import { HelmetWrapper } from '../common/HelmetWrapper/HelmetWrapper';
import { CONTACT_PATH } from '../Contact/Contact';

const generateReviews = (reviewOrder: ReviewEnum[]) => {
  return reviewOrder.map((reviewKey, i) => {
    const review = REVIEW_CONTENT.reviews[reviewKey];

    return (
      <PortfolioItem
        key={i}
        heading={review.heading}
        websiteImage={review.websiteImage}
        url={review.url}
        customer={review.customer}
        customerPhoto={review.customerPhoto}
      >
        {review.feedback.long}
      </PortfolioItem>
    );
  });
};

export const PORTFOLIO_PATH = 'portfolio';

const Portfolio = React.memo(() => {
  const [iframeBlocked, setIframeBlocked] = useState(false);

  const reviewOrder: ReviewEnum[] = [
    ReviewEnum['fantasymuzeum.cz'],
    ReviewEnum['bydevil.cz'],
    ReviewEnum['tatjanahorka.cz'],
    ReviewEnum['keramika-rosice.cz'],
    ReviewEnum['adluxholo.cz'],
    ReviewEnum['gttournament.cz'],
  ];

  const iframeBlocker = (e: MouseEvent) => {
    if (!(e.target instanceof Element)) return;
    if (e.target.classList.contains('PortfolioItem__detail__heading__link')) return;
    if (!e.target.closest('.rec-carousel')) return;
    setIframeBlocked(true);
  };
  const iframeUnblocker = () => {
    setIframeBlocked(false);
  };

  useEffect(() => {
    window.addEventListener('mousedown', iframeBlocker);
    window.addEventListener('mouseup', iframeUnblocker);

    return () => {
      window.removeEventListener('mousedown', iframeBlocker);
      window.removeEventListener('mouseup', iframeUnblocker);
    };
  });

  let iframeBlockerClass = 'portfolio__iframe-blocker';
  if (iframeBlocked) {
    iframeBlockerClass += ' portfolio__iframe-blocker--active';
  }

  return (
    <motion.div
      className="portfolio"
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <HelmetWrapper
        title="Inspirativní portfolio webovek"
        description={`
          Don't believe us, just watch! Opravdu děláme unikátní weby - každej projekt má svůj vibe,
          pojďte se kouknout :P Cool animace, interaktivita a důraz na UX a cílovku.
        `}
        path={PORTFOLIO_PATH}
      />
      <div className={iframeBlockerClass}></div>
      <Square
        className={'portfolio__square portfolio__square--1'}
        config={{ cols: 6, rows: 6, estimatedDuration: 5 }}
      ></Square>
      <Square
        className={'portfolio__square portfolio__square--2'}
        config={{ cols: 6, rows: 6, estimatedDuration: 7 }}
      ></Square>
      <Carousel isRTL={false} showArrows={false}>
        {generateReviews(reviewOrder)}
      </Carousel>
      <img src={arrows} alt="swipe" className="portfolio__swipeIcon" />
      <CTA className="portfolio__button" type="secondary" path={`/${CONTACT_PATH}`}>
        NEZÁVAZNÁ KONZULTACE
      </CTA>
    </motion.div>
  );
});

Portfolio.displayName = Portfolio.name;

export { Portfolio };
