import { RGB } from '../animations/gradients';

export const RGB_SECONDARY: RGB = { r: 5, g: 242, b: 199 };
export const RGB_SECONDARY_DARK: RGB = { r: 29, g: 168, b: 143 };

export const RGB_ACTION_LIGHT: RGB = { r: 245, g: 203, b: 87 };
export const RGB_ACTION: RGB = { r: 221, g: 176, b: 50 };
export const RGB_ACTION_DARK: RGB = { r: 197, g: 155, b: 37 };

export const RGB_BLUE_LIGHT: RGB = { r: 0, g: 155, b: 231 };

export const RGB_BLACK: RGB = { r: 0, g: 0, b: 0 };
export const RGB_WHITE: RGB = { r: 255, g: 255, b: 255 };
