import { useState, useEffect, FC } from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { Footer } from './layouts/footer/Footer';
import { Navigation } from './layouts/navigation/Navigation';

import { Home, HOME_PATH } from './components/Home/Home';
import { SitesApps, SITESAPPS_PATH } from './components/SitesApps/SitesApps';
import { Contact, CONTACT_PATH } from './components/Contact/Contact';
import { Portfolio, PORTFOLIO_PATH } from './components/Portfolio/Portfolio';
import { NotFound } from './components/NotFound/NotFound';

import './App.scss';
import { Calculation, CALCULATION_PATH } from './components/Calculation/Calculation';

export const App: FC = () => {
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <div className="app" style={{ visibility: isReady ? 'visible' : 'hidden' }}>
      <header className="app__header">
        <Navigation />
      </header>
      <div className="app__body">
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.key}>
            <Route path={`/${HOME_PATH}`} element={<Home />} />
            <Route path={`/${SITESAPPS_PATH}`} element={<SitesApps />} />
            <Route path={`/${PORTFOLIO_PATH}`} element={<Portfolio />} />
            <Route path={`/${CALCULATION_PATH}`} element={<Calculation />} />
            <Route path={`/${CONTACT_PATH}`} element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
      </div>
      <Footer />
    </div>
  );
};
