import { FC } from 'react';
import './ReviewRound.scss';

interface ReviewRoundProps {
  className?: string;
}

export const ReviewRound: FC<ReviewRoundProps> = ({ className }) => {
  return (
    <svg
      className={'review-round ' + className}
      width="356"
      height="356"
      viewBox="0 0 356 356"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="review-round__circle">
        <path
          // eslint-disable-next-line max-len
          d="M268.47 247.686C304.801 207.166 301.405 144.865 260.885 108.534C220.365 72.2031 158.065 75.599 121.733 116.119C85.4024 156.639 88.7982 218.939 129.318 255.271C169.838 291.602 232.139 288.206 268.47 247.686Z"
          stroke="#05F2C7"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
        <path
          // eslint-disable-next-line max-len
          d="M270.06 244.131C306.391 203.611 302.995 141.311 262.475 104.98C221.955 68.6484 159.655 72.0443 123.324 112.564C86.9925 153.085 90.3883 215.385 130.908 251.716C171.429 288.047 233.729 284.651 270.06 244.131Z"
          stroke="#05F2C7"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
        <path
          // eslint-disable-next-line max-len
          d="M262.61 239.26C296.398 201.576 293.24 143.636 255.556 109.847C217.871 76.0587 159.931 79.2169 126.143 116.901C92.3544 154.586 95.5126 212.526 133.197 246.314C170.881 280.103 228.821 276.944 262.61 239.26Z"
          stroke="#05F2C7"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
        <path
          // eslint-disable-next-line max-len
          d="M267.394 240.572C301.182 202.887 298.024 144.947 260.34 111.159C222.655 77.3702 164.715 80.5284 130.927 118.213C97.1383 155.897 100.296 213.837 137.981 247.626C175.665 281.414 233.605 278.256 267.394 240.572Z"
          stroke="#05F2C7"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
        <path
          // eslint-disable-next-line max-len
          d="M265.705 247.44C302.036 206.919 298.64 144.619 258.12 108.288C217.6 71.957 155.299 75.3529 118.968 115.873C82.6372 156.393 86.0331 218.693 126.553 255.024C167.073 291.356 229.374 287.96 265.705 247.44Z"
          stroke="#1DA88F"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
        <path
          // eslint-disable-next-line max-len
          d="M267.297 243.887C303.628 203.367 300.232 141.066 259.712 104.735C219.192 68.4043 156.892 71.8002 120.561 112.32C84.2295 152.84 87.6254 215.141 128.145 251.472C168.666 287.803 230.966 284.407 267.297 243.887Z"
          stroke="#1DA88F"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
        <path
          // eslint-disable-next-line max-len
          d="M259.847 239.016C293.635 201.332 290.477 143.391 252.793 109.603C215.108 75.8145 157.168 78.9728 123.38 116.657C89.5912 154.341 92.7494 212.282 130.434 246.07C168.118 279.859 226.058 276.7 259.847 239.016Z"
          stroke="#1DA88F"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
        <path
          // eslint-disable-next-line max-len
          d="M264.631 240.327C298.419 202.643 295.261 144.703 257.577 110.915C219.892 77.1261 161.952 80.2843 128.164 117.969C94.3753 155.653 97.5335 213.593 135.218 247.382C172.902 281.17 230.842 278.012 264.631 240.327Z"
          stroke="#1DA88F"
          strokeWidth="0.85"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};
