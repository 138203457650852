/* eslint-disable max-len */
import { FC } from 'react';
import './IconFunnel.scss';

interface IconFunnelProps {
  className?: string;
}

export const IconFunnel: FC<IconFunnelProps> = ({ className }) => {
  return (
    <svg
      className={'icon__funnel ' + className}
      width="135"
      height="140"
      viewBox="0 0 135 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g className="icon__funnel__funnel">
          <path
            d="M131.368 5.71627L100.065 49.0885C98.3152 51.5134 96.0809 53.5484 93.5035 55.0645C90.9261 56.5805 88.0618 57.5446 85.0923 57.8955V98.7866C85.0894 100.027 84.5957 101.216 83.719 102.094C82.8423 102.972 81.6539 103.467 80.4133 103.471H57.8048C56.5634 103.468 55.3735 102.974 54.4957 102.096C53.6178 101.218 53.1234 100.028 53.1206 98.7866V57.8955C50.1512 57.5439 47.2872 56.5796 44.7099 55.0636C42.1326 53.5476 39.8981 51.5129 38.148 49.0885L6.84475 5.71627C6.60578 5.38423 6.46301 4.99271 6.43213 4.58478C6.40125 4.17684 6.48346 3.76829 6.66973 3.40406C6.85599 3.03982 7.13909 2.734 7.4879 2.52023C7.8367 2.30646 8.2377 2.19302 8.6468 2.19238H129.566C129.975 2.19302 130.376 2.30646 130.725 2.52023C131.074 2.734 131.357 3.03982 131.543 3.40406C131.729 3.76829 131.812 4.17684 131.781 4.58478C131.75 4.99271 131.607 5.38423 131.368 5.71627V5.71627Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M127.495 7.58737L96.1918 50.9596C94.4422 53.3845 92.2079 55.4195 89.6305 56.9355C87.0531 58.4516 84.1889 59.4157 81.2193 59.7666V100.658C81.2165 101.898 80.7228 103.087 79.8461 103.965C78.9694 104.843 77.7809 105.338 76.5404 105.342H53.9319C52.6904 105.339 51.5006 104.845 50.6227 103.967C49.7449 103.089 49.2504 101.899 49.2476 100.658V59.7666C46.2782 59.415 43.4142 58.4507 40.8369 56.9347C38.2596 55.4187 36.0252 53.384 34.2751 50.9596L2.99319 7.58737C2.75422 7.25532 2.61144 6.8638 2.58057 6.45587C2.54969 6.04794 2.6319 5.63939 2.81816 5.27515C3.00443 4.91091 3.28753 4.60509 3.63633 4.39133C3.98514 4.17756 4.38614 4.06411 4.79524 4.06348H125.693C126.102 4.06411 126.503 4.17756 126.852 4.39133C127.201 4.60509 127.484 4.91091 127.67 5.27515C127.856 5.63939 127.939 6.04794 127.908 6.45587C127.877 6.8638 127.734 7.25532 127.495 7.58737V7.58737Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M128.089 10.8442L96.7856 54.2164C95.036 56.6413 92.8017 58.6763 90.2243 60.1924C87.6469 61.7085 84.7826 62.6725 81.8131 63.0235V103.915C81.8102 105.155 81.3165 106.344 80.4398 107.222C79.5631 108.099 78.3747 108.595 77.1341 108.599H54.5256C53.2841 108.596 52.0943 108.102 51.2165 107.224C50.3386 106.346 49.8442 105.156 49.8414 103.915V63.0235C46.872 62.6719 44.008 61.7075 41.4307 60.1915C38.8534 58.6755 36.6189 56.6409 34.8688 54.2164L3.58694 10.8442C3.34797 10.5122 3.20519 10.1206 3.17432 9.7127C3.14344 9.30477 3.22565 8.89622 3.41191 8.53199C3.59818 8.16775 3.88128 7.86193 4.23008 7.64816C4.57889 7.43439 4.97989 7.32095 5.38899 7.32031H126.287C126.696 7.32095 127.097 7.43439 127.446 7.64816C127.795 7.86193 128.078 8.16775 128.264 8.53199C128.45 8.89622 128.532 9.30477 128.501 9.7127C128.471 10.1206 128.328 10.5122 128.089 10.8442V10.8442Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M129.069 6.40142L96.9853 50.8698C95.1949 53.3534 92.9083 55.4381 90.2702 56.9919C87.6321 58.5456 84.7001 59.5346 81.6599 59.8961V101.825C81.6599 102.457 81.5354 103.082 81.2935 103.666C81.0517 104.25 80.6972 104.781 80.2503 105.228C79.8034 105.674 79.2728 106.029 78.689 106.271C78.1051 106.513 77.4793 106.637 76.8473 106.637H53.6452C52.3688 106.637 51.1447 106.13 50.2422 105.228C49.3396 104.325 48.8326 103.101 48.8326 101.825V59.8961C45.7937 59.5323 42.8633 58.5423 40.2265 56.9887C37.5896 55.4351 35.3034 53.3516 33.5125 50.8698L1.42848 6.40142C1.18457 6.06167 1.03867 5.66146 1.00669 5.24445C0.974704 4.82744 1.05787 4.40966 1.24712 4.03669C1.43636 3.66372 1.72441 3.34989 2.07983 3.12945C2.43525 2.90901 2.8444 2.79042 3.26261 2.78663H127.219C127.639 2.78557 128.052 2.90085 128.411 3.11972C128.77 3.33858 129.061 3.65249 129.253 4.0267C129.444 4.4009 129.528 4.82081 129.496 5.23993C129.464 5.65906 129.316 6.06105 129.069 6.40142V6.40142Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M116.819 14.4319L90.0819 51.505C88.5881 53.5755 86.6811 55.3137 84.4813 56.6097C82.2816 57.9056 79.8369 58.7311 77.3017 59.034V93.9895C77.2989 95.0495 76.8766 96.0652 76.1271 96.8147C75.3776 97.5642 74.3619 97.9865 73.3019 97.9893H53.9393C52.8784 97.9879 51.8613 97.5662 51.1106 96.8166C50.36 96.0669 49.9369 95.0504 49.9341 93.9895V59.034C47.399 58.7311 44.9543 57.9056 42.7545 56.6097C40.5547 55.3137 38.6477 53.5755 37.154 51.505L10.4173 14.4319C10.2129 14.1479 10.0906 13.813 10.0641 13.4641C10.0375 13.1151 10.1077 12.7656 10.2668 12.4539C10.426 12.1422 10.668 11.8805 10.9662 11.6974C11.2645 11.5143 11.6074 11.4169 11.9574 11.416H115.279C115.628 11.4169 115.971 11.5143 116.27 11.6974C116.568 11.8805 116.81 12.1422 116.969 12.4539C117.128 12.7656 117.198 13.1151 117.172 13.4641C117.145 13.813 117.023 14.1479 116.819 14.4319V14.4319Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M100.361 18.1165L84.656 55.1896C82.87 59.4033 80.1375 62.1411 77.1698 62.7186V97.6741C77.1698 99.8772 76.1003 101.679 74.8223 101.679H63.4432C62.1491 101.679 61.0957 99.8772 61.0957 97.6741V62.7186C58.1065 62.1143 55.374 59.4033 53.6094 55.1896L37.8615 18.1165C37.3268 16.8599 37.8615 15.106 38.7652 15.106H99.4574C100.366 15.106 100.896 16.8545 100.361 18.1165Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M92.3129 14.4319L76.6025 51.505C74.8164 55.7187 72.0893 58.4244 69.1162 59.034V93.9895C69.1162 96.1926 68.0467 97.9893 66.7687 97.9893H55.3949C54.1009 97.9893 53.0421 96.1926 53.0421 93.9895V59.034C50.0583 58.4244 47.3258 55.7187 45.5558 51.505L29.84 14.4319C29.3053 13.1753 29.84 11.416 30.7437 11.416H91.4092C92.3183 11.416 92.8477 13.1753 92.3129 14.4319Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M105.333 18.1165L89.6229 55.1896C87.8369 59.4033 85.1098 62.1411 82.1367 62.7186V97.6741C82.1367 99.8772 81.0672 101.679 79.7892 101.679H68.4154C67.1213 101.679 66.0626 99.8772 66.0626 97.6741V62.7186C63.0788 62.1143 60.3463 59.4033 58.5763 55.1896L42.8498 18.1165C42.3151 16.8599 42.8498 15.106 43.7535 15.106H104.43C105.339 15.106 105.868 16.8545 105.333 18.1165Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M131.802 12.4638L102.317 53.3174C100.671 55.5996 98.5696 57.5152 96.145 58.9429C93.7205 60.3706 91.0259 61.2791 88.2319 61.6111V100.112C88.2362 101.281 87.7773 102.404 86.9556 103.236C86.1339 104.068 85.0162 104.54 83.8471 104.55H62.5112C61.3421 104.547 60.2216 104.082 59.3949 103.255C58.5682 102.428 58.1025 101.308 58.0997 100.139V61.6378C55.3043 61.3001 52.6093 60.3863 50.185 58.9542C47.7606 57.5221 45.6597 55.6027 44.0148 53.3174L14.5296 12.4638C14.3043 12.1507 14.1697 11.7815 14.1407 11.3969C14.1117 11.0123 14.1894 10.6271 14.3652 10.2838C14.541 9.94049 14.8081 9.65233 15.1371 9.45106C15.4662 9.2498 15.8444 9.14322 16.2301 9.14307H130.128C130.512 9.14809 130.886 9.2582 131.212 9.46141C131.537 9.66462 131.8 9.95315 131.973 10.2956C132.145 10.638 132.221 11.0212 132.191 11.4035C132.16 11.7858 132.026 12.1525 131.802 12.4638V12.4638Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M133.151 4.52389L101.848 47.8961C100.098 50.321 97.8641 52.356 95.2867 53.8721C92.7093 55.3881 89.845 56.3522 86.8755 56.7032V97.5942C86.8726 98.8348 86.3789 100.024 85.5022 100.901C84.6255 101.779 83.4371 102.274 82.1965 102.278H59.588C58.3466 102.276 57.1567 101.781 56.2789 100.903C55.401 100.025 54.9066 98.8357 54.9038 97.5942V56.7032C51.9344 56.3516 49.0704 55.3872 46.4931 53.8712C43.9158 52.3552 41.6813 50.3206 39.9312 47.8961L8.62795 4.52389C8.38898 4.19184 8.24621 3.80033 8.21533 3.39239C8.18445 2.98446 8.26666 2.57591 8.45293 2.21167C8.63919 1.84744 8.92229 1.54162 9.2711 1.32785C9.6199 1.11408 10.0209 1.00064 10.43 1H131.349C131.758 1.00064 132.159 1.11408 132.508 1.32785C132.857 1.54162 133.14 1.84744 133.326 2.21167C133.513 2.57591 133.595 2.98446 133.564 3.39239C133.533 3.80033 133.39 4.19184 133.151 4.52389V4.52389Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M129.278 6.39499L97.975 49.7672C96.2254 52.1921 93.9911 54.2271 91.4137 55.7432C88.8363 57.2592 85.9721 58.2233 83.0025 58.5742V99.4653C82.9997 100.706 82.506 101.895 81.6293 102.773C80.7526 103.65 79.5641 104.145 78.3236 104.15H55.7151C54.4736 104.147 53.2838 103.652 52.4059 102.774C51.5281 101.897 51.0336 100.707 51.0308 99.4653V58.5742C48.0614 58.2227 45.1974 57.2583 42.6201 55.7423C40.0428 54.2263 37.8084 52.1917 36.0583 49.7672L4.77639 6.39499C4.53742 6.06294 4.39465 5.67142 4.36377 5.26349C4.33289 4.85555 4.4151 4.447 4.60137 4.08277C4.78763 3.71853 5.07073 3.41271 5.41954 3.19894C5.76834 2.98517 6.16934 2.87173 6.57844 2.87109H127.476C127.885 2.87173 128.286 2.98517 128.635 3.19894C128.984 3.41271 129.267 3.71853 129.453 4.08277C129.64 4.447 129.722 4.85555 129.691 5.26349C129.66 5.67142 129.517 6.06294 129.278 6.39499V6.39499Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M129.872 9.65182L98.5688 53.024C96.8192 55.449 94.5849 57.4839 92.0075 59C89.4301 60.5161 86.5658 61.4801 83.5963 61.8311V102.722C83.5934 103.963 83.0997 105.152 82.223 106.029C81.3463 106.907 80.1579 107.402 78.9173 107.406H56.3088C55.0673 107.404 53.8775 106.909 52.9997 106.031C52.1218 105.153 51.6274 103.964 51.6246 102.722V61.8311C48.6552 61.4795 45.7912 60.5152 43.2139 58.9992C40.6366 57.4831 38.4021 55.4485 36.652 53.024L5.37014 9.65182C5.13117 9.31977 4.9884 8.92826 4.95752 8.52032C4.92664 8.11239 5.00885 7.70384 5.19512 7.3396C5.38138 6.97537 5.66448 6.66955 6.01329 6.45578C6.36209 6.24201 6.76309 6.12857 7.17219 6.12793H128.07C128.479 6.12857 128.88 6.24201 129.229 6.45578C129.578 6.66955 129.861 6.97537 130.047 7.3396C130.233 7.70384 130.316 8.11239 130.285 8.52032C130.254 8.92826 130.111 9.31977 129.872 9.65182V9.65182Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M130.853 5.20904L98.7685 49.6775C96.9781 52.161 94.6915 54.2457 92.0534 55.7995C89.4153 57.3533 86.4833 58.3422 83.4431 58.7037V100.632C83.4431 101.264 83.3186 101.89 83.0767 102.474C82.8349 103.058 82.4804 103.588 82.0335 104.035C81.5866 104.482 81.0561 104.837 80.4722 105.078C79.8883 105.32 79.2625 105.445 78.6305 105.445H55.4284C54.152 105.445 52.9279 104.938 52.0254 104.035C51.1228 103.133 50.6158 101.909 50.6158 100.632V58.7037C47.5769 58.3399 44.6466 57.35 42.0097 55.7964C39.3728 54.2427 37.0866 52.1593 35.2957 49.6775L3.21168 5.20904C2.96778 4.86929 2.82187 4.46908 2.78989 4.05207C2.75791 3.63506 2.84108 3.21727 3.03032 2.8443C3.21956 2.47133 3.50761 2.15751 3.86303 1.93706C4.21846 1.71662 4.6276 1.59804 5.04582 1.59425H129.002C129.423 1.59319 129.835 1.70847 130.194 1.92734C130.553 2.1462 130.844 2.46011 131.036 2.83431C131.227 3.20852 131.312 3.62842 131.279 4.04755C131.247 4.46667 131.099 4.86867 130.853 5.20904V5.20904Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M118.602 13.2395L91.8651 50.3126C90.3713 52.3831 88.4643 54.1213 86.2645 55.4173C84.0648 56.7132 81.6201 57.5387 79.085 57.8416V92.7971C79.0821 93.8571 78.6598 94.8728 77.9103 95.6223C77.1608 96.3718 76.1451 96.7941 75.0851 96.7969H55.7225C54.6616 96.7955 53.6445 96.3738 52.8938 95.6242C52.1432 94.8745 51.7201 93.858 51.7173 92.7971V57.8416C49.1822 57.5387 46.7375 56.7132 44.5377 55.4173C42.3379 54.1213 40.4309 52.3831 38.9372 50.3126L12.2005 13.2395C11.9961 12.9555 11.8738 12.6206 11.8473 12.2717C11.8207 11.9227 11.8909 11.5732 12.05 11.2615C12.2092 10.9499 12.4512 10.6881 12.7494 10.505C13.0477 10.3219 13.3906 10.2245 13.7406 10.2236H117.062C117.412 10.2245 117.755 10.3219 118.053 10.505C118.351 10.6881 118.593 10.9499 118.752 11.2615C118.911 11.5732 118.982 11.9227 118.955 12.2717C118.928 12.6206 118.806 12.9555 118.602 13.2395V13.2395Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M102.144 16.9241L86.4392 53.9972C84.6532 58.2109 81.9207 60.9487 78.953 61.5262V96.4817C78.953 98.6848 77.8835 100.487 76.6055 100.487H65.2264C63.9323 100.487 62.8789 98.6848 62.8789 96.4817V61.5262C59.8897 60.922 57.1572 58.2109 55.3926 53.9972L39.6447 16.9241C39.11 15.6675 39.6447 13.9136 40.5484 13.9136H101.241C102.15 13.9136 102.679 15.6622 102.144 16.9241Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M94.0961 13.2395L78.3857 50.3126C76.5996 54.5263 73.8725 57.232 70.8994 57.8416V92.7971C70.8994 95.0002 69.8299 96.7969 68.5519 96.7969H57.1781C55.8841 96.7969 54.8253 95.0002 54.8253 92.7971V57.8416C51.8415 57.232 49.109 54.5263 47.3391 50.3126L31.6232 13.2395C31.0885 11.9829 31.6232 10.2236 32.5269 10.2236H93.1924C94.1015 10.2236 94.6309 11.9829 94.0961 13.2395Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M107.117 16.9241L91.4061 53.9972C89.6201 58.2109 86.893 60.9487 83.9199 61.5262V96.4817C83.9199 98.6848 82.8504 100.487 81.5724 100.487H70.1986C68.9045 100.487 67.8458 98.6848 67.8458 96.4817V61.5262C64.862 60.922 62.1295 58.2109 60.3595 53.9972L44.633 16.9241C44.0983 15.6675 44.633 13.9136 45.5367 13.9136H106.213C107.122 13.9136 107.651 15.6622 107.117 16.9241Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M133.585 11.2714L104.1 52.125C102.454 54.4072 100.353 56.3229 97.9282 57.7505C95.5037 59.1782 92.8091 60.0868 90.0151 60.4187V98.9195C90.0194 100.089 89.5605 101.212 88.7388 102.044C87.9171 102.875 86.7994 103.348 85.6303 103.358H64.2944C63.1253 103.355 62.0048 102.889 61.1781 102.063C60.3514 101.236 59.8857 100.115 59.8829 98.9462V60.4454C57.0875 60.1077 54.3925 59.194 51.9682 57.7618C49.5439 56.3297 47.4429 54.4103 45.798 52.125L16.3128 11.2714C16.0875 10.9583 15.9529 10.5892 15.9239 10.2045C15.8949 9.81992 15.9726 9.43475 16.1484 9.09143C16.3242 8.74811 16.5913 8.45995 16.9203 8.25868C17.2494 8.05741 17.6276 7.95084 18.0133 7.95068H131.911C132.295 7.9557 132.67 8.06581 132.995 8.26903C133.32 8.47224 133.583 8.76077 133.756 9.10321C133.928 9.44564 134.004 9.82887 133.974 10.2112C133.944 10.5935 133.809 10.9602 133.585 11.2714V11.2714Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
        </g>
        <g className="icon__funnel__dollar-3">
          <path
            d="M84.1966 114.712L84.7313 116.653C84.3969 116.767 84.0731 116.91 83.7635 117.081L83.1699 114.995L84.1966 114.712Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M85.4109 118.99L89.9187 134.898C89.5636 134.942 89.2044 134.942 88.8492 134.898L84.4805 119.476C84.7742 119.285 85.0858 119.122 85.4109 118.99V118.99Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M89.5527 137.491C89.9115 137.474 90.2688 137.433 90.6222 137.368L91.2104 139.443L90.2051 139.732L89.5527 137.491Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M86.86 113.962L87.4803 116.155C87.0913 116.151 86.7028 116.183 86.3199 116.251L85.7852 114.273L86.86 113.962Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M92.1855 136.944C92.5529 136.815 92.9103 136.659 93.255 136.479L93.8807 138.687L92.7791 138.997L92.1855 136.944Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M87.9513 124.972C87.6465 124.972 87.3631 124.972 87.0957 124.972L87.8711 127.715H88.0636L89.5822 127.752L88.7962 124.982C88.5288 124.972 88.2454 124.972 87.9513 124.972Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M88.1885 118.614L92.5573 134.046C92.2294 134.252 91.8797 134.42 91.5146 134.549L86.9961 118.598C87.3925 118.549 87.7936 118.555 88.1885 118.614V118.614Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M96.1476 129.32C96.4725 130.416 96.5001 131.578 96.2278 132.688C95.9757 133.752 95.4181 134.718 94.6236 135.469C94.1997 135.869 93.7229 136.209 93.2066 136.48C92.8619 136.661 92.5044 136.816 92.1371 136.945C91.9286 137.02 91.704 137.089 91.4741 137.153C91.1734 137.241 90.8681 137.313 90.5597 137.367C90.2063 137.432 89.849 137.473 89.4902 137.49C88.2987 137.562 87.1119 137.287 86.0733 136.699C84.519 135.815 83.421 134.335 82.7793 132.261L84.7738 131.459C85.0788 132.35 85.5836 133.161 86.2497 133.827C86.838 134.352 87.5597 134.703 88.3352 134.843C88.4766 134.872 88.6193 134.894 88.763 134.908C89.1181 134.951 89.4773 134.951 89.8324 134.908C90.1949 134.876 90.5532 134.808 90.9019 134.704C91.0832 134.66 91.2618 134.604 91.4366 134.539C91.8017 134.41 92.1514 134.242 92.4794 134.036C92.9585 133.754 93.3665 133.367 93.6718 132.902C93.9359 132.497 94.1065 132.039 94.1713 131.56C94.236 131.08 94.1933 130.593 94.0461 130.132C93.914 129.581 93.6249 129.081 93.214 128.691C92.803 128.3 92.2878 128.038 91.7307 127.935C91.395 127.871 91.0557 127.828 90.7147 127.806L89.918 124.988C90.4932 125.012 91.0664 125.071 91.6345 125.165C92.704 125.338 93.6982 125.824 94.4919 126.562C95.2855 127.299 95.843 128.255 96.0942 129.309L96.1476 129.32Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M93.1908 120.722L91.1535 121.508C90.8392 120.437 90.1402 119.521 89.191 118.936C88.8822 118.777 88.55 118.669 88.2071 118.615C87.8115 118.561 87.4103 118.561 87.0147 118.615C86.7019 118.656 86.3927 118.72 86.0896 118.807C85.8596 118.871 85.6404 118.946 85.4319 119.026C85.0998 119.154 84.781 119.313 84.48 119.502C84.0967 119.745 83.7673 120.063 83.5122 120.438C83.2703 120.785 83.1112 121.183 83.0473 121.601C82.9833 122.019 83.0161 122.446 83.1432 122.85C83.2204 123.203 83.3712 123.536 83.5859 123.828C83.8006 124.119 84.0743 124.361 84.3891 124.54C84.8967 124.761 85.4401 124.89 85.9933 124.919L86.774 127.678C85.9541 127.653 85.1413 127.518 84.357 127.277C83.5794 127.004 82.8813 126.543 82.3251 125.935C81.7343 125.292 81.3102 124.513 81.0898 123.668C80.8539 122.938 80.7716 122.168 80.848 121.405C80.9243 120.642 81.1578 119.903 81.5336 119.235C82.0573 118.318 82.8381 117.574 83.7795 117.096C84.0891 116.926 84.413 116.783 84.7474 116.668C84.9346 116.599 85.1378 116.535 85.341 116.476C85.6637 116.381 85.9924 116.308 86.3249 116.257C87.131 116.118 87.9557 116.129 88.7579 116.289C89.7908 116.505 90.7373 117.02 91.4797 117.77C92.2818 118.6 92.8692 119.613 93.1908 120.722Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M84.7458 116.669L85.2056 118.273C84.9271 118.368 84.657 118.486 84.3982 118.626L83.9062 116.894L84.7458 116.669Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M85.7552 120.246L89.4984 133.491C89.1965 133.528 88.8912 133.528 88.5893 133.491L84.9531 120.657C85.2043 120.491 85.4734 120.353 85.7552 120.246V120.246Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M89.1934 135.634C89.4875 135.618 89.7803 135.584 90.0703 135.533L90.5623 137.26L89.7227 137.501L89.1934 135.634Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M86.9671 116.037L87.5019 117.865C87.1729 117.854 86.8436 117.872 86.5179 117.919L86.0527 116.277L86.9671 116.037Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M91.3984 135.18C91.6999 135.071 91.993 134.941 92.2754 134.79L92.8101 136.629L91.8957 136.891L91.3984 135.18Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M87.8786 125.208H87.1621L87.8091 127.491H87.9696C88.4401 127.491 88.8626 127.491 89.2315 127.523L88.5792 125.213L87.8786 125.208Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M88.065 119.913L91.7065 132.747C91.4328 132.919 91.1404 133.059 90.8349 133.164L87.0918 119.876C87.4165 119.846 87.7436 119.859 88.065 119.913Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M94.7155 128.833C94.9871 129.744 95.0093 130.712 94.7796 131.635C94.5781 132.521 94.1229 133.329 93.4695 133.961C93.1172 134.295 92.7193 134.578 92.2878 134.8C92.0053 134.952 91.7122 135.082 91.4108 135.191C91.2343 135.255 91.0472 135.308 90.8761 135.362C90.705 135.415 90.3413 135.495 90.1114 135.544C89.8214 135.595 89.5285 135.629 89.2344 135.645C88.2411 135.703 87.2519 135.475 86.3843 134.987C85.0208 134.162 84.0373 132.832 83.6465 131.287L85.3042 130.624C85.5577 131.366 85.9785 132.039 86.534 132.592C87.0231 133.03 87.6251 133.323 88.2719 133.437C88.3882 133.464 88.5061 133.483 88.6248 133.496C88.9267 133.533 89.232 133.533 89.5339 133.496C89.8398 133.47 90.1425 133.415 90.4376 133.33C90.5807 133.291 90.7217 133.244 90.86 133.191C91.1655 133.085 91.4579 132.945 91.7316 132.774C92.1317 132.537 92.4719 132.211 92.7263 131.822C92.9457 131.485 93.0873 131.103 93.141 130.704C93.1946 130.306 93.1589 129.9 93.0364 129.517C92.9196 129.063 92.6811 128.649 92.3466 128.319C92.0015 127.999 91.5736 127.782 91.1114 127.694C90.8308 127.637 90.5467 127.6 90.2611 127.581L89.5981 125.239C90.0764 125.257 90.5532 125.303 91.0258 125.378C91.9235 125.516 92.7595 125.919 93.4265 126.536C94.0934 127.152 94.5609 127.954 94.7689 128.838L94.7155 128.833Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M92.2416 121.664L90.5465 122.321C90.2764 121.427 89.6837 120.665 88.8835 120.182C88.6273 120.049 88.3509 119.959 88.0653 119.915C87.737 119.872 87.4044 119.872 87.0761 119.915C86.8159 119.95 86.5586 120.004 86.3061 120.075C86.1245 120.128 85.946 120.19 85.7713 120.263C85.4939 120.365 85.2285 120.498 84.9799 120.658C84.6602 120.858 84.3853 121.122 84.1725 121.434C83.9715 121.723 83.8391 122.055 83.7854 122.403C83.7316 122.752 83.7579 123.108 83.8623 123.444C83.9271 123.739 84.0535 124.017 84.2332 124.259C84.4129 124.501 84.6419 124.703 84.9051 124.851C85.3334 125.036 85.7917 125.143 86.2579 125.166L86.9103 127.465C86.2268 127.443 85.5491 127.331 84.8944 127.134C84.248 126.905 83.6677 126.522 83.2046 126.016C82.7129 125.478 82.3588 124.828 82.1726 124.123C81.9756 123.516 81.9067 122.875 81.9702 122.24C82.0337 121.605 82.2282 120.99 82.5415 120.434C82.9796 119.673 83.63 119.056 84.4131 118.658C84.6719 118.518 84.942 118.4 85.2206 118.305C85.3774 118.248 85.5432 118.195 85.7179 118.145C85.9803 118.053 86.2482 117.978 86.52 117.92C86.8397 117.867 87.1636 117.842 87.4878 117.846C87.8471 117.842 88.2058 117.878 88.5573 117.953C89.4187 118.132 90.2088 118.559 90.8299 119.182C91.496 119.882 91.9807 120.734 92.2416 121.664Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M85.9798 113.52L86.5145 115.461C86.1801 115.575 85.8563 115.718 85.5467 115.888L84.9531 113.803L85.9798 113.52Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M87.1941 117.797L91.7019 133.706C91.3468 133.749 90.9876 133.749 90.6324 133.706L86.2637 118.284C86.5574 118.092 86.869 117.929 87.1941 117.797V117.797Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M91.3359 136.299C91.6947 136.281 92.052 136.24 92.4054 136.176L92.9936 138.251L91.9883 138.539L91.3359 136.299Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M88.6432 112.77L89.2635 114.962C88.8745 114.958 88.486 114.991 88.1031 115.059L87.5684 113.08L88.6432 112.77Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M93.9688 135.751C94.3361 135.622 94.6935 135.467 95.0382 135.286L95.6639 137.495L94.5623 137.805L93.9688 135.751Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M89.7345 123.779C89.4297 123.779 89.1463 123.779 88.8789 123.779L89.6543 126.522H89.8468L91.3654 126.56L90.5794 123.79C90.312 123.779 90.0286 123.779 89.7345 123.779Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M89.9717 117.421L94.3405 132.854C94.0126 133.059 93.6629 133.228 93.2978 133.356L88.7793 117.405C89.1757 117.357 89.5768 117.362 89.9717 117.421V117.421Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M97.9308 128.127C98.2557 129.223 98.2833 130.386 98.011 131.496C97.7589 132.56 97.2013 133.526 96.4068 134.277C95.9829 134.677 95.5061 135.017 94.9898 135.287C94.6451 135.468 94.2876 135.624 93.9203 135.753C93.7118 135.827 93.4872 135.897 93.2573 135.961C92.9566 136.049 92.6513 136.12 92.3429 136.175C91.9895 136.239 91.6322 136.281 91.2734 136.298C90.0819 136.37 88.8951 136.095 87.8565 135.507C86.3022 134.622 85.2042 133.143 84.5625 131.068L86.5571 130.266C86.862 131.158 87.3668 131.968 88.0329 132.635C88.6212 133.159 89.3429 133.511 90.1184 133.651C90.2598 133.68 90.4025 133.701 90.5462 133.715C90.9013 133.759 91.2605 133.759 91.6156 133.715C91.9781 133.684 92.3364 133.616 92.6851 133.512C92.8664 133.467 93.045 133.412 93.2198 133.346C93.5849 133.218 93.9346 133.049 94.2626 132.844C94.7417 132.562 95.1497 132.174 95.455 131.71C95.7191 131.305 95.8897 130.846 95.9545 130.367C96.0192 129.888 95.9765 129.401 95.8293 128.94C95.6972 128.389 95.4081 127.888 94.9972 127.498C94.5862 127.108 94.071 126.846 93.5139 126.742C93.1782 126.679 92.8389 126.636 92.4979 126.614L91.7012 123.796C92.2764 123.82 92.8496 123.879 93.4177 123.972C94.4872 124.146 95.4814 124.632 96.2751 125.369C97.0687 126.107 97.6262 127.063 97.8774 128.117L97.9308 128.127Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M94.974 119.529L92.9367 120.315C92.6224 119.245 91.9234 118.329 90.9742 117.743C90.6654 117.585 90.3332 117.476 89.9903 117.422C89.5947 117.368 89.1935 117.368 88.7979 117.422C88.4851 117.463 88.1759 117.528 87.8728 117.615C87.6428 117.679 87.4236 117.754 87.2151 117.834C86.883 117.961 86.5642 118.121 86.2632 118.31C85.8799 118.552 85.5505 118.871 85.2954 119.246C85.0535 119.593 84.8944 119.991 84.8305 120.409C84.7665 120.827 84.7993 121.254 84.9264 121.657C85.0036 122.011 85.1544 122.344 85.3691 122.635C85.5838 122.926 85.8575 123.169 86.1723 123.347C86.6799 123.569 87.2233 123.698 87.7765 123.727L88.5572 126.486C87.7373 126.461 86.9245 126.326 86.1403 126.085C85.3626 125.812 84.6645 125.351 84.1083 124.743C83.5175 124.099 83.0934 123.321 82.873 122.476C82.6371 121.746 82.5548 120.976 82.6312 120.213C82.7075 119.45 82.941 118.711 83.3169 118.043C83.8405 117.126 84.6213 116.382 85.5627 115.904C85.8724 115.733 86.1962 115.59 86.5306 115.476C86.7178 115.406 86.921 115.342 87.1242 115.283C87.4469 115.189 87.7756 115.115 88.1081 115.064C88.9142 114.926 89.7389 114.937 90.5411 115.096C91.574 115.312 92.5206 115.827 93.2629 116.577C94.065 117.407 94.6524 118.421 94.974 119.529Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M86.529 115.477L86.9889 117.081C86.7103 117.176 86.4402 117.294 86.1814 117.434L85.6895 115.701L86.529 115.477Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M87.5384 119.053L91.2816 132.299C90.9797 132.336 90.6744 132.336 90.3725 132.299L86.7363 119.465C86.9875 119.298 87.2566 119.16 87.5384 119.053V119.053Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M90.9766 134.442C91.2707 134.426 91.5635 134.392 91.8535 134.34L92.3455 136.068L91.506 136.308L90.9766 134.442Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M88.7503 114.844L89.2851 116.673C88.9562 116.661 88.6269 116.679 88.3012 116.727L87.8359 115.085L88.7503 114.844Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M93.1816 133.988C93.4831 133.879 93.7762 133.748 94.0586 133.597L94.5933 135.437L93.6789 135.699L93.1816 133.988Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M89.6619 124.016H88.9453L89.5923 126.299H89.7528C90.2233 126.299 90.6458 126.299 91.0147 126.331L90.3624 124.021L89.6619 124.016Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M89.8482 118.721L93.4897 131.554C93.216 131.726 92.9236 131.866 92.6181 131.972L88.875 118.683C89.1997 118.654 89.5268 118.667 89.8482 118.721Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M96.4987 127.64C96.7704 128.552 96.7925 129.519 96.5628 130.442C96.3613 131.328 95.9061 132.137 95.2527 132.768C94.9004 133.103 94.5025 133.385 94.071 133.608C93.7885 133.759 93.4954 133.89 93.194 133.998C93.0175 134.062 92.8304 134.116 92.6593 134.169C92.4882 134.223 92.1245 134.303 91.8946 134.351C91.6046 134.403 91.3117 134.436 91.0176 134.453C90.0243 134.511 89.0351 134.282 88.1675 133.795C86.804 132.969 85.8205 131.64 85.4297 130.095L87.0874 129.432C87.3409 130.173 87.7617 130.847 88.3172 131.399C88.8063 131.838 89.4083 132.13 90.0551 132.244C90.1714 132.271 90.2893 132.291 90.4081 132.303C90.7099 132.341 91.0152 132.341 91.3171 132.303C91.623 132.278 91.9257 132.222 92.2208 132.137C92.3639 132.098 92.5049 132.052 92.6432 131.998C92.9487 131.893 93.2411 131.753 93.5149 131.581C93.9149 131.344 94.2551 131.019 94.5095 130.629C94.7289 130.292 94.8705 129.911 94.9242 129.512C94.9778 129.113 94.9421 128.708 94.8196 128.325C94.7028 127.87 94.4643 127.456 94.1298 127.127C93.7847 126.807 93.3568 126.59 92.8946 126.501C92.614 126.445 92.3299 126.407 92.0443 126.389L91.3813 124.047C91.8596 124.064 92.3364 124.111 92.809 124.186C93.7067 124.324 94.5427 124.727 95.2097 125.343C95.8766 125.96 96.3441 126.762 96.5521 127.646L96.4987 127.64Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M94.0248 120.471L92.3297 121.129C92.0596 120.234 91.4669 119.472 90.6667 118.99C90.4105 118.856 90.1341 118.766 89.8485 118.723C89.5202 118.679 89.1876 118.679 88.8593 118.723C88.5991 118.758 88.3418 118.811 88.0893 118.883C87.9077 118.935 87.7292 118.998 87.5545 119.07C87.2771 119.173 87.0117 119.305 86.7631 119.466C86.4434 119.666 86.1686 119.93 85.9557 120.241C85.7547 120.531 85.6223 120.862 85.5686 121.211C85.5148 121.559 85.5411 121.915 85.6455 122.252C85.7103 122.547 85.8367 122.824 86.0164 123.067C86.1961 123.309 86.4251 123.511 86.6883 123.658C87.1166 123.844 87.5749 123.951 88.0411 123.974L88.6935 126.273C88.01 126.251 87.3323 126.139 86.6776 125.942C86.0312 125.713 85.4509 125.329 84.9878 124.824C84.4961 124.285 84.142 123.636 83.9558 122.931C83.7588 122.324 83.6899 121.683 83.7534 121.047C83.8169 120.412 84.0114 119.797 84.3247 119.241C84.7628 118.48 85.4132 117.863 86.1963 117.466C86.4551 117.326 86.7252 117.208 87.0038 117.113C87.1606 117.056 87.3264 117.003 87.5011 116.953C87.7635 116.861 88.0314 116.786 88.3032 116.728C88.6229 116.675 88.9468 116.649 89.271 116.653C89.6303 116.65 89.989 116.686 90.3405 116.76C91.2019 116.939 91.992 117.367 92.6131 117.99C93.2792 118.689 93.7639 119.541 94.0248 120.471Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
        </g>
        <g className="icon__funnel__dollar-2">
          <path
            d="M51.8499 112.865L51.2778 114.501C50.985 114.419 50.6865 114.358 50.3848 114.319L51.0051 112.571L51.8499 112.865Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M50.5773 116.479L45.8824 129.874C45.609 129.726 45.3541 129.547 45.123 129.339L49.6897 116.351C49.9894 116.361 50.287 116.404 50.5773 116.479V116.479Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M44.3428 131.501C44.5988 131.666 44.8669 131.811 45.1449 131.935L44.5246 133.689L43.6797 133.389L44.3428 131.501Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M54.1012 113.642L53.4488 115.487C53.1775 115.292 52.8894 115.122 52.5879 114.979L53.1761 113.316L54.1012 113.642Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M46.4612 132.42C46.7784 132.51 47.1019 132.577 47.429 132.618L46.7766 134.495L45.8516 134.169L46.4612 132.42Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M49.4152 121.925C49.1959 121.775 49.0034 121.636 48.8109 121.497L48.0195 123.802L48.1586 123.898C48.5436 124.187 48.8965 124.433 49.228 124.679L50.0515 122.342L49.4152 121.925Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M52.7205 117.571L48.1485 130.57C47.8168 130.555 47.4882 130.499 47.1699 130.405L51.8916 116.972C52.1945 117.132 52.4735 117.334 52.7205 117.571V117.571Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M53.0237 129.04C52.7115 129.97 52.1579 130.8 51.4195 131.446C50.7194 132.067 49.8534 132.47 48.9277 132.607C48.431 132.676 47.9271 132.676 47.4304 132.607C47.1033 132.565 46.7798 132.499 46.4625 132.409C46.2754 132.361 46.0829 132.296 45.8904 132.227C45.6371 132.139 45.389 132.038 45.1471 131.922C44.8692 131.798 44.601 131.653 44.345 131.489C43.4733 130.949 42.7754 130.169 42.3344 129.243C41.6745 127.738 41.6342 126.034 42.2221 124.5L44.0188 124.922C43.7898 125.7 43.744 126.52 43.8851 127.318C44.0378 127.976 44.369 128.579 44.8423 129.061C44.9332 129.152 45.0188 129.238 45.1097 129.318C45.3407 129.526 45.5956 129.705 45.869 129.853C46.143 130.011 46.4312 130.144 46.7299 130.248C46.8796 130.302 47.024 130.345 47.1737 130.382C47.4921 130.477 47.8206 130.532 48.1523 130.548C48.629 130.585 49.108 130.514 49.5533 130.339C49.9337 130.182 50.2747 129.943 50.552 129.639C50.8293 129.335 51.036 128.973 51.1575 128.58C51.3293 128.128 51.3755 127.639 51.2912 127.163C51.1885 126.704 50.9651 126.282 50.6442 125.938C50.4412 125.725 50.2232 125.527 49.9918 125.345L50.826 122.971C51.219 123.273 51.5939 123.598 51.9489 123.944C52.6138 124.595 53.0703 125.429 53.2607 126.34C53.4511 127.251 53.3668 128.198 53.0184 129.061L53.0237 129.04Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M55.1992 121.534L53.3651 121.08C53.6714 120.166 53.6276 119.172 53.2421 118.288C53.1022 118.023 52.9218 117.782 52.7074 117.572C52.4604 117.335 52.1814 117.133 51.8785 116.973C51.6402 116.849 51.3936 116.742 51.1406 116.652C50.9515 116.584 50.7587 116.527 50.5631 116.481C50.268 116.401 49.9649 116.354 49.6594 116.342C49.2716 116.321 48.8835 116.381 48.5204 116.519C48.1788 116.642 47.87 116.842 47.6179 117.103C47.3657 117.365 47.1769 117.681 47.0659 118.026C46.9463 118.314 46.8878 118.623 46.8943 118.934C46.9007 119.245 46.9719 119.552 47.1034 119.834C47.3562 120.243 47.6783 120.605 48.0552 120.903L47.2371 123.229C46.6758 122.803 46.1725 122.305 45.7398 121.748C45.3297 121.172 45.0688 120.503 44.9805 119.802C44.8854 119.057 44.9736 118.301 45.2372 117.599C45.4333 116.97 45.758 116.388 46.1906 115.891C46.6233 115.394 47.1544 114.992 47.7504 114.711C48.5719 114.332 49.4855 114.198 50.3813 114.326C50.683 114.365 50.9815 114.425 51.2743 114.508C51.4418 114.551 51.6201 114.604 51.809 114.668C52.0814 114.762 52.3476 114.872 52.6058 115C52.9073 115.143 53.1954 115.313 53.4667 115.508C53.7668 115.718 54.0445 115.958 54.2955 116.224C54.9148 116.888 55.3256 117.719 55.4773 118.615C55.6214 119.596 55.526 120.598 55.1992 121.534Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M51.27 114.507L50.7941 115.87C50.5512 115.798 50.3025 115.748 50.0508 115.721L50.5855 114.255L51.27 114.507Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M50.2161 117.518L46.2911 128.673C46.0629 128.548 45.851 128.395 45.6602 128.218L49.4621 117.385C49.7175 117.402 49.9702 117.447 50.2161 117.518V117.518Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M45.0189 130.03C45.2316 130.165 45.4531 130.284 45.682 130.389L45.1473 131.848L44.4414 131.602L45.0189 130.03Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M53.1439 115.167L52.6091 116.707C52.3834 116.543 52.1436 116.4 51.8926 116.279L52.3792 114.894L53.1439 115.167Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M46.7791 130.796C47.0458 130.869 47.3174 130.923 47.5919 130.957L47.0571 132.508L46.2871 132.235L46.7791 130.796Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M49.2428 122.055C49.061 121.932 48.9006 121.814 48.7402 121.696L48.0664 123.621L48.1787 123.702C48.4995 123.942 48.7936 124.156 49.045 124.354L49.7294 122.407C49.5797 122.29 49.4139 122.199 49.2428 122.055Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M51.9965 118.453L48.1892 129.255C47.912 129.241 47.6374 129.194 47.3711 129.116L51.3067 117.918C51.5608 118.063 51.7931 118.243 51.9965 118.453Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M52.2539 127.982C51.9919 128.756 51.5286 129.446 50.9117 129.982C50.3312 130.499 49.6118 130.836 48.8423 130.95C48.4293 131.011 48.0094 131.011 47.5964 130.95C47.3219 130.916 47.0503 130.862 46.7836 130.789C46.6285 130.746 46.4734 130.693 46.313 130.639C46.1526 130.586 45.8852 130.474 45.6874 130.383C45.4585 130.278 45.237 130.159 45.0243 130.024C44.2979 129.575 43.7167 128.925 43.3506 128.153C42.7987 126.9 42.7641 125.48 43.2543 124.201L44.7516 124.554C44.5606 125.201 44.5222 125.884 44.6393 126.549C44.7675 127.097 45.0452 127.598 45.4414 127.998C45.5104 128.073 45.5836 128.145 45.6606 128.212C45.8514 128.389 46.0634 128.541 46.2916 128.666C46.5195 128.799 46.7594 128.91 47.0082 128.998L47.3771 129.11C47.6435 129.188 47.918 129.235 48.1953 129.249C48.5922 129.28 48.991 129.22 49.361 129.073C49.6779 128.943 49.9621 128.745 50.1933 128.493C50.4244 128.24 50.5967 127.94 50.6978 127.613C50.8425 127.236 50.8795 126.827 50.8048 126.431C50.7318 126.031 50.5463 125.66 50.27 125.362C50.1027 125.184 49.9241 125.018 49.7353 124.864L50.4304 122.891C50.7582 123.141 51.0706 123.411 51.3662 123.699C51.8967 124.248 52.2666 124.932 52.4357 125.677C52.5955 126.447 52.5325 127.246 52.2539 127.982V127.982Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M54.0593 121.728L52.5407 121.348C52.7957 120.587 52.7595 119.758 52.4391 119.022C52.3234 118.802 52.1736 118.602 51.9953 118.429C51.7894 118.232 51.5572 118.065 51.3055 117.932C51.106 117.825 50.8986 117.734 50.6852 117.659C50.5248 117.605 50.3643 117.557 50.2093 117.52C49.9628 117.454 49.7101 117.414 49.4553 117.402C49.1311 117.386 48.8072 117.437 48.5035 117.552C48.2194 117.655 47.9628 117.822 47.7533 118.04C47.5439 118.258 47.387 118.521 47.295 118.808C47.1946 119.047 47.1455 119.304 47.1511 119.563C47.1566 119.822 47.2165 120.077 47.327 120.311C47.5359 120.653 47.806 120.954 48.1238 121.199L47.4393 123.14C46.9694 122.787 46.5502 122.371 46.1934 121.905C45.8522 121.431 45.6336 120.879 45.5571 120.3C45.4766 119.681 45.55 119.051 45.771 118.466C45.9332 117.942 46.2023 117.457 46.5612 117.042C46.9201 116.627 47.3611 116.291 47.8564 116.055C48.5412 115.739 49.3029 115.629 50.0488 115.739C50.3005 115.767 50.5493 115.817 50.7921 115.889C50.9374 115.924 51.0803 115.969 51.2199 116.023C51.446 116.1 51.6674 116.191 51.883 116.295C52.134 116.416 52.3738 116.559 52.5995 116.723C52.8521 116.895 53.0836 117.096 53.2893 117.322C53.8061 117.871 54.1484 118.562 54.2732 119.306C54.3978 120.119 54.3244 120.95 54.0593 121.728V121.728Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M53.6331 111.673L53.061 113.309C52.7682 113.226 52.4697 113.165 52.168 113.127L52.7883 111.378L53.6331 111.673Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M52.3605 115.287L47.6656 128.682C47.3922 128.534 47.1373 128.355 46.9062 128.147L51.4729 115.158C51.7726 115.168 52.0702 115.211 52.3605 115.287V115.287Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M46.126 130.309C46.382 130.474 46.6501 130.618 46.9281 130.742L46.3078 132.496L45.4629 132.197L46.126 130.309Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M55.8844 112.45L55.232 114.295C54.9607 114.1 54.6726 113.93 54.3711 113.787L54.9593 112.124L55.8844 112.45Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M48.2444 131.228C48.5616 131.318 48.8851 131.384 49.2122 131.426L48.5599 133.303L47.6348 132.977L48.2444 131.228Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M51.1984 120.732C50.9791 120.583 50.7866 120.444 50.5941 120.305L49.8027 122.609L49.9418 122.706C50.3268 122.994 50.6797 123.24 51.0112 123.486L51.8347 121.15L51.1984 120.732Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M54.5037 116.379L49.9317 129.378C49.6 129.362 49.2714 129.307 48.9531 129.212L53.6748 115.78C53.9777 115.94 54.2567 116.141 54.5037 116.379V116.379Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M54.8069 127.849C54.4947 128.779 53.9411 129.61 53.2027 130.256C52.5026 130.877 51.6366 131.28 50.7109 131.416C50.2142 131.486 49.7103 131.486 49.2136 131.416C48.8865 131.374 48.563 131.308 48.2457 131.218C48.0586 131.17 47.8661 131.106 47.6736 131.037C47.4203 130.949 47.1722 130.847 46.9303 130.732C46.6524 130.608 46.3842 130.463 46.1282 130.299C45.2565 129.758 44.5586 128.979 44.1176 128.053C43.4578 126.548 43.4174 124.844 44.0053 123.31L45.802 123.732C45.573 124.51 45.5272 125.33 45.6683 126.128C45.821 126.786 46.1522 127.389 46.6255 127.871C46.7164 127.962 46.802 128.047 46.8929 128.128C47.1239 128.335 47.3788 128.515 47.6522 128.662C47.9262 128.821 48.2144 128.953 48.5131 129.058C48.6628 129.111 48.8072 129.154 48.9569 129.192C49.2753 129.286 49.6038 129.342 49.9355 129.357C50.4122 129.394 50.8912 129.323 51.3365 129.149C51.7169 128.992 52.0579 128.753 52.3352 128.448C52.6125 128.144 52.8192 127.783 52.9407 127.39C53.1125 126.938 53.1587 126.448 53.0744 125.973C52.9717 125.514 52.7483 125.091 52.4274 124.748C52.2244 124.535 52.0064 124.336 51.775 124.154L52.6092 121.78C53.0022 122.082 53.3771 122.407 53.7321 122.753C54.397 123.405 54.8536 124.239 55.0439 125.15C55.2343 126.061 55.15 127.008 54.8016 127.871L54.8069 127.849Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M56.9824 120.342L55.1483 119.887C55.4546 118.974 55.4108 117.979 55.0253 117.096C54.8854 116.831 54.705 116.589 54.4906 116.38C54.2436 116.142 53.9646 115.941 53.6617 115.781C53.4234 115.657 53.1768 115.55 52.9238 115.46C52.7347 115.392 52.5419 115.335 52.3463 115.289C52.0512 115.209 51.7481 115.162 51.4426 115.15C51.0548 115.128 50.6667 115.188 50.3036 115.326C49.962 115.449 49.6532 115.65 49.4011 115.911C49.1489 116.172 48.9601 116.488 48.8491 116.834C48.7295 117.121 48.671 117.431 48.6775 117.742C48.6839 118.053 48.7551 118.359 48.8866 118.641C49.1394 119.05 49.4615 119.412 49.8384 119.711L49.0203 122.037C48.459 121.61 47.9557 121.112 47.523 120.556C47.1129 119.98 46.852 119.311 46.7637 118.609C46.6686 117.865 46.7568 117.109 47.0204 116.406C47.2165 115.777 47.5412 115.196 47.9738 114.699C48.4065 114.202 48.9376 113.8 49.5336 113.519C50.3551 113.139 51.2687 113.006 52.1645 113.134C52.4662 113.172 52.7647 113.233 53.0575 113.316C53.225 113.358 53.4033 113.412 53.5922 113.476C53.8646 113.569 54.1308 113.68 54.389 113.807C54.6905 113.951 54.9786 114.121 55.2499 114.315C55.55 114.525 55.8277 114.765 56.0787 115.032C56.698 115.696 57.1088 116.527 57.2605 117.422C57.4046 118.404 57.3092 119.405 56.9824 120.342Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M53.0532 113.314L52.5773 114.678C52.3344 114.606 52.0857 114.556 51.834 114.528L52.3687 113.063L53.0532 113.314Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M51.9993 116.326L48.0743 127.481C47.8461 127.356 47.6342 127.203 47.4434 127.026L51.2453 116.192C51.5007 116.21 51.7534 116.255 51.9993 116.326V116.326Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M46.8021 128.838C47.0148 128.972 47.2363 129.092 47.4652 129.196L46.9305 130.656L46.2246 130.41L46.8021 128.838Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M54.9271 113.974L54.3923 115.514C54.1666 115.351 53.9268 115.208 53.6758 115.087L54.1624 113.702L54.9271 113.974Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M48.5623 129.604C48.829 129.677 49.1006 129.731 49.3751 129.764L48.8403 131.315L48.0703 131.042L48.5623 129.604Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M51.026 120.862C50.8442 120.739 50.6838 120.622 50.5234 120.504L49.8496 122.429L49.9619 122.509C50.2827 122.75 50.5768 122.964 50.8282 123.162L51.5126 121.215C51.3629 121.097 51.1971 121.007 51.026 120.862Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M53.7797 117.261L49.9724 128.062C49.6952 128.048 49.4206 128.002 49.1543 127.923L53.0899 116.726C53.344 116.871 53.5763 117.051 53.7797 117.261Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M54.0371 126.789C53.7751 127.563 53.3118 128.254 52.6949 128.789C52.1144 129.307 51.395 129.643 50.6255 129.757C50.2125 129.819 49.7926 129.819 49.3796 129.757C49.1051 129.723 48.8335 129.67 48.5668 129.597C48.4117 129.554 48.2566 129.5 48.0962 129.447C47.9358 129.394 47.6684 129.281 47.4706 129.19C47.2417 129.086 47.0202 128.966 46.8075 128.832C46.0811 128.382 45.4999 127.732 45.1338 126.961C44.5819 125.707 44.5473 124.287 45.0375 123.009L46.5348 123.362C46.3438 124.009 46.3054 124.692 46.4225 125.356C46.5507 125.904 46.8284 126.406 47.2246 126.805C47.2936 126.881 47.3668 126.952 47.4438 127.019C47.6346 127.196 47.8466 127.349 48.0748 127.474C48.3027 127.607 48.5426 127.718 48.7914 127.805L49.1603 127.918C49.4267 127.996 49.7012 128.043 49.9785 128.057C50.3754 128.088 50.7742 128.027 51.1442 127.88C51.4611 127.751 51.7453 127.553 51.9765 127.3C52.2076 127.048 52.3799 126.747 52.481 126.42C52.6257 126.044 52.6627 125.635 52.588 125.239C52.515 124.839 52.3295 124.468 52.0532 124.169C51.8859 123.992 51.7073 123.826 51.5185 123.672L52.2137 121.699C52.5414 121.949 52.8538 122.219 53.1494 122.506C53.6799 123.056 54.0498 123.74 54.2189 124.485C54.3787 125.255 54.3157 126.054 54.0371 126.789V126.789Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M55.8425 120.536L54.3239 120.156C54.5789 119.395 54.5427 118.566 54.2223 117.83C54.1066 117.61 53.9568 117.41 53.7785 117.236C53.5726 117.04 53.3405 116.872 53.0887 116.739C52.8892 116.632 52.6818 116.541 52.4684 116.466C52.308 116.413 52.1475 116.365 51.9925 116.327C51.746 116.262 51.4933 116.222 51.2385 116.21C50.9143 116.194 50.5904 116.245 50.2867 116.359C50.0026 116.463 49.746 116.63 49.5365 116.847C49.3271 117.065 49.1703 117.328 49.0782 117.616C48.9778 117.855 48.9287 118.112 48.9343 118.371C48.9398 118.63 48.9997 118.885 49.1102 119.119C49.3191 119.461 49.5892 119.762 49.907 120.006L49.2225 121.947C48.7526 121.595 48.3334 121.179 47.9766 120.712C47.6354 120.238 47.4168 119.687 47.3403 119.108C47.2598 118.488 47.3332 117.858 47.5542 117.274C47.7164 116.75 47.9855 116.265 48.3444 115.85C48.7033 115.435 49.1443 115.098 49.6396 114.862C50.3244 114.547 51.0861 114.437 51.832 114.547C52.0837 114.575 52.3325 114.625 52.5753 114.696C52.7206 114.732 52.8635 114.777 53.0031 114.83C53.2292 114.908 53.4507 114.999 53.6662 115.103C53.9172 115.224 54.157 115.367 54.3827 115.531C54.6353 115.703 54.8668 115.904 55.0725 116.13C55.5893 116.679 55.9316 117.369 56.0564 118.113C56.181 118.926 56.1076 119.757 55.8425 120.536V120.536Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
        </g>
        <g className="icon__funnel__dollar-1">
          <path
            d="M75.1109 111.434L74.1109 112.337C73.9442 112.173 73.7654 112.021 73.5762 111.883L74.6456 110.915L75.1109 111.434Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M72.8933 113.428L64.6905 120.834C64.5587 120.63 64.4528 120.411 64.375 120.181L72.3265 113C72.5291 113.123 72.719 113.267 72.8933 113.428V113.428Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M63.0462 121.378C63.1599 121.587 63.2885 121.787 63.4312 121.977L62.3617 122.945L61.8965 122.41L63.0462 121.378Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M76.3609 112.807L75.2272 113.828C75.1147 113.594 74.9804 113.37 74.8262 113.16L75.8475 112.24L76.3609 112.807Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M64.1446 122.797C64.3306 122.976 64.5289 123.143 64.7382 123.294L63.5992 124.337L63.0645 123.77L64.1446 122.797Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M70.0746 116.722C69.9784 116.54 69.8982 116.369 69.8179 116.188L68.4062 117.466C68.4263 117.504 68.4441 117.543 68.4597 117.583C68.6201 117.92 68.7645 118.23 68.8875 118.503L70.3152 117.209C70.2404 117.064 70.1602 116.893 70.0746 116.722Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M73.9507 114.972L65.9885 122.159C65.7681 122.024 65.5636 121.864 65.3789 121.683L73.6084 114.255C73.7547 114.477 73.8698 114.719 73.9507 114.972V114.972Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M69.8975 122.929C69.3377 123.448 68.649 123.808 67.903 123.972C67.1929 124.14 66.4488 124.096 65.7641 123.843C65.3986 123.714 65.053 123.534 64.7374 123.309C64.5281 123.157 64.3298 122.991 64.1438 122.811C64.0369 122.711 63.9299 122.599 63.823 122.474C63.6819 122.322 63.5498 122.161 63.4273 121.993C63.2846 121.803 63.156 121.603 63.0423 121.394C62.645 120.702 62.4591 119.908 62.5075 119.111C62.6164 117.837 63.2236 116.657 64.1973 115.828L65.2668 116.785C64.8184 117.236 64.4815 117.785 64.2828 118.389C64.1386 118.894 64.1386 119.429 64.2828 119.934C64.3032 120.029 64.3319 120.122 64.3684 120.212C64.4462 120.442 64.5521 120.661 64.6839 120.865C64.8098 121.077 64.9567 121.276 65.1224 121.458C65.2079 121.549 65.2881 121.635 65.3737 121.715C65.5584 121.896 65.7628 122.056 65.9833 122.191C66.2961 122.394 66.6507 122.524 67.0207 122.571C67.3412 122.609 67.6663 122.575 67.9724 122.473C68.2784 122.37 68.5579 122.201 68.7906 121.977C69.0841 121.723 69.2993 121.391 69.4109 121.02C69.5158 120.657 69.5158 120.271 69.4109 119.908C69.3525 119.687 69.2775 119.47 69.1863 119.261L70.6408 117.945C70.7954 118.294 70.9311 118.651 71.0472 119.015C71.2475 119.714 71.2475 120.455 71.0472 121.154C70.8383 121.842 70.4399 122.457 69.8975 122.929V122.929Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M74.1721 118.603L73.1026 117.614C73.6554 117.105 73.9954 116.406 74.0545 115.657C74.0597 115.425 74.0272 115.194 73.9582 114.972C73.8774 114.719 73.7622 114.478 73.616 114.256C73.4976 114.081 73.3672 113.915 73.2256 113.758C73.1237 113.641 73.0148 113.531 72.8994 113.427C72.7251 113.266 72.5353 113.123 72.3326 112.999C72.0751 112.84 71.7874 112.736 71.4877 112.694C71.208 112.652 70.9223 112.674 70.6525 112.759C70.3828 112.845 70.1362 112.991 69.9317 113.186C69.7427 113.338 69.5875 113.528 69.4761 113.744C69.3647 113.959 69.2995 114.195 69.2846 114.437C69.3059 114.806 69.3928 115.169 69.5413 115.507L68.1136 116.796C67.8888 116.296 67.7305 115.77 67.643 115.229C67.5775 114.686 67.6473 114.135 67.8462 113.625C68.0575 113.078 68.3989 112.592 68.8408 112.208C69.2093 111.853 69.6466 111.577 70.1258 111.398C70.6049 111.218 71.1158 111.139 71.6268 111.165C72.3297 111.21 73.0043 111.459 73.5678 111.881C73.7571 112.02 73.9358 112.171 74.1026 112.336C74.1988 112.427 74.2951 112.528 74.3913 112.635C74.5434 112.805 74.6845 112.983 74.8138 113.17C74.968 113.38 75.1023 113.604 75.2148 113.839C75.3428 114.095 75.4432 114.363 75.5143 114.641C75.6912 115.323 75.6633 116.043 75.4341 116.71C75.1738 117.434 74.7406 118.084 74.1721 118.603V118.603Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M74.1017 112.337L73.2675 113.107C73.1281 112.97 72.9779 112.845 72.8184 112.733L73.7114 111.92L74.1017 112.337Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M72.2619 114.004L65.428 120.169C65.3222 120.001 65.2343 119.821 65.166 119.634L71.7914 113.64C71.9621 113.742 72.1201 113.864 72.2619 114.004V114.004Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M64.0602 120.625C64.1557 120.797 64.2629 120.964 64.381 121.122L63.488 121.924L63.0977 121.496L64.0602 120.625Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M75.1414 113.487L74.2217 114.337C74.1264 114.149 74.0136 113.97 73.8848 113.802L74.735 113.038L75.1414 113.487Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M64.9761 121.807C65.1309 121.955 65.2952 122.093 65.468 122.219L64.5215 123.074L64.0938 122.604L64.9761 121.807Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M69.9098 116.743L69.7013 116.315L68.5195 117.384L68.5677 117.486C68.7013 117.769 68.819 118.021 68.9206 118.251L70.113 117.181C70.0489 117.031 69.9847 116.887 69.9098 116.743Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M73.1518 115.289L66.5211 121.278C66.3288 121.164 66.1496 121.03 65.9863 120.877L72.8576 114.712C72.9811 114.89 73.08 115.085 73.1518 115.289V115.289Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M69.7597 121.914C69.2934 122.348 68.7191 122.649 68.0967 122.785C67.507 122.922 66.8903 122.885 66.3213 122.678C66.0167 122.564 65.7289 122.41 65.4658 122.218C65.293 122.092 65.1286 121.955 64.9738 121.807C64.8829 121.716 64.792 121.625 64.7064 121.523C64.5868 121.397 64.476 121.263 64.3749 121.122C64.2567 120.964 64.1496 120.798 64.0541 120.625C63.7241 120.047 63.5681 119.386 63.6049 118.721C63.6963 117.661 64.2015 116.679 65.0112 115.989L65.8989 116.786C65.53 117.158 65.2503 117.61 65.0808 118.106C64.9633 118.528 64.9633 118.973 65.0808 119.395C65.0808 119.475 65.1235 119.55 65.1503 119.625C65.2186 119.812 65.3064 119.991 65.4123 120.16C65.5187 120.335 65.6423 120.5 65.7813 120.652C65.8467 120.727 65.9164 120.798 65.9898 120.866C66.153 121.018 66.3323 121.153 66.5245 121.267C66.7861 121.435 67.0821 121.543 67.3908 121.582C67.6574 121.612 67.9273 121.583 68.1814 121.497C68.4356 121.411 68.6677 121.27 68.8613 121.085C69.1086 120.884 69.294 120.617 69.3961 120.315C69.4847 120.013 69.4847 119.692 69.3961 119.39C69.3443 119.208 69.2818 119.029 69.2089 118.855L70.4174 117.785C70.5485 118.079 70.661 118.381 70.7543 118.689C70.9182 119.264 70.9182 119.873 70.7543 120.448C70.5668 121.02 70.2221 121.528 69.7597 121.914V121.914Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M73.328 118.314L72.4296 117.491C72.8808 117.071 73.1624 116.5 73.221 115.886C73.2263 115.693 73.1992 115.499 73.1408 115.314C73.0742 115.103 72.9789 114.901 72.8574 114.715C72.7604 114.569 72.6513 114.432 72.5312 114.304C72.4458 114.207 72.3547 114.116 72.2585 114.031C72.1164 113.893 71.9584 113.773 71.788 113.673C71.5722 113.541 71.3322 113.453 71.0821 113.416C70.8493 113.381 70.6116 113.4 70.3872 113.471C70.1629 113.543 69.9579 113.665 69.7881 113.828C69.6311 113.953 69.5024 114.111 69.4103 114.289C69.3182 114.468 69.2647 114.664 69.2533 114.865C69.2704 115.177 69.3428 115.483 69.4672 115.769L68.2801 116.838C68.0926 116.42 67.9596 115.98 67.8844 115.528C67.8309 115.073 67.8897 114.613 68.0555 114.186C68.2291 113.731 68.5141 113.326 68.8844 113.01C69.1897 112.711 69.5537 112.48 69.9532 112.33C70.3527 112.18 70.779 112.115 71.2051 112.138C71.7865 112.178 72.3437 112.386 72.8093 112.737C72.9689 112.849 73.1191 112.974 73.2585 113.111C73.3387 113.191 73.4242 113.272 73.5045 113.362C73.6298 113.501 73.7459 113.647 73.852 113.801C73.9809 113.968 74.0937 114.147 74.1889 114.336C74.2927 114.548 74.3751 114.77 74.4349 114.999C74.5833 115.569 74.5591 116.17 74.3654 116.726C74.1535 117.332 73.7974 117.877 73.328 118.314V118.314Z"
            stroke="#1DA88F"
            strokeWidth="0.3"
          />
          <path
            d="M76.8941 110.241L75.8941 111.145C75.7274 110.981 75.5486 110.829 75.3594 110.691L76.4288 109.723L76.8941 110.241Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M74.6765 112.235L66.4737 119.641C66.3419 119.438 66.236 119.219 66.1582 118.989L74.1097 111.808C74.3123 111.931 74.5022 112.074 74.6765 112.235V112.235Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M64.8294 120.186C64.9431 120.394 65.0717 120.594 65.2144 120.784L64.1449 121.752L63.6797 121.218L64.8294 120.186Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M78.1441 111.615L77.0104 112.636C76.8979 112.401 76.7636 112.177 76.6094 111.968L77.6307 111.048L78.1441 111.615Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M65.9278 121.604C66.1138 121.784 66.3121 121.95 66.5214 122.102L65.3824 123.145L64.8477 122.578L65.9278 121.604Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M71.8578 115.53C71.7616 115.348 71.6814 115.177 71.6011 114.995L70.1895 116.273C70.2095 116.311 70.2273 116.351 70.2429 116.391C70.4033 116.728 70.5477 117.038 70.6707 117.311L72.0985 116.016C72.0236 115.872 71.9434 115.701 71.8578 115.53Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M75.7339 113.78L67.7717 120.966C67.5513 120.831 67.3468 120.671 67.1621 120.49L75.3917 113.063C75.5379 113.285 75.653 113.526 75.7339 113.78V113.78Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M71.6807 121.737C71.1209 122.256 70.4322 122.616 69.6862 122.779C68.9761 122.948 68.232 122.903 67.5473 122.651C67.1818 122.521 66.8362 122.341 66.5206 122.116C66.3113 121.964 66.113 121.798 65.927 121.619C65.8201 121.519 65.7131 121.407 65.6062 121.282C65.4651 121.129 65.333 120.969 65.2105 120.801C65.0678 120.611 64.9392 120.41 64.8255 120.202C64.4282 119.509 64.2423 118.715 64.2907 117.919C64.3996 116.644 65.0068 115.464 65.9805 114.635L67.05 115.592C66.6016 116.043 66.2647 116.593 66.066 117.197C65.9218 117.702 65.9218 118.237 66.066 118.742C66.0864 118.837 66.1151 118.93 66.1516 119.02C66.2294 119.25 66.3353 119.469 66.4671 119.672C66.593 119.884 66.7399 120.083 66.9056 120.266C66.9911 120.357 67.0713 120.442 67.1569 120.523C67.3416 120.704 67.546 120.863 67.7665 120.999C68.0793 121.202 68.4339 121.331 68.8039 121.378C69.1244 121.416 69.4495 121.383 69.7556 121.28C70.0616 121.178 70.3411 121.008 70.5738 120.785C70.8673 120.531 71.0825 120.199 71.1941 119.828C71.299 119.464 71.299 119.079 71.1941 118.715C71.1357 118.494 71.0607 118.278 70.9696 118.068L72.424 116.753C72.5786 117.102 72.7143 117.459 72.8304 117.822C73.0307 118.521 73.0307 119.262 72.8304 119.961C72.6215 120.649 72.2231 121.264 71.6807 121.737V121.737Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M75.9553 117.411L74.8858 116.421C75.4386 115.912 75.7786 115.213 75.8377 114.464C75.8429 114.232 75.8104 114.001 75.7414 113.78C75.6606 113.527 75.5454 113.285 75.3992 113.063C75.2808 112.889 75.1504 112.722 75.0088 112.566C74.9069 112.449 74.798 112.338 74.6826 112.234C74.5083 112.073 74.3185 111.93 74.1158 111.807C73.8583 111.648 73.5706 111.544 73.2709 111.502C72.9912 111.459 72.7055 111.482 72.4357 111.567C72.166 111.652 71.9194 111.798 71.7149 111.994C71.5259 112.146 71.3707 112.336 71.2593 112.551C71.1479 112.767 71.0827 113.003 71.0678 113.245C71.0891 113.614 71.176 113.976 71.3245 114.315L69.8968 115.603C69.672 115.104 69.5138 114.577 69.4262 114.037C69.3607 113.493 69.4305 112.942 69.6294 112.432C69.8407 111.886 70.1821 111.4 70.624 111.015C70.9925 110.66 71.4298 110.385 71.909 110.205C72.3881 110.026 72.899 109.947 73.41 109.973C74.1129 110.017 74.7875 110.266 75.351 110.689C75.5403 110.827 75.719 110.979 75.8858 111.144C75.982 111.235 76.0783 111.336 76.1745 111.443C76.3266 111.612 76.4677 111.791 76.597 111.978C76.7512 112.188 76.8855 112.411 76.998 112.646C77.126 112.902 77.2264 113.171 77.2975 113.448C77.4744 114.131 77.4465 114.851 77.2173 115.518C76.957 116.242 76.5238 116.892 75.9553 117.411V117.411Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M75.8849 111.145L75.0507 111.915C74.9113 111.778 74.7611 111.653 74.6016 111.54L75.4946 110.728L75.8849 111.145Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M74.0451 112.811L67.2112 118.977C67.1054 118.808 67.0175 118.629 66.9492 118.442L73.5746 112.448C73.7453 112.55 73.9033 112.672 74.0451 112.811V112.811Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M65.8434 119.432C65.9389 119.605 66.0461 119.771 66.1642 119.929L65.2712 120.732L64.8809 120.304L65.8434 119.432Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M76.9246 112.294L76.0049 113.145C75.9096 112.956 75.7968 112.777 75.668 112.61L76.5182 111.845L76.9246 112.294Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M66.7593 120.615C66.9141 120.763 67.0784 120.9 67.2512 121.026L66.3047 121.882L65.877 121.411L66.7593 120.615Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
            strokeMiterlimit="10"
          />
          <path
            d="M71.693 115.55L71.4845 115.123L70.3027 116.192L70.3509 116.294C70.4845 116.577 70.6022 116.828 70.7038 117.058L71.8962 115.989C71.8321 115.839 71.7679 115.695 71.693 115.55Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M74.935 114.097L68.3043 120.086C68.112 119.972 67.9328 119.838 67.7695 119.685L74.6409 113.52C74.7643 113.698 74.8632 113.892 74.935 114.097V114.097Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M71.5429 120.721C71.0766 121.156 70.5023 121.457 69.8799 121.593C69.2902 121.73 68.6735 121.693 68.1045 121.486C67.7999 121.372 67.5121 121.217 67.249 121.026C67.0762 120.9 66.9118 120.762 66.757 120.614C66.6661 120.523 66.5752 120.432 66.4896 120.331C66.37 120.205 66.2592 120.071 66.1581 119.93C66.04 119.772 65.9328 119.605 65.8373 119.433C65.5073 118.854 65.3513 118.193 65.3881 117.529C65.4795 116.469 65.9847 115.487 66.7944 114.796L67.6821 115.593C67.3132 115.966 67.0335 116.418 66.864 116.914C66.7465 117.335 66.7465 117.781 66.864 118.203C66.864 118.283 66.9067 118.358 66.9335 118.433C67.0018 118.62 67.0896 118.799 67.1955 118.967C67.3019 119.143 67.4255 119.308 67.5645 119.459C67.6299 119.534 67.6996 119.606 67.773 119.673C67.9362 119.826 68.1155 119.96 68.3077 120.074C68.5693 120.243 68.8653 120.351 69.174 120.39C69.4406 120.42 69.7105 120.391 69.9646 120.305C70.2188 120.219 70.4509 120.078 70.6445 119.892C70.8918 119.691 71.0772 119.424 71.1793 119.122C71.2679 118.82 71.2679 118.499 71.1793 118.197C71.1275 118.015 71.065 117.837 70.9921 117.663L72.2006 116.593C72.3317 116.887 72.4442 117.189 72.5375 117.497C72.7014 118.072 72.7014 118.681 72.5375 119.256C72.35 119.828 72.0053 120.336 71.5429 120.721V120.721Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
          <path
            d="M75.1112 117.122L74.2128 116.298C74.664 115.878 74.9456 115.308 75.0042 114.694C75.0095 114.5 74.9824 114.307 74.924 114.122C74.8574 113.91 74.7621 113.709 74.6406 113.523C74.5436 113.377 74.4345 113.239 74.3144 113.111C74.229 113.015 74.1379 112.924 74.0417 112.839C73.8996 112.701 73.7417 112.581 73.5712 112.48C73.3554 112.348 73.1154 112.261 72.8653 112.224C72.6325 112.189 72.3948 112.208 72.1705 112.279C71.9461 112.35 71.7411 112.472 71.5713 112.635C71.4143 112.761 71.2856 112.918 71.1935 113.097C71.1014 113.276 71.0479 113.472 71.0365 113.673C71.0536 113.984 71.126 114.29 71.2504 114.576L70.0633 115.646C69.8758 115.228 69.7429 114.788 69.6676 114.336C69.6141 113.881 69.6729 113.42 69.8387 112.994C70.0123 112.538 70.2973 112.134 70.6676 111.817C70.9729 111.519 71.3369 111.288 71.7364 111.138C72.1359 110.988 72.5622 110.922 72.9883 110.946C73.5697 110.986 74.1269 111.194 74.5925 111.544C74.7521 111.657 74.9023 111.782 75.0417 111.919C75.1219 111.999 75.2074 112.079 75.2877 112.17C75.413 112.309 75.5291 112.455 75.6352 112.609C75.7641 112.776 75.8769 112.955 75.9721 113.143C76.0759 113.356 76.1583 113.578 76.2181 113.806C76.3665 114.376 76.3423 114.977 76.1486 115.534C75.9367 116.139 75.5806 116.684 75.1112 117.122V117.122Z"
            stroke="#05F2C7"
            strokeWidth="0.3"
          />
        </g>
      </g>
    </svg>
  );
};
